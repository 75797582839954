export const IS_FORBIDDEN = "IS_FORBIDDEN";

const initialState = {
  forbidden: false
};

const isForbidden = (state = initialState, action) => {
  switch (action.type) {
    case IS_FORBIDDEN:
      return {
        ...state,
        forbidden: true
      };
    default:
      return state;
  }
};
export default isForbidden;
