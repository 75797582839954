import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from "reactstrap";
import { Formik } from "formik";
import { startRemoteAssistanceSession } from "../../../common/api/remote-assistances";
import { getDeviceTypesConfig } from "../../../common/api/device_types";
import { getDevicesByID } from "common/api/devices";
import { getOperatorConfig } from "common/api/operators-config";
import { versionRemoteAssitance } from "API_routes";

interface IPropsInterface {
  history: { push(data: any): void };
  isOpen: boolean;
  toggle(): void;
  operatorDeviceId: number | string;
  operatorProfileId?: string | null | string;
  authorized: boolean;
}

interface IStateInterface {
  serviceDeskId: number | string | null;
  status: boolean;
  version: number | string | null;
  serviceDeskStatus: boolean;
}

const initialValues: { service_desk_id: number | string } = {
  service_desk_id: ""
};

const validate = values => {
  const errors: any = {};

  if (!values.service_desk_id) {
    errors.service_desk_id = "Required";
  }

  return errors;
};

class RemoteAssistanceModal extends Component<
  IPropsInterface,
  IStateInterface
> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      serviceDeskId: null,
      status: true,
      version: null,
      serviceDeskStatus: false
    };
  }

  async componentDidMount() {
    const { isOpen } = this.props;

    const fetchData = async () => {
      const result = await getOperatorConfig();
      if (result.status === 200) {
        this.setState({
          serviceDeskStatus: result.data.data.serviceDesk.status
        });
        if (!result.data.data.serviceDesk.status && isOpen) {
          this._onStartSession();
        }
      }
    };
    fetchData();
    return this._getDeviceTypesConfig();
  }

  async componentDidUpdate(prevProps) {
    const { operatorProfileId }: IPropsInterface = this.props;

    if (operatorProfileId !== prevProps.operatorProfileId) {
      return this._getDeviceTypesConfig();
    }
  }

  _getDeviceTypesConfig = async () => {
    const { operatorProfileId }: IPropsInterface = this.props;

    if (!operatorProfileId) {
      return;
    }
    const {
      data: {
        data: { remoteSupport: resultGetDeviceTypesConfig }
      }
    } = await getDeviceTypesConfig(operatorProfileId);
    // if (!serviceDeskStatus && isOpen) {
    //   this._onStartSession();
    // }
    this.setState({
      status: resultGetDeviceTypesConfig?.status
    });
  };

  compareVersion2 = (ver1: string, ver2: string) => {
    ver1 = ver1
      .split(".")
      .map(s => s.padStart(10))
      .join(".");
    ver2 = ver2
      .split(".")
      .map(s => s.padStart(10))
      .join(".");
    return ver1 >= ver2;
  };

  _onRedirectToRemoteAssistanceSession = async (id: number) => {
    const { history, operatorDeviceId }: IPropsInterface = this.props;
    const dataDevicesByID = await getDevicesByID(operatorDeviceId);
    if (dataDevicesByID.status === 200) {
      const version = dataDevicesByID?.data?.resource?.device_control_version;
      //const defaultNewVersion = "2.0.49.0";
      if (
        version &&
        versionRemoteAssitance &&
        this.compareVersion2(version, versionRemoteAssitance)
      ) {
        history.push(`/remote-assistance-actions/${id}`);
      } else {
        history.push(`/remote-assistance/${id}`);
      }
    }
  };

  _onStartSession: any = async () => {
    const { operatorDeviceId, authorized }: IPropsInterface = this.props;
    const { serviceDeskId } = this.state;

    if (operatorDeviceId) {
      try {
        const result: {
          data: { resource: { id: number } };
          status: number;
        } = await startRemoteAssistanceSession({
          operator_device_id: operatorDeviceId,
          service_desk_id: serviceDeskId,
          authorized: authorized
        });

        if (result && result.status === 201) {
          //toggle();
          this._onRedirectToRemoteAssistanceSession(result.data.resource.id);
        }
      } catch (e) {
        //console.info(e);
      }
    }
  };

  render(): React.ReactNode {
    const { isOpen, toggle }: IPropsInterface = this.props;
    const { status, serviceDeskId, serviceDeskStatus } = this.state;

    if (!serviceDeskStatus) {
      return (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          backdrop="static"
          keyboard={false}
          unmountOnClose={true}
        >
          <div className="d-flex justify-content-center m-3 in">
            <Spinner color="primary" />
          </div>
        </Modal>
      );
    }

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        keyboard={false}
        unmountOnClose={true}
      >
        <ModalHeader toggle={toggle}>
          {status
            ? "Link Remote Session to Service Desk"
            : "This device cannot be accessed because its device profile has Remote Support Disabled"}
        </ModalHeader>
        {status && (
          <ModalBody>
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={this._onStartSession}
              render={({
                errors,
                touched,
                handleChange,
                handleSubmit
              }): any => (
                <Row>
                  <Col lg="12">
                    <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                      <FormGroup>
                        <Label for="service_desk_id">Service Desk ID</Label>
                        <Input
                          type="text"
                          name="service_desk_id"
                          id="service_desk_id"
                          placeholder="Enter Service Desk ID"
                          required
                          valid={
                            touched.service_desk_id && !errors.service_desk_id
                          }
                          invalid={
                            touched.service_desk_id && !!errors.service_desk_id
                          }
                          onChange={(event: any): any => {
                            handleChange(event);
                            this.setState({
                              serviceDeskId: event.target.value
                            });
                          }}
                        />
                        <FormFeedback>{errors.service_desk_id}</FormFeedback>
                      </FormGroup>
                      <ModalFooter className="pl-0 pr-0">
                        <Button color="danger" onClick={toggle}>
                          Cancel
                        </Button>
                        <Button
                          disabled={!serviceDeskId}
                          type="submit"
                          color="primary"
                        >
                          Link
                        </Button>
                        <Button
                          color="purple"
                          className="buttonPurple"
                          onClick={this._onStartSession}
                        >
                          Skip
                        </Button>
                      </ModalFooter>
                    </Form>
                  </Col>
                </Row>
              )}
            />
          </ModalBody>
        )}
      </Modal>
    );
  }
}

export default withRouter<any, any>(RemoteAssistanceModal);
