import React from "react";
import style from "./DetailsWithImage.module.scss";

const DetailsWithImage = ({ data }: any) => {
  const renderData = (): JSX.Element =>
    data.map((key: { value: any; name: string }) =>
      key.name === "Picture" ? (
        ""
      ) : (
        <div key={key.name} className={style.detailRow}>
          <div className="d-flex mb-3">
            <strong style={{ textTransform: "capitalize" }} className="mr-1">
              {key.name}:
            </strong>
            {Array.isArray(key.value) ? (
              <div style={{ display: "flex", flexFlow: "column" }}>
                {key.value.map((el: { build: string }, i: number) => (
                  <i key={i}>
                    <strong>Build:</strong> {el.build}
                  </i>
                ))}
              </div>
            ) : (
              <span>{key.value}</span>
            )}
          </div>
        </div>
      )
    );
  const image = data.filter((obj: { name: string }) => {
    return obj.name === "Picture";
  });

  return (
    <div className={style.wrapper}>
      {image[0].value && (
        <div key={image[0].name ?? null} className={style.imageRow}>
          <div className="d-flex mb-12">
            <figure>
              <img src={image[0].value ?? null} alt="Profile" />
            </figure>
          </div>
        </div>
      )}
      <div className={style.contentWrapper}>{renderData()}</div>
    </div>
  );
};

export default DetailsWithImage;
