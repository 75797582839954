import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import moment, { Moment } from "moment";
import TimeAgo from "react-timeago";
import { get } from "lodash";
import {
  Button,
  CardBody,
  Card,
  Row,
  Form,
  Alert,
  Input,
  FormFeedback,
  FormGroup
} from "reactstrap";
import {
  IDevicesInterface,
  IFilteredDevices,
  IFilteredRemoteAssistances,
  IRemoteAssistanceInterface,
  ISingleDeviceInterface,
  ISingleRemoteAssistanceInterface
} from "../../../common/interfaces";
import { Formik } from "formik";
import FormInputs from "../../Forms/FormInputs";
import RemoteAssistanceModal from "../../ModalComponent/RemoteAssistanceModal";
import style from "./RemoteAssistancesTable.module.scss";
import AppTable from "../AppTable";
import {
  renderPagination,
  generateColButtons
} from "../../../common/helper/helper";
import { getOperatorConfig } from "common/api/operators-config";

interface IPropsInterface {
  onFilter(data: { orderBy: string; direction?: string }): void;
  remoteAssistances: IRemoteAssistanceInterface;
  findPairingCode: any;
  getFilteredDevices(data: IFilteredDevices): IDevicesInterface;
  getFilteredRemoteAssistances(
    data: IFilteredRemoteAssistances
  ): IRemoteAssistanceInterface;
  devices: IDevicesInterface;
  history: { push(data: any): void };
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
}

interface IStateInterface {
  selectedRows: Array<number>;
  selectAll: boolean;
  selectedOption: Array<{ value: number; label: string }>;
  romSelectedOption: Array<{ value: number; label: string }>;
  remoteAssistanceAuthorized: boolean;
  showRemoteAssistanceModal: boolean;
  deviceId: number | null;
  loading1: boolean;
  loading2: boolean;
  responseParingCode: string;
  operatorProfileId: string | null;
  valuesForFilter: any;
  serviceDeskStatus: any;
}

const initialDevicesfilteredValues: any = {
  current_page: "",
  orderBy: "",
  direction: "",
  id: "",
  mac_address: "",
  provision_id: ""
};

const devicesfilteredValues: any = {
  current_page: "",
  orderBy: "",
  direction: "",
  id: "",
  mac_address: "",
  provision_id: ""
};

const initialRemoteSessionFilteredValues: any = {
  current_page: "",
  orderBy: "",
  direction: "",
  id: "",
  operator_device_id: "",
  sequential_id: "",
  service_desk_id: "",
  created_by: "",
  created_at: "",
  closed: ""
};

const remoteSessionFilteredValues: any = {
  current_page: "",
  orderBy: "",
  direction: "",
  id: "",
  operator_device_id: "",
  sequential_id: "",
  service_desk_id: "",
  created_by: "",
  created_at: "",
  closed: ""
};

class RemoteAssistancesTable extends Component<
  IPropsInterface,
  IStateInterface
> {
  private timer;
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      selectedRows: [],
      selectAll: false,
      selectedOption: [],
      romSelectedOption: [],
      remoteAssistanceAuthorized: false,
      showRemoteAssistanceModal: false,
      deviceId: null,
      loading1: false,
      loading2: false,
      responseParingCode: "",
      operatorProfileId: "",
      valuesForFilter: {},
      serviceDeskStatus: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IPropsInterface): void {
    const { remoteAssistances }: IPropsInterface = this.props;

    if (remoteAssistances && nextProps.remoteAssistances) {
      const {
        remoteAssistances: { current_page }
      }: IPropsInterface = this.props;
      const {
        remoteAssistances: { current_page: nextCurrentPage }
      }: IPropsInterface = nextProps;

      if (current_page && nextCurrentPage) {
        this.setState({
          selectedRows: [],
          selectAll: false
        });
      }
    }
  }

  componentWillUnmount() {
    setTimeout(this.timer);
  }

  componentDidMount() {
    this._deviceFilteringOnSubmit("");
    const fetchData = async () => {
      const result = await getOperatorConfig();
      if (result.status === 200) {
        this.setState({
          serviceDeskStatus: result.data.data.serviceDesk.status
        });
      }
    };
    fetchData();
  }

  _toggleRemoteAssistanceModal: any = (): void => {
    const { showRemoteAssistanceModal }: IStateInterface = this.state;
    this.setState({
      showRemoteAssistanceModal: !showRemoteAssistanceModal
    });
  };

  _onChange: any = (page: number) => {
    const { valuesForFilter }: any = this.state;
    this._remoteSessionFilteringOnSubmit(valuesForFilter, page);
  };

  _onRedirectToActionDetails: any = (id: number) => {
    const { history }: any = this.props;
    history.push(`/remote-assistance/${id}`);
  };

  _renderResultRows = (): React.ReactNode => {
    const { remoteAssistances }: IPropsInterface = this.props;
    const { serviceDeskStatus } = this.state;

    if (get(remoteAssistances, "data")) {
      const { data }: IRemoteAssistanceInterface = remoteAssistances;
      return data.map((item: ISingleRemoteAssistanceInterface) => {
        const {
          id,
          service_desk_id,
          created_at,
          closed,
          sequential_id,
          operator_device
        }: ISingleRemoteAssistanceInterface = item;

        const datetime: string = moment(
          created_at ? parseInt(created_at) * 1000 : ""
        ).format("MM DD YYYY HH:mm");

        return (
          <tr key={id}>
            <td>{sequential_id}</td>
            <td>{get(operator_device, "provision_id")}</td>
            <td>{get(operator_device, "serial_number")}</td>
            <td>{get(operator_device, "mac_address")}</td>
            {serviceDeskStatus && <td>{service_desk_id}</td>}
            <td>
              <TimeAgo date={datetime} live={false} />
            </td>
            <td>{closed ? "Closed" : "Open"}</td>
            {generateColButtons({
              id,
              onRedirectToDetails: this._onRedirectToActionDetails,
              closed,
              remoteAssitance: true
            })}
          </tr>
        );
      });
    }
  };

  _renderDeviceResultRows = (): React.ReactNode => {
    const { devices }: IPropsInterface = this.props;

    if (get(devices, "data")) {
      const { data }: IDevicesInterface = devices;

      return data.map((item: ISingleDeviceInterface) => {
        const {
          id,
          mac_address,
          provision_id,
          uid,
          serial_number,
          operator_device_type_id
        }: ISingleDeviceInterface = item;

        return (
          <tr key={id}>
            <td>{mac_address}</td>
            <td>{serial_number}</td>
            <td>{uid}</td>
            <td>{provision_id}</td>
            <td className={style.buttonsWrapper}>
              <Button
                className="buttonPurple icon-btn material-icons desktop_windows"
                color="purple"
                size="sm"
                disabled={localStorage.getItem("acr") === "OPR_VIEWER"}
                onClick={(e: any): any => {
                  this.setState({
                    deviceId: id,
                    operatorProfileId: operator_device_type_id
                  });
                  this._toggleRemoteAssistanceModal();
                }}
              />
            </td>
          </tr>
        );
      });
    }
  };

  _renderEmtpyCells = (numberOfCells: number): React.ReactNode => {
    return Array.apply(
      null,
      Array(numberOfCells)
    ).map((item: any, index: any) => <td key={index} />);
  };

  _renderTableFieldNames = (): Array<JSX.Element> => {
    const { serviceDeskStatus } = this.state;
    const { onFilter, filtering }: IPropsInterface = this.props;
    const keys: Array<{ name: string; id: string }> = [
      { name: "Session Id", id: "session_id" },
      { name: "Provision id", id: "Provision_id" },
      { name: "Serial", id: "serial_number" },
      { name: "Mac Address", id: "mac_address" },
      { name: "ServiceDesk id", id: "service_desk_id" },
      { name: "Created at", id: "Created_at" },
      { name: "Status", id: "closed" }
    ];

    return keys
      .filter(item => {
        return !serviceDeskStatus && item.id === "service_desk_id"
          ? false
          : item;
      })
      .map((item: any) => (
        <th
          style={{ textTransform: "capitalize", userSelect: "none" }}
          key={item.id}
          onClick={(): any => {
            onFilter({
              orderBy: item.id,
              direction:
                get(filtering, "orderBy") !== item.id
                  ? "asc"
                  : !get(filtering, "direction")
                  ? "asc"
                  : get(filtering, "direction") &&
                    get(filtering, "direction") === "asc"
                  ? "desc"
                  : "asc"
            });
          }}
        >
          {item.name}
          {(get(filtering, "orderBy") &&
            get(filtering, "orderBy") === item.id &&
            get(filtering, "direction") &&
            get(filtering, "direction") === "asc" && (
              <i style={{ marginLeft: 5 }} className="fa fa-sort-desc" />
            )) ||
            (get(filtering, "orderBy") &&
              get(filtering, "orderBy") === item.id &&
              get(filtering, "direction") &&
              get(filtering, "direction") === "desc" && (
                <i style={{ marginLeft: 5 }} className="fa fa-sort-asc" />
              )) || (
              <i
                style={{ marginLeft: 5, opacity: 0.4 }}
                className="fa fa-sort"
              />
            )}
        </th>
      ));
  };

  _renderTableFieldNamesSearchDevices = (): Array<JSX.Element> => {
    const keys: Array<{ name: string; id: string; noFilter?: boolean }> = [
      { name: "Mac Address", id: "mac_address", noFilter: true },
      { name: "Serial", id: "serial_number", noFilter: true },
      { name: "UID", id: "uid", noFilter: true },
      { name: "Provision ID", id: "provision_id", noFilter: true }
    ];

    return keys.map((item: any) => (
      <th
        style={{ textTransform: "capitalize", userSelect: "none" }}
        key={item.id}
      >
        {item.name}
      </th>
    ));
  };

  _deviceFilteringOnSubmit: any = async (values: {
    id: number;
    uid: number;
    mac_address: string;
    provision_id: number;
    serial_number: number | string;
    pairing_code: number | string;
  }): Promise<void> => {
    const { getFilteredDevices } = this.props;
    this.setState({ loading1: true });

    devicesfilteredValues.current_page = null;
    devicesfilteredValues.orderBy = null;
    devicesfilteredValues.direction = null;
    devicesfilteredValues.id = values.uid;
    devicesfilteredValues.mac_address = values.mac_address;
    devicesfilteredValues.provision_id = values.provision_id;
    devicesfilteredValues.serial_number = values.serial_number;
    devicesfilteredValues.pairing_code = values.pairing_code;

    try {
      await getFilteredDevices(devicesfilteredValues);
    } catch (e) {
      // console.log(e);
    } finally {
      this.timer = setTimeout(() => {
        this.setState({ loading1: false });
      }, 200);
    }
  };

  _pairingCodeSubmit: any = async (pairing_code): Promise<void> => {
    const { findPairingCode } = this.props;

    try {
      const result = await findPairingCode(pairing_code);
      if (result) {
        this.setState({
          deviceId: result.elementValid.id
        });
        this._toggleRemoteAssistanceModal();
      } else {
        this.setState({
          responseParingCode:
            "There is no device available for this pairing code!"
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };
  _remoteSessionFilteringOnSubmit: any = async (
    values: {
      id: number;
      operator_device_id: string;
      service_desk_id: string;
      sequential_id: number;
      created_by: string;
      created_at: Moment;
      mac_address: string;
      provision_id: number | string;
    },
    page = 1
  ): Promise<void> => {
    const { getFilteredRemoteAssistances } = this.props;
    this.setState({ loading2: true });
    remoteSessionFilteredValues.current_page = Number.isInteger(page)
      ? page
      : 1;

    remoteSessionFilteredValues.orderBy = null;
    remoteSessionFilteredValues.direction = null;
    remoteSessionFilteredValues.id = values.id;
    remoteSessionFilteredValues.operator_device_id = values.operator_device_id;
    remoteSessionFilteredValues.service_desk_id = values.service_desk_id;
    remoteSessionFilteredValues.created_by = values.created_by;
    remoteSessionFilteredValues.created_at = values.created_at
      ? values.created_at.format("YYYY-MM-DD")
      : "";
    remoteSessionFilteredValues.sequential_id = values.sequential_id;
    remoteSessionFilteredValues.mac_address = values.mac_address;
    remoteSessionFilteredValues.provision_id = values.provision_id;

    try {
      await getFilteredRemoteAssistances(remoteSessionFilteredValues);
    } catch (e) {
      // console.log(e);
    } finally {
      this.timer = setTimeout(() => {
        this.setState({ loading2: false, valuesForFilter: values });
      }, 200);
    }
  };

  render(): JSX.Element {
    const { remoteAssistances, devices }: IPropsInterface = this.props;
    const {
      showRemoteAssistanceModal,
      remoteAssistanceAuthorized,
      deviceId,
      loading1,
      loading2,
      responseParingCode,
      operatorProfileId,
      serviceDeskStatus
    }: IStateInterface = this.state;

    return (
      <div className={style.administratorsTableWrapper}>
        <Row>
          <div className="card-title col-12">
            <h1>
              <i className="material-icons">code</i>
              Pairing Code
            </h1>
          </div>
        </Row>

        <Formik
          initialValues={{}}
          onSubmit={this._pairingCodeSubmit}
          render={({ handleChange, handleSubmit }: any): any => {
            return (
              <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                <Card>
                  <CardBody>
                    <FormGroup className="mb-0" style={{ width: "100%" }}>
                      <div className="d-flex">
                        <Input
                          onChange={handleChange}
                          htmlFor="pairing_code"
                          id="pairing_code"
                          placeholder="Enter Pairing Code"
                          name="pairing_code"
                          type="text"
                          invalid={!!responseParingCode}
                          required
                        />

                        {localStorage.getItem("acr") !== "OPR_VIEWER" && (
                          <Button
                            className="buttonPurple icon-btn material-icons desktop_windows"
                            color="purple"
                            size="sm"
                            type="submit"
                          />
                        )}
                      </div>
                      <FormFeedback className="d-block">
                        {responseParingCode}
                      </FormFeedback>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Form>
            );
          }}
        />

        <Row>
          <div className="card-title col-12">
            <h1>
              <i className="material-icons">search</i>
              Search Device
            </h1>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Formik
              initialValues={initialDevicesfilteredValues}
              onSubmit={this._deviceFilteringOnSubmit}
              render={({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset
              }: any): any => {
                return (
                  <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                    <Row className={style.filteringRow}>
                      <FormInputs
                        type="text"
                        htmlFor="mac_address"
                        id="mac_address"
                        labelTitle="Mac Address"
                        name="mac_address"
                        placeholder="Enter Mac Address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mac_address}
                        errors={errors.mac_address}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="serial_number"
                        value={values.serial_number}
                        id="serial_number"
                        labelTitle="Serial Number"
                        placeholder="Enter Serial Number"
                        onBlur={handleBlur}
                        name="serial_number"
                        errors={errors.serial_number}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="uid"
                        value={values.uid}
                        id="uid"
                        labelTitle="UID"
                        placeholder="Enter  UID"
                        onBlur={handleBlur}
                        name="uid"
                        errors={errors.uid}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />

                      <FormInputs
                        type="text"
                        id="provision_id"
                        labelTitle="Provision ID"
                        htmlFor="provision_id"
                        name="provision_id"
                        placeholder="Enter Provision ID"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.provision_id}
                        errors={errors.provision_id}
                        rowInputClassName={style.filteringInput}
                      />
                      <div>
                        <Button
                          className={style.filteringButton}
                          type="submit"
                          color="primary"
                          size="md"
                        >
                          Search Device
                        </Button>
                        <Button
                          className={style.filteringButton}
                          color="danger"
                          size="md"
                          type="reset"
                          onClick={() => {
                            handleReset(values);
                            handleSubmit();
                          }}
                        >
                          Reset filters
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            />
            <br />
            <div className={style.deviceTable}>
              {devices?.data?.length > 0 ? (
                <AppTable
                  renderTableFieldNames={
                    this._renderTableFieldNamesSearchDevices
                  }
                  renderResultRows={this._renderDeviceResultRows}
                  numberOfCells={5}
                  loading={loading1}
                  section="remote-assistance"
                />
              ) : (
                <Alert color="danger">No results found</Alert>
              )}
            </div>
            {showRemoteAssistanceModal && (
              <RemoteAssistanceModal
                toggle={this._toggleRemoteAssistanceModal}
                authorized={remoteAssistanceAuthorized}
                operatorDeviceId={deviceId}
                operatorProfileId={operatorProfileId}
                isOpen={showRemoteAssistanceModal}
              />
            )}
          </CardBody>
        </Card>
        <Row style={{ marginTop: -1 + "rem" }}>
          <div className="card-title col-12">
            <h1>
              <i className="material-icons">history</i>
              Recent Remote Assistance Sessions
            </h1>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Formik
              initialValues={initialRemoteSessionFilteredValues}
              onSubmit={this._remoteSessionFilteringOnSubmit}
              render={({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset
              }: any): any => {
                return (
                  <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                    <Row className={style.filteringRow}>
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="sequential_id"
                        value={values.sequential_id}
                        id="sequential_id"
                        labelTitle="Session Id"
                        placeholder="Enter  Session Id"
                        onBlur={handleBlur}
                        name="sequential_id"
                        errors={errors.sequential_id}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="provision_id"
                        value={values.provision_id}
                        id="provision_id"
                        labelTitle="Provision ID"
                        placeholder="Enter Provision ID"
                        onBlur={handleBlur}
                        name="provision_id"
                        errors={errors.provision_id}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="serial_number"
                        value={values.serial_number}
                        id="serial_number"
                        labelTitle="Serial Number"
                        placeholder="Enter Serial Number"
                        onBlur={handleBlur}
                        name="serial_number"
                        errors={errors.serial_number}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        type="text"
                        htmlFor="mac_address"
                        id="mac_address"
                        labelTitle="Mac Address"
                        name="mac_address"
                        placeholder="Enter Mac Address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mac_address}
                        errors={errors.mac_address}
                        rowInputClassName={style.filteringInput}
                      />
                      {serviceDeskStatus && (
                        <FormInputs
                          type="text"
                          htmlFor="service_desk_id"
                          id="service_desk_id"
                          labelTitle="ServiceDesk ID"
                          name="service_desk_id"
                          placeholder="Enter ServiceDesk ID"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.service_desk_id}
                          errors={errors.service_desk_id}
                          rowInputClassName={style.filteringInput}
                        />
                      )}
                      <FormInputs
                        type="date"
                        htmlFor="created_at"
                        id="created_at"
                        labelTitle="Created Date"
                        name="created_at"
                        placeholder="Enter Created Date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.created_at}
                        errors={errors.created_at}
                        rowInputClassName={style.filteringInput}
                      />
                      <Button
                        className={style.filteringButton}
                        type="submit"
                        color="primary"
                        size="md"
                      >
                        Apply Filters
                      </Button>
                      <Button
                        className={style.filteringButton}
                        color="danger"
                        size="md"
                        type="reset"
                        onClick={() => {
                          handleReset(values);
                          handleSubmit();
                        }}
                      >
                        Reset filters
                      </Button>
                    </Row>
                  </Form>
                );
              }}
            />
            <br />
            <Fragment>
              {remoteAssistances &&
              get(remoteAssistances, "data") &&
              get(remoteAssistances, "data").length ? (
                <AppTable
                  renderTableFieldNames={this._renderTableFieldNames}
                  renderResultRows={this._renderResultRows}
                  numberOfCells={7}
                  loading={loading2}
                  section="remote-assistance"
                />
              ) : (
                <Alert color="danger">No results found</Alert>
              )}
              {remoteAssistances &&
                renderPagination(remoteAssistances, this._onChange)}
            </Fragment>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter<any, any>(RemoteAssistancesTable);
