import React, { Component } from "react";
import { Formik } from "formik";
import { get } from "lodash";
import {
  ICreateDeviceTypesInterface,
  ISingleDeviceTypesInterface,
  ISingleRomInterface,
  IRomsInterface,
  ICreateDeviceTypeRoms
} from "../../../common/interfaces";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  ModalFooter,
  Spinner
} from "reactstrap";
import style from "./DeviceProfilesForm.module.scss";
import FormInputs from "../FormInputs";

const validationSchema: any = function(values: any): any {
  return Yup.object().shape({
    name: Yup.string()
      .min(1)
      .required("Name is required"),
    // key_type: Yup.string()
    //   .min(1)
    //   .required("Key Type is required"),
    // operator_rom: Yup.string()
    //   .min(1)
    //   .required("ROM is required"),
    manufacturer: Yup.string().required("Manufacturer is required"),
    model: Yup.string()
      .min(1)
      .required("Model is required"),
    playback_support_id: Yup.string().required("Device Type is required"),
    status: Yup.string()
      .max(1, "Status is required")
      .required("Status is required")
  });
};

const validationEditSchema: any = function(values: any): any {
  return Yup.object().shape({
    name: Yup.string()
      .min(1)
      .required("Name is required"),
    // key_type: Yup.string()
    //   .min(1)
    //   .required("Key Type is required"),
    // operator_rom: Yup.string()
    //   .min(1)
    //   .required("ROM is required"),
    manufacturer: Yup.string().required("Manufacturer is required"),
    model: Yup.string()
      .min(1)
      .required("Model is required"),
    playback_support_id: Yup.string().required("Device Type is required"),
    status: Yup.string()
      .max(1, "Status is required")
      .required("Status is required")
  });
};

const validate: any = (getValidationSchema: any): any => {
  return (values: any): any => {
    const validationSchema: any = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError: any = (validationError: any): any => {
  const FIRST_ERROR: any = 0;

  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
};

const initialValues: ICreateDeviceTypesInterface = {
  key_type: "-1",
  manufacturer: "",
  model: "",
  playback_support_id: "",
  unidirectional: false,
  operator_rom_id: "",
  operator_rom: "",
  status: 100,
  name: "",
  profile_picture: null
};

interface IPropsInterface {
  title: string;
  onShowForm(): void;
  createNewDeviceProfile?(data: ICreateDeviceTypesInterface): void;
  deviceProfiles(pageNumber: number): any;
  data?: ISingleDeviceTypesInterface;
  match: any;
  edit?: boolean;
  onEditDeviceProfile?(data: ICreateDeviceTypesInterface): void;
  playbackSupports: Array<{ id: number; name: string }>;
  roms: IRomsInterface;
  createNewDeviceProfileRom?(data: ICreateDeviceTypeRoms): void;
  onEditDeviceTypeRom?(data: ICreateDeviceTypeRoms): void;
  statuses: Array<{ id: number; name: string }>;
}

interface IStateInterface {
  responseError: { details: object; message: string } | boolean | any;
  deviceProfile: number | null;
  loading: boolean;
  currentsValue: ICreateDeviceTypesInterface | null;
}

class DeviceProfilesForm extends Component<IPropsInterface, IStateInterface> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      responseError: null,
      deviceProfile: null,
      loading: false,
      currentsValue: null
    };
  }

  _generatePlaybackSupport: Function = (
    playBackSupportList: Array<{ id: number; name: string }>
  ): Array<JSX.Element> => {
    return playBackSupportList.map((item: { id: number; name: string }) => (
      <option value={item.id} key={item.id}>
        {item.name}
      </option>
    ));
  };

  _generateRoms: Function = (romList: IRomsInterface): Array<JSX.Element> => {
    return get(romList, "data").map((item: ISingleRomInterface) => (
      <option value={item.id} key={item.id}>
        {item.name || item.operation_system}
      </option>
    ));
  };

  _generateStatusOptions: Function = (
    statusList: Array<{ name: string; id: number }>
  ) => {
    return statusList.map((item: { id: number; name: string }) => (
      <option value={item.id} key={item.id}>
        {item.name}
      </option>
    ));
  };

  _renderAlert: Function = () => {
    const { responseError }: IStateInterface = this.state;
    if (responseError) {
      const {
        details
      }: { details: object; message: string } | boolean | any = responseError;

      return (
        <Alert color="warning">
          {!details && responseError && get(responseError, "message") && (
            <p>{get(responseError, "message")}</p>
          )}

          {details &&
            Object.keys(details).map((item: any, index: any) => (
              <div key={index}>{`${details[item]}`}</div>
            ))}
        </Alert>
      );
    }
  };

  _onSubmit: any = async (values: any, { resetForm }: any): Promise<void> => {
    const {
      createNewDeviceProfile,
      onEditDeviceProfile,
      edit
    }: IPropsInterface = this.props;
    values.operator_rom_id = values.operator_rom;
    this.setState({ loading: true, currentsValue: values });

    if (edit && onEditDeviceProfile) {
      const { status, data }: any = await onEditDeviceProfile(values);
      if (status > 201) {
        this.setState({
          responseError: data?.error
        });
      } else {
        this.setState({
          responseError: false
        });
      }
      this.setState({ loading: false });
    } else if (createNewDeviceProfile) {
      const { status, data }: any = await createNewDeviceProfile(values);

      if (status > 201) {
        this.setState({
          responseError: data?.error
        });
      } else {
        this.setState({
          responseError: false
        });
        resetForm();
      }
      this.setState({ loading: false });
    }
  };

  render(): JSX.Element {
    const {
      onShowForm,
      data,
      edit,
      playbackSupports,
      statuses
    }: IPropsInterface = this.props;
    const { loading, currentsValue }: IStateInterface = this.state;
    initialValues.status = get(statuses[1], "id");

    if (loading) {
      return (
        <div className="d-flex justify-content-center mt-3 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <div>
        {this._renderAlert()}
        <Formik
          initialValues={currentsValue || data || initialValues}
          validate={
            edit ? validate(validationEditSchema) : validate(validationSchema)
          }
          onSubmit={this._onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue
          }: any): any => {
            return (
              <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                <FormGroup row className="my-0">
                  <FormInputs
                    onChange={handleChange}
                    htmlFor="name"
                    placeholder="Name"
                    valid={touched.name && !errors.name}
                    value={values.name}
                    id="name"
                    labelTitle="Name"
                    invalid={touched.name && !!errors.name}
                    //onBlur={handleBlur}
                    name="name"
                    errors={errors.name}
                    type="text"
                    required={true}
                    hasColumn={true}
                  />

                  <FormInputs
                    onChange={handleChange}
                    htmlFor="manufacturer"
                    placeholder="Enter Manufacturer"
                    valid={touched.manufacturer && !errors.manufacturer}
                    value={values.manufacturer}
                    id="manufacturer"
                    labelTitle="Manufacturer"
                    invalid={touched.manufacturer && !!errors.manufacturer}
                    //onBlur={handleBlur}
                    name="manufacturer"
                    errors={errors.manufacturer}
                    type="text"
                    required={true}
                    hasColumn={true}
                  />
                  <FormInputs
                    onChange={handleChange}
                    htmlFor="model"
                    placeholder="Enter Model"
                    valid={touched.model && !errors.model}
                    value={values.model}
                    id="model"
                    labelTitle="Model"
                    invalid={touched.model && !!errors.model}
                    //onBlur={handleBlur}
                    name="model"
                    errors={errors.model}
                    type="text"
                    required={true}
                    hasColumn={true}
                  />
                  <FormInputs
                    onChange={(event: any): any => {
                      handleChange(event);
                      setFieldValue("playback_support_id", event.target.value);
                    }}
                    htmlFor="playback_support_id"
                    valid={
                      touched.playback_support_id && !errors.playback_support_id
                    }
                    value={values.playback_support_id}
                    id="playback_support_id"
                    labelTitle="Device Type"
                    invalid={
                      touched.playback_support_id &&
                      !!errors.playback_support_id
                    }
                    //onBlur={handleBlur}
                    name="playback_support_id"
                    errors={errors.playback_support_id}
                    type="select"
                    option={
                      <option value="" label="Device Type">
                        Select device profile
                      </option>
                    }
                    renderData={this._generatePlaybackSupport(playbackSupports)}
                    hasColumn={true}
                  />
                  <FormInputs
                    onChange={(event: any): any => {
                      handleChange(event);
                      setFieldValue("status", parseInt(event.target.value));
                    }}
                    htmlFor="status"
                    valid={touched.status && !errors.status}
                    value={values.status}
                    id="status"
                    labelTitle="Status"
                    invalid={touched.status && !!errors.status}
                    //onBlur={handleBlur}
                    name="status"
                    errors={errors.status}
                    type="select"
                    option={
                      <option value={100} label="Select Status">
                        Select Status
                      </option>
                    }
                    renderData={this._generateStatusOptions(statuses)}
                    required={true}
                    hasColumn={true}
                  />

                  <FormInputs
                    onChange={(event: any): any => {
                      handleChange(event);
                      setFieldValue("profile_picture", event.target.files[0]);
                    }}
                    htmlFor="profile_picture"
                    valid={touched.profile_picture && !errors.profile_picture}
                    id="profile_picture"
                    labelTitle="Profile picture"
                    invalid={
                      touched.profile_picture && !!errors.profile_picture
                    }
                    //onBlur={handleBlur}
                    name="profile_picture"
                    errors={errors.profile_picture}
                    type="file"
                    hasColumn={true}
                  />
                </FormGroup>
                <ModalFooter className={style.footerPadding}>
                  <Button
                    type="button"
                    color="danger"
                    size="md"
                    onClick={onShowForm}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    size="md"
                    //disabled={!edit && !isValid}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}
export default withRouter<any, any>(DeviceProfilesForm);
