import axios from "../axiosInstance";
import { LOCATION_API_DOMAIN } from "../../API_routes";

const apiPrefix = `${LOCATION_API_DOMAIN}/list/countries`;

export interface createCountryInterface {
  name: string;
  code: string | number;
}

export const getCountries = (
  pageNumber?: string | number,
  limit?: number,
  orderBy?: string | null,
  direction?: string | null
) =>
  axios.get(
    `${apiPrefix}
      ${pageNumber ? "?page=" + pageNumber : ""} ${
      limit ? "&limit=" + limit : ""
    }${orderBy ? "&order=" + orderBy : ""}${
      direction ? "&direction=" + direction : ""
    }`
  );
export const getCountryByID = (ID: string | number) =>
  axios.get(`${apiPrefix}/${ID}`);
export const createCountry = (data: createCountryInterface) =>
  axios.post(`${apiPrefix}`, { ...data });
export const deleteCountry = (id: number | string) =>
  axios.delete(`${apiPrefix}/${id}`);
export const deleteCountries = (ids: Array<number | string>) => {
  return axios.delete(
    `${apiPrefix}?${ids
      .map((item: number | string, index: number) => `ids[${index}]=${item}`)
      .join("&")}`
  );
};
export const updateCountry = () => axios.put(`${apiPrefix}`);

export const filterCountriesByName = (countryName: string) =>
  axios.get(
    `${apiPrefix}?direction=asc${
      countryName ? "&filter[name]=" + countryName : ""
    }`
  );
