import axios from "../axiosInstance";
import { AUTH_BASE_URL } from "../../API_routes";
import { ICreateOperatorInterface } from "../interfaces";
import { handleOrderBy } from "../helper/helper";

const apiPrefix: string = `${AUTH_BASE_URL}/operators`;
const apiPrefixList: string = `${AUTH_BASE_URL}/list/operators`;

export const getOperators = (
  pageNumber?: number | string,
  limit?: number | null,
  orderBy?: string | null,
  direction?: string | null,
  type_id?: number | null,
  name?: string | null,
  email?: string | null,
  country_id?: number | null,
  city_id?: number | null,
  status?: number | null
) => {
  let filters = `${pageNumber ? "?page=" + pageNumber : "?page=1"}${
    limit ? "&limit=" + limit : "".replace(/\s/g, "")
  }
        ${orderBy ? "&order=" + handleOrderBy(orderBy) : "".replace(/\s/g, "")}
        ${direction ? "&direction=" + direction : "".replace(/\s/g, "")}
        ${type_id ? "&filter[type_id]=" + type_id : "".replace(/\s/g, "")}
        ${name ? "&filter[name]=" + name : "".replace(/\s/g, "")}
        ${email ? "&filter[email]=" + email : "".replace(/\s/g, "")}
        ${
          country_id
            ? "&filter[country_id]=" + country_id
            : "".replace(/\s/g, "")
        }
        ${city_id ? "&filter[city_id]=" + city_id : "".replace(/\s/g, "")} ${
    status ? "&filter[status]=" + status : "".replace(/\s/g, "")
  }`.replace(/\s/g, "");

  return axios.get(`${apiPrefix}${filters}`);
};

export const getOperatorsList = () => axios.get(`${apiPrefixList}`);

export const getOperatorByStatus: any = (): any =>
  axios.get(`${apiPrefix}?page=1&'&filter[status]=1`);

export const getOperatorByID: any = (ID: number | string): any =>
  axios.get(`${apiPrefix}/${ID}`);

export const createOperators: any = (data: ICreateOperatorInterface): any =>
  axios.post(`${apiPrefix}`, { ...data });

export const deleteOperator: any = (id: number | string): any =>
  axios.delete(`${apiPrefix}/${id}`);

export const deleteOperators: any = (ids: Array<number | string>): any => {
  return axios.delete(
    `${apiPrefix}?${ids
      .map((item: number | string, index: number) => `ids[${index}]=${item}`)
      .join("&")}`
  );
};

export const updateOperator: any = (
  id: number | string,
  data: ICreateOperatorInterface
): any => axios.put(`${apiPrefix}/${id}`, { ...data });

export const filterOperatorsByName = (operatorName: string) =>
  axios.get(
    `${apiPrefix}?direction=asc
         ${operatorName ? "&filter[name]=" + operatorName : ""}`
  );

export const getServiceTypes = () =>
  axios.get(`${AUTH_BASE_URL}/list/operators/service-types`);

export const getTypes = () =>
  axios.get(`${AUTH_BASE_URL}//list/operators/types`);
