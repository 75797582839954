import React, { Component } from "react";
import { Formik } from "formik";
import { get } from "lodash";
import {
  ICreateDevicesInterface,
  ISingleDeviceInterface
} from "../../../common/interfaces";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner
} from "reactstrap";
import style from "./DevicesForm.module.scss";
import { uploadDevices } from "../../../common/api/devices";
import { Switch } from "antd";
import {
  releaseCycle,
  parseKeyToCapitalize
} from "../../../common/helper/helper";
import { ImportDevices } from "./components";

const validationSchema: any = function(values: any): any {
  return Yup.object().shape({
    operator_device_type_id: Yup.string()
      .min(1)
      .required("Device Profile is required"),
    // uid: Yup.string()
    //   .min(1)
    // .required("UID is required"),
    status: Yup.string()
      .max(1, "Status is required")
      .required("Status is required"),
    // provision_id: Yup.string()
    //.min(1, "Provision ID is required")
    // .required("Provision ID is required"),
    mac_address: Yup.string()
      .min(1, "Mac Address is required")
      .required("Mac Address is required"),
    //device_id: Yup.string()
    //.min(1, "Device ID is required"),
    //.required("Device ID is required"),
    serial_number: Yup.string()
      .min(1, "Serial Number is required")
      .required("Serial Number is required"),

    release_cycle: Yup.number()
      .min(0, "Release Cycle is required")
      .max(10, "Release Cycle is required")
      .required("Release Cycle is required")
  });
};

const validationSchemaImport: any = function(values: any): any {
  return Yup.object().shape({
    operator_device_type_id_import: Yup.string()
      .min(1)
      .required("Device Profile is required"),
    operator_device_release_cycle: Yup.string()
      .min(1)
      .required("Release Cycle is required")
  });
};

const validationEditSchema: any = function(values: any): any {
  return Yup.object().shape({
    operator_device_type_id: Yup.string()
      .min(1)
      .required("Device Profile is required"),
    // uid: Yup.string()
    //   .min(1)
    //.required("UID is required"),
    status: Yup.string()
      .max(1, "Status is required")
      .required("Status is required"),
    // provision_id: Yup.string()
    //   .min(1, "Provision ID is required"),
    //.required("Provision ID is required"),
    mac_address: Yup.string()
      .min(1, "Mac Address is required")
      .required("Mac Address is required"),
    // device_id: Yup.string()
    //   .min(1, "Device ID is required"),
    //.required("Device ID is required"),
    serial_number: Yup.string()
      .min(1, "Serial Number is required")
      .required("Serial Number is required"),

    release_cycle: Yup.number()
      .min(0, "Release Cycle is required")
      .max(10, "Release Cycle is required")
      .required("Release Cycle is required")
  });
};

const validate: any = (getValidationSchema: any): any => {
  return (values: any): any => {
    const validationSchema: any = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError: any = (validationError: any): any => {
  const FIRST_ERROR: any = 0;

  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
};

const initialValues = {
  operator_device_type_id: "",
  uid: "",
  mac_address: "",
  serial_number: "",
  // socket_connection?: string;
  device_status: 0,
  status: "",
  provision_id: "",
  name: "",
  device_id: "",
  release_cycle: 5
};

interface IPropsInterface {
  deviceTypes: Array<{}>;
  statuses: Array<{ id: number; name: string }>;
  title: string;
  data?: ISingleDeviceInterface;
  match: any;
  edit?: boolean;
  devices(pageNumber: number): any;

  onShowForm(): void;

  createNewDevice?(data: ICreateDevicesInterface): void;

  onEditDevice?(data: ICreateDevicesInterface): void;
}

interface IStateInterface {
  responseError: { details: object; message: string } | boolean | any;
  device: number | null;
  activeTab: string;
  file: File | null;
  progressPercentage: number;
  animated: boolean;
  fileDeviceType: {
    isLock: boolean | string;
    operator_device_release_cycle: string | number;
    operator_device_type_id_import: string | null;
  };
  loading: boolean;
  releaseCycle: Array<{ id: number; name: string }>;
  errorFile: string;
}

class DevicesForm extends Component<IPropsInterface, IStateInterface> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      responseError: null,
      device: null,
      activeTab: "1",
      file: null,
      progressPercentage: 0,
      animated: false,
      fileDeviceType: {
        isLock: false,
        operator_device_release_cycle: 5,
        operator_device_type_id_import: null
      },
      loading: false,
      releaseCycle: [...releaseCycle],
      errorFile: ""
    };
  }

  async componentDidMount(): Promise<void> {
    this.setState({
      device: null
    });
  }

  _generateOptionsList = (
    list: Array<{ name: string; id: number }>
  ): Array<JSX.Element> => {
    return list.map((item: { id: number; name: string }) => (
      <option value={item.id} key={item.id}>
        {item.name}
      </option>
    ));
  };

  _generateDeviceTypes = (list: Array<{}>): Array<JSX.Element> => {
    return list.map((item: any) => (
      <option value={item.id} key={item.id}>
        {item.name}
      </option>
    ));
  };

  _onSubmit: any = async (
    values: any,
    { setSubmitting, setErrors, resetForm }: any
  ): Promise<void> => {
    const { createNewDevice, onEditDevice, edit }: IPropsInterface = this.props;
    this.setState({ loading: true });
    if (edit && onEditDevice) {
      const result: any = await onEditDevice(values);

      if (get(result, "response.data.error") || get(result, "data.error")) {
        this.setState({
          responseError:
            get(result, "response.data.error") ||
            get(result, "data.error") ||
            "Error"
        });
      } else {
        this.setState({
          responseError: false
        });
      }
      this.setState({ loading: false });
    } else if (createNewDevice) {
      const result: any = await createNewDevice(values);

      if (get(result, "response.data.error") || get(result, "data.error")) {
        this.setState({
          responseError:
            get(result, "response.data.error") ||
            get(result, "data.error") ||
            "Error"
        });
      } else {
        this.setState({
          responseError: false
        });
        resetForm();
      }
      this.setState({ loading: false });
    }
  };

  _renderAlert: Function = () => {
    const { responseError }: IStateInterface = this.state;
    if (responseError) {
      const {
        details
      }: { details: object; message: string } | boolean | any = responseError;

      const errorParse = details || responseError?.error?.details;

      if (
        get(responseError, "message") === "OK" &&
        get(responseError, "status") === 200
      ) {
        return (
          <Alert color="success">
            {get(responseError, "resource") || get(responseError, "message")}
          </Alert>
        );
      }

      return (
        <Alert color="warning">
          {!details && responseError && get(responseError, "message") && (
            <p>
              {get(responseError, "error.message") ||
                get(responseError, "message")}
            </p>
          )}

          {errorParse &&
            Object.keys(errorParse).map((item: any, index: any) => (
              <div key={index}>{`${parseKeyToCapitalize(item)}: ${
                errorParse[item]
              }`}</div>
            ))}
        </Alert>
      );
    }
  };

  _handleDrop: Function = (files: Object): void => {
    //if (files[0]?.type === "text/csv") {
    this.setState({
      file: files[0],
      animated: false,
      progressPercentage: 0,
      errorFile: ""
    });
    // } else {
    //   this.setState({ errorFile: "File extension should be .csv format only" });
    // }
  };

  _toggle: Function = (tab: string): void => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  _handleCsvUpload: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = async (): Promise<void> => {
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;

    const internalError: { details: any; message: string } = {
      details: null,
      message: "An internal error happened"
    };

    try {
      this.setState({
        // progressPercentage: 100,
        animated: true
      });

      const result: {
        status: number;
        data: { message: string };
      } = await uploadDevices(this.state.file, this.state.fileDeviceType);

      if (get(result, "data.message")) {
        this.setState({
          responseError: get(result, "data")
        });
      } else {
        this.setState({
          responseError: false
        });
      }

      if (result && result.status === 200) {
        this.setState({
          progressPercentage: 100,
          animated: false
        });

        await this.props.devices(pageNumber);

        setTimeout(() => {
          this.props.onShowForm();
        }, 5000);
      }
    } catch (e) {
      this.setState({
        progressPercentage: 99,
        animated: false,
        responseError: internalError
      });
    }
  };

  render(): JSX.Element {
    const {
      onShowForm,
      data,
      edit,
      statuses,
      deviceTypes
    }: {
      onShowForm(): void;
      data?: ISingleDeviceInterface;
      edit?: boolean;
      statuses: Array<{ id: number; name: string }>;
      deviceTypes: Array<{}>;
    } = this.props;

    const { fileDeviceType, errorFile } = this.state;

    if (data) {
      data.device_id = data.id;
    }

    initialValues.status = get(statuses[1], "id").toString();

    const {
      progressPercentage,
      animated,
      loading,
      releaseCycle
    }: IStateInterface = this.state;

    if (loading) {
      return (
        <div className="d-flex justify-content-center mt-3 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <div>
        {this._renderAlert()}
        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "1" ? "active" : ""}
                onClick={(): void => {
                  this._toggle("1");
                }}
              >
                Manual
              </NavLink>
            </NavItem>
            {!data && (
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "2" ? "active" : ""}
                  onClick={(): void => {
                    this._toggle("2");
                  }}
                >
                  Import CSV
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Formik
                initialValues={data || initialValues}
                validate={
                  edit
                    ? validate(validationEditSchema)
                    : validate(validationSchema)
                }
                onSubmit={this._onSubmit}
                render={({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  setFieldValue
                }: any): any => {
                  return (
                    <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                      <FormGroup row className="my-0">
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="name">Name</Label>
                            <Input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Enter Name"
                              required
                              valid={touched.name && !errors.name}
                              invalid={touched.name && !!errors.name}
                              onChange={handleChange}
                              //onBlur={handleBlur}
                              defaultValue={values.name}
                            />
                            <FormFeedback>{errors.name}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="operator_device_type_id">
                              Device Profile
                            </Label>
                            <Input
                              type="select"
                              name="operator_device_type_id"
                              id="operator_device_type_id"
                              required
                              valid={
                                touched.operator_device_type_id &&
                                !errors.operator_device_type_id
                              }
                              invalid={
                                touched.operator_device_type_id &&
                                !!errors.operator_device_type_id
                              }
                              onChange={handleChange}
                              //onBlur={handleBlur}
                              defaultValue={values.operator_device_type_id}
                            >
                              <option value="" label="Select device profile">
                                Select device profile
                              </option>
                              {this._generateDeviceTypes(deviceTypes)}
                            </Input>
                            <FormFeedback>
                              {errors.operator_device_type_id}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="uid">UID</Label>
                            <Input
                              type="text"
                              id="uid"
                              name="uid"
                              valid={touched.uid && !errors.uid}
                              invalid={touched.uid && !!errors.uid}
                              placeholder="Enter UID"
                              onChange={handleChange}
                              //onBlur={handleBlur}
                              defaultValue={values.uid}
                            />
                            <FormFeedback>{errors.uid}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="provision_id">Provision ID</Label>
                            <Input
                              type="text"
                              id="provision_id"
                              name="provision_id"
                              valid={
                                touched.provision_id && !errors.provision_id
                              }
                              invalid={
                                touched.provision_id && !!errors.provision_id
                              }
                              placeholder="Enter Provision ID"
                              onChange={handleChange}
                              //onBlur={handleBlur}
                              defaultValue={values.provision_id}
                            />
                            <FormFeedback>{errors.provision_id}</FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="mac_address">Mac Address</Label>
                            <Input
                              type="text"
                              id="mac_address"
                              name="mac_address"
                              placeholder="Enter Mac Address"
                              required
                              valid={touched.mac_address && !errors.mac_address}
                              invalid={
                                touched.mac_address && !!errors.mac_address
                              }
                              onChange={handleChange}
                              //onBlur={handleBlur}
                              defaultValue={values.mac_address}
                            />
                            <FormFeedback>{errors.mac_address}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="serial_number">Serial Number</Label>
                            <Input
                              type="text"
                              id="serial_number"
                              name="serial_number"
                              placeholder="Enter Serial Number"
                              required
                              valid={
                                touched.serial_number && !errors.serial_number
                              }
                              invalid={
                                touched.serial_number && !!errors.serial_number
                              }
                              onChange={handleChange}
                              //onBlur={handleBlur}
                              defaultValue={values.serial_number}
                            />
                            <FormFeedback>{errors.serial_number}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="status">Status</Label>
                            <Input
                              type="select"
                              id="status"
                              name="status"
                              required
                              valid={touched.status && !errors.status}
                              invalid={touched.status && !!errors.status}
                              onChange={(event: any): any => {
                                handleChange(event);
                                setFieldValue(
                                  "status",
                                  parseInt(event.target.value)
                                );
                              }}
                              //onBlur={handleBlur}
                              defaultValue={values.status}
                            >
                              <option value={1000} label="Select status">
                                Select status
                              </option>
                              {this._generateOptionsList(statuses)}
                            </Input>
                            <FormFeedback>{errors.status}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="release_cycle">Release Cycle</Label>
                            <Input
                              type="select"
                              name="release_cycle"
                              id="release_cycle"
                              defaultValue={values.release_cycle || 5}
                              valid={
                                touched.release_cycle && !errors.release_cycle
                              }
                              invalid={
                                touched.release_cycle && !!errors.release_cycle
                              }
                              onChange={event => {
                                setFieldValue(
                                  "release_cycle",
                                  parseInt(event.target.value)
                                );
                              }}
                              required
                            >
                              <option value={9999} label="Select release cycle">
                                Select release cycle
                              </option>
                              {this._generateDeviceTypes(releaseCycle)}
                            </Input>
                            <FormFeedback>{errors.release_cycle}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col className="no-gutters row">
                          <Col xs="3">
                            <Label
                              className={style.labelSwitch}
                              htmlFor="operator_device_lock"
                            >
                              Device Lock
                            </Label>
                          </Col>
                          <Col xs="6">
                            <Switch
                              size="small"
                              defaultChecked={values.lock}
                              onChange={(checked: boolean) => {
                                handleChange(checked);
                                setFieldValue(
                                  "lock",
                                  parseInt(checked ? "1" : "0")
                                );
                              }}
                            />
                          </Col>
                        </Col>
                      </FormGroup>
                      <ModalFooter className={style.footerPadding}>
                        <Button
                          type="button"
                          color="danger"
                          size="md"
                          onClick={onShowForm}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          size="md"
                          //disabled={!edit && !isValid}
                        >
                          Save
                        </Button>
                      </ModalFooter>
                    </Form>
                  );
                }}
              />
            </TabPane>
            <TabPane tabId="2">
              <ImportDevices
                fileDeviceType={fileDeviceType}
                generateDeviceTypes={this._generateDeviceTypes(deviceTypes)}
                progressPercentage={progressPercentage}
                animated={animated}
                generateReleaseCycle={this._generateDeviceTypes(releaseCycle)}
                onChangeDeviceId={(name: string, value: string) => {
                  this.setState(state => ({
                    fileDeviceType: {
                      ...state.fileDeviceType,
                      [name]: value
                    }
                  }));
                }}
                onLock={(checked: boolean) => {
                  this.setState(state => ({
                    fileDeviceType: {
                      ...state.fileDeviceType,
                      isLock: checked ? "1" : "0"
                    }
                  }));
                }}
                file={this.state.file}
                handleCsvUpload={this._handleCsvUpload}
                handleDrop={this._handleDrop}
                onShowForm={onShowForm}
                validate={validate(validationSchemaImport)}
                errorFile={errorFile}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

export default withRouter<any, any>(DevicesForm);
