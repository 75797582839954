import { message } from "antd";

export const TAKE_SCREENSHOT = [
  "ALLOW_REMOTE_ASSISTANT",
  "KEY",
  "APP",
  "UPDATE",
  "KEY_PRESS",
  "KEY_PRESS_APPS",
  "KEY_PRESS_HOME",
  "KEYCODE_DPAD_UP",
  "KEYCODE_DPAD_LEFT",
  "KEYCODE_DPAD_RIGHT",
  "KEYCODE_DPAD_DOWN",
  "KEYCODE_DPAD_CENTER",
  "KEYCODE_VOLUME_DOWN",
  "KEYCODE_VOLUME_UP",
  "KEYCODE_MUTE",
  //"KEYCODE_VOLUME_CONTROL",
  "UPDATE_DEVICE_STATUS",
  "LOCK_UNLOCK",
  "DISPLAY",
  "REBOOT",
  "CHECK_OTA_UPDATE",
  "ADB_COMMAND",
  "SHELL_COMMAND",
  "TAKE_SCREENSHOT",
  "REMOVE_APP",
  "INSTALL_APP",
  "KEY_PRESS_CUSTOM",
  "KEYBOARD_INPUT",
  "TAP_SCREEN",
  "KEYCODE_SETTINGS"
];

export const successAlert = (msg: string) => {
  message.destroy();
  message.success({
    content: msg,
    style: {
      marginTop: "10vh"
    }
  });
};

export const errorAlert = (msg: string) => {
  message.destroy();
  message.error({
    content: msg,
    style: {
      marginTop: "10vh"
    }
  });
};

export const GET_STATUS = [
  "KEYCODE_VOLUME_CONTROL",
  "KEYCODE_MUTE",
  "REMOVE_APP",
  "INSTALL_APP"
];

export const downloadTxtFile = (log: string) => {
  const element = document.createElement("a");
  const file = new Blob([log], { type: "text/plain;charset=utf-8" });
  element.href = URL.createObjectURL(file);
  element.download = "log.txt";
  document.body.appendChild(element);
  element.click();
};

export const downloadImage = (img: string) => {
  let a = document.createElement("a");
  a.href = "data:image/png;base64," + img;
  a.download = "Image.jpg";
  a.click();
};
