import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Alert,
  Card,
  CardHeader,
  CardBody,
  Row,
  CardFooter,
  Form,
  Container,
  Spinner
} from "reactstrap";
import { Formik } from "formik";
import style from "./OperatorConfigPage.module.scss";
import { Switch } from "antd";
import {
  updateOperatorConfig,
  getOperatorConfig
} from "../../common/api/operators-config";
import {
  PublicAPI,
  PushNotifications,
  SpeedTest,
  Analytics,
  SelfProvision
} from "./components";
import { getDeviceTypes } from "common/api/device_types";
import Handshake from "./components/Handshake";

const OperatorConfigPage = () => {
  let timeout: any = null;
  const [operatorConfig, setOperatorConfig] = useState<any>(null);
  const [deviceTypes, setDeviceTypes] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPublicKey, setLoadingPublicKey] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<any>(null);
  const [responseSuccess, setResponseSuccess] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      const result = await getOperatorConfig();
      if (result?.status === 200) {
        setOperatorConfig({ ...result?.data?.data });
      }

      const { status: StatusDevideType, data } = await getDeviceTypes(
        1,
        null,
        null,
        10000
      );

      if (StatusDevideType === 200 && data?.resource?.data) {
        setDeviceTypes(data.resource.data);
      }
    };
    fetchData();
  }, [responseSuccess]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [timeout]);

  const _renderAlert: Function = () => {
    if (responseSuccess || responseError) {
      return (
        <Alert
          in={responseSuccess || responseError}
          color={responseError ? "danger" : "success"}
          tag="h5"
          className="mt-3"
        >
          {responseSuccess || responseError}
        </Alert>
      );
    }
  };

  const _renderButton: Function = () => {
    return (
      <Button
        type="submit"
        size="lg mr-2"
        color="primary"
        className={style.addButton}
      >
        <i className="material-icons">save</i>Save All
      </Button>
    );
  };

  const _renderSwitchLabel = ({ key, keyChild, valueKeyChild }) => {
    return (
      <div className={style.labelSwitchWrap}>
        <Col xs="9">
          <Label className={style.labelSwitch} htmlFor={keyChild}>
            {parseKeyTitle(keyChild, key)}
          </Label>
        </Col>
        <Col xs="3">
          <Switch
            style={{ float: "right" }}
            size="small"
            defaultChecked={valueKeyChild}
            disabled={
              typeof operatorConfig[key]?.status === "boolean" &&
              !operatorConfig[key]?.status &&
              keyChild !== "status"
            }
            onChange={(checked: boolean) => {
              setOperatorConfig({
                ...operatorConfig,
                [key]: {
                  ...operatorConfig[key],
                  [keyChild]: checked
                }
              });
            }}
          />
        </Col>
      </div>
    );
  };

  const _renderInputTextLabel = ({
    key,
    keyChild,
    valueKeyChild,
    index,
    handleChange
  }) => {
    return (
      <>
        <Label className={style.labelSwitch} htmlFor={keyChild}>
          {parseKeyTitle(keyChild, key)}
        </Label>
        {_renderWrapInput({
          key,
          keyChild,
          valueKeyChild,
          index,
          handleChange
        })}
      </>
    );
  };

  const _renderWrapInput = ({
    key,
    keyChild,
    valueKeyChild,
    index,
    handleChange
  }) => {
    if (keyChild !== "publicAPI") {
      return (
        <>
          <Input
            type={validadeTypeInput(keyChild)}
            id={valueKeyChild + index}
            name={keyChild}
            defaultValue={valueKeyChild}
            placeholder={`Enter ${parseKeyTitle(keyChild)}`}
            readOnly={
              typeof operatorConfig[key]?.status === "boolean" &&
              !operatorConfig[key]?.status
            }
            onChange={e => {
              setOperatorConfig({
                ...operatorConfig,
                [key]: {
                  ...operatorConfig[key],
                  [keyChild]: e.target.value
                }
              });
            }}
          />
          {keyChild === "logo" && valueKeyChild && (
            <img src={valueKeyChild} className={style.logo} alt="Logo" />
          )}
        </>
      );
    }
  };

  const _renderBox = ({ side, handleChange }) => {
    if (operatorConfig) {
      return Object.entries(operatorConfig)
        .map(([key, keyValue], index) => {
          return (
            <div className={style.contentWrapper} key={key + index}>
              <Card>
                <CardHeader>
                  <h3 className={style.title}>
                    <i className="material-icons ">{parseKeyLogo(key)}</i>
                    <strong>{parseKeyTitle(key)}</strong>
                  </h3>
                </CardHeader>

                {key === "publicApi" && (
                  <PublicAPI
                    values={operatorConfig?.publicApi}
                    onReset={onReset}
                    loading={loadingPublicKey}
                    handleChange={newValue =>
                      setOperatorConfig({
                        ...operatorConfig,
                        [key]: {
                          ...operatorConfig[key],

                          ...newValue
                        }
                      })
                    }
                  />
                )}

                {key === "pushNotifications" && (
                  <PushNotifications
                    values={operatorConfig?.pushNotifications}
                    handleChange={newValue =>
                      setOperatorConfig({
                        ...operatorConfig,
                        [key]: {
                          ...operatorConfig[key],

                          ...newValue
                        }
                      })
                    }
                  />
                )}
                {key === "speedTest" && (
                  <SpeedTest
                    values={operatorConfig?.speedTest}
                    handleChange={newValue =>
                      setOperatorConfig({
                        ...operatorConfig,
                        [key]: {
                          ...operatorConfig[key],

                          ...newValue
                        }
                      })
                    }
                  />
                )}

                {key === "selfProvision" && (
                  <SelfProvision
                    values={operatorConfig?.selfProvision?.configs}
                    deviceTypes={deviceTypes}
                    onRemove={newValue => {
                      setOperatorConfig({
                        ...operatorConfig,
                        selfProvision: {
                          configs: [
                            ...Object.entries(newValue).map(
                              ([, value]) => value
                            )
                          ]
                        }
                      });
                    }}
                    handleChange={newValue => {
                      setOperatorConfig({
                        ...operatorConfig,
                        selfProvision: {
                          configs: [
                            ...operatorConfig[key].configs.filter(
                              c => c.id !== newValue.id
                            ),

                            {
                              status: 0,
                              ...operatorConfig[key].configs.find(
                                c => c.id === newValue.id
                              ),
                              ...newValue
                            }
                          ]
                        }
                      });
                    }}
                  />
                )}

                {/* {console.log({ operatorConfig: operatorConfig?.handshake })} */}
                {key === "handshake" && (
                  <Handshake
                    handshakeKeys={operatorConfig?.handshake?.handshakeKeys}
                    values={operatorConfig?.handshake?.keys}
                    onRemove={newValue => {
                      setOperatorConfig({
                        ...operatorConfig,
                        handshakeKeys: operatorConfig?.handshake?.handshakeKeys,
                        handshake: {
                          keys: [
                            ...Object.entries(newValue).map(
                              ([, value]) => value
                            )
                          ]
                        }
                      });
                    }}
                    handleChange={newValue => {
                      setOperatorConfig({
                        ...operatorConfig,
                        handshake: {
                          handshakeKeys: !!newValue.handshakeKeys,
                          keys: [
                            ...operatorConfig[key].keys.filter(c => {
                              return c.id && c.id !== newValue.id;
                            }),
                            // {
                            //   ...operatorConfig[key].keys.find(c => {
                            //     return c.id && c.id === newValue.id;
                            //   })
                            // },
                            { ...(newValue.id ? newValue : null) }
                          ].filter(el => el?.id)
                        }
                      });
                    }}
                  />
                )}
                {key === "analytics" && (
                  <Analytics
                    values={operatorConfig?.analytics}
                    handleChange={newValue =>
                      setOperatorConfig({
                        ...operatorConfig,
                        [key]: {
                          ...operatorConfig[key],

                          ...newValue
                        }
                      })
                    }
                  />
                )}
                {key !== "publicApi" &&
                  key !== "pushNotifications" &&
                  key !== "handshake" &&
                  key !== "selfProvision" &&
                  key !== "speedTest" &&
                  key !== "analytics" && (
                    <CardBody>
                      {keyValue
                        ? Object
                            //@ts-ignore
                            .entries(keyValue)
                            .map(([keyChild, valueKeyChild], indexChild) => {
                              return (
                                <FormGroup key={key + keyChild + index} row>
                                  {typeof valueKeyChild === "boolean"
                                    ? _renderSwitchLabel({
                                        key,
                                        keyChild,
                                        valueKeyChild
                                      })
                                    : _renderInputTextLabel({
                                        key,
                                        keyChild,
                                        valueKeyChild,
                                        index,
                                        handleChange
                                      })}
                                </FormGroup>
                              );
                            })
                        : null}
                    </CardBody>
                  )}
              </Card>
            </div>
          );
        })
        .filter((item, indexFilter) => side(indexFilter));
    }
  };

  const isNumber = key => {
    const arr = ["interval", "updateInterval", "specialInterval"];
    return arr.some(i => i === key);
  };

  const isEmail = key => {
    const email = ["to", "cc", "bcc"];
    return email.includes(key) || key.toLowerCase().indexOf("email") > -1
      ? true
      : false;
  };

  const validadeTypeInput = (key: string) => {
    if (isNumber(key)) {
      return "number";
    } else if (key.toLowerCase().indexOf("url") > -1) {
      return "url";
    } else if (key === "keys") {
      return "textarea";
    } else {
      return isEmail(key) ? "email" : "text";
    }
  };

  const parseKeyTitle = (key, keyMaster = "") =>
    parseLabelsForOtherStrings(key, keyMaster) ||
    key.replace(/([a-z0-9])([A-Z])/g, "$1 $2");

  const parseKeyLogo = key => {
    const newStrings = {
      details: "speaker_notes",
      disconnect: "phonelink_off",
      lock: "lock",
      analytics: "bug_report",
      pushNotifications: "add_alert",
      alarms: "alarm",
      publicApi: "public",
      authApi: "fingerprint",
      speedTest: "shutter_speed",
      serviceDesk: "live_help"
    };
    return newStrings[key] || "device_unknown";
  };

  const parseLabelsForOtherStrings = (key, keyMaster) => {
    const keyToShow = key && keyMaster === "" ? "keyMaster" + key : key;
    const newStrings = {
      to: "Report email to",
      cc: "Report email cc",
      bcc: "Report email bcc",
      logo: "Icon",
      details: "Operator details",
      graceperiod: "Graceperiod (in days)",
      timeout: "Timeout Active Devices (in minutes)",
      timeoutInactive: "Timeout Inactive Devices (in minutes)",
      checkInterval: "Check Interval (in minutes)",
      interval: "Interval (in hours)",
      authApi: "External Authentication Api",
      gracefulPeriod: "Devices outside graceful period",
      USBDebug: "USB debug",
      tcUrl: "Terms and conditions Url",
      excludeInactive: "Exclude inactive and unprovisioned devices from lock",
      status: "Enable",
      reportUI: "Report Support App UI"
    };
    return newStrings[keyToShow];
  };

  const onSubmit = async () => {
    clearTimeout(timeout);
    setLoading(true);
    const result = await updateOperatorConfig({
      ...operatorConfig,
      publicApi: {
        status: operatorConfig.publicApi.status,
        key: operatorConfig.publicApi.status ? operatorConfig.publicApi.key : ""
      }
    });

    if (result?.statusText === "Created") {
      setResponseSuccess("Configurations updated!");
      timeout = setTimeout(() => {
        setResponseSuccess("");
      }, 4000);
    } else {
      setResponseError(
        result?.data?.error?.message ||
          result?.response?.data?.error ||
          result?.data?.message ||
          "Error"
      );
      timeout = setTimeout(() => {
        setResponseError("");
      }, 6000);
    }

    timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const _loading = () => {
    return (
      <div className="d-flex justify-content-center m-5 in">
        <Spinner color="primary" />
      </div>
    );
  };

  const onReset = async values => {
    setLoadingPublicKey(true);
    const result = await updateOperatorConfig({
      ...operatorConfig,
      publicApi: {
        status: "1",
        key: ""
      }
    });
    if (result?.statusText === "Created") {
      setResponseSuccess("API key has been reset!");
      timeout = setTimeout(() => {
        setResponseSuccess("");
      }, 4000);
    } else {
      setResponseError(result?.response?.data?.error || result?.data?.message);
    }

    timeout = setTimeout(() => {
      setLoadingPublicKey(false);
    }, 1000);
  };

  const _wrapForm = () => {
    return (
      <div>
        <Formik
          initialValues={operatorConfig}
          enableReinitialize={true}
          onSubmit={onSubmit}
          render={({ handleChange }) => (
            <Form onSubmit={onSubmit}>
              <CardHeader className={style.floatButtons}>
                {localStorage.getItem("acr") !== "OPR_VIEWER" &&
                  _renderButton()}
              </CardHeader>
              {_renderAlert()}
              <Row className="p-3">
                <Col md="6">
                  {_renderBox({
                    side: num => num % 2 === 0,
                    handleChange
                  })}
                </Col>
                <Col md="6">
                  {_renderBox({
                    side: num => num % 2 !== 0,
                    handleChange
                  })}
                </Col>
              </Row>
              <CardFooter className={style.floatButtons}>
                {localStorage.getItem("acr") !== "OPR_VIEWER" &&
                  _renderButton()}
              </CardFooter>
            </Form>
          )}
        />
      </div>
    );
  };

  return (
    <main className={"main admin-details-page-wrapper in"}>
      <Container fluid>
        <Row>
          <div className="card-title col-12">
            <h1>
              <i className="material-icons settings" />
              Configurations
            </h1>
          </div>
        </Row>
        <Card>{operatorConfig && !loading ? _wrapForm() : _loading()}</Card>
      </Container>
    </main>
  );
};

export default OperatorConfigPage;
