import React, { memo, useMemo, useState } from "react";
import {
  CardBody,
  FormGroup,
  Label,
  Input,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Button
} from "reactstrap";
import { Switch } from "antd";
import { v4 as uuidv4 } from "uuid";
import style from "../OperatorConfigPage.module.scss";

interface IPropsInterface {
  values: any;
  handleChange: any;
  deviceTypes: {
    operator_id: string;
    profile_picture: string;
    name: string;
  };
  onRemove: (newValue: any) => void;
}

const Form = ({ value, deviceTypes, handleChange }) => {
  const [status, setStatus] = useState(!!value.status);
  const generateOptions = () => {
    return deviceTypes.map(item => (
      <option value={item?.id} key={item?.id}>
        {item.name}
      </option>
    ));
  };
  return (
    <div className={style.contentWrapper}>
      <CardBody>
        <FormGroup row>
          <Col xs="4">
            <Label htmlFor="status">Status</Label>
          </Col>
          <Col xs="8" md="8">
            <Switch
              style={{ float: "right" }}
              size="small"
              defaultChecked={status}
              onChange={(checked: boolean) => {
                handleChange({
                  status: checked ? 1 : 0
                });
                setStatus(checked);
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="12">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              defaultValue={value.name}
              onChange={e => {
                handleChange({
                  name: e.target.value
                });
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="12">
            <Label htmlFor="operator_device_type_id">
              Device Profile to provision
            </Label>
            {deviceTypes && (
              <Input
                type="select"
                id="key"
                name="key"
                // readOnly={!status}
                required
                defaultValue={
                  value.operator_device_type_id
                    ? value.operator_device_type_id
                    : ""
                }
                onChange={e => {
                  handleChange({
                    operator_device_type_id: e.target.value
                  });
                }}
              >
                {<option value="">Select Device Profile</option>}
                {generateOptions()}
              </Input>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="12">
            <Label htmlFor="selfProvisionId">Self Provision ID</Label>
            <Input
              type="text"
              id={`selfProvisionId-${value.id}`}
              name="selfProvisionId"
              placeholder="Self Provision ID"
              defaultValue={value.id}
              readOnly={true}
              onChange={e => {
                handleChange({
                  id: e.target.id
                });
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="12">
            <Label htmlFor="serial_regex">Serial Regex</Label>
            <Input
              type="text"
              id={`serial_regex-${value.id}`}
              name="serial_regex"
              placeholder="Serial Regex"
              defaultValue={value.serial_regex}
              readOnly={!status}
              onChange={e => {
                handleChange({
                  serial_regex: e.target.value
                });
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="12">
            <Label htmlFor="mac_regex">Mac Regex</Label>
            <Input
              type="text"
              name="mac_regex"
              placeholder="Mac Regex"
              defaultValue={value.mac_regex}
              readOnly={!status}
              onChange={e => {
                handleChange({
                  mac_regex: e.target.value
                });
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="12">
            <Label htmlFor="build_regex">Build Regex</Label>
            <Input
              type="text"
              name="build_regex"
              placeholder="Build Regex"
              defaultValue={value.build_regex}
              readOnly={!status}
              onChange={e => {
                handleChange({
                  build_regex: e.target.value
                });
              }}
            />
          </Col>
        </FormGroup>
      </CardBody>
    </div>
  );
};

const SelfProvision = ({
  values,
  deviceTypes,
  handleChange,
  onRemove
}: IPropsInterface) => {
  const [config, setConfig] = useState(values);

  const form = useMemo(
    () =>
      config.map((value, i) => (
        <Card key={value.id}>
          <CardHeader>
            <strong>{value.name || `Config ${i + 1}`} </strong>
          </CardHeader>
          <CardBody>
            <Form
              value={value}
              deviceTypes={deviceTypes}
              handleChange={newValue =>
                handleChange({
                  // ...config.find(c => c.id === value.id),
                  id: value.id,
                  ...newValue
                })
              }
            />
          </CardBody>
          <CardFooter>
            <Button
              color="danger"
              className={style.addButton}
              onClick={() => {
                setConfig([
                  ...config.filter(itemToDelete => itemToDelete.id !== value.id)
                ]);

                onRemove({
                  ...config.filter(itemToDelete => itemToDelete.id !== value.id)
                });
              }}
            >
              <i className="material-icons">delete</i>Remove
            </Button>
          </CardFooter>
        </Card>
      )),
    [config, deviceTypes, handleChange, onRemove]
  );

  return (
    <div className="row form-group mt-0 m-4">
      <div className="w-100">
        {form}
        <Button
          color="primary"
          className={style.addButton}
          onClick={() => {
            const id = uuidv4();
            setConfig([
              ...config,
              {
                id,
                operator_id: "",
                operator_device_type_id: "",
                status: 0,
                serial_regex: null,
                mac_regex: "",
                build_regex: ""
              }
            ]);
          }}
        >
          <i className="material-icons">add</i>Add New Config
        </Button>
      </div>
    </div>
  );
};

export default memo(SelfProvision);
