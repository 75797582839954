import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { doLogout } from "../../common/api/authorization";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav
} from "reactstrap";
import { AppNavbarBrand, AppSidebarToggler } from "@coreui/react";
import logo from "../../assets/img/brand/wbc.svg";
import sygnet from "../../assets/img/brand/sygnet.svg";
import style from "./Navbar.module.scss";

interface IStateInterface {
  dropdownOpen: boolean;
}

class Navbar extends Component<any, IStateInterface> {
  constructor (props: any) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
  }

  _toggle = (): void => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  render (): React.ReactNode {
    const { dropdownOpen }: { dropdownOpen: boolean } = this.state;
    const { numItems } = this.props;

    return (
      <Fragment>
        {
          numItems > 1 && (
            <AppSidebarToggler className="d-lg-none" display="md" mobile />
          )
        }
        <AppNavbarBrand
          onClick={() => this.props.history.push("/dashboard")}
          full={{ src: logo, width: 140, alt: "WBC LOGO" }}
          minimized={{
            src: sygnet,
            width: 30,
            height: 30,
            alt: "WBC LOGO",
          }}
          style={{ cursor: "pointer" }}
          className={style.logoImg}
        />
        {
          numItems > 1 && <AppSidebarToggler className="d-md-down-none" display="lg" />
        }
        
        <Nav className={`ml-auto ${style.avatar}`} navbar>
          <Dropdown nav isOpen={dropdownOpen} toggle={this._toggle}>
            <DropdownToggle nav>
              <i className="material-icons ">face</i>
            </DropdownToggle>
            <DropdownMenu right className={style.profileDropdown}>
              <DropdownItem header tag="div" className="text-center">
                <strong>Account</strong>
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.history.push("/manage/profile")}
              >
                <i className="material-icons ">face</i>
                Profile
              </DropdownItem>
              {/* <DropdownItem
                onClick={() => this.props.history.push("/manage/profile")}
              >
                <i className="fa icon-settings" />
                Operator Config
              </DropdownItem> */}
              <DropdownItem onClick={doLogout}>
                <i className="material-icons ">exit_to_app</i>
                Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Fragment>
    );
  }
}

export default withRouter(Navbar);
