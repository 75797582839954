import React, { memo } from "react";
import {
  CardBody,
  FormGroup,
  Label,
  Input,
  Col,
  CardFooter,
  Button,
  Spinner
} from "reactstrap";
import style from "../AdminConfigPage.module.scss";

interface IPropsInterface {
  values: any;
  handleChange: any;
  onReset(values: any): any;
  loading: boolean;
}

const RemoteAssistance = ({
  values,
  handleChange,
  onReset,
  loading
}: IPropsInterface) => {
  return (
    <div className={style.contentWrapper}>
      <CardBody>
        <FormGroup row>
          <Col xs="4">
            <Label htmlFor="consolePassword">Console password</Label>
          </Col>
          <Col xs="8" md="8">
            <Input
              type="password"
              id="consolePassword"
              name="consolePassword"
              placeholder="Console password"
              defaultValue={values.consolePassword || ""}
              onChange={e => {
                handleChange({ consolePassword: e.currentTarget.value });
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="12">
            <Label htmlFor="secretKey">Secret Key</Label>
            {loading ? (
              <div className="d-flex justify-content-center m-5 in">
                <Spinner color="primary" />
              </div>
            ) : (
              <Input
                type="textarea"
                style={{ height: values.key && "170px" }}
                id="secretKey"
                name="secretKey"
                placeholder="Secret key"
                value={values.secretKey ? values.secretKey : ""}
                onChange={handleChange}
                readOnly
              />
            )}
          </Col>
        </FormGroup>
      </CardBody>
      <CardFooter className={style.floatButtons}>
        <Button onClick={onReset} size="lg mr-2" color="danger">
          <i className="fa fa-lock" /> Reset Key
        </Button>
      </CardFooter>
    </div>
  );
};

export default memo(RemoteAssistance);
