import React, { Component, memo } from "react";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";

const uninstallApkPackage: { uninstallApkPackage: string } = {
  uninstallApkPackage: ""
};

interface IPropsInterface {
  modalTitle: string;
  isOpen: boolean;
  onToggle(): void;
  onUninstallApk(uninstallApkPackage): void;
  installedApps: Array<{
    application_name: string;
    package_name: string;
    version_code: string;
  }>;
}

class UninstallApkModal extends Component<IPropsInterface, any> {
  constructor(props) {
    super(props);
    this.state = {
      uninstallApkPackage: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IPropsInterface): void {
    if (this.props !== nextProps) {
      this.setState({
        uninstallApkPackage: null
      });
    }
  }

  _onUninstallApk: any = async () => {
    const { onUninstallApk } = this.props;
    const { uninstallApkPackage } = this.state;
    await onUninstallApk(uninstallApkPackage);
  };

  _generateOptions: Function = (
    optionsData: Array<{
      application_name: string;
      package_name: string;
      version_code: string;
    }>
  ): Array<JSX.Element> => {
    const staticLabel = {
      application_name: "Select APK",
      package_name: "-1",
      version_code: "-1"
    };
    const optionsDataWithFirstLael = [staticLabel, ...optionsData];

    return optionsDataWithFirstLael.map(item => (
      <option value={item.package_name} key={item.version_code}>
        {item.application_name ? item.application_name : item.package_name}
      </option>
    ));
  };

  render(): React.ReactNode {
    const { isOpen, onToggle, installedApps }: IPropsInterface = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={onToggle}>Uninstall APK</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={uninstallApkPackage}
            validate={() => {}}
            onSubmit={this._onUninstallApk}
            render={({
              values,
              errors,
              touched,
              status,
              dirty,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              handleReset,
              setTouched
            }): any => (
              <Row>
                <Col lg="6">
                  <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                    <FormGroup>
                      <Label for="uninstall_apk_package" />
                      {installedApps && installedApps.length > 0 ? (
                        <Input
                          type="select"
                          name="uninstallApkPackage"
                          id="uninstallApkPackage"
                          placeholder="Enter APK package name"
                          required
                          onChange={(event: any): any => {
                            handleChange(event);
                            this.setState({
                              uninstallApkPackage: event.target.value
                            });
                          }}
                          onBlur={handleBlur}
                          value={values.uninstallApkPackage}
                        >
                          {this._generateOptions(installedApps)}
                        </Input>
                      ) : (
                        "You have no apk to uninstall."
                      )}
                      <FormFeedback>{errors.uninstallApkPackage}</FormFeedback>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            )}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={onToggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={this._onUninstallApk}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withRouter<any, any>(memo(UninstallApkModal));
