import React, { Component } from "react";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { Container, Spinner, Alert } from "reactstrap";
import {
  IDevicesInterface,
  IFilteredDevices,
  IFilteredRemoteAssistances,
  IRemoteAssistanceInterface,
  ISingleRomInterface
} from "../../common/interfaces";
import {
  getRemoteAssistanceDevices,
  getRemoteAssistances,
  startRemoteAssistanceSession
} from "../../common/api/remote-assistances";
import RemoteAssistancesTable from "../../app-components/Tables/RemoteAssistancesTable";
import queryString from "query-string";
import { getDevices } from "../../common/api/devices";
import { getDeviceTypesConfig } from "../../common/api/device_types";
import RemoteAssistanceModal from "../../app-components/ModalComponent/RemoteAssistanceModal";

interface IPropsInterface {
  match: any;
  location: any;
  history: any;
}

interface IStateInterface {
  remoteAssistances: IRemoteAssistanceInterface | null;
  devices: IDevicesInterface | null;
  roms: Array<ISingleRomInterface> | null;
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
  showRemoteAssistanceModal: boolean;
  remoteAssistanceAuthorized: boolean;
  deviceId: number | null;
  errorMessage: string;
  resultPairingCode: {
    responseMessage: string;
    elementValid: any;
  };
  remoteSupportStatus: boolean;
  serviceDeskStatus: boolean;
}

class RemoteAssistancesListPage extends Component<
  IPropsInterface,
  IStateInterface
> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      remoteAssistances: null,
      devices: null,
      roms: [],
      filtering: {
        orderBy: null,
        direction: null
      },
      showRemoteAssistanceModal: false,
      remoteAssistanceAuthorized: false,
      deviceId: null,
      errorMessage: "",
      resultPairingCode: {
        responseMessage: "",
        elementValid: null
      },
      remoteSupportStatus: false,
      serviceDeskStatus: false
    };
  }

  async UNSAFE_componentWillReceiveProps(
    nextProps: IPropsInterface
  ): Promise<void> {
    const {
      filtering: { orderBy, direction }
    }: IStateInterface = this.state;
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;
    const {
      match: {
        params: { pageNumber: nextPageNumber }
      }
    }: IPropsInterface = nextProps;

    if (nextPageNumber && pageNumber !== nextPageNumber) {
      try {
        await this._getRemoteAssistances(nextPageNumber, orderBy, direction);
      } catch (e) {
        // console.log(e);
      }
    }
  }

  async componentDidMount(): Promise<void> {
    const {
      match: {
        params: { pageNumber }
      },
      history
    }: IPropsInterface = this.props;
    if (this.props.location.search) {
      this._deepLink(history);
    }

    try {
      await this._getRemoteAssistances(pageNumber);
    } catch (e) {
      // console.log(e);
    }
  }

  _deepLink = async history => {
    const {
      servicedesk,
      mac_address,
      provision_id,
      serial_number,
      uid,
      code,
      pairing_code,
      integration_uuid
    } = queryString.parse(this.props.location.search);

    console.log({ pairing_code, integration_uuid });
    const result = await getDevices({
      mac_address,
      provision_id,
      serial_number,
      uid,
      pairing_code,
      integration_uuid
    });
    if (result?.data?.length === 1) {
      const { id: idDevice, operator_device_type_id } = result?.data[0];
      const {
        data: {
          data: { remoteSupport: resultGetDeviceTypesConfig }
        }
      } = await getDeviceTypesConfig(operator_device_type_id);

      if (!resultGetDeviceTypesConfig.status) {
        this.setState({
          errorMessage:
            "This device cannot be accessed because its device profile has Remote Support Disabled"
        });
        return;
      }

      if (!servicedesk) {
        this.setState({
          remoteAssistanceAuthorized: false,
          deviceId: idDevice,
          showRemoteAssistanceModal: true
        });
      } else {
        try {
          const result2 = await startRemoteAssistanceSession({
            operator_device_id: idDevice,
            service_desk_id: servicedesk,
            authorized: 0
          });

          if (result2 && result2.status === 201) {
            history.push(`/remote-assistance/${result2.data.resource.id}`);
          }
        } catch (e) {
          //console.info(e);
        }
      }
    } else if (code) {
      const result = await this._findPairingCode({
        pairing_code: code.toString()
      });

      if (result) {
        this._toggleRemoteAssistanceModal();
      } else {
        this.setState({
          errorMessage: "There is no device available for this pairing code!"
        });
      }
    } else {
      this.setState({ errorMessage: "Device not found" });
      return;
    }
  };

  _getRemoteAssistances = async (
    pageNumber: number | string = 1,
    orderBy?: string | null,
    direction?: string | null
  ): Promise<void> => {
    try {
      const result: {
        data: { resource: string };
      } = await getRemoteAssistances({
        current_page: pageNumber,
        orderBy,
        direction
      });

      if (result && get(result, "data.resource")) {
        this.setState({
          remoteAssistances: get(result, "data.resource")
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _toggleRemoteAssistanceModal: any = (): void => {
    const { showRemoteAssistanceModal }: IStateInterface = this.state;
    this.setState({
      showRemoteAssistanceModal: !showRemoteAssistanceModal
    });
  };

  _onFilter: any = (data: { orderBy: string; direction: string }): void => {
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;

    if (data) {
      const {
        orderBy,
        direction
      }: { orderBy: string; direction: string } = data;
      if (orderBy) {
        this.setState(
          {
            filtering: {
              orderBy,
              direction: direction || null
            }
          },
          async () => {
            const {
              filtering: { orderBy, direction }
            }: IStateInterface = this.state;
            try {
              await this._getRemoteAssistances(pageNumber, orderBy, direction);
            } catch (e) {
              // console.log(e);
            }
          }
        );
      }
    }
  };

  _getFilteredDevices = async (data: IFilteredDevices): Promise<void> => {
    try {
      const result: {
        data: { resource: string };
      } = await getRemoteAssistanceDevices(data);

      if (result && get(result, "data.resource")) {
        this.setState({ devices: get(result, "data.resource") });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _findPairingCode = async (pairing_code): Promise<any> => {
    try {
      const result: {
        data: { resource: string };
      } = await getRemoteAssistanceDevices(pairing_code);

      if (result && get(result, "data.resource.total") === 1) {
        return {
          responseMessage: "Success",
          elementValid: get(result, "data.resource.data[0]")
        };
      } else {
        return false;
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _getFilteredRemoteAssistances = async (
    data: IFilteredRemoteAssistances
  ): Promise<void> => {
    try {
      const result: {
        data: { resource: string };
      } = await getRemoteAssistances(data);

      if (result && get(result, "data.resource")) {
        this.setState({ remoteAssistances: get(result, "data.resource") });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  render(): JSX.Element {
    const {
      remoteAssistances,
      filtering,
      devices,
      remoteAssistanceAuthorized,
      deviceId,
      showRemoteAssistanceModal,
      errorMessage,
      resultPairingCode
    }: IStateInterface = this.state;

    if (!remoteAssistances) {
      return (
        <div className="d-flex justify-content-center col-12 mt-5 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <main className="users-table-wrapper in main">
        <Container fluid>
          <Alert isOpen={errorMessage !== ""} className="mt-3" color="danger">
            <strong> {errorMessage} </strong>
          </Alert>
          {showRemoteAssistanceModal && (
            <RemoteAssistanceModal
              toggle={this._toggleRemoteAssistanceModal}
              authorized={remoteAssistanceAuthorized}
              operatorDeviceId={deviceId}
              isOpen={showRemoteAssistanceModal}
            />
          )}
          <RemoteAssistancesTable
            remoteAssistances={remoteAssistances}
            filtering={filtering}
            devices={devices}
            getFilteredDevices={this._getFilteredDevices}
            findPairingCode={this._findPairingCode}
            resultPairingCode={resultPairingCode}
            getFilteredRemoteAssistances={this._getFilteredRemoteAssistances}
            onFilter={this._onFilter}
          />
        </Container>
      </main>
    );
  }
}

export default withRouter<any, any>(RemoteAssistancesListPage);
