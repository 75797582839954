import React, { Component, memo } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { get } from "lodash";
import { withRouter } from "react-router";
import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  Spinner
} from "reactstrap";
import * as Yup from "yup";
import {
  ICreateOperatorInterface,
  ISingleCityInterface,
  ISingleOperatorInterface
} from "../../../common/interfaces";

const validationSchema = function(values) {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    type_id: Yup.string().nullable(true),
    service_type_id: Yup.string().nullable(true),
    status: Yup.string()
      .max(1, "Status is required")
      .required("Status is required")
  });
};

const validationEditSchema = function(values) {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    city_id: Yup.string().nullable(true),
    type_id: Yup.string().nullable(true),
    service_type_id: Yup.string().nullable(true),
    status: Yup.string()
      .max(1, "Status is required")
      .required("Status is required")
  });
};

const validate = getValidationSchema => {
  return values => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError = validationError => {
  const FIRST_ERROR = 0;

  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
};

const initialValues = {
  country_id: "",
  city_id: "",
  name: "",
  type_id: "",
  service_type_id: "",
  status: ""
};

interface PropsInterface {
  statuses: Array<{ id: number; name: string }>;
  serviceTypes: Array<{ name: string; id: number }> | string;
  types: Array<{ name: string; id: number }> | string;
  title: string;
  data?: ISingleOperatorInterface;
  match: any;
  edit?: boolean;
  OperatorForm: [];
  countries: [];
  onShowForm(): void;

  createNewOperator?(data: ICreateOperatorInterface): void;

  onEditOperator?(data: ICreateOperatorInterface): void;
}

interface StateInterface {
  //countries: Array<ISingleCountryInterface>;
  cities: Array<ISingleCityInterface>;
  responseError: { details: object; message: string } | boolean | any;
  suggestions: Array<{ id: number; name: string }>;
  countryCode: string;
  countryId: string;
  loading: boolean;
}

class OperatorForm extends Component<PropsInterface, StateInterface> {
  constructor(props) {
    super(props);
    this.state = {
      responseError: null,
      //countries: [],
      cities: [],
      suggestions: [],
      countryCode: "",
      countryId: "",
      loading: false
    };
  }

  static propTypes = {
    onShowForm: PropTypes.func.isRequired,
    data: PropTypes.shape({}),
    title: PropTypes.string.isRequired,
    statuses: PropTypes.array,
    onEditOperator: PropTypes.func
  };

  _generateOptionsList = (list: any) => {
    if (list && list.length) {
      return list.map(item => (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      ));
    }
  };

  _onSubmit = async (
    values,
    { setSubmitting, setErrors, resetForm }
  ): Promise<void> => {
    const { createNewOperator, onEditOperator, edit } = this.props;
    this.setState({ loading: true });

    // set null values if empty selected
    if (values.country_id === "") values.country_id = null;
    if (values.city_id === "") values.city_id = null;
    if (values.type_id === "") values.type_id = null;
    if (values.service_type_id === "") values.service_type_id = null;

    if (edit && onEditOperator) {
      const result = await onEditOperator(values);

      if (get(result, "response.data.error") || get(result, "data.error")) {
        this.setState({
          responseError:
            get(result, "response.data.error") ||
            get(result, "data.error") ||
            "Error"
        });
      } else {
        this.setState({
          responseError: false
        });
      }
      this.setState({ loading: false });
    } else if (createNewOperator) {
      const result = await createNewOperator(values);

      if (get(result, "response.data.error") || get(result, "data.error")) {
        this.setState({
          responseError:
            get(result, "response.data.error") ||
            get(result, "data.error") ||
            "Error"
        });
      } else {
        this.setState({
          responseError: false
        });
      }
      this.setState({ loading: false });
    }
  };

  _renderAlert = () => {
    const { responseError } = this.state;
    if (responseError) {
      const { details } = responseError;

      return (
        <Alert color="warning">
          {responseError.message && responseError.message}
          <br />
          {details &&
            Object.keys(details).map((item, index) => (
              <div key={index}>{`${item}: ${details[item]}`}</div>
            ))}
        </Alert>
      );
    }
  };

  render() {
    const {
      onShowForm,
      data,
      statuses,
      types,
      serviceTypes,
      edit
    } = this.props;
    const { loading }: StateInterface = this.state;

    if (loading) {
      return (
        <div className="d-flex justify-content-center mt-3 in">
          <Spinner color="primary" />
        </div>
      );
    }

    initialValues.status = get(statuses[1], "id").toString();

    return (
      <div>
        {this._renderAlert()}
        <Formik
          initialValues={data || initialValues}
          validate={
            edit ? validate(validationEditSchema) : validate(validationSchema)
          }
          onSubmit={this._onSubmit}
          render={({
            setFieldValue,
            values,
            errors,
            touched,
            status,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid
          }) => (
            <Form onSubmit={handleSubmit} noValidate name="simpleForm">
              <FormGroup row className="my-0">
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      valid={touched.name && !!values.name}
                      invalid={touched.name && !!errors.name}
                      placeholder="Enter name"
                      onChange={handleChange}
                      //onBlur={handleBlur}
                      value={values.name}
                    />
                    <FormFeedback>{errors.name}</FormFeedback>
                  </FormGroup>
                </Col>

                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="type_id">Type</Label>
                    <Input
                      type="select"
                      name="type_id"
                      id="type_id"
                      valid={touched.type_id && !!values.type_id}
                      invalid={touched.type_id && !!errors.type_id}
                      onChange={e => {
                        handleChange(e);
                        setFieldValue("type_id", parseInt(e.target.value));
                      }}
                      //onBlur={handleBlur}
                      defaultValue={values.type_id || ""}
                    >
                      <option label="Select Type" value="">
                        Select Type
                      </option>
                      {this._generateOptionsList(types)}
                    </Input>
                    <FormFeedback>{errors.type_id}</FormFeedback>
                  </FormGroup>
                </Col>

                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="service_type_id">Service Type</Label>
                    <Input
                      type="select"
                      name="service_type_id"
                      id="service_type_id"
                      valid={
                        touched.service_type_id && !!values.service_type_id
                      }
                      invalid={
                        touched.service_type_id && !!errors.service_type_id
                      }
                      onChange={e => {
                        handleChange(e);
                        setFieldValue(
                          "service_type_id",
                          parseInt(e.target.value)
                        );
                      }}
                      //onBlur={handleBlur}
                      defaultValue={values.service_type_id || ""}
                    >
                      <option label="Select service type" value="">
                        Select service type
                      </option>
                      {this._generateOptionsList(serviceTypes)}
                    </Input>
                    <FormFeedback>{errors.service_type_id}</FormFeedback>
                  </FormGroup>
                </Col>

                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="status">Status</Label>
                    <Input
                      type="select"
                      name="status"
                      id="status"
                      valid={touched.status && !errors.status}
                      invalid={touched.status && !!errors.status}
                      onChange={e => {
                        handleChange(e);
                        setFieldValue("status", parseInt(e.target.value));
                      }}
                      //onBlur={handleBlur}
                      value={values.status}
                    >
                      <option value={100} label="Select status">
                        Select status
                      </option>
                      {this._generateOptionsList(statuses)}
                    </Input>
                    <FormFeedback>{errors.status}</FormFeedback>
                  </FormGroup>
                </Col>
              </FormGroup>
              <ModalFooter className="footerPadding">
                <Button
                  type="button"
                  color="danger"
                  size="md"
                  onClick={onShowForm}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  size="md"
                  //disabled={!edit && !isValid}
                >
                  Save
                </Button>
              </ModalFooter>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default withRouter<any, any>(memo(OperatorForm));
