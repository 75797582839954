import { fetchAuthRulesSuccess } from "../actions/actionTypes";

const initialState = {
  roles: []
};

const roles = (state = initialState, action) => {
  switch (action.type) {
    case fetchAuthRulesSuccess:
      // localStorage.setItem('roles', action.payload);
      return {
        ...state,
        roles: action.payload
      };
    default:
      return state;
  }
};

export default roles;
