import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Alert,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { Formik, Form } from "formik";
import style from "../../DeviceProfileDetailsPage.module.scss";
import * as Yup from "yup";
import ImgNotFound from "../../images/ic_launcher.png";
import { Switch } from "antd";

const initialValues = {
  package_name: "",
  get_status: 0,
  append_status: 0,
  get_lock: 0,
  set_lock: 0,
  get_integration_id: 0,
  get_uuid: 0,
  get_provision_id: 0
};

const validationSchema: any = function(values: any): any {
  //const passwordValue = get(values, "password");
  return Yup.object().shape({
    package_name: Yup.string()
      .required("Package Name is required")
      .matches(
        /^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/,
        "Wrong package name format."
      )
  });
};

const validate: any = (getValidationSchema: any): any => {
  return (values: any): any => {
    const validationSchema: any = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError: any = (validationError: any): any => {
  const FIRST_ERROR: any = 0;

  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
};

const AddAppPermissionsForm = ({
  data,
  onShowForm,
  onEditDeviceProfileConfig,
  responseError,
  typeId
}) => {
  const READ_ONLY = ["id"];
  const NOT_SHOW = [
    "id",
    "operator_device_type_id",
    "operator_id",
    "created_at",
    "updated_at",
    "updated_by",
    "created_by",
    "deleted_at",
    "logo"
  ];
  const SWITCH = [
    "get_status",
    "append_status",
    "get_lock",
    "set_lock",
    "get_integration_id",
    "get_uuid",
    "get_provision_id"
  ];

  const [switchItems, setSwitchItems] = useState({
    get_status: 0,
    append_status: 0,
    get_lock: 0,
    set_lock: 0,
    get_integration_id: 0,
    get_uuid: 0,
    get_provision_id: 0
  });

  useEffect(() => {
    if (data) {
      const {
        get_status,
        append_status,
        get_lock,
        set_lock,
        get_integration_id,
        get_uuid,
        get_provision_id
      } = data;
      setSwitchItems({
        get_status,
        append_status,
        get_lock,
        set_lock,
        get_integration_id,
        get_uuid,
        get_provision_id
      });
    }
  }, [data]);

  const _renderAlert: Function = () => {
    if (responseError) {
      return (
        <Alert color="danger">
          {responseError && responseError?.message && (
            <p>
              {responseError?.error?.message ||
                responseError?.message ||
                "Error"}
            </p>
          )}

          {responseError?.error?.details &&
            Object.keys(responseError?.error?.details).map(
              (item: any, index: any) => (
                <div key={index}>
                  <strong>{item}: </strong>
                  {responseError?.error?.details[item]}
                </div>
              )
            )}
        </Alert>
      );
    }
  };

  const addDefaultSrc = ev => (ev.target.src = ImgNotFound);

  const _renderInputLabel = ({
    key,
    keyValue,
    handleChange,
    errors,
    touched
  }) => {
    return (
      <>
        <Label className={style.labelSwitch} htmlFor={key}>
          {parseKeyTitle(key)}
        </Label>
        {key !== "logo" ? (
          <Input
            type="text"
            id={key}
            name={key}
            value={typeof keyValue === "string" ? keyValue : keyValue?.value}
            placeholder={
              READ_ONLY.includes(key)
                ? `${parseKeyTitle(key)}`
                : `Enter ${parseKeyTitle(key)}`
            }
            readOnly={READ_ONLY.includes(key)}
            onChange={handleChange}
            valid={touched[key] && !errors[key]}
            invalid={touched[key] && !!errors[key]}
          />
        ) : (
          <img alt="Img logo" width="50px" onError={addDefaultSrc} />
        )}
        <FormFeedback>{errors[key]}</FormFeedback>
      </>
    );
  };

  const _renderSwitchLabel = ({ key, keyValue, handleChange, errors }) => {
    return (
      <>
        <Label className={style.labelSwitch} htmlFor={key}>
          {parseKeyTitle(key)}:
        </Label>
        <Switch
          className={style.fieldSwitch}
          size="small"
          defaultChecked={keyValue}
          onChange={(checked: boolean) => {
            const valueToSend = checked ? 1 : 0;
            setSwitchItems({ ...switchItems, [key]: valueToSend });
          }}
        />
        <FormFeedback>{errors.name}</FormFeedback>
      </>
    );
  };

  const _renderField = valuesForm => {
    return Object.entries(valuesForm?.values)
      .filter(
        ([key]) => !NOT_SHOW.includes(key) && valuesForm.switchFilter(key)
      )
      .map(([key, keyValue], index) => {
        return (
          <Col xs="6" key={key + index}>
            <FormGroup>
              {valuesForm.renderFieldType({ key, keyValue, ...valuesForm })}
            </FormGroup>
          </Col>
        );
      });
  };

  const _onSubmit = async data => {
    const { package_name } = data;

    await onEditDeviceProfileConfig({
      type: typeId,
      package_name,
      ...switchItems
    });
  };

  const parseKeyTitle = (key: string) =>
    parseLabelsForOtherStrings(key) || key.split("_").join(" ");

  const parseLabelsForOtherStrings = key => {
    const newStrings = {
      release: "Release",
      release_candidate: "Release Candidate",
      beta: "Beta",
      alpha: "Alpha",
      quality_assurance: "Quality Assurance",
      development: "Development"
    };
    return newStrings[key];
  };

  return (
    <div>
      {_renderAlert()}
      <Formik
        initialValues={data || initialValues}
        validate={validate(validationSchema)}
        enableReinitialize={true}
        onSubmit={_onSubmit}
        render={({ values, errors, touched, handleChange }: any): any => {
          return (
            <Form>
              <FormGroup row className={style.FormAddApp}>
                {_renderField({
                  values,
                  handleChange,
                  errors,
                  touched,
                  switchFilter: key => !SWITCH.includes(key),
                  renderFieldType: values => _renderInputLabel(values)
                })}

                <Col xs="12" className={style.TitleAvailable}>
                  <ModalHeader>Permissions</ModalHeader>
                </Col>

                {_renderField({
                  values,
                  handleChange,
                  errors,
                  touched,
                  switchFilter: key => SWITCH.includes(key),
                  renderFieldType: values => _renderSwitchLabel(values)
                })}
              </FormGroup>
              <ModalFooter className={style.footerPadding}>
                <Button
                  type="button"
                  color="danger"
                  size="md"
                  onClick={onShowForm}
                >
                  Cancel
                </Button>
                {localStorage.getItem("acr") !== "OPR_VIEWER" && (
                  <Button
                    type="submit"
                    color="primary"
                    size="md"
                    //disabled={!edit && !isValid}
                  >
                    Save
                  </Button>
                )}
              </ModalFooter>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default AddAppPermissionsForm;
