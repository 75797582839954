import io from "socket.io-client";

const urlSocketLegacy = process.env.REACT_APP_PHP_DOMAIN_SOCKET;
const urlSocket = process.env.REACT_APP_PHP_DOMAIN_REMOTE;

export interface ISocketInterface {
  id: string | null;
  mac_address: string | null;
  serial_number: string | null;
  remote_assistance_id: string | null;
}

export const onCreateSocket = (key?: string) => {
  let url = "";
  if (key) {
    url = urlSocket;
  } else {
    url = urlSocketLegacy;
  }
  return io(url, {
    query: key ? `token=${key}` : null,
    transports: ["websocket"]
  });
};
