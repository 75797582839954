import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Spinner,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  FormGroup,
  CardFooter,
  Alert
} from "reactstrap";
import { Formik, Form } from "formik";
import style from "../DeviceProfileDetailsPage.module.scss";
import {
  updateDeviceTypesRoms,
  getDeviceTypesRoms
} from "../../../common/api/device_types";
import { useRef } from "react";

interface IPropsInterface {
  match: any;
  history: { push(data: any): void };
}

const DeviceProfileRoms = ({ history, match }: IPropsInterface) => {
  let timeout: any = null;

  const [configurations, setConfigurations] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<any>(null);
  const [responseSuccess, setResponseSuccess] = useState<string>("");
  const [dataForm, setDataForm] = useState<any>({});
  const [showFirstHeader, setShowFirstHeader] = useState<boolean>(true);
  const refBlock: any = useRef(null);

  useEffect(() => {
    if (refBlock) {
      const html: HTMLElement = refBlock?.current;
      const windowHeight: number = window?.innerHeight;
      setShowFirstHeader(windowHeight > 700 && html.offsetHeight > 600);
    }
  }, []);

  useEffect(() => {
    const {
      params: { ID }
    } = match;
    const fetchData = async () => {
      const result = await getDeviceTypesRoms(ID);
      if (result?.data?.data) {
        setConfigurations(result?.data?.data);
        setDataForm({
          ...result?.data?.data
        });
      }
    };
    fetchData();
  }, [match, responseSuccess]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [timeout]);

  const _onEditDeviceProfileConfig: any = async (
    data
  ): Promise<undefined | object> => {
    const {
      params: { ID }
    } = match;

    try {
      setLoading(true);
      const result: {
        status: number;
        error: string | any;
        message: string;
        data: any;
      } = await updateDeviceTypesRoms(ID, data);

      if (result && result.status === 200) {
        setResponseSuccess("Device Profile Roms updated!");
        timeout = setTimeout(() => {
          setResponseSuccess("");
        }, 4000);
      } else {
        setResponseError(
          result?.error ||
            result?.message ||
            result?.data?.error?.message ||
            "Error"
        );
        timeout = setTimeout(() => {
          setResponseError("");
        }, 6000);
      }

      timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      return err;
    }
  };

  const _renderAlert: Function = () => {
    if (responseSuccess || responseError) {
      return (
        <Alert
          in={responseSuccess || responseError}
          color={responseError ? "danger" : "success"}
          tag="h5"
          className="mt-3"
        >
          {responseSuccess || responseError}
        </Alert>
      );
    }
  };

  const _loading = () => {
    return (
      <div className="d-flex justify-content-center m-5 in">
        <Spinner color="primary" />
      </div>
    );
  };

  const _renderBox = ({ side }) => {
    const listOfElements = [
      { id: 5, name: "Release" },
      { id: 4, name: "Release Candidate" },
      { id: 3, name: "Beta" },
      { id: 2, name: "Alpha" },
      { id: 1, name: "Quality Assurance" },
      { id: 0, name: "Development" }
    ];

    const profileRoms = configurations?.profileRoms;

    if (configurations) {
      return listOfElements
        .map(({ name, id: releaseCycleId }, index) => {
          const currentElement = profileRoms.find(
            el => el.releaseCycleId === releaseCycleId
          );

          return (
            <div className={style.contentWrapper} key={index}>
              <Card>
                <CardHeader>
                  <h3 className={style.title}>
                    <strong>{name}</strong>
                  </h3>
                </CardHeader>
                <CardBody>
                  <FormGroup row>
                    <Input
                      data-name="teste"
                      type="select"
                      defaultValue={currentElement?.romId}
                      onChange={e => {
                        const { value } = e.currentTarget;
                        const findCurrentRom = configurations?.romOptions.find(
                          el => el.id === value
                        );
                        setDataForm({
                          ...dataForm,
                          profileRoms: [
                            ...dataForm.profileRoms.filter(
                              el => el.releaseCycleId !== releaseCycleId
                            ),
                            {
                              releaseCycleId: releaseCycleId,
                              releaseCycleName: name,
                              romId: findCurrentRom && findCurrentRom.id,
                              romName: findCurrentRom && findCurrentRom.name
                            }
                          ]
                        });
                      }}
                    >
                      <option value="">Select ROM</option>;
                      {configurations?.romOptions.map(
                        ({ id, name: nameConfig }, indexChild) => {
                          return (
                            <option key={id} value={id}>
                              {nameConfig}
                            </option>
                          );
                        }
                      )}
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </div>
          );
        })
        .filter((item, indexFilter) => side(indexFilter));
    }
  };

  const _renderButton: Function = () => {
    return (
      <Button
        type="submit"
        size="lg mr-2"
        color="primary"
        className={style.addButton}
      >
        <i className="material-icons">save</i>Save All
      </Button>
    );
  };

  const onSubmit = async () => {
    const ids = dataForm?.profileRoms?.map(el => ({
      [el.releaseCycleId]: el.romId
    }));

    await _onEditDeviceProfileConfig(
      ids && ids.length === 1 && Object.values(ids[0])[0] === undefined
        ? {}
        : ids.reduce((acc, next) => ({ ...acc, ...next }), {})
    );
  };

  if (loading) {
    return _loading();
  }

  return (
    <div ref={refBlock}>
      <Formik
        initialValues={configurations}
        enableReinitialize={true}
        // validate={validate(validationSchema)}
        onSubmit={onSubmit}
        render={() => (
          <Form>
            <CardHeader
              hidden={!showFirstHeader}
              className={style.floatButtons}
            >
              {localStorage.getItem("acr") !== "OPR_VIEWER" && _renderButton()}
            </CardHeader>
            {_renderAlert()}
            <Row className="p-3">
              <Col md="6">
                {_renderBox({
                  side: num => num % 2 === 0
                })}
              </Col>
              <Col md="6">
                {_renderBox({
                  side: num => num % 2 !== 0
                })}
              </Col>
            </Row>
            <CardFooter className={style.floatButtons}>
              {localStorage.getItem("acr") !== "OPR_VIEWER" && _renderButton()}
            </CardFooter>
          </Form>
        )}
      />
    </div>
  );
};

export default withRouter<any, any>(DeviceProfileRoms);
