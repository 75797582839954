import React, { Component } from "react";
import { Formik } from "formik";
import { get } from "lodash";
import {
  ICreateRomInterface,
  ISingleRomInterface
} from "../../../common/interfaces";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  ModalFooter,
  Spinner,
  Input,
  Col,
  Label,
  ModalHeader
} from "reactstrap";
import style from "./RomsForm.module.scss";
import FormInputs from "../FormInputs";
import { getRoms } from "common/api/roms";

const validationSchema: any = function(values: any): any {
  return Yup.object().shape({
    package_path: Yup.string()
      .min(1)
      .required("Package path is required"),
    build_number: Yup.string()
      .min(1)
      .required("Build Number is required"),
    name: Yup.string()
      .min(1)
      .required("Name is required"),
    status: Yup.string()
      .max(1, "Status is required")
      .required("Status is required"),
    release_date: Yup.string()
      .min(1)
      .required("Scheduled Update is Required"),
    operation_system: Yup.string()
      .min(1)
      .required("Operating System date is required")
  });
};

const validationEditSchema: any = function(values: any): any {
  return Yup.object().shape({
    name: Yup.string()
      .min(1)
      .required("Name is required"),
    package_path: Yup.string().required("Package path is required"),
    build_number: Yup.string()
      .min(1)
      .required("Build Number is required"),
    status: Yup.string()
      .max(1, "Status is required")
      .required("Status is required"),
    release_date: Yup.string()
      .min(1)
      .required("Scheduled Update is Required"),
    operation_system: Yup.string()
      .min(1)
      .required("Operating System date is required")
  });
};

const validate: any = (getValidationSchema: any): any => {
  return (values: any): any => {
    const validationSchema: any = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError: any = (validationError: any): any => {
  const FIRST_ERROR: any = 0;

  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
};

const initialValues: ICreateRomInterface = {
  operation_system: "",
  operation_system_version: "",
  build_number: "",
  kernel_version: "",
  baseband_version: "",
  relase_note: "",
  package_path: "",
  release_date: "",
  ui_version: "",
  wipe_data: null,
  force_update: null,
  status: 100,
  name: "",
  is_incremental: 0,
  whitelist: [],
  blacklist: [],
  a_b: 0,
  cascade_link: null
};

interface IPropsInterface {
  statuses: Array<{ id: number; name: string }>;
  operatingSystems: Array<{ id: number; name: string }>;
  title: string;
  data?: ISingleRomInterface;
  match: any;
  edit?: boolean;
  roms: any;
  onShowForm(): void;
  createNewRom?(data: ICreateRomInterface): void;
  onEditRom?(data: ICreateRomInterface): void;
}

interface IStateInterface {
  responseError: { details: object; message: string } | boolean | any;
  loading: boolean;
  whiteList: any;
  blackList: any;
  currentsValue: any;
  nameRoms: Array<{ id: number; name: string }>;
}

class RomsForm extends Component<IPropsInterface, IStateInterface> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      responseError: null,
      loading: false,
      whiteList: 1,
      blackList: 1,
      currentsValue: null,
      nameRoms: []
    };
  }

  async componentDidMount() {
    const { data } = this.props;
    const roms = await getRoms({ pageNumber: 1, limite: 5000 });
    if (data) {
      this.setState({
        whiteList: parseInt(data.whitelist.length),
        blackList: parseInt(data.blacklist.length)
      });
    }
    this.setState({
      nameRoms: roms?.data.map((rom, i) => ({
        id: rom?.name || i,
        name: rom?.name || "no name"
      }))
    });
  }

  _generateStatusOptions: Function = (
    statusList: Array<{ name: string; id: number }>
  ) => {
    return statusList.map((item: { id: number; name: string }) => (
      <option value={item.id} key={item.id}>
        {item.name}
      </option>
    ));
  };

  _generateOperatingSystemOptions: Function = (
    operatingSystemList: Array<{ name: string; id: number }>
  ) => {
    return (
      operatingSystemList &&
      operatingSystemList.map((item: { id: number; name: string }) => (
        <option value={item.name} key={item.id}>
          {item.name}
        </option>
      ))
    );
  };

  _onSubmit: any = async (values: any, { resetForm }: any): Promise<void> => {
    const { createNewRom, onEditRom, edit }: IPropsInterface = this.props;
    const { blacklist, whitelist } = values;
    const parseValues = {
      ...values,
      whitelist: [
        ...whitelist.filter((build: { build: any }) => !!build?.build)
      ],
      blacklist: [
        ...blacklist.filter((build: { build: any }) => !!build?.build)
      ],
      operation_system: "Android"
    };
    this.setState({ loading: true, currentsValue: values });
    if (edit && onEditRom) {
      const result: any = await onEditRom(parseValues);
      if (get(result, "response.data.error") || get(result, "data.error")) {
        this.setState({
          responseError:
            get(result, "response.data.error") ||
            get(result, "data.error") ||
            "Error",
          loading: false
        });
      } else {
        this.setState({
          responseError: false,
          loading: false
        });
      }
    } else if (createNewRom) {
      const result: any = await createNewRom(parseValues);
      if (
        get(result, "response.data.error") ||
        get(result, "data.error") ||
        result.status > 201
      ) {
        this.setState({
          responseError:
            get(result, "data.error") ||
            get(result, "response.data.error") ||
            "Error",
          loading: false
        });
      } else {
        this.setState({
          responseError: false,
          loading: false
        });
        resetForm();
      }
    }
  };

  _renderAlert: Function = () => {
    const { responseError }: IStateInterface = this.state;
    if (responseError) {
      const {
        details
      }: { details: object; message: string } | boolean | any = responseError;

      return (
        <Alert color="warning">
          {!details && responseError && get(responseError, "message") && (
            <p>{get(responseError, "message")}</p>
          )}

          {details &&
            Object.keys(details).map((item: any, index: any) => (
              <div key={index}>{`${details[item]}`}</div>
            ))}
        </Alert>
      );
    }
  };

  render(): JSX.Element {
    const { onShowForm, data, edit, statuses }: IPropsInterface = this.props;
    const {
      loading,
      whiteList,
      blackList,
      currentsValue,
      nameRoms
    }: IStateInterface = this.state;

    if (loading) {
      return (
        <div className="d-flex justify-content-center mt-3 in">
          <Spinner color="primary" />
        </div>
      );
    }
    initialValues.status = get(statuses[1], "id");
    initialValues.operation_system = "Android";

    return (
      <div>
        {this._renderAlert()}

        <Formik
          initialValues={currentsValue || data || initialValues}
          validate={
            edit ? validate(validationEditSchema) : validate(validationSchema)
          }
          onSubmit={this._onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValid,
            getValues
          }: any): any => {
            return (
              <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                <FormGroup row className="my-0">
                  <FormInputs
                    onChange={handleChange}
                    htmlFor="name"
                    placeholder="Enter Name"
                    valid={touched.name && !errors.name}
                    value={values.name}
                    id="name"
                    labelTitle="Name"
                    invalid={touched.name && !!errors.name}
                    onBlur={handleBlur}
                    name="name"
                    errors={errors.name}
                    type="text"
                    hasColumn={true}
                  />
                  <FormInputs
                    onChange={(event: any): any => {
                      handleChange(event);
                      setFieldValue("status", parseInt(event.target.value));
                    }}
                    htmlFor="status"
                    valid={touched.status && !errors.status}
                    value={values.status}
                    id="status"
                    labelTitle="Status"
                    invalid={touched.status && !!errors.status}
                    onBlur={handleBlur}
                    name="status"
                    errors={errors.status}
                    type="select"
                    option={
                      <option value={100} label="Select Status">
                        Select Status
                      </option>
                    }
                    renderData={this._generateStatusOptions(statuses)}
                    required={true}
                    hasColumn={true}
                  />
                  <FormInputs
                    onChange={handleChange}
                    htmlFor="build_number"
                    placeholder="Enter Build Number"
                    valid={touched.build_number && !errors.build_number}
                    value={values.build_number}
                    id="build_number"
                    labelTitle="Build Number"
                    invalid={touched.build_number && !!errors.build_number}
                    onBlur={handleBlur}
                    name="build_number"
                    errors={errors.build_number}
                    type="text"
                    hasColumn={true}
                  />
                  <FormInputs
                    onChange={handleChange}
                    htmlFor="cascade_link"
                    placeholder="Enter Cascade build link"
                    valid={touched.cascade_link && !errors.cascade_link}
                    value={values.cascade_link}
                    id="cascade_link"
                    labelTitle="Cascade build link"
                    invalid={touched.cascade_link && !!errors.cascade_link}
                    onBlur={handleBlur}
                    name="cascade_link"
                    errors={errors.cascade_link}
                    type="select"
                    required={true}
                    hasColumn={true}
                    option={
                      <option label="Select Cascade build link">
                        Cascade build link
                      </option>
                    }
                    renderData={this._generateStatusOptions(nameRoms)}
                  />
                  <FormInputs
                    onChange={handleChange}
                    htmlFor="kernel_version"
                    placeholder="Enter Kernel Version"
                    valid={touched.kernel_version && !errors.kernel_version}
                    value={values.kernel_version}
                    id="kernel_version"
                    labelTitle="Kernel Version"
                    invalid={touched.kernel_version && !!errors.kernel_version}
                    onBlur={handleBlur}
                    name="kernel_version"
                    errors={errors.kernel_version}
                    type="text"
                    hasColumn={true}
                  />
                  <FormInputs
                    onChange={handleChange}
                    htmlFor="baseband_version"
                    placeholder="Enter Baseband Version"
                    valid={touched.baseband_version && !errors.baseband_version}
                    value={values.baseband_version}
                    id="baseband_version"
                    labelTitle="Baseband Version"
                    invalid={
                      touched.baseband_version && !!errors.baseband_version
                    }
                    onBlur={handleBlur}
                    name="baseband_version"
                    errors={errors.baseband_version}
                    type="text"
                    hasColumn={true}
                  />
                  <FormInputs
                    htmlFor="ui_version"
                    placeholder="Enter UI Version"
                    valid={touched.ui_version && !errors.ui_version}
                    value={values.ui_version}
                    id="ui_version"
                    labelTitle="UI Version"
                    invalid={touched.ui_version && !!errors.ui_version}
                    name="ui_version"
                    errors={errors.ui_version}
                    type="text"
                    hasColumn={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormInputs
                    onChange={handleChange}
                    htmlFor="relase_note"
                    placeholder="Enter Release Notes"
                    valid={touched.relase_note && !errors.relase_note}
                    value={values.relase_note}
                    id="relase_note"
                    labelTitle="Release Notes"
                    invalid={touched.relase_note && !!errors.relase_note}
                    onBlur={handleBlur}
                    name="relase_note"
                    errors={errors.relase_note}
                    type="textarea"
                    hasColumn={true}
                  />
                  <FormInputs
                    onChange={setFieldValue}
                    htmlFor="release_date"
                    placeholder="Choose Release Date"
                    valid={touched.release_date && !errors.release_date}
                    value={values.release_date}
                    id="release_date"
                    labelTitle="Scheduled Update"
                    invalid={touched.release_date && !!errors.release_date}
                    onBlur={handleBlur}
                    name="release_date"
                    errors={errors.release_date}
                    type="date"
                    hasColumn={true}
                  />
                  <FormInputs
                    onChange={handleChange}
                    htmlFor="package_path"
                    placeholder="Enter Package Path"
                    valid={touched.package_path && !errors.package_path}
                    value={values.package_path}
                    id="package_path"
                    labelTitle="Package Path"
                    invalid={touched.package_path && !!errors.package_path}
                    onBlur={handleBlur}
                    name="package_path"
                    errors={errors.package_path}
                    type="text"
                    required={true}
                    hasColumn={true}
                  />
                  <FormInputs
                    onChange={handleChange}
                    htmlFor="package_path"
                    placeholder="Enter Local Recovery Path"
                    valid={touched.recovery_path && !errors.recovery_path}
                    value={values.recovery_path}
                    id="recovery_path"
                    labelTitle="Local Recovery Path"
                    invalid={touched.recovery_path && !!errors.recovery_path}
                    onBlur={handleBlur}
                    name="recovery_path"
                    errors={errors.recovery_path}
                    type="text"
                    required={true}
                    hasColumn={true}
                  />

                  <Col xs="12" className={style.TitleAvailable}>
                    <ModalHeader>Flags</ModalHeader>
                  </Col>
                  <FormInputs
                    onChange={setFieldValue}
                    htmlFor="wipe_data"
                    valid={touched.wipe_data && !errors.wipe_data}
                    value={values.wipe_data}
                    id="wipe_data"
                    labelTitle="Wipe Data: "
                    invalid={touched.wipe_data && !!errors.wipe_data}
                    onBlur={handleBlur}
                    name="wipe_data"
                    errors={errors.wipe_data}
                    type="checkbox"
                    required={true}
                    defaultChecked={values.wipe_data ? true : false}
                    colClassName={style.wipeDataCol}
                    inputClassName={style.checkboxInputWipeData}
                    rowInputClassName="d-flex justify-content-between align-items-center mb-2"
                    hasColumn={true}
                  />
                  <FormInputs
                    onChange={setFieldValue}
                    htmlFor="force_update"
                    valid={touched.force_update && !errors.force_update}
                    value={values.force_update}
                    id="force_update"
                    labelTitle="Force Update: "
                    invalid={touched.force_update && !!errors.force_update}
                    onBlur={handleBlur}
                    name="force_update"
                    errors={errors.force_update}
                    type="checkbox"
                    required={true}
                    defaultChecked={values.force_update ? true : false}
                    inputClassName={"d-flex"}
                    hasColumn={true}
                    rowInputClassName="d-flex justify-content-between align-items-center mb-2"
                    colClassName={style.wipeDataCol}
                  />
                  <FormInputs
                    onChange={setFieldValue}
                    htmlFor="is_incremental"
                    valid={touched.is_incremental && !errors.is_incremental}
                    value={values.is_incremental}
                    id="is_incremental"
                    labelTitle="Incremental: "
                    invalid={touched.is_incremental && !!errors.is_incremental}
                    onBlur={handleBlur}
                    name="is_incremental"
                    errors={errors.is_incremental}
                    type="checkbox"
                    required={true}
                    defaultChecked={values.is_incremental ? true : false}
                    inputClassName={style.checkboxInput}
                    hasColumn={true}
                    rowInputClassName="d-flex justify-content-between align-items-center mb-2"
                    colClassName={style.wipeDataCol}
                  />
                  <FormInputs
                    onChange={setFieldValue}
                    htmlFor="a_b"
                    valid={touched.a_b && !errors.a_b}
                    value={values.a_b}
                    id="a_b"
                    labelTitle="A/B: "
                    invalid={touched.a_b && !!errors.a_b}
                    onBlur={handleBlur}
                    name="a_b"
                    errors={errors.a_b}
                    type="checkbox"
                    required={true}
                    defaultChecked={values.a_b ? true : false}
                    inputClassName={style.checkboxInput}
                    hasColumn={true}
                    rowInputClassName="d-flex justify-content-between align-items-center mb-2"
                    colClassName={style.wipeDataCol}
                  />
                  <Col xs="12" className={style.TitleAvailable}>
                    <ModalHeader>Build filter</ModalHeader>
                  </Col>
                  <Col xs={6}>
                    <FormGroup className="mb-1">
                      <Label>White List</Label>
                      {Array.apply(null, Array(whiteList)).map((el, i) => (
                        <Input
                          key={i}
                          className="mb-1"
                          placeholder="Add White List"
                          name={`white_list-${i}`}
                          defaultValue={values.whitelist[i]?.build}
                          onChange={event =>
                            setFieldValue(`whitelist.${i}`, {
                              build: event.target.value.trim()
                            })
                          }
                        />
                      ))}

                      <Button
                        className=" d-flex float-right"
                        color="primary"
                        size="xs"
                        onClick={() =>
                          this.setState(prevState => ({
                            whiteList: prevState.whiteList + 1
                          }))
                        }
                      >
                        <i className="material-icons" style={{ fontSize: 20 }}>
                          add
                        </i>
                      </Button>
                    </FormGroup>
                  </Col>

                  <Col xs={6}>
                    <FormGroup className="mb-1">
                      <Label>Black List</Label>
                      {Array.apply(null, Array(blackList)).map((el, i) => (
                        <Input
                          key={i}
                          className="mb-1"
                          placeholder="Add Black List"
                          name={`black_list-${i}`}
                          defaultValue={values.blacklist[i]?.build}
                          onChange={event => {
                            setFieldValue(`blacklist.${i}`, {
                              build: event.target.value.trim()
                            });
                          }}
                        />
                      ))}

                      <Button
                        className="d-flex float-right mb-3"
                        color="primary"
                        size="md"
                        onClick={() =>
                          this.setState(prevState => ({
                            blackList: prevState.blackList + 1
                          }))
                        }
                      >
                        <i className="material-icons" style={{ fontSize: 20 }}>
                          add
                        </i>
                      </Button>
                    </FormGroup>
                  </Col>
                </FormGroup>

                <ModalFooter className={style.footerPadding}>
                  <Button
                    type="button"
                    color="danger"
                    size="md"
                    onClick={onShowForm}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    size="md"
                    // disabled={(!edit && !isValid) || loading}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}
export default withRouter<any, any>(RomsForm);
