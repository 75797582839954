import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Col,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Alert,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { Formik, Form } from "formik";
import style from "../../DeviceProfileDetailsPage.module.scss";
import * as Yup from "yup";
import ImgNotFound from "../../images/ic_launcher.png";
//import { releaseCycle } from "../../../../common/helper/helper";

const initialValues = {
  key: "",
  package_name: "",
  release: "",
  release_candidate: "",
  beta: "",
  alpha: "",
  quality_assurance: "",
  development: ""
};

const validationSchema: any = function(values: any): any {
  //const passwordValue = get(values, "password");
  return Yup.object().shape({
    package_name: Yup.string().required("Package Name is required"),
    key: Yup.string().required("Key is required")
  });
};

const validate: any = (getValidationSchema: any): any => {
  return (values: any): any => {
    const validationSchema: any = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError: any = (validationError: any): any => {
  const FIRST_ERROR: any = 0;

  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
};

const AddAppConfigForm = ({
  data,
  onShowForm,
  onEditDeviceProfileConfig,
  responseError,
  typeId
}) => {
  const READ_ONLY = ["id"];
  const NOT_SHOW = [
    "id",
    "operator_device_type_id",
    "operator_id",
    "created_at",
    "updated_at",
    "updated_by",
    "created_by",
    "deleted_at",
    "logo"
  ];
  const SWITCH = [
    "release",
    "release_candidate",
    "beta",
    "alpha",
    "quality_assurance",
    "development"
  ];
  const initialSWITCH = useMemo(
    () => [
      "release",
      "release_candidate",
      "beta",
      "alpha",
      "quality_assurance",
      "development"
    ],
    []
  );

  const [renderDataForm, setRenderDataForm] = useState<any>(null);

  useEffect(() => {
    if (data && !renderDataForm) {
      const { value, key, package_name } = data;
      const newMap = initialSWITCH
        .map((el, i) => ({
          [el]: value[i]
        }))
        .reduce((acc, next) => ({ ...acc, ...next }), {});

      setRenderDataForm({
        key,
        package_name,
        ...newMap
      });
    }
  }, [initialSWITCH, data, renderDataForm]);

  const _renderAlert: Function = () => {
    if (responseError) {
      return (
        <Alert color="danger">
          {responseError && responseError?.message && (
            <p>
              {responseError?.error?.message ||
                responseError?.message ||
                "Error"}
            </p>
          )}

          {responseError?.error?.details &&
            Object.keys(responseError?.error?.details).map(
              (item: any, index: any) => (
                <div key={index}>
                  <strong>{item}: </strong>
                  {responseError?.error?.details[item]}
                </div>
              )
            )}
        </Alert>
      );
    }
  };

  const addDefaultSrc = ev => (ev.target.src = ImgNotFound);

  const _renderInputLabel = ({
    key,
    keyValue,
    handleChange,
    errors,
    touched
  }) => {
    return (
      <>
        <Label className={style.labelSwitch} htmlFor={key}>
          {parseKeyTitle(key)}
        </Label>
        {key !== "logo" ? (
          <Input
            type="text"
            id={key}
            name={key}
            value={typeof keyValue === "string" ? keyValue : keyValue?.value}
            placeholder={
              READ_ONLY.includes(key)
                ? `${parseKeyTitle(key)}`
                : `Enter ${parseKeyTitle(key)}`
            }
            readOnly={READ_ONLY.includes(key)}
            onChange={handleChange}
            valid={touched[key] && !errors[key]}
            invalid={touched[key] && !!errors[key]}
          />
        ) : (
          <img alt="Img logo" width="50px" onError={addDefaultSrc} />
        )}
        <FormFeedback>{errors[key]}</FormFeedback>
      </>
    );
  };

  const _renderField = valuesForm => {
    return Object.entries(valuesForm?.values)
      .filter(
        ([key]) => !NOT_SHOW.includes(key) && valuesForm.switchFilter(key)
      )
      .map(([key, keyValue], index) => {
        return (
          <Col xs="6" key={key + index}>
            <FormGroup>
              {valuesForm.renderFieldType({ key, keyValue, ...valuesForm })}
            </FormGroup>
          </Col>
        );
      });
  };

  const _onSubmit = async data => {
    const { key, package_name } = data;
    const newMap = SWITCH.map((el, i) => {
      return {
        id: i,
        value: typeof data[el] === "string" ? data[el] : data[el].value,
        release_name: [el].toString()
      };
    });

    await onEditDeviceProfileConfig({
      type: typeId,
      key,
      package_name,
      value: [...newMap]
    });
  };

  const parseKeyTitle = (key: string) =>
    parseLabelsForOtherStrings(key) || key.split("_").join(" ");

  const parseLabelsForOtherStrings = key => {
    const newStrings = {
      release: "Release",
      release_candidate: "Release Candidate",
      beta: "Beta",
      alpha: "Alpha",
      quality_assurance: "Quality Assurance",
      development: "Development"
    };
    return newStrings[key];
  };

  return (
    <div>
      {_renderAlert()}
      <Formik
        initialValues={renderDataForm || initialValues}
        validate={validate(validationSchema)}
        enableReinitialize={true}
        onSubmit={_onSubmit}
        render={({ values, errors, touched, handleChange }: any): any => {
          return (
            <Form>
              <FormGroup row className={style.FormAddApp}>
                {_renderField({
                  values,
                  handleChange,
                  errors,
                  touched,
                  switchFilter: key => !SWITCH.includes(key),
                  renderFieldType: values => _renderInputLabel(values)
                })}

                <Col xs="12" className={style.TitleAvailable}>
                  <ModalHeader>Releases Cycles</ModalHeader>
                </Col>

                {_renderField({
                  values,
                  handleChange,
                  errors,
                  touched,
                  switchFilter: key => SWITCH.includes(key),
                  renderFieldType: values => _renderInputLabel(values)
                })}
              </FormGroup>
              <ModalFooter className={style.footerPadding}>
                <Button
                  type="button"
                  color="danger"
                  size="md"
                  onClick={onShowForm}
                >
                  Cancel
                </Button>
                {localStorage.getItem("acr") !== "OPR_VIEWER" && (
                  <Button
                    type="submit"
                    color="primary"
                    size="md"
                    //disabled={!edit && !isValid}
                  >
                    Save
                  </Button>
                )}
              </ModalFooter>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default AddAppConfigForm;
