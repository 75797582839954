import React, { Component, Fragment } from "react";
import { Container, Row, Table, Spinner, Col, Input, Alert } from "reactstrap";
import {
  SingleActivityLogsInterface,
  StaticsInterface
} from "../../common/interfaces";
import {
  getStatics,
  getUsers,
  getDashboardProfiles,
  getDashboardControlVersion,
  getDashboardRom
} from "../../common/api/common";
import { getUserProfile } from "../../common/api/authorization";
import { getOperators } from "../../common/api/operators";
import { get, filter } from "lodash";
import { withRouter } from "react-router";
import CardsDashBoard from "../../components/CardsDashBoard";
import Title from "../../components/Title";
import { Pie } from "@ant-design/charts";
import { getFailed, GetFailedProps } from "common/api/failure";
import { Link } from "react-router-dom";

interface IPropsInterface {
  history: { push(data): void };
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
}

interface IStateInterface {
  statics: StaticsInterface | null;
  activityLogs: Array<SingleActivityLogsInterface> | null;
  userProfile: {
    role_id: string;
  } | null;
  users: { total: number; active: [] } | null;
  operators: { total: number; active: [] } | null | any;
  dashboardProfiles: [];
  dashboardControlVersion: [];
  dashboardRom: [];
  OTA: any;
  push: any;
  speedtest: any;
  socket: any;
  install: any;
  errors: any;
  activeTable: { data: [] };
  acknowledgeChecked: Array<string>;
  isLoading: boolean;
  valuesForFilter: any;
}

const SHADE_RED = [
  "#f93751",
  "#e03249",
  "#c72c41",
  "#ae2739",
  "#952131",
  "#7d1c29",
  "#641620",
  "#4b1018",
  "#320b10",
  "#190508",
  "#000000"
];

const SHADE_PURPLE = [
  "#5e5ce6",
  "#5553cf",
  "#4b4ab8",
  "#4240a1",
  "#38378a",
  "#2f2e73",
  "#26255c",
  "#1c1c45",
  "#13122e",
  "#090917",
  "#000000"
];

const keys = [
  {
    value: "type",
    name: "Type"
  },
  {
    value: "acknowledge",
    name: "Acknowledge"
  },

  {
    value: "device_id",
    name: "Device"
  },
  {
    value: "error_code",
    name: "Error code"
  },
  {
    value: "error_data",
    name: "Error data"
  },
  {
    value: "created_at",
    name: "Created"
  }
];

class DashboardPage extends Component<IPropsInterface, IStateInterface> {
  constructor(props) {
    super(props);
    this.state = {
      statics: null,
      activityLogs: null,
      users: null,
      userProfile: null,
      operators: null,
      dashboardProfiles: [],
      dashboardControlVersion: [],
      dashboardRom: [],
      isLoading: false,
      OTA: null,
      push: null,
      speedtest: null,
      install: null,
      socket: null,
      errors: null,
      activeTable: null,
      acknowledgeChecked: [],
      valuesForFilter: {}
    };
  }
  async componentDidMount(): Promise<void> {
    const result = await getStatics();
    const resultUsers = await getUsers();
    const resultDashboardProfiles = await getDashboardProfiles();
    const resultDashboardControlVersion = await getDashboardControlVersion();
    const resultDashboardRom = await getDashboardRom();
    const resulUserProfile = await getUserProfile();

    const otaResult = await getFailed({ type: "OTA" });
    const pushResult = await getFailed({ type: "push" });
    const speedtestResult = await getFailed({ type: "speedtest" });
    const socketResult = await getFailed({ type: "SOCKET" });
    const installResult = await getFailed({ type: "install" });

    if (otaResult?.data?.resource) {
      this.setState({
        OTA: otaResult?.data?.resource,
        push: pushResult?.data?.resource,
        speedtest: speedtestResult?.data?.resource,
        socket: socketResult?.data?.resource,
        errors: otaResult?.data?.resource,
        install: installResult?.data?.resource
      });
    }
    // if (speedtestResult?.data?.resource) {
    //   this.setState({ speedtest: speedtestResult?.data?.resource });
    // }
    // if (pushResult?.data?.resource) {
    //   this.setState({ push: pushResult?.data?.resource });
    // }
    // if (errorsResult?.data?.resource) {
    //   this.setState({ errors: errorsResult?.data?.resource });
    // }

    if (get(resulUserProfile, "data.resource")) {
      this.setState({
        userProfile: get(resulUserProfile, "data.resource")
      });
      if (
        get(resulUserProfile, "data.resource.role_id")?.includes(
          "OPR_SUPPORT_LINE"
        )
      ) {
        document.location.href = "/remote-assistance";
      }
      if (
        get(resulUserProfile, "data.resource.role_id") === "SYS_ADMIN" ||
        get(resulUserProfile, "data.resource.role_id") === "SYS_OWNER"
      ) {
        const operatorsResult = await getOperators();

        if (operatorsResult && get(operatorsResult, "data.resource")) {
          const operators = get(operatorsResult, "data.resource.data");
          const filterActivesOperators = filter(operators, item => item.status)
            .length;
          this.setState({
            operators: {
              total: get(operatorsResult, "data.resource.total"),
              active: filterActivesOperators
            }
          });
        }
      }
    }
    if (get(result, "data.resource")) {
      this.setState({ statics: get(result, "data.resource") });
    }
    //  if (get(activityLogsResult, "data.resource.data")) {
    //    this.setState({
    //      activityLogs: get(activityLogsResult, "data.resource.data"),
    //    });
    //  }
    if (get(resultUsers, "data.resource")) {
      this.setState({
        users: {
          total: get(resultUsers, "data.resource.users.total"),
          active: get(resultUsers, "data.resource.users.active")
        }
      });
    }

    if (
      resultDashboardProfiles.status === 200 &&
      resultDashboardControlVersion.status === 200 &&
      resultDashboardRom.status === 200
    ) {
      this.setState({
        dashboardProfiles: resultDashboardProfiles.data.resource.map(
          (el: { name: string; share: string; count: string }) => ({
            type: el.name,
            value: +el.count,
            shared: +el.share
          })
        ),
        dashboardControlVersion: resultDashboardControlVersion.data.resource.map(
          (el: {
            device_control_version: string;
            share: string;
            count: string;
          }) => ({
            type: el.device_control_version || "no version",
            value: +el.count,
            shared: +el.share
          })
        ),
        dashboardRom: resultDashboardRom.data.resource.map(
          (el: { rom_version: string; share: string; count: string }) => ({
            type: el.rom_version || "no version",
            value: +el.count,
            shared: +el.share
          })
        )
      });
    }
  }

  _redirectToOperatorAdministrators = () => {
    const { history } = this.props;
    history.push(`/operators`);
  };

  _redirectToOperatorUser = () => {
    const { history } = this.props;
    history.push(`/users`);
  };

  _redirectToOperatorDeviceTypes = () => {
    const { history } = this.props;
    history.push(`/device-profiles`);
  };

  _redirectToOperatorDevices = () => {
    const { history } = this.props;
    history.push(`/devices`);
  };

  _renderSpinnerCenter = () => {
    return (
      <div className="d-flex justify-content-center col-12 mt-10">
        <Spinner color="primary" />
      </div>
    );
  };

  _renderTable = activityLogs => {
    return (
      <Table hover bordered striped responsive size="sm">
        <thead>
          <tr>
            <th />
            {this._renderTableFieldNames()}
          </tr>
        </thead>
        <tbody>{this._renderActivityLogsData(activityLogs)}</tbody>
      </Table>
    );
  };

  _renderBaseDashBoard = ({ generateDashboard, activityLogs }) => {
    const spinner = this._renderSpinnerCenter();

    const { OTA, push, speedtest, socket, install, userProfile } = this.state;

    return (
      <Fragment>
        <Row>
          <Title value="Dashboard" icon="dashboard" />
          <Col md={12} hidden={userProfile?.role_id === "SYS_OWNER"}>
            {!!OTA?.total && (
              <Link to="/alarms?OTA">
                <Alert color="danger">
                  {OTA?.total} New OTA error(s) registered
                </Alert>
              </Link>
            )}
            {!!push?.total && (
              <Link to="/alarms?push">
                <Alert color="danger">
                  {push?.total} New Push error(s) registered
                </Alert>
              </Link>
            )}
            {!!socket?.total && (
              <Link to="/alarms?socket">
                <Alert color="danger">
                  {socket?.total} New Socket error(s) registered
                </Alert>
              </Link>
            )}
            {!!install?.total && (
              <Link to="/alarms?install">
                <Alert color="danger">
                  {install?.total} New Install error(s) registered
                </Alert>
              </Link>
            )}
            {!!speedtest?.total && (
              <Link to="/alarms?speedtest">
                <Alert color="danger">
                  {speedtest?.total} New Speedtest error(s) registered
                </Alert>
              </Link>
            )}
          </Col>
          {generateDashboard && get(generateDashboard[0], "values") ? (
            <CardsDashBoard generateDashboard={generateDashboard} />
          ) : (
            spinner
          )}
        </Row>
      </Fragment>
    );
  };

  _renderTableFieldNames = (): Array<JSX.Element> => {
    return keys.map(item => (
      <th style={{ cursor: "default" }} key={item.name}>
        {item.name}
      </th>
    ));
  };

  _renderActivityLogsData = activityLogs => {
    return activityLogs.map(
      (log: SingleActivityLogsInterface, index: number) => {
        const { user, user_id, model, model_id, operation, request_url } = log;

        return (
          <tr key={index + user_id}>
            <td>
              <i className={`activity-log-operation ${operation}`} />
            </td>
            <td>{user}</td>
            <td>{model}</td>
            <td>{model_id}</td>
            <td>{operation}</td>
            <td>{request_url}</td>
          </tr>
        );
      }
    );
  };

  _generateTypeOptions: Function = (
    types: Array<{ name: string; id: number }>
  ) => {
    return (
      types &&
      types.map((item: { id: number; name: string }) => (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      ))
    );
  };

  onSubmit = async ({
    type,
    device_id,
    error_data,
    created_at,
    acknowledge,
    error_code,
    current_page
  }: Partial<GetFailedProps>): Promise<void> => {
    this.setState({
      isLoading: true
    });

    const result = await getFailed({
      type,
      device_id,
      error_data,
      created_at,
      acknowledge,
      error_code,
      current_page
    });

    if (result?.data?.resource) {
      this.setState({
        activeTable: result?.data?.resource,
        isLoading: false,
        valuesForFilter: {
          type,
          device_id,
          error_data,
          created_at,
          acknowledge,
          error_code,
          current_page
        }
      });
    }
  };

  _onChange: any = (page: any) => {
    const { valuesForFilter }: any = this.state;
    console.log({ valuesForFilter });
    this.onSubmit({ ...valuesForFilter, current_page: page || 1 });
  };

  _renderResultRows = () => {
    const { activeTable, acknowledgeChecked } = this.state;

    return activeTable?.data?.map(
      ({
        id,
        acknowledge,
        created_at,
        device_id,
        error_code,
        error_data,
        type
      }) => (
        <tr key={id}>
          <td>{type}</td>
          <td className="d-flex justify-content-center p-0 pt-3">
            {/*  */}
            <Input
              type="checkbox"
              checked={acknowledge || null}
              onChange={e => {
                if (e.target.checked) {
                  this.setState({
                    acknowledgeChecked: [...acknowledgeChecked, id]
                  });
                } else {
                  this.setState({
                    acknowledgeChecked: [
                      ...acknowledgeChecked.filter(el => el !== id)
                    ]
                  });
                }
              }}
            />
          </td>
          <td>
            {
              <a rel="noreferrer" target="_blank" href={`device/${device_id}`}>
                <button
                  type="button"
                  id="info"
                  className="material-icons info_outline btn btn-primary btn-sm"
                ></button>
              </a>
            }
          </td>
          <td>{error_code}</td>
          <td>{error_data}</td>
          <td>{created_at}</td>
        </tr>
      )
    );
  };

  render() {
    const {
      statics,
      users,
      operators,
      userProfile,
      activityLogs,
      dashboardProfiles,
      dashboardControlVersion,
      dashboardRom
    } = this.state;
    let generateDashboard: Array<{
      values: any;
      name: String;
      clickToRedirect: Function | null;
      color: String;
      icon: String;
    }> = [];

    const activeOperator = {
      values: operators,
      name: "Active Operators",
      clickToRedirect: this._redirectToOperatorAdministrators,
      color: "warning",
      icon: "material-icons perm_data_setting"
    };
    const activeUser = {
      values: users,
      name: "Active Users",
      clickToRedirect: this._redirectToOperatorUser,
      color: "primary",
      icon: "material-icons face"
    };

    if (
      get(userProfile, "role_id") === "SYS_ADMIN" ||
      get(userProfile, "role_id") === "SYS_OWNER"
    ) {
      generateDashboard.push(activeOperator, activeUser);
    } else {
      generateDashboard.push(
        {
          values: get(statics, "operator_devices"),
          name: "ACTIVE DEVICES",
          clickToRedirect: this._redirectToOperatorDevices,
          color: "purple",
          icon: "material-icons router"
        },
        {
          values: get(statics, "operator_device_types"),
          name: "Active Device Profiles",
          clickToRedirect: this._redirectToOperatorDeviceTypes,
          color: "danger",
          icon: "material-icons fingerprint"
        },

        activeUser
      );
    }

    const config = {
      appendPadding: 10,
      padding: 10,
      angleField: "shared",
      colorField: "type",
      radius: 1,
      position: "bottom",
      label: {
        type: "horizontal",
        offset: "-30%",
        content: function content(_ref) {
          var percent = _ref.percent;
          return "".concat((percent * 100).toFixed(2).toString(), "%");
        },
        style: {
          fontSize: 14,
          textAlign: "center"
        }
      },
      tooltip: {
        fields: ["value", "type"],
        formatter: datum => {
          return { name: datum.type, value: datum.value };
        }
      }
    };

    return (
      <main className="main in">
        <Container fluid>
          {this._renderBaseDashBoard({
            generateDashboard,
            activityLogs
          })}

          <Row
            hidden={
              !!userProfile &&
              (get(userProfile, "role_id") === "SYS_ADMIN" ||
                get(userProfile, "role_id") === "SYS_OWNER")
            }
          >
            <Col md={4}>
              {dashboardRom && dashboardRom.length ? (
                <>
                  <Title value="Device ROMs" icon="android" />
                  <Pie
                    legend={false}
                    data={dashboardRom}
                    color={[...SHADE_PURPLE]}
                    {...config}
                  />
                </>
              ) : (
                "No data"
              )}
              {dashboardControlVersion && dashboardControlVersion.length ? (
                <>
                  <Title value="Device Control App Version" icon="history" />
                  <Pie
                    legend={false}
                    data={dashboardControlVersion}
                    color={[...SHADE_PURPLE]}
                    {...config}
                  />
                </>
              ) : (
                "No data"
              )}
            </Col>
            <Col md={4}>
              {dashboardControlVersion && dashboardControlVersion.length ? (
                <>
                  <Title value="Devices Per Profile" icon="fingerprint" />
                  <Pie
                    legend={false}
                    data={dashboardProfiles}
                    color={[...SHADE_RED]}
                    {...config}
                  />
                </>
              ) : (
                "No data"
              )}
            </Col>
          </Row>
        </Container>
        {/* {showRemoteAssistanceModal && (
          <RemoteAssistanceModal
            toggle={this._toggleRemoteAssistanceModal}
            authorized={remoteAssistanceAuthorized}
            operatorDeviceId={deviceId}
            operatorProfileId={operatorProfileId}
            isOpen={showRemoteAssistanceModal}
          />
        )} */}
      </main>
    );
  }
}

export default withRouter<any, any>(DashboardPage);
