import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";
import { DatePicker, Switch } from "antd";
import style from "./FormInputs.module.scss";
import moment from "moment";
interface IPropsInterface {
  htmlFor: string;
  labelTitle: string;
  placeholder?: string;
  required?: boolean;
  type: InputType;
  id: string;
  name: string;
  onChange: any;
  onBlur: any;
  value: any;
  valid?: boolean;
  invalid?: boolean;
  option?: React.ReactNode;
  renderData?: Function;
  errors: any;
  defaultChecked?: boolean;
  colClassName?: string;
  inputClassName?: string;
  rowInputClassName?: string;
  xs?: number;
  hasColumn?: boolean;
  romCheckboxFiltering?: string;
  disabled: any;
}

class FormInputs extends Component<IPropsInterface, any> {
  parseToData(data: any): any {
    if (data) {
      if (typeof data === "string" && data.includes("-")) {
        return moment(data);
      } else {
        return moment(parseInt(data) * 1000);
      }
    }
  }

  renderInputs = ({
    renderData,
    option,
    name,
    type,
    id,
    placeholder,
    required,
    onChange,
    value,
    invalid,
    inputClassName,
    defaultChecked,
    disabled,
    valid,
    romCheckboxFiltering,
    errors
  }) => {
    const inputs = [
      {
        type: "select",
        render: () => (
          <Input
            type={type}
            id={id}
            name={name}
            placeholder={placeholder}
            required={required}
            onChange={onChange}
            defaultValue={value}
            invalid={invalid}
            className={inputClassName ? inputClassName : ""}
            defaultChecked={defaultChecked}
            disabled={disabled}
          >
            {option}
            {renderData}
          </Input>
        )
      },

      {
        type: "checkbox",
        render: () => (
          <Switch
            onChange={(checked: boolean) => {
              onChange(name, checked);
            }}
            className={romCheckboxFiltering}
            defaultChecked={defaultChecked}
            size="small"
          />
        )
      },

      {
        type: "date",
        render: () => (
          <div className={style.Picker}>
            <DatePicker
              id={id}
              name={name}
              onChange={valeDate => {
                onChange(name, valeDate?.format("YYYY-MM-DD") || "");
              }}
              format="DD/MM/YYYY"
              className={`form-control ${invalid && style.errorPicker}`}
              defaultValue={this.parseToData(value)}
            />

            {invalid && (
              <div className="invalid-feedback d-block">{errors}</div>
            )}
          </div>
        )
      },
      {
        type: "others",
        render: () => (
          <Input
            type={type}
            id={id}
            name={name}
            placeholder={placeholder}
            required={required}
            onChange={onChange}
            valid={valid}
            invalid={invalid}
            className={inputClassName ? inputClassName : ""}
            defaultChecked={defaultChecked}
            defaultValue={value}
          />
        )
      }
    ];
    const findElement = (typeElement: string) =>
      inputs.find(input => input.type === typeElement);
    return (findElement(type) || findElement("others"))?.render();
  };
  render(): React.ReactNode {
    const {
      htmlFor,
      labelTitle,
      placeholder,
      required,
      type,
      id,
      name,
      onChange,
      value,
      valid,
      invalid,
      option,
      renderData,
      errors,
      colClassName,
      defaultChecked,
      inputClassName,
      rowInputClassName,
      romCheckboxFiltering,
      xs,
      hasColumn,
      disabled
    }: IPropsInterface = this.props;

    if (hasColumn) {
      return (
        <Col xs={xs || 6} className={colClassName ? colClassName : ""}>
          <FormGroup className={rowInputClassName ? rowInputClassName : ""}>
            <Label htmlFor={htmlFor || name}>{labelTitle}</Label>
            {this.renderInputs({
              renderData,
              option,
              name,
              type,
              id,
              placeholder,
              required,
              onChange,
              value,
              invalid,
              inputClassName,
              defaultChecked,
              disabled,
              valid,
              romCheckboxFiltering,
              errors
            })}
            <FormFeedback>{errors}</FormFeedback>
          </FormGroup>
        </Col>
      );
    }

    return (
      <FormGroup className={rowInputClassName ? rowInputClassName : ""}>
        <Label className={romCheckboxFiltering} htmlFor={htmlFor}>
          {labelTitle}
        </Label>
        {type === "select" ? (
          <Input
            type={type}
            id={id}
            name={name}
            placeholder={placeholder}
            required={required}
            onChange={onChange}
            defaultValue={value}
            valid={valid}
            invalid={invalid}
            className={inputClassName ? inputClassName : ""}
            defaultChecked={defaultChecked}
          >
            {option}
            {renderData}
          </Input>
        ) : type === "checkbox" ? (
          <Switch
            onChange={(checked: boolean) => {
              onChange(name, checked);
            }}
            size="small"
            className={romCheckboxFiltering}
            defaultChecked={defaultChecked}
          />
        ) : type === "date" ? (
          <div className={style.Picker}>
            <DatePicker
              id={id}
              name={name}
              onChange={onChange(name)}
              format="DD/MM/YYYY"
              className={inputClassName}
              defaultValue={value}
            />
          </div>
        ) : (
          <Input
            type={type}
            id={id}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            valid={valid}
            invalid={invalid}
            className={inputClassName ? inputClassName : ""}
            defaultChecked={defaultChecked}
            defaultValue={value}
          />
        )}

        <FormFeedback>{errors}</FormFeedback>
      </FormGroup>
    );
  }
}

export default withRouter<any, any>(FormInputs);
