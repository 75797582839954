const protocol = process.env.REACT_APP_WBC_ACCESS_PROTOCOL + "://";
const version = process.env.REACT_APP_PHP_API_VERSION;
export const AUTH_BASE_URL = `${protocol}${process.env.REACT_APP_PHP_DOMAIN_AUTH}${version}`;

export const REACT_APP_PHP_DOMAIN_API: string = `${protocol}${process.env.REACT_APP_PHP_DOMAIN_API}`;

export const CORE_API_DOMAIN: string = `${protocol}${process.env.REACT_APP_PHP_DOMAIN_CORE}${version}`;

export const CORE_API_DOMAIN_V2 = `${protocol}${process.env.REACT_APP_PHP_DOMAIN_CORE}/v2`;

export const LOCATION_API_DOMAIN: string = `${protocol}${process.env.REACT_APP_PHP_DOMAIN_LOCATION}${version}`;

export const REACT_UPLOAD_DOMAIN: string = `${REACT_APP_PHP_DOMAIN_API}/uploads/`;

export const LOGIN_URL = AUTH_BASE_URL + "/auth/login";

export const AUTHORIZATION_URL = AUTH_BASE_URL + "/list/rbac";

export const enableRemoteAssistence =
  process.env.REACT_APP_WBC_ENABLE_REMOTE_ASSISTANT_NOCONFIRM;

export const CORE_API_DOMAIN_UPLOAD: string = `${protocol}${process.env.REACT_APP_PHP_DOMAIN_CORE}/uploads/`;

export const versionRemoteAssitance = process.env.REACT_APP_NEW_REMOTE_ACTION;

export const uiVersion = process.env.REACT_APP_UI_VERSION;
export const wbcVersion = process.env.REACT_APP_WBC_VERSION;
