import React, { ChangeEvent, Component, Fragment } from "react";
import { find } from "lodash";
import {
  IDevicesInterface,
  IFilteredDevices,
  ISingleDeviceInterface,
  ISingleRomInterface
} from "../../../common/interfaces";
import moment, { Moment } from "moment";
import TimeAgo from "react-timeago";
import DeleteModal from "../../ModalComponent/DeleteModal";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  Alert
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import Select from "react-select";
import style from "./DevicesTable.module.scss";
import FormInputs from "../../Forms/FormInputs";
import RemoteAssistanceModal from "../../ModalComponent/RemoteAssistanceModal";
import AppTable from "../AppTable";
import {
  renderPagination,
  generateColButtons,
  renderTableFieldNames,
  ButtonToForm,
  releaseCycle
} from "../../../common/helper/helper";

interface IPropsInterface {
  devices: IDevicesInterface;
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
  history: { push(data: any): void };
  statuses: Array<{ id: number; name: string }>;
  deviceTypes: Array<{
    id: number | string;
    model: string;
    manufacturer: string;
    name: string;
  }>;
  filteredDevices(data: IFilteredDevices): IDevicesInterface;
  roms: Array<ISingleRomInterface>;
  onShowForm(): void;

  onFilter(data: { orderBy: string; direction?: string }): void;

  onDeleteDevice(id: string | number): void;

  onDeleteDevices(ids: Array<string | number>): void;

  responseError: { details: object; message: string } | boolean | any;

  toggleErrorResponse(): void;

  responseItemMessage: null;
}

interface IStateInterface {
  selectedRows: any[];
  selectAll: boolean;
  selectedOption: Array<{ value: number; label: string }>;
  romSelectedOption: Array<{ value: number; label: string }>;
  showDeleteModal: boolean;
  deviceId: string | number | null;
  showRemoteAssistanceModal: boolean;
  remoteAssistanceAuthorized: boolean;
  loading: boolean;
  valuesForFilter: any;
  operatorProfileId: string | null;
}

const filteredValues: any = {
  current_page: "",
  orderBy: "",
  direction: "",
  id: "",
  mac_address: "",
  provision_id: "",
  deviceTypeIds: "",
  romIds: "",
  created_at: "",
  updated_at: "",
  status: ""
};

const initialValues: any = {};

class DevicesTable extends Component<IPropsInterface, IStateInterface> {
  private timer;
  constructor(props: IPropsInterface) {
    super(props);

    this.state = {
      selectedRows: [],
      selectAll: false,
      selectedOption: [],
      romSelectedOption: [],
      showDeleteModal: false,
      deviceId: null,
      showRemoteAssistanceModal: false,
      remoteAssistanceAuthorized: false,
      loading: false,
      valuesForFilter: {},
      operatorProfileId: ""
    };
  }

  componentWillUnmount() {
    setTimeout(this.timer);
  }

  _generateDeviceTypes = (
    list: Array<{
      id: number | string;
      model: string;
      manufacturer: string;
      name: string;
    }>
  ): Array<{ value: number | string; label: string }> => {
    return list.map(
      (item: {
        id: number | string;
        model: string;
        manufacturer: string;
        name: string;
      }) => {
        return {
          value: item.id,
          label: `${item.name}`
        };
      }
    );
  };

  _generateRoms = (
    list: Array<ISingleRomInterface>
  ): Array<{ value: number; label: string }> => {
    return list.map((item: ISingleRomInterface) => {
      return {
        value: item.id,
        label: `${item.operation_system}-${item.operation_system_version}-${item.build_number}`
      };
    });
  };

  _onRedirectToDetails = (id: string): void => {
    const { history }: IPropsInterface = this.props;
    history.push(`/device/${id}`);
  };

  _onChange: any = (page: number) => {
    const { valuesForFilter }: any = this.state;
    this._onSubmit(valuesForFilter, page);
  };

  _handleChange: any = (
    selectedOption: Array<{ value: number; label: string }>
  ): void => {
    this.setState({ selectedOption });
  };

  _handleRomChange: any = (
    romSelectedOption: Array<{ value: number; label: string }>
  ): void => {
    this.setState({ romSelectedOption });
  };

  _onCheckboxClick: (event: ChangeEvent<HTMLInputElement>) => void = ({
    target: { id }
  }: any) => {
    this.setState((state: IStateInterface) => ({
      selectedRows: !state.selectedRows.includes(id)
        ? [...state.selectedRows, id]
        : state.selectedRows.filter((item: number) => item !== id)
    }));
  };

  _renderTableFieldNames = (): Array<JSX.Element> => {
    const { onFilter, filtering }: IPropsInterface = this.props;
    const keys: Array<{ name: string; keyId: string; noFilter?: boolean }> = [
      { name: "Mac Address", keyId: "mac_address" },
      { name: "Serial", keyId: "serial_number" },
      { name: "UID", keyId: "uid" },
      { name: "Provision Id", keyId: "provision_id" },
      { name: "Name", keyId: "name" },
      {
        name: "Device Profile",
        keyId: "operator_device_type_id",
        noFilter: true
      },
      {
        name: "Release Cycle",
        keyId: "release_cycle"
      },
      { name: "Status", keyId: "status" },
      { name: "Updated", keyId: "updated_at" }
    ];

    return renderTableFieldNames({ onFilter, filtering, keys });
  };

  _renderStatuses: any = (status: number): JSX.Element | undefined => {
    const { statuses }: IPropsInterface = this.props;
    if (statuses && statuses.length) {
      const item: any = find(statuses, (o: { id: number }) => o.id === status);
      if (item) {
        switch (status) {
          case 0:
            return <Badge color="secondary">{item.name}</Badge>;
          case 1:
            return <Badge color="success">{item.name}</Badge>;
          case 2:
            return <Badge color="danger">{item.name}</Badge>;
          default:
            return;
        }
      }
    }

    return;
  };

  _toggleRemoteAssistanceModal: any = (): void => {
    const { showRemoteAssistanceModal }: IStateInterface = this.state;
    this.setState({
      showRemoteAssistanceModal: !showRemoteAssistanceModal
    });
  };

  _renderResultRows = (): React.ReactNode => {
    const { devices, deviceTypes }: IPropsInterface = this.props;
    const { selectedRows }: IStateInterface = this.state;
    const data: Array<ISingleDeviceInterface> | any = devices.data || devices;

    return data.map((item: ISingleDeviceInterface) => {
      const {
        id,
        name,
        status,
        mac_address,
        provision_id,
        updated_at,
        operator_device_type_id,
        serial_number,
        uid,
        release_cycle
      }: ISingleDeviceInterface = item;

      const updatedDate: string = moment(parseInt(updated_at) * 1000).format(
        "MM DD YYYY HH:mm"
      );

      const deviceProfileName = deviceTypes.find(
        item => item.id === operator_device_type_id
      );

      const releaseName = releaseCycle
        .find(el => el.id === release_cycle)
        .name.toLocaleLowerCase();

      return (
        <tr key={id}>
          <td>{mac_address}</td>
          <td>{serial_number}</td>
          <td>{uid}</td>
          <td>{provision_id}</td>
          <td>{name}</td>
          <td>
            {deviceProfileName?.name && (
              <Link to={`device-profile/${deviceProfileName?.id}`}>
                {deviceProfileName?.name}
              </Link>
            )}
          </td>
          <td>
            {releaseName === "release" && (
              <span className="badge-success text-white badge-pill mr-1">
                R
              </span>
            )}
            {releaseName === "release candidate" && (
              <span className="btn-primary text-white badge-pill mr-1">RC</span>
            )}
            {releaseName === "beta" && (
              <span className="btn-primary text-white badge-pill mr-1">B</span>
            )}
            {releaseName === "alpha" && (
              <span className="btn-primary text-white badge-pill mr-1">A</span>
            )}
            {releaseName === "quality assurance" && (
              <span className="bg-purple text-white badge-pill mr-1">QA</span>
            )}
            {releaseName === "development" && (
              <span className="bg-purple text-white badge-pill ">DEV</span>
            )}
          </td>
          <td>{this._renderStatuses(status)}</td>
          <td>
            <TimeAgo date={updatedDate} live={false} />
          </td>
          {generateColButtons({
            id,
            selectedRows,
            onToggleRemoteAssistanceModal: this._toggleRemoteAssistanceModal,
            onRedirectToDetails: this._onRedirectToDetails,
            toggleDeleteModal: this._toggleDeleteModal,
            onCheckboxClick: this._onCheckboxClick,
            setIdOnClick: () =>
              this.setState({
                deviceId: id,
                operatorProfileId: operator_device_type_id
              }),
            status
          })}
        </tr>
      );
    });
  };

  _renderEmtpyCells = (numberOfCells: number): React.ReactNode => {
    return Array.apply(
      null,
      Array(numberOfCells)
    ).map((item: any, index: any) => <td key={index} />);
  };

  _onSelectAll: (event: ChangeEvent<HTMLInputElement>) => void = (): void => {
    const {
      devices: { data }
    }: IPropsInterface = this.props;
    this.setState((state: IStateInterface) => ({
      selectAll: !state.selectAll,
      selectedRows: !state.selectAll
        ? data.map(({ id }: ISingleDeviceInterface) => id)
        : []
    }));
  };

  _toggleDeleteModal: any = (): void => {
    const { showDeleteModal }: IStateInterface = this.state;
    //console.log("showDeleteModal", showDeleteModal);
    this.setState({
      showDeleteModal: !showDeleteModal
    });
  };

  _resetIds = () => {
    const { deviceId, selectedRows } = this.state;
    if (deviceId) {
      this.setState({
        deviceId: null
      });
    } else if (selectedRows && selectedRows.length > 0) {
      this.setState({
        selectedRows: []
      });
    }
  };

  _generateTypeOptions: Function = (
    types: Array<{ name: string; id: number }>
  ) => {
    return (
      types &&
      types.map((item: { id: number; name: string }) => (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      ))
    );
  };

  _onSubmit: any = async (
    values: {
      id: number;
      name: string;
      mac_address: string;
      provision_id: number;
      created_at: Moment;
      status: string | number;
      updated_at: Moment;
      uid: string | number;
      serial_number: string | number;
      release_cycle?: number;
    },
    page = 1
  ): Promise<void> => {
    const { filteredDevices, filtering } = this.props;
    const { selectedOption, romSelectedOption } = this.state;
    this.setState({ loading: true });

    let deviceTypeIds: Array<number> | null = [];
    if (selectedOption && selectedOption.length > 0) {
      for (let i: number = 0; i < selectedOption.length; i++) {
        deviceTypeIds.push(selectedOption[i].value);
      }
    } else {
      deviceTypeIds = null;
    }

    let romIds: Array<number> | null = [];
    if (romSelectedOption && romSelectedOption.length > 0) {
      for (let i: number = 0; i < romSelectedOption.length; i++) {
        romIds.push(romSelectedOption[i].value);
      }
    } else {
      romIds = null;
    }
    filteredValues.current_page = Number.isInteger(page) ? page : 1;
    filteredValues.orderBy = filtering.orderBy;
    filteredValues.direction = filtering.direction;
    filteredValues.id = values.id;
    filteredValues.mac_address = values.mac_address;
    filteredValues.provision_id = values.provision_id;
    filteredValues.deviceTypeIds = deviceTypeIds;
    filteredValues.romIds = romIds;
    filteredValues.created_at = values.created_at?.format("YYYY-MM-DD");
    filteredValues.updated_at = values.updated_at?.format("YYYY-MM-DD");
    filteredValues.name = values.name;
    filteredValues.uid = values.uid;
    filteredValues.status = values.status;
    filteredValues.serial_number = values.serial_number;
    filteredValues.release_cycle = values.release_cycle;
    try {
      await filteredDevices(filteredValues);
    } catch (e) {
      // console.log(e);
    } finally {
      this.timer = setTimeout(() => {
        this.setState({
          loading: false,
          valuesForFilter: {
            ...values,
            orderBy: filtering.orderBy,
            direction: filteredValues.direction
          }
        });
      }, 200);
    }
  };

  render(): JSX.Element {
    const {
      devices,
      onShowForm,
      onDeleteDevices,
      deviceTypes,
      onDeleteDevice,
      responseError,
      toggleErrorResponse,
      responseItemMessage,
      statuses
    }: IPropsInterface = this.props;
    const {
      selectAll,
      selectedRows,
      deviceId,
      showDeleteModal,
      showRemoteAssistanceModal,
      remoteAssistanceAuthorized,
      loading,
      operatorProfileId
    }: IStateInterface = this.state;

    const currentDeviceToDelete =
      devices && devices.data && devices.data.find(el => el.id === deviceId);

    let modalMessage: string | JSX.Element =
      deviceId && selectedRows.length === 0 ? (
        <>
          <strong>
            {currentDeviceToDelete?.name || currentDeviceToDelete?.mac_address}
          </strong>
          <span> will be deleted. Please confirm action!</span>
        </>
      ) : (
        "Selected devices will be deleted. Please confirm action!"
      );

    return (
      <div className={style.administratorsTableWrapper}>
        <Row>
          <div className="card-title col-12">
            <h1>
              <i className="material-icons router" />
              Devices
            </h1>
            <ButtonToForm value="Add Devices" click={onShowForm} />
          </div>
        </Row>

        <Card>
          <CardBody>
            {responseItemMessage && <Alert>{responseItemMessage}</Alert>}
            <Formik
              initialValues={initialValues}
              onSubmit={this._onSubmit}
              render={({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue
              }: any): any => {
                return (
                  <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                    <Row className={style.filteringRow}>
                      <FormInputs
                        type="text"
                        htmlFor="mac_address"
                        id="mac_address_search"
                        labelTitle="Mac Address"
                        name="mac_address"
                        placeholder="Enter Mac Address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mac_address}
                        errors={errors.mac_address}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="serial_number"
                        value={values.serial_number}
                        id="serial_number_search"
                        labelTitle="Serial"
                        placeholder="Serial"
                        onBlur={handleBlur}
                        name="serial_number"
                        errors={errors.serial_number}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="uid"
                        value={values.uid}
                        id="uid_search"
                        labelTitle="UID"
                        placeholder="UID"
                        onBlur={handleBlur}
                        name="uid"
                        errors={errors.uid}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        type="text"
                        id="provision_id_search"
                        labelTitle="Provision ID"
                        htmlFor="provision_id"
                        name="provision_id"
                        placeholder="Enter Provision ID"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.provision_id}
                        errors={errors.provision_id}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="name"
                        value={values.name}
                        id="name_search"
                        labelTitle="Name"
                        placeholder="Name"
                        onBlur={handleBlur}
                        name="name"
                        errors={errors.name}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />

                      <FormGroup className={style.selectInput}>
                        <Label htmlFor="operator_device_type_id">
                          Device Profile
                        </Label>
                        <Select
                          name="operator_device_type_id"
                          id="operator_device_type_id_search"
                          autosize={false}
                          options={this._generateDeviceTypes(deviceTypes)}
                          isMulti
                          value={this.state.selectedOption}
                          onChange={this._handleChange}
                        />
                        <FormFeedback>
                          {errors.operator_device_type_id}
                        </FormFeedback>
                      </FormGroup>
                      <FormInputs
                        onChange={(event: any): any => {
                          handleChange(event);
                          setFieldValue("release_cycle", event.target.value);
                        }}
                        htmlFor="release_cycle"
                        value={values.release_cyclees}
                        id="release_cyclees"
                        labelTitle="Release Cycle"
                        name="release_cycle"
                        errors={errors.release_cyclees}
                        type="select"
                        option={
                          <option value="" label="Release Cycle">
                            Release Cycle
                          </option>
                        }
                        renderData={this._generateTypeOptions(releaseCycle)}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={(event: any): any => {
                          handleChange(event);
                          setFieldValue("status", event.target.value);
                        }}
                        htmlFor="status"
                        value={values.statuses}
                        id="statuses"
                        labelTitle="Status"
                        name="status"
                        errors={errors.statuses}
                        type="select"
                        option={
                          <option value="" label="Select Satus">
                            Select Satus
                          </option>
                        }
                        renderData={this._generateTypeOptions(statuses)}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        type="date"
                        htmlFor="created_at"
                        id="created_at"
                        labelTitle="Created Date"
                        name="created_at"
                        placeholder="Enter Created Date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.created_at}
                        errors={errors.created_at}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        type="date"
                        htmlFor="updated_at"
                        id="updated_at"
                        labelTitle="Updated"
                        name="updated_at"
                        placeholder="Enter Updated Date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.updated_at}
                        errors={errors.updated_at}
                        rowInputClassName={style.filteringInput}
                      />
                      <div>
                        <Button
                          className={style.filteringButton}
                          type="submit"
                          color="primary"
                          size="md"
                        >
                          Apply filters
                        </Button>
                        <Button
                          className={style.filteringButton}
                          color="danger"
                          size="md"
                          type="reset"
                          onClick={() => {
                            this.setState({
                              selectedOption: [],
                              romSelectedOption: []
                            });
                            handleReset(values);
                            handleSubmit();
                          }}
                        >
                          Reset filters
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            />
            <br />
            <Fragment>
              {devices?.data?.length ? (
                <AppTable
                  renderTableFieldNames={this._renderTableFieldNames}
                  renderResultRows={this._renderResultRows}
                  toggleDeleteModal={this._toggleDeleteModal}
                  selectAll={selectAll}
                  onSelectAll={this._onSelectAll}
                  selectedRows={selectedRows}
                  loading={loading}
                />
              ) : (
                <Alert color="danger">No results found</Alert>
              )}
              {devices && renderPagination(devices, this._onChange)}
            </Fragment>
          </CardBody>
        </Card>
        <DeleteModal
          selectedRows={selectedRows}
          onToggle={this._toggleDeleteModal}
          onDelete={onDeleteDevice}
          onMultipleDelete={onDeleteDevices}
          id={deviceId}
          isOpen={showDeleteModal}
          modalTitle="Delete Device"
          responseError={responseError}
          toggleResponseError={toggleErrorResponse}
          resetIds={this._resetIds}
        >
          {modalMessage}
        </DeleteModal>

        {showRemoteAssistanceModal && (
          <RemoteAssistanceModal
            toggle={this._toggleRemoteAssistanceModal}
            authorized={remoteAssistanceAuthorized}
            operatorDeviceId={deviceId}
            operatorProfileId={operatorProfileId}
            isOpen={showRemoteAssistanceModal}
          />
        )}
      </div>
    );
  }
}

export default withRouter<any, any>(DevicesTable);
