import axios from "../axiosInstance";
import { CORE_API_DOMAIN } from "../../API_routes";
import { get } from "lodash";

const apiPrefix: string = `${CORE_API_DOMAIN}/admin-configs`;

export const getAdminConfig = () => axios.get(`${apiPrefix}`);

export const updateAdminConfig: any = (data): any => {
  return axios.post(`${apiPrefix}`, {
    //details: {
    ...data
    //},
  });
};

export const getHandshakeValidationOptions: any = (): any =>
  axios
    .get(`${CORE_API_DOMAIN}/admin-configs/handshake-validation-options`)
    .then(resp => {
      if (get(resp, "data.resource")) {
        return get(resp, "data.resource");
      }
    });

export const getSecretKeySocket: any = (): any =>
  axios.get(`${CORE_API_DOMAIN}/admin-configs/remote-secret-key`).then(resp => {
    if (get(resp, "data.resource")) {
      return get(resp, "data.resource");
    }
  });
