import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Container, Row, Spinner } from "reactstrap";
import { get } from "lodash";
import style from "./UserDetails.module.scss";
import { getStatuses, getRoles } from "common/api/common";
import {
  parseRolesIdToRolesName,
  ButtonToForm,
  renderStatuses,
  timeAgo
} from "common/helper/helper";

import ModalComponent from "app-components/ModalComponent";
import DeleteModal from "app-components/ModalComponent/DeleteModal";
import { ICreateUser, ISingleUser, IUserProfile } from "common/interfaces";
import { deleteUser, getUserByID, updateUser } from "common/api/users";
import UserForm from "app-components/Forms/UserForm";
import { getOperatorsList } from "common/api/operators";
import { getUserProfile } from "common/api/authorization";
import Details from "app-components/Details/Details";

interface PropsInterface {
  match: any;
  history: { push(data): void };
}

interface StateInterface {
  user: ISingleUser | null;
  statuses: Array<{ id: number; name: string }>;
  showForm: boolean;
  deleted: boolean | null;
  errorMessage: string | null;
  showDeleteModal: boolean;
  operators: any;
  roles: Array<{ id: number; name: string }>;
  idCurrentProfile: string | null;
  roleName: string | null;
}

class UserDetails extends Component<PropsInterface, StateInterface> {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      showForm: false,
      statuses: [],
      deleted: null,
      errorMessage: null,
      showDeleteModal: false,
      roles: [],
      operators: null,
      idCurrentProfile: null,
      roleName: null
    };
  }

  async componentDidMount() {
    await this._getUsers();

    const statuses = await getStatuses();
    if (get(statuses, "data.resource.data")) {
      this.setState({ statuses: get(statuses, "data.resource.data") });
    }
    const {
      data: dataUserProfile,
      data: { resource }
    }: any = await getUserProfile();

    if (get(resource, "id")) {
      this.setState({
        idCurrentProfile: get(resource, "id"),
        roleName: get(resource, "role_id")
      });
    } else if (get(dataUserProfile, "data.error")) {
      const { message, status } = get(dataUserProfile, "data");
      setTimeout(() => {
        this.props.history.push({
          pathname: "/error",
          state: { message, status }
        });
      }, 2000);
    }

    if (get(resource, "role_id") !== "OPR_ADMIN") {
      const operators: {
        data: { resource: { data: any } };
      } = await getOperatorsList();

      if (get(operators, "data.resource")) {
        this.setState({ operators: get(operators, "data.resource") });
      }
    } else {
      const { operator_id } = resource;
      this.setState({
        operators: [
          {
            id: operator_id
          }
        ]
      });
    }

    const roles: {
      data: { resource: { data: string } };
    } = await getRoles();

    if (get(roles, "data.resource")) {
      this.setState({ roles: get(roles, "data.resource") });
    }
  }

  _getUsers = async () => {
    const {
      match: {
        params: { ID }
      }
    } = this.props;
    const result = await getUserByID(ID);

    if (result && get(result, "data.resource")) {
      this.setState({ user: get(result, "data.resource") });
    } else {
      this.setState({
        user: null,
        errorMessage: JSON.parse(result.request.response).message
      });
    }
  };

  _onEditSystemUser = async (
    data: ICreateUser
  ): Promise<undefined | object | unknown> => {
    const {
      match: {
        params: { ID }
      }
    } = this.props;

    try {
      const result = await updateUser(ID, data);
      if (result && result.status === 200) {
        await this._getUsers();

        this.setState({
          showForm: false
        });
      }
      return result;
    } catch (er) {
      return er;
    }
  };

  _onShowForm = (): void => {
    this.setState(
      state => ({
        showForm: !state.showForm
      }),
      () => {
        const { showForm } = this.state;
        if (showForm) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  _onDeleteSystemUser = async (id): Promise<void> => {
    const result = await deleteUser(id);
    if (result && result.status === 204) {
      this.setState({
        deleted: true
      });
      setTimeout(() => {
        this.props.history.push({
          pathname: "/users",
          state: { itemDeleted: true }
        });
      }, 2000);
    } else {
      this.setState({
        deleted: false,
        errorMessage:
          get(result, "data.error.message") ||
          get(result, "response.data.message") ||
          get(result, "data.message") ||
          get(result, "message")
      });
    }
  };

  _renderData: any = (userProfile: IUserProfile) => {
    return [
      { name: "Status", value: renderStatuses(userProfile.status) },
      { name: "External Id", value: userProfile.external_id },
      { name: "Email", value: userProfile.email },
      {
        name: "Role Name",
        value: parseRolesIdToRolesName(userProfile?.role_id)
      },
      { name: "Login At", value: timeAgo(userProfile.login_at) },
      { name: "Created At", value: timeAgo(userProfile.created_at) },
      {
        name: "Operator",
        value: userProfile?.operator?.name || "No operator"
      }
    ];
  };

  _renderBottomButtons = () => {
    const { idCurrentProfile, user } = this.state;

    return (
      <div className={style.buttonsWrapper}>
        <ButtonToForm click={this._onShowForm} value="Edit" />
        <ButtonToForm
          color="danger"
          click={this._toggleDeleteModal}
          value="Delete"
          disabled={idCurrentProfile && idCurrentProfile === get(user, "id")}
        />
      </div>
    );
  };

  _toggleDeleteModal: any = (): void => {
    const { showDeleteModal }: StateInterface = this.state;
    this.setState({
      showDeleteModal: !showDeleteModal
    });
  };

  render() {
    const {
      user,
      showForm,
      statuses,
      showDeleteModal,
      errorMessage,
      operators,
      roles,
      roleName
    } = this.state;
    const {
      match: {
        params: { ID }
      }
    }: PropsInterface = this.props;

    let modalMessage: string = "User will be deleted. Please confirm action!";

    if (!user) {
      return (
        <div className="d-flex justify-content-center col-12 mt-5 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <main className="main admin-details-page-wrapper in">
        <Container fluid>
          <Row>
            <div className="card-title col-12">
              <h1>
                <i className="material-icons face" />
                User » {user.name}
              </h1>
              {this._renderBottomButtons()}
            </div>
          </Row>
          <Card>
            <CardBody>
              <Details data={this._renderData(user)} />
            </CardBody>
          </Card>
          <DeleteModal
            onToggle={this._toggleDeleteModal}
            onDelete={this._onDeleteSystemUser}
            id={ID}
            isOpen={showDeleteModal}
            modalTitle="Delete user"
            responseError={errorMessage}
          >
            {modalMessage}
          </DeleteModal>
          <ModalComponent
            modalTitle="Edit User"
            toggle={this._onShowForm}
            isOpen={showForm}
          >
            <UserForm
              edit={true}
              data={user}
              statuses={statuses}
              title="Edit User"
              onShowForm={this._onShowForm}
              onEditUser={this._onEditSystemUser}
              roles={roles}
              operators={operators}
              roleName={roleName}
            />
          </ModalComponent>
        </Container>
      </main>
    );
  }
}

export default withRouter<any, any>(UserDetails);
