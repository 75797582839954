import React from "react";
import { withRouter } from "react-router-dom";
import { Modal, ModalHeader, Button, ModalFooter } from "reactstrap";

const ConfirmModal = ({ isOpen, onToggle, modalTitle, callBack }) => {
  return (
    <Modal isOpen={isOpen} toggle={onToggle} backdrop="static" keyboard={false}>
      <ModalHeader toggle={onToggle}>{modalTitle}</ModalHeader>
      <ModalFooter>
        <Button color="danger" onClick={onToggle}>
          Cancel
        </Button>
        <Button color="primary" onClick={callBack}>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withRouter<any, any>(ConfirmModal);
