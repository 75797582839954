import axios from "../axiosInstance";
import { CORE_API_DOMAIN } from "../../API_routes";
import { handleOrderBy } from "../helper/helper";

const apiPrefix: string = `${CORE_API_DOMAIN}/operator-configs`;

export const getOperators = (
  pageNumber?: number | string,
  limit?: number | null,
  orderBy?: string | null,
  direction?: string | null,
  type_id?: number | null,
  name?: string | null,
  email?: string | null,
  country_id?: number | null,
  city_id?: number | null,
  status?: number | null
) => {
  let filters = `${pageNumber ? "?page=" + pageNumber : "?page=1"}${
    limit ? "&limit=" + limit : "".replace(/\s/g, "")
  }
        ${orderBy ? "&order=" + handleOrderBy(orderBy) : "".replace(/\s/g, "")}
        ${direction ? "&direction=" + direction : "".replace(/\s/g, "")}
        ${type_id ? "&filter[type_id]=" + type_id : "".replace(/\s/g, "")}
        ${name ? "&filter[name]=" + name : "".replace(/\s/g, "")}
        ${email ? "&filter[email]=" + email : "".replace(/\s/g, "")}
        ${
          country_id
            ? "&filter[country_id]=" + country_id
            : "".replace(/\s/g, "")
        }
        ${city_id ? "&filter[city_id]=" + city_id : "".replace(/\s/g, "")} ${
    status ? "&filter[status]=" + status : "".replace(/\s/g, "")
  }`.replace(/\s/g, "");

  return axios.get(`${apiPrefix}${filters}`);
};

export const getOperatorConfig = () => axios.get(`${apiPrefix}`);


export const updateOperatorConfig: any = (data): any => {
  return axios.post(`${apiPrefix}`, {
    //details: {
      ...data,
    //},
    
  });
} 
