import React, { Fragment } from "react";
import style from "./AppTable.module.scss";
//import { renderEmtpyCells } from "../../common/helper/helper";
import { Button, Table, CustomInput, Spinner } from "reactstrap";

interface IPropsInterface {
  renderTableFieldNames: Function;
  renderResultRows: Function;
  numberOfCells?: Number;
  toggleDeleteModal?: Function;
  onSubmit?: VoidFunction;
  selectAll?: boolean;
  onSelectAll?: any;
  selectedRows?: Array<any>;
  loading?: boolean;
  actions?: boolean;
  section?: String;
  isAcknowledge?: boolean;
}

const AppTable = ({
  renderTableFieldNames,
  renderResultRows,
  toggleDeleteModal,
  selectAll,
  onSelectAll,
  selectedRows,
  loading,
  actions = true,
  onSubmit,
  isAcknowledge
}: IPropsInterface) => {
  if (loading) {
    return (
      <div className="d-flex justify-content-center col-12 mt-5 in">
        <Spinner color="primary" />
      </div>
    );
  }
  return (
    <Fragment>
      <Table hover bordered striped responsive size="sm">
        <thead>
          <tr className={style.tableFieldsWrapper}>
            {renderTableFieldNames()}
            {actions && <th className={style.textRight}>Actions</th>}
          </tr>
        </thead>
        <tbody>{renderResultRows()}</tbody>
      </Table>
      {toggleDeleteModal && localStorage.getItem("acr") !== "OPR_VIEWER" && (
        <div className={style.emptyCellsRow}>
          <div className={style.buttonsWrapper}>
            <Fragment>
              <Button
                color="danger"
                size="sm"
                disabled={selectedRows && selectedRows?.length === 0}
                onClick={(): any => {
                  toggleDeleteModal && toggleDeleteModal();
                }}
              >
                Delete Selected
              </Button>
              <CustomInput
                type="checkbox"
                id="accept"
                required
                checked={selectAll}
                onChange={onSelectAll}
              />
            </Fragment>
          </div>
        </div>
      )}
      {isAcknowledge && (
        <div className={style.emptyCellsRow}>
          <div className={style.buttonsWrapper}>
            <Fragment>
              <Button size="sm" className="buttonPurple" onClick={onSubmit}>
                Aknowledge all
              </Button>
              <CustomInput
                type="checkbox"
                id="accept"
                required
                checked={selectAll}
                onChange={onSelectAll}
              />
            </Fragment>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AppTable;
