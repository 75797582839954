export const DO_LOGIN = "DO_LOGIN";
export const DO_LOGIN_SUCCESS = "DO_LOGIN_SUCCESS";
export const DO_LOGIN_FAIL = "DO_LOGIN_FAIL";

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  isAuthorized: false,
  doRedirect: "/login",
  currentUser: {
    roles: [localStorage.getItem("acr")]
  }
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case DO_LOGIN:
      return {
        ...state,
        isLoading: true
      };
    case DO_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        doRedirect: "/dashboard"
      };

    case DO_LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        doRedirect: "/login"
      };

    default:
      return state;
  }
};

export default auth;
