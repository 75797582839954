const navigation = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "material-icons dashboard",
      allowedRoles: [
        "SYS_OWNER",
        "SYS_ADMIN",
        "OPR_OWNER",
        "OPR_ADMIN",
        "OPR_EDITOR",
        "OPR_VIEWER"
      ]
    },

    {
      name: "Users",
      url: "/users",
      icon: "material-icons face",
      allowedRoles: [
        "SYS_OWNER",
        "SYS_ADMIN",
        "OPR_OWNER",
        "OPR_ADMIN",
        "OPR_EDITOR",
        "OPR_VIEWER"
      ]
    },
    {
      name: "Operators",
      url: "/operators",
      icon: "material-icons perm_data_setting",
      allowedRoles: ["SYS_OWNER", "SYS_ADMIN"]
    },
    {
      name: "Configurations",
      url: "/admin-configurations",
      icon: "material-icons settings",
      allowedRoles: ["SYS_OWNER", "SYS_ADMIN"]
    },
    {
      name: "Alarms",
      url: "/alarms",
      icon: "icon-exclamation",
      allowedRoles: ["OPR_OWNER", "OPR_ADMIN", "OPR_VIEWER", "OPR_EDITOR"]
    },
    {
      name: "Devices",
      url: "/devices",
      icon: "material-icons router",
      allowedRoles: ["OPR_OWNER", "OPR_ADMIN", "OPR_VIEWER", "OPR_EDITOR"]
    },
    {
      name: "Device Profiles",
      url: "/device-profiles",
      icon: "material-icons fingerprint",
      allowedRoles: ["OPR_OWNER", "OPR_ADMIN", "OPR_VIEWER", "OPR_EDITOR"]
    },
    {
      name: "Roms",
      url: "/roms",
      icon: "material-icons android",
      allowedRoles: ["OPR_OWNER", "OPR_ADMIN", "OPR_VIEWER", "OPR_EDITOR"]
    },
    {
      name: "Remote Assistance",
      url: "/remote-assistance",
      icon: "material-icons desktop_windows",
      allowedRoles: [
        "OPR_OWNER",
        "OPR_ADMIN",
        "OPR_VIEWER",
        "OPR_EDITOR",
        "OPR_SUPPORT_LINE_1",
        "OPR_SUPPORT_LINE_2"
      ]
    },
    {
      name: "Configurations",
      url: "/configurations",
      icon: "material-icons settings",
      allowedRoles: ["OPR_OWNER", "OPR_ADMIN"]
    }
  ]
};
export default navigation;
