import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  Row,
  Form
} from "reactstrap";
import classNames from "classnames";
import style from "./TabsDevice.module.scss";
import Details from "app-components/Details";
import DeviceCofig from "../DeviceCofig/DeviceCofig";
import RemoteSection from "../RemoteSection";
import UserSettings from "../UserSettings/UserSettings";
import DeviceCofigVersion from "../DeviceCofigVersion";
import ReactJson from "react-json-view";
import { ButtonToForm, renderStatuses, timeAgo } from "common/helper/helper";

interface IPropsInterface {
  loading: boolean;
  details: any;
  onShowForm: any;
  toggleDeleteModal: any;
  deviceTypes: any;
  authorized: any;
  operatorDeviceId: any;
  buttonRemote: any;
  deviceStatus: object;
}

const TabsDevice = ({
  loading,
  details,
  onShowForm,
  toggleDeleteModal,
  deviceTypes,
  buttonRemote,
  deviceStatus
}: IPropsInterface) => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const _renderDataDetails: any = device => {
    const {
      name,
      status,
      serial_number,
      uid,
      provision_id,
      mac_address,
      operator_device_type_id,
      integration_uuid
    } = device;

    const profileNameDevice = deviceTypes.find(
      (device: { id: string }) => device.id === operator_device_type_id
    );

    const objToRender = [
      {
        name: "Status",
        value: renderStatuses(status)
      },
      {
        name: "Device Profile",
        value: profileNameDevice?.name
      },
      {
        name: "Name",
        value: name
      },
      {
        name: "Uid",
        value: uid
      },
      {
        name: "Mac Address",
        value: mac_address
      },
      {
        name: "Provision Id",
        value: provision_id
      },
      {
        name: "Serial Number",
        value: serial_number
      },
      {
        name: "Integration Uuid",
        value: integration_uuid
      }
    ];

    return objToRender;
  };

  const _renderBottomButtons: any = () => {
    return (
      <div className={style.buttonsWrapper}>
        <ButtonToForm click={onShowForm} value="Edit" />
        <ButtonToForm color="danger" click={toggleDeleteModal} value="Delete" />
      </div>
    );
  };

  return (
    <Col xs="12" md="12" className="mt-1">
      {_renderBottomButtons()}
      <Details data={_renderDataDetails(details)} />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames(style.link, {
              active: activeTab === "1"
            })}
            onClick={() => {
              toggle("1");
            }}
          >
            <span className={activeTab === "1" ? style.active : ""}>
              Configurations
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames(style.link, {
              active: activeTab === "2"
            })}
            onClick={() => {
              toggle("2");
            }}
          >
            <span className={activeTab === "2" ? style.active : ""}>
              User Settings
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames(style.link, {
              active: activeTab === "3"
            })}
            onClick={() => {
              toggle("3");
            }}
          >
            <span className={activeTab === "3" ? style.active : ""}>
              Remote Assistance
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames(style.link, {
              active: activeTab === "4"
            })}
            onClick={() => {
              toggle("4");
            }}
          >
            <span className={activeTab === "4" ? style.active : ""}>
              Status
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className={style.TabContent}>
        {loading ? (
          <div className={style.progressCircle}>
            <Spinner color="primary" />
          </div>
        ) : (
          <>
            <TabPane tabId="1" className={style.TabPane}>
              <DeviceCofig
                lock={details?.lock}
                release_cycle={details?.release_cycle}
                debug_mode={details?.debug_mode}
              />
            </TabPane>
            <TabPane tabId="2" className={style.TabPane}>
              <UserSettings consent_status={details?.consent_status} />
            </TabPane>
            <TabPane tabId="3" className={style.TabPane}>
              <RemoteSection
                mac_address={details?.mac_address}
                buttonRemote={buttonRemote}
              />
            </TabPane>
            <TabPane
              tabId="4"
              className={style.TabPane}
              style={{ minHeight: 300 }}
            >
              <DeviceCofigVersion
                versions={[
                  {
                    name: "Version",
                    value: [
                      {
                        name: "Device Control Version",
                        value: details?.device_control_version
                      },
                      {
                        name: "Device Control Package name",
                        value: details?.device_control_package_name
                      },

                      { name: "Rom version", value: details?.rom_version }
                    ]
                  },

                  {
                    name: "Connectivity",
                    value: [
                      {
                        name: "ASYNC heartbeat",
                        value: details?.push_id_updated_at
                      },
                      {
                        name: "Support Link",
                        value: details?.socket_status,
                        status: true
                      },
                      {
                        name: "Support Link heartbeat",
                        value: details?.socket_status_updated_at
                      }
                    ]
                  }
                ]}
              />
              <Form>
                <Col md="12">
                  <Row className="p-3">
                    <Card className="w-100">
                      <CardHeader>
                        <h3 className={style.title}>
                          <strong>
                            {deviceStatus ? "Status" : "Status Unavailable"}
                          </strong>
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <div style={{ padding: "1.4rem 1rem" }}>
                          {deviceStatus && (
                            <ReactJson src={deviceStatus} collapsed={true} />
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
              </Form>
            </TabPane>
          </>
        )}
      </TabContent>
      <div className="d-flex mb-3 float-right mt-3">
        <strong className="mr-1">Last Updated Time:</strong>
        <span>
          <span>{timeAgo(details.updated_at)}</span>
        </span>
      </div>
    </Col>
  );
};

export default TabsDevice;
