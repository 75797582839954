import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

interface IPropsInterface {
  modalTitle: string;
  isOpen: boolean;
  onToggle(): void;
  screenshot: {
    src: string;
    fallback: string;
  };
}

class ScreenshotModal extends Component<IPropsInterface, any> {
  UNSAFE_componentWillReceiveProps(nextProps: IPropsInterface): void {}

  render(): React.ReactNode {
    const { isOpen, onToggle, screenshot }: IPropsInterface = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <ModalHeader toggle={onToggle}>Screenshot</ModalHeader>
        <ModalBody>
          <img alt="screenshot" src={screenshot.src} width="100%" />
        </ModalBody>
        <ModalFooter />
      </Modal>
    );
  }
}

export default withRouter<any, any>(ScreenshotModal);
