import React, { useState, useEffect } from "react";
import style from "../../DeviceProfileDetailsPage.module.scss";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Label,
  CardFooter,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const ListOfVideos = ({
  listOfVideos,
  dataFormClone,
  setDataFormClone,
  disabled,
}) => {
  const [dataForm, setDataForm] = useState<any>([]);
  useEffect(() => {
    if (listOfVideos && listOfVideos.length > 0) {
      setDataForm([...listOfVideos]);
      setDataFormClone([...listOfVideos]);
    }
  }, [listOfVideos, setDataFormClone]);

  return (
    <div className={style.smallBox}>
      {dataForm?.length > 0
        ? dataForm.map((item, indexMap) => {
            const lessCurrentItem = dataFormClone.filter(
              (itemDataForm) => itemDataForm.id !== item.id
            );

            return (
              <Card className="mb-2" key={item.id + indexMap}>
                <CardHeader>
                  <strong>Video {indexMap + 1}</strong>
                </CardHeader>
                <CardBody>
                  <Label className={style.labelSwitch}>Name Video</Label>
                  <Input
                    type="text"
                    id={`name ${item.id}`}
                    name={`name ${item.id}`}
                    defaultValue={item.name}
                    className="mb-2"
                    placeholder="Enter Name Video"
                    onChange={(e) => {
                      const url = dataFormClone.filter(
                        (dataFilter) => dataFilter.id === item.id
                      )[0].url;
                      const image = dataFormClone.filter(
                        (dataFilter) => dataFilter.id === item.id
                      )[0].image;
                      setDataFormClone([
                        ...lessCurrentItem,
                        {
                          ...item,
                          url,
                          name: e.target.value,
                          image,
                        },
                      ]);
                    }}
                  />
                  <Label className={style.labelSwitch}>Url Video</Label>
                  <Input
                    type="url"
                    id={`video ${item.id}`}
                    name={`video ${item.id}`}
                    defaultValue={item.url}
                    placeholder="Enter Url Video"
                    onChange={(e) => {
                      const name = dataFormClone.filter(
                        (dataFilter) => dataFilter.id === item.id
                      )[0].name;
                      const image = dataFormClone.filter(
                        (dataFilter) => dataFilter.id === item.id
                      )[0].image;

                      setDataFormClone([
                        ...lessCurrentItem,
                        {
                          ...item,
                          name,
                          url: e.target.value,
                          image,
                        },
                      ]);
                    }}
                  />
                  <Label className={style.labelSwitch}>
                    Url Video Thumbnail
                  </Label>
                  <Input
                    type="url"
                    id={`image ${item.id}`}
                    name={`image ${item.id}`}
                    defaultValue={item?.image}
                    placeholder="Enter Url Video Thumbnail"
                    onChange={(e) => {
                      const name = dataFormClone.filter(
                        (dataFilter) => dataFilter.id === item.id
                      )[0].name;
                      const url = dataFormClone.filter(
                        (dataFilter) => dataFilter.id === item.id
                      )[0].url;

                      setDataFormClone([
                        ...lessCurrentItem,
                        {
                          ...item,
                          name,
                          url,
                          image: e.target.value,
                        },
                      ]);
                    }}
                  />
                </CardBody>
                <CardFooter>
                  <Button
                    color="danger"
                    className={style.addButton}
                    disabled={disabled}
                    onClick={() => {
                      setDataForm([
                        ...dataFormClone.filter(
                          (itemToDelete) => itemToDelete.id !== item.id
                        ),
                      ]);
                      setDataFormClone([
                        ...dataFormClone.filter(
                          (itemToDelete) => itemToDelete.id !== item.id
                        ),
                      ]);
                    }}
                  >
                    <i className="material-icons">delete</i>Remove
                  </Button>
                </CardFooter>
              </Card>
            );
          })
        : "Please add a video"}
      <Button
        color="primary"
        className={style.addButton}
        disabled={dataForm.length >= 20 || disabled}
        onClick={() => {
          const id = uuidv4();
          setDataForm([
            ...dataForm,
            {
              id,
              name: "",
              image: "",
            },
          ]);
          setDataFormClone([
            ...dataFormClone,
            {
              id,
              name: "",
              image: "",
            },
          ]);
        }}
      >
        <i className="material-icons">add</i>Add Video
      </Button>
    </div>
  );
};

export default ListOfVideos;
