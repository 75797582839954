import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { get } from "lodash";
import * as Yup from "yup";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import { resetPassword, IResetPassword } from "../../common/api/authorization";
import style from "./ResetPassword.module.scss";

const validationSchema: any = function(): any {
  return Yup.object().shape({
    password: Yup.string()
      .min(6, `Password name has to be at least 6 characters`)
      .required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required")
  });
};

const validate: any = (getValidationSchema: any): any => {
  return (values: any): any => {
    const validationSchema: any = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError: any = (validationError: any): any => {
  const FIRST_ERROR: any = 0;

  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
};

const initialValues: {
  password: string;
  password_confirmation: string;
  token: string;
} = {
  password: "",
  password_confirmation: "",
  token: ""
};

interface IPropsInterface {
  match: any;
  history: { push(data: any): void };
  location: { search: string };
}

interface IStateInterface {
  responseMessage: boolean | string | null;
}

interface IResetPasswordResult {
  data: {
    message: string;
  };
  status?: number;
}

class ResetPassword extends Component<IPropsInterface, IStateInterface> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      responseMessage: null
    };
  }

  _onSubmit: any = async (values: IResetPassword) => {
    const queryString: string = this.props.location.search;
    const index: number = queryString.indexOf("=");
    values.token = queryString.substring(index + 1);

    try {
      const result: IResetPasswordResult = await resetPassword(values);
      if (result && result.status === 200) {
        this.setState({
          responseMessage: get(result, "data.message")
        });
        setTimeout(() => {
          this.props.history.push(`/login`);
        }, 3000);
      } else {
        this.setState({
          responseMessage: "Request failed!"
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  render(): JSX.Element | React.ReactNode {
    const { responseMessage }: IStateInterface = this.state;

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={8} md={6} lg={4}>
              <CardGroup>
                <Card className="p-4 container">
                  <CardBody>
                    {responseMessage && (
                      <Alert color="warning">{responseMessage}</Alert>
                    )}
                    <Formik
                      initialValues={initialValues}
                      validate={validate(validationSchema)}
                      onSubmit={this._onSubmit}
                      render={({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        touched
                      }: any): any => (
                        <Form onSubmit={handleSubmit} name="simpleForm">
                          <FormGroup className="my-0" style={{ width: "100%" }}>
                            <p className={style.formTitle}>Reset Password</p>
                            <InputGroup className="mb-4">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-lock" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Enter new password"
                                valid={touched.password && !errors.password}
                                invalid={touched.password && !!errors.password}
                                required
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                              />
                              <FormFeedback>{errors.password}</FormFeedback>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className="my-0" style={{ width: "100%" }}>
                            <InputGroup className="mb-4">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-lock" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                type="password"
                                id="password_confirmation"
                                name="password_confirmation"
                                placeholder="Confirm your password"
                                valid={
                                  touched.password_confirmation &&
                                  !errors.password_confirmation
                                }
                                invalid={
                                  touched.password_confirmation &&
                                  !!errors.password_confirmation
                                }
                                required
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password_confirmation}
                              />
                              <FormFeedback>
                                {errors.password_confirmation}
                              </FormFeedback>
                            </InputGroup>
                          </FormGroup>
                          <Col xs="12" className={style.resetButtonMargin}>
                            <Button
                              type="submit"
                              color="primary"
                              className="px-4"
                            >
                              Reset Password
                            </Button>
                          </Col>
                        </Form>
                      )}
                    />
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter<any, any>(ResetPassword);
