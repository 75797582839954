import React, { useState, memo } from "react";
import { CardBody, FormGroup, Label, Input, Col } from "reactstrap";
import style from "../OperatorConfigPage.module.scss";
import { Switch } from "antd";

interface IPropsInterface {
  values: any;
  handleChange: any;
}

const SpeedTest = ({ values, handleChange }: IPropsInterface) => {
  const [status, setStatus] = useState(values.status);

  const generateOptions = () => {
    return ["LibreSpeed"].map((item, index) => (
      <option value={index} key={item}>
        {item}
      </option>
    ));
  };

  return (
    <div className={style.contentWrapper}>
      <CardBody>
        <FormGroup row>
          <Col xs="4">
            <Label htmlFor="status">Enable</Label>
          </Col>
          <Col xs="8" md="8">
            <Switch
              style={{ float: "right" }}
              size="small"
              defaultChecked={values.status}
              onChange={(checked: boolean) => {
                setStatus(checked);
                handleChange({
                  status: checked
                });
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="12">
            <Label htmlFor="interval">Interval (in hours)</Label>
            <Input
              type="number"
              id="key"
              name="key"
              disabled={!status}
              required={status}
              defaultValue={values.interval ? values.interval : ""}
              onChange={e => {
                handleChange({
                  interval: e.target.value
                });
              }}
            ></Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="12">
            <Label htmlFor="sppedTestPlatform">Platform</Label>
            <Input
              type="select"
              id="key"
              name="key"
              disabled={!status}
              required={status}
              defaultValue={values.platform ? values.platform : ""}
              onChange={e => {
                handleChange({
                  platform: e.target.value
                });
              }}
            >
              {<option value="">Select Plataform</option>}
              {generateOptions()}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="12">
            <Label htmlFor="server">Server</Label>
            <Input
              type="text"
              id="server"
              name="server"
              required={status}
              placeholder="https://test.html"
              defaultValue={values.server ? values.server : ""}
              onChange={e => {
                handleChange({
                  server: e.target.value
                });
              }}
              disabled={!status}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="4">
            <Label htmlFor="allowUserToPerformSpeedTest">
              Allow User To Perform Speed Test
            </Label>
          </Col>
          <Col xs="8" md="8">
            <Switch
              style={{ float: "right" }}
              size="small"
              disabled={!status}
              defaultChecked={values.allowUserToPerformSpeedTest}
              onChange={(checked: boolean) => {
                handleChange({
                  allowUserToPerformSpeedTest: checked
                });
              }}
            />
          </Col>
        </FormGroup>
      </CardBody>
    </div>
  );
};

export default memo(SpeedTest);
