import React from "react";
import style from "./Details.module.scss";
import cx from "classnames";

const Details = ({
  data,
  buildList
}: {
  data: any;
  buildList?: Array<any>;
}) => {
  const renderData = (dataList): JSX.Element =>
    dataList.map((key: { value: any; name: string }) => (
      <div key={key.name} className={style.detailRow}>
        <div className="d-flex mb-3">
          <strong style={{ textTransform: "capitalize" }} className="mr-1">
            {key.name}:
          </strong>
          {Array.isArray(key.value) ? (
            <div style={{ display: "flex", flexFlow: "column" }}>
              {key.value.map((el: { build: string }, i: number) => (
                <small key={i}>{el.build && <>Build: {el.build}</>}</small>
              ))}
            </div>
          ) : (
            <span>{key.value}</span>
          )}
        </div>
      </div>
    ));
  return (
    <>
      <div className={style.contentWrapper}>{renderData(data)}</div>
      {buildList && (
        <div className={cx(style.contentWrapper, style.rowBuilds)}>
          {renderData(buildList)}
        </div>
      )}
    </>
  );
};

export default Details;
