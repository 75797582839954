import React, { Component } from "react";
import {
  ICreateDeviceTypeRoms,
  ICreateDeviceTypesInterface,
  IDeviceTypesInterface,
  IRomsInterface,
  ISingleDeviceTypesInterface
} from "../../common/interfaces";
import { getStatuses } from "../../common/api/common";
import { getPlaybackSupports } from "../../common/api/common";
import { get } from "lodash";
import { Container, Spinner } from "reactstrap";
import ModalComponent from "../../app-components/ModalComponent";
import DeviceProfilesTable from "../../app-components/Tables/DeviceProfilesTable";
import {
  createDeviceType,
  deleteDeviceType,
  deleteDeviceTypes,
  getDeviceTypes,
  getDeviceTypesFilter
} from "../../common/api/device_types";
import DeviceProfilesForm from "../../app-components/Forms/DeviceProfilesForm";
import { withRouter } from "react-router-dom";
import { getRoms } from "../../common/api/roms";
import { createDeviceTypeRoms } from "../../common/api/operator_type_roms";
import { showAndHideAlertItem } from "../../common/helper/helper";

interface IPropsInterface {
  match: any;
  history: any;
}

interface IStateInterface {
  deviceProfiles: IDeviceTypesInterface | null;
  deviceProfilesFilter: IDeviceTypesInterface | null;
  showCreateDeviceProfileForm: boolean;
  playbackSupports: Array<{ id: number; name: string }>;
  roms: IRomsInterface | null;
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
  responseError: { details: object; message: string } | boolean | any;
  responseItemMessage: null | string;
  statuses: Array<{ id: number; name: string }>;
}

class DeviceProfilesListPage extends Component<
  IPropsInterface,
  IStateInterface
> {
  private readonly showAndHideAlertItem;
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      deviceProfiles: null,
      deviceProfilesFilter: null,
      showCreateDeviceProfileForm: false,
      playbackSupports: [],
      roms: null,
      filtering: {
        orderBy: null,
        direction: null
      },
      responseError: null,
      responseItemMessage: null,
      statuses: []
    };
    this.showAndHideAlertItem = showAndHideAlertItem.bind(this);
  }

  async UNSAFE_componentWillReceiveProps(
    nextProps: IPropsInterface
  ): Promise<void> {
    const {
      filtering: { orderBy, direction }
    }: IStateInterface = this.state;
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;
    const {
      match: {
        params: { pageNumber: nextPageNumber }
      }
    }: IPropsInterface = nextProps;

    if (nextPageNumber && pageNumber !== nextPageNumber) {
      try {
        await this._getDeviceProfiles(nextPageNumber, orderBy, direction);
      } catch (e) {
        // console.log(e);
      }
    }
  }

  async componentDidMount(): Promise<void> {
    const {
      match: {
        params: { pageNumber }
      },
      history: { location }
    }: IPropsInterface = this.props;

    try {
      const statuses = await getStatuses();
      if (get(statuses, "data.resource.data")) {
        this.setState({ statuses: get(statuses, "data.resource.data") });
      }
      const playbackSupports = await getPlaybackSupports();
      if (get(location, "state.itemDeleted")) {
        this.showAndHideAlertItem(this.props);
      }
      if (playbackSupports) {
        this.setState({
          playbackSupports: playbackSupports.data
        });
      }

      const operatorRoms = await getRoms({ pageNumber: 1, limit: 10000 });
      if (operatorRoms) {
        this.setState({
          roms: operatorRoms
        });
      }

      await this._getDeviceProfiles(pageNumber);
    } catch (e) {
      // console.log(e);
    }
  }

  _getDeviceProfiles = async (
    pageNumber: number | string = 1,
    orderBy?: string | null,
    direction?: string | null
  ): Promise<void> => {
    try {
      const result: {
        data: { resource: string };
      } = await getDeviceTypes(pageNumber, orderBy, direction);
      if (result && get(result, "data.resource")) {
        this.setState({
          deviceProfiles: get(result, "data.resource"),
          deviceProfilesFilter: get(result, "data.resource")
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _getFilteredDeviceProfiles = async (data): Promise<void> => {
    try {
      const result: {
        data: { resource: string };
      } = await getDeviceTypesFilter(data);
      if (result && get(result, "data.resource")) {
        this.setState({
          deviceProfilesFilter: get(result, "data.resource")
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _onFilter: Function = (data: {
    orderBy: string;
    direction: string;
  }): void => {
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;

    if (data) {
      const {
        orderBy,
        direction
      }: { orderBy: string; direction: string } = data;
      if (orderBy) {
        this.setState(
          {
            filtering: {
              orderBy,
              direction: direction || null
            }
          },
          async () => {
            const {
              filtering: { orderBy, direction }
            }: IStateInterface = this.state;
            try {
              await this._getDeviceProfiles(pageNumber, orderBy, direction);
            } catch (e) {
              // console.log(e);
            }
          }
        );
      }
    }
  };

  _createNewDeviceProfile: any = async (
    data: ICreateDeviceTypesInterface
  ): Promise<undefined | object | unknown> => {
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;
    try {
      const result: { status: number } = await createDeviceType(data);

      if (result && result.status === 201) {
        await this._getDeviceProfiles(pageNumber);
        this.setState({
          showCreateDeviceProfileForm: false
        });
      }

      return result;
    } catch (er) {
      return er;
    }
  };

  _createNewDeviceProfileRom: any = async (
    data: ICreateDeviceTypeRoms
  ): Promise<undefined | object | unknown> => {
    try {
      const result: { status: number } = await createDeviceTypeRoms(data);

      if (result && result.status === 201) {
        this.setState({
          showCreateDeviceProfileForm: false
        });
      }

      return result;
    } catch (er) {
      return er;
    }
  };

  _onDeleteDeviceProfile = async (id: number | string): Promise<boolean> => {
    const { deviceProfiles } = this.state;
    const result: { status: number } = await deleteDeviceType(id);

    if (result && result.status === 204) {
      if (deviceProfiles) {
        this.setState({
          deviceProfiles: {
            ...deviceProfiles,
            data: [
              ...deviceProfiles.data.filter(
                (deviceProfile: ISingleDeviceTypesInterface) =>
                  deviceProfile.id !== id
              )
            ]
          }
        });
      }
      this.showAndHideAlertItem(this.props);
      return true;
    } else {
      this.setState({
        responseError:
          get(result, "data.error.message") ||
          get(result, "response.data.error.message") ||
          get(result, "data.message") ||
          get(result, "message")
      });

      return false;
    }
  };

  _onDeleteDeviceProfiles = async (ids: Array<number>): Promise<boolean> => {
    const { deviceProfiles } = this.state;
    const result: { status: number } = await deleteDeviceTypes(ids);

    if (result && result.status === 204) {
      if (deviceProfiles) {
        this.setState({
          deviceProfiles: {
            ...deviceProfiles,
            data: [
              ...deviceProfiles.data.filter(
                (deviceProfile: ISingleDeviceTypesInterface) =>
                  !ids.includes(deviceProfile.id)
              )
            ]
          }
        });
      }
      this.showAndHideAlertItem(this.props);
      return true;
    } else {
      this.setState({
        responseError:
          get(result, "response.data.error.message") ||
          get(result, "data.message") ||
          get(result, "data.error.message") ||
          get(result, "message")
      });

      return false;
    }
  };

  _onShowForm: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = (): void => {
    this.setState(
      (state: IStateInterface) => ({
        showCreateDeviceProfileForm: !state.showCreateDeviceProfileForm
      }),
      (): void => {
        const { showCreateDeviceProfileForm }: IStateInterface = this.state;
        if (showCreateDeviceProfileForm) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  _toggleErrorResponse = (): void => {
    this.setState({
      responseError: null
    });
  };

  render(): JSX.Element {
    const {
      showCreateDeviceProfileForm,
      deviceProfiles,
      filtering,
      playbackSupports,
      roms,
      responseError,
      deviceProfilesFilter,
      responseItemMessage,
      statuses
    }: IStateInterface = this.state;

    if (!deviceProfiles) {
      return (
        <div className="d-flex justify-content-center col-12 mt-5 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <main className="users-table-wrapper in main">
        <Container fluid>
          <DeviceProfilesTable
            deviceProfiles={deviceProfiles}
            filtering={filtering}
            statuses={statuses}
            onShowForm={this._onShowForm}
            onDeleteDeviceProfile={this._onDeleteDeviceProfile}
            onDeleteDeviceProfiles={this._onDeleteDeviceProfiles}
            responseItemMessage={responseItemMessage}
            onFilter={this._onFilter}
            responseError={responseError}
            toggleErrorResponse={this._toggleErrorResponse}
            filteredDevicesProfile={this._getFilteredDeviceProfiles}
            deviceProfilesFilter={deviceProfilesFilter}
            playbackSupports={playbackSupports}
          />
          <ModalComponent
            modalTitle="Create device profile"
            s
            toggle={this._onShowForm}
            isOpen={showCreateDeviceProfileForm}
          >
            <DeviceProfilesForm
              title="Create device profile"
              statuses={statuses}
              playbackSupports={playbackSupports}
              roms={roms}
              onShowForm={this._onShowForm}
              createNewDeviceProfile={this._createNewDeviceProfile}
              createNewDeviceProfileRom={this._createNewDeviceProfileRom}
              deviceProfiles={this._getDeviceProfiles}
            />
          </ModalComponent>
        </Container>
      </main>
    );
  }
}

export default withRouter<any, any>(DeviceProfilesListPage);
