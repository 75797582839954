import React from "react";
import {
  CloudDownloadOutlined,
  RedoOutlined,
  FileImageOutlined
} from "@ant-design/icons";
import moment from "moment";

const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match =>
    match.toUpperCase()
  );

export const actionsTop = (deviceStatus: any) => {
  const { Connection, Display, PowerStatus, ResourceStatus } = deviceStatus;
  const timeConnect = PowerStatus?.uptime || deviceStatus?.power?.up_time;
  const findConnection = conect => {
    for (const key in conect) {
      if (Object.prototype.hasOwnProperty.call(conect, key)) {
        const element = conect[key];
        if (element.is_available) {
          return {
            labelConnection: `${capitalize(key)} connection`,
            iconConnection: "wifi"
          };
        }
      }
    }
    return {
      labelConnection: `Not found`,
      iconConnection: "wifi"
    };
  };
  const { labelConnection, iconConnection } = findConnection(
    deviceStatus?.network || Connection
  );
  return [
    {
      type: 3,
      label: labelConnection,
      position: "top",
      icon: <i className="material-icons">{iconConnection}</i>
    },
    {
      type: 999,
      label: `Battery Alert: ${ResourceStatus?.RemoteStatus?.battery ||
        deviceStatus?.power?.battery_level}`,
      position: "top",
      icon: <i className="material-icons">battery_alert</i>
    },
    {
      type: 999,
      label: `Uptime: ${moment(timeConnect * 1000).format("hh:mm:ss")}`,
      position: "top",
      icon: <i className="material-icons">alarm</i>
    },
    {
      type: 600,
      label: `Screen resolution ${Display?.hdmi_resolution ||
        deviceStatus?.display?.resolution}`,
      position: "top",
      icon: <i className="material-icons">close_fullscreen</i>
    }
  ];
};

export const actionsBottom = (deviceInfo: any) => {
  return [
    {
      type: 9,
      label: !deviceInfo?.lock ? "Lock" : "Unlock",
      key_id: `LOCK_UNLOCK_${deviceInfo?.lock}`,
      event: (e: number, label: string, eventSocket: Function) =>
        eventSocket(e, label),
      position: "bottom",
      icon: !deviceInfo?.lock ? (
        <i className="material-icons">lock</i>
      ) : (
        <i className="material-icons">lock_open</i>
      )
    },
    {
      type: 10,
      label: "Display on/off",
      key_id: "DISPLAY",
      event: (e: number, label: string, eventSocket: Function) =>
        eventSocket(e, label),
      position: "bottom",
      icon: {
        on: <i className="material-icons">phonelink</i>,
        off: <i className="material-icons">phonelink_off</i>
      }
    },
    {
      type: 8,
      label: "Reboot",
      key_id: "REBOOT",
      event: (e: number, label: string, eventSocket: Function) =>
        eventSocket(e, label),
      position: "bottom",
      icon: <RedoOutlined />
    },
    {
      type: 15,
      label: "Check OTA update",
      key_id: "CHECK_OTA_UPDATE",
      event: (e: number, label: string, eventSocket: Function) =>
        eventSocket(e, label),
      position: "bottom",
      icon: <i className="material-icons">system_update</i>
    },
    {
      type: 4,
      label: "Refresh screen",
      key_id: "TAKE_SCREENSHOT",
      event: (e: number, label: string, eventSocket: Function) =>
        eventSocket(e, label),
      position: "bottom",
      icon: <i className="material-icons">update</i>
    },
    {
      type: 100,
      label: "ADB command",
      key_id: "ADB_COMMAND",
      event: (e: number, label: string, eventSocket: Function) =>
        eventSocket(e, label),
      position: "bottom",
      icon: <i className="material-icons">adb</i>
    },
    {
      type: 101,
      label: "Shell command",
      key_id: "SHELL_COMMAND",
      event: (e: number, label: string, eventSocket: Function) =>
        eventSocket(e, label),
      position: "bottom",
      icon: <i className="material-icons">keyboard</i>
    },
    {
      type: 4,
      label: "Download Screenshot",
      key_id: "DOWNLOAD_SCREENSHOT",
      event: (e: number, label: string, eventSocket: Function) =>
        eventSocket(e, label),
      position: "bottom",
      icon: <FileImageOutlined />
    },
    {
      type: 11,
      label: "Download Log",
      key_id: "LOG",
      event: (e: number, label: string, eventSocket: Function) =>
        eventSocket(e, label),
      position: "bottom",
      icon: <CloudDownloadOutlined />
    },
    {
      type: 27,
      label: "Reset Device",
      key_id: "RESET_DEVICE",
      event: (e: number, label: string, eventSocket: Function) =>
        eventSocket(e, label),
      position: "bottom",
      hidden: localStorage.getItem("acr") === "OPR_SUPPORT_LINE_1",
      icon: <i className="material-icons">reset_tv</i>
    }
  ];
};
