import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Spinner,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Label,
  FormGroup,
  CardFooter,
  Alert,
  Form,
  FormFeedback
} from "reactstrap";
import { Formik } from "formik";
import { Switch } from "antd";
import style from "../DeviceProfileDetailsPage.module.scss";
import {
  updateDeviceTypesConfig,
  getDeviceTypesConfig
} from "../../../common/api/device_types";
import ListfOfVideos from "./components/LisfOfVideos";
import ListOfPersistence from "./components/ListOfPersistence";
import { TimePicker } from "antd";
import moment from "moment";
import { getOperatorConfig } from "common/api/operators-config";

interface IPropsInterface {
  match: any;
  history: { push(data: any): void };
}

const DeviceProfileCofig = ({ history, match }: IPropsInterface) => {
  let timeout: any = null;

  const [configurations, setConfigurations] = useState<any>(null);
  const [operatorConfig, setOperatorConfig] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<any>(null);
  const [responseSuccess, setResponseSuccess] = useState<string>("");
  const [dataForm, setDataForm] = useState<any>({});
  const [numVideos, setNumVideos] = useState<any[]>([]);
  const [numPackages, setNumPackages] = useState<any[]>([]);
  const [dataFormCloneVideos, setDataFormCloneVideos] = useState<any>([]);
  const [dataFormClonePackages, setDataFormClonePackages] = useState<any>([]);

  useEffect(() => {
    const {
      params: { ID }
    } = match;
    const fetchData = async () => {
      if (numVideos.length > 0 || numPackages.length > 0) return;
      const result = await getDeviceTypesConfig(ID);
      if (result?.data?.data) {
        setConfigurations(result?.data?.data);
        const videosList = result?.data?.data.videoTutorials.listOfVideos;
        const videosToShow =
          videosList && videosList.length > 0 ? videosList : numVideos;
        const packagesList = result?.data?.data.persistence.packages;
        const packagesToShow =
          packagesList && packagesList.length > 0 ? packagesList : numPackages;
        setDataForm({
          ...result?.data?.data,
          videoTutorials: {
            ...result?.data?.data.videoTutorials,
            listOfVideos: [...videosToShow]
          },
          persistence: {
            packages: [...packagesToShow]
          }
        });
        if (videosList && videosList.length > 0) {
          setNumVideos([...videosToShow]);
        } else if (packagesList && packagesList.length > 0) {
          setNumPackages([...packagesToShow]);
        } else {
          setDataForm({
            ...result?.data?.data,
            videoTutorials: {
              ...result?.data?.data.videoTutorials,
              listOfVideos: []
            },
            persistence: {
              ...result?.data?.data.videoTutorials,
              packages: []
            }
          });
        }
      }
    };
    fetchData();
  }, [match, responseSuccess, numVideos, numPackages.length, numPackages]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getOperatorConfig();
      if (result?.status === 200) {
        setOperatorConfig({ ...result?.data?.data });
      }
    };
    fetchData();
  }, [responseSuccess]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [timeout]);

  const _onEditDeviceProfileConfig: any = async (data: {}): Promise<
    undefined | object
  > => {
    const {
      params: { ID }
    } = match;

    try {
      setLoading(true);
      const result: {
        status: number;
        error: string;
        message: string;
        data: {
          message: string;
        };
      } = await updateDeviceTypesConfig(ID, data);

      if (result && result.status === 201) {
        setResponseSuccess("Device Profile Config updated!");
        setNumVideos([]);
        setNumPackages([]);
        timeout = setTimeout(() => {
          setResponseSuccess("");
        }, 4000);
      } else {
        setResponseError(
          result?.error || result?.message || result?.data?.message || "Error"
        );
        timeout = setTimeout(() => {
          setResponseError("");
        }, 6000);
      }

      timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      return err;
    }
  };

  const _renderAlert: Function = () => {
    if (responseSuccess || responseError) {
      return (
        <Alert
          in={responseSuccess || responseError}
          color={responseError ? "danger" : "success"}
          tag="h5"
          className="mt-3"
        >
          {responseSuccess || responseError}
        </Alert>
      );
    }
  };

  const _loading = () => {
    return (
      <div className="d-flex justify-content-center m-5 in">
        <Spinner color="primary" />
      </div>
    );
  };

  const isNumber = key => {
    const arr = ["interval", "updateInterval", "specialInterval"];
    return arr.includes(key);
  };

  const isTime = (key: string) => {
    const arr = ["endTime", "startTime"];
    return arr.includes(key);
  };

  const minValue = (keyMaster: string, keyChild: string) => {
    const finder = keyMaster + keyChild;
    if (!finder) {
      return 1;
    }
    const arrMinValue = [
      { keyName: "coresAppsupdateInterval", value: 6 },
      { keyName: "appsinterval", value: 6 },
      { keyName: "otaUpdatesinterval", value: 6 },
      { keyName: "helpupdateInterval", value: 6 },
      { keyName: "statusReportinterval", value: 30 }
    ];
    return arrMinValue.find(el => el.keyName === finder)?.value;
  };

  const _renderSwitchLabel = ({
    key,
    keyChild,
    valueKeyChild,
    index,
    handleChange
  }) => {
    return (
      <>
        <div className={style.labelSwitchWrap}>
          <Col xs="9">
            <Label className={style.labelSwitch} htmlFor={keyChild}>
              {parseKeyTitle(keyChild)}
            </Label>
          </Col>
          <Col xs="3">
            <Switch
              style={{ float: "right" }}
              size="small"
              defaultChecked={valueKeyChild}
              disabled={
                keyChild !== "status" && dataForm[key]?.status === false
              }
              onChange={(checked: boolean) => {
                setDataForm({
                  ...dataForm,
                  [key]: {
                    ...dataForm[key],
                    [keyChild]: checked
                  }
                });
              }}
            />
          </Col>
        </div>
        {key === "remoteSupport" &&
          keyChild === "async" &&
          !operatorConfig?.pushNotifications?.status && (
            <Row>
              <Col xs="12">
                <FormFeedback
                  style={{
                    display:
                      operatorConfig?.remoteSupport?.async ||
                      dataForm?.remoteSupport?.async
                        ? "block"
                        : "none"
                  }}
                >
                  Push notifications are disabled on operator configurations so
                  Remote Assistance will only work on some conditions. Please
                  check the manual to know more
                </FormFeedback>
              </Col>
            </Row>
          )}
      </>
    );
  };

  const _renderInputTextLabel = ({
    key,
    keyChild,
    valueKeyChild,
    index,
    handleChange
  }) => {
    return (
      <>
        <Label className={style.labelSwitch} htmlFor={keyChild}>
          {parseKeyTitle(keyChild, key)}
        </Label>
        {_renderWrapInput({
          key,
          keyChild,
          valueKeyChild,
          index,
          handleChange
        })}
      </>
    );
  };

  const _renderWrapInput = ({
    key,
    keyChild,
    valueKeyChild,
    index,
    handleChange
  }) => {
    if (keyChild === "allowPrograms") {
      return (
        <Card className={style.smallBox}>
          <CardBody>
            {valueKeyChild &&
              valueKeyChild.map(({ name, allowed, id }, i) => {
                return (
                  <div key={name + i}>
                    <Label className={style.labelSwitch} htmlFor={keyChild}>
                      <strong>{parseKeyTitle(name)}</strong>
                    </Label>
                    <Switch
                      size="small"
                      disabled={!dataForm[key]?.status || id === 5}
                      className="float-right"
                      defaultChecked={allowed}
                      onChange={(checked: boolean) => {
                        setDataForm({
                          ...dataForm,
                          [key]: {
                            ...dataForm.earlyAccessProgram,
                            allowPrograms: [
                              ...dataForm.earlyAccessProgram.allowPrograms.filter(
                                allowProgram => allowProgram.name !== name
                              ),
                              {
                                name: name,
                                allowed: checked
                              }
                            ]
                          }
                        });
                      }}
                    />
                  </div>
                );
              })}
          </CardBody>
        </Card>
      );
    }
    if (keyChild === "listOfVideos") {
      return (
        <ListfOfVideos
          listOfVideos={dataForm.videoTutorials?.listOfVideos}
          setDataFormClone={setDataFormCloneVideos}
          dataFormClone={dataFormCloneVideos}
          disabled={dataForm[key]?.status === false}
        />
      );
    }
    if (keyChild === "packages") {
      return (
        <ListOfPersistence
          list={dataForm.persistence?.packages}
          setDataFormClone={setDataFormClonePackages}
          dataFormClone={dataFormClonePackages}
          disabled={false}
        />
      );
    }

    return isTime(keyChild) ? (
      <TimePicker
        format="HH:mm"
        defaultValue={
          valueKeyChild
            ? moment(valueKeyChild)
            : keyChild === "startTime"
            ? moment("2021-03-23T03:00:24+00:00")
            : moment("2021-03-23T06:00:24+00:00")
        }
        disabled={dataForm[key]?.status === false}
        className="form-control"
        name={keyChild}
        // placeholder={`Enter ${parseKeyTitle(keyChild)}`}
        onChange={e => {
          setDataForm({
            ...dataForm,
            [key]: {
              ...dataForm[key],
              [keyChild]: e
            }
          });
        }}
      />
    ) : (
      <Input
        type={
          isNumber(keyChild)
            ? "number"
            : keyChild.indexOf("Url") > -1
            ? "url"
            : "text"
        }
        required={dataForm[key]?.status && isRequired(keyChild)}
        min={minValue(key, keyChild)}
        id={valueKeyChild + index}
        name={keyChild}
        defaultValue={valueKeyChild}
        placeholder={`Enter ${parseKeyTitle(keyChild)}`}
        disabled={dataForm[key]?.status === false}
        readOnly={
          keyChild.indexOf("version") > -1 ||
          keyChild.indexOf("packageName") > -1
        }
        onChange={e => {
          setDataForm({
            ...dataForm,
            [key]: {
              ...dataForm[key],
              [keyChild]: e.target.value
            }
          });
        }}
      />
    );
  };

  const _renderBox = ({ side, handleChange }) => {
    if (configurations) {
      return Object.entries(configurations)
        .map(([key, keyValue], index) => {
          return (
            <div className={style.contentWrapper} key={key + index}>
              <Card>
                <CardHeader>
                  <h3 className={style.title}>
                    <strong>{parseKeyTitle(key)}</strong>
                  </h3>
                </CardHeader>
                <CardBody>
                  {keyValue
                    ? Object
                        //@ts-ignore
                        .entries(keyValue)
                        .map(([keyChild, valueKeyChild], indexChild) => {
                          return (
                            <FormGroup key={key + keyChild + index} row>
                              {typeof valueKeyChild === "boolean"
                                ? _renderSwitchLabel({
                                    key,
                                    keyChild,
                                    valueKeyChild,
                                    index,
                                    handleChange
                                  })
                                : _renderInputTextLabel({
                                    key,
                                    keyChild,
                                    valueKeyChild,
                                    index,
                                    handleChange
                                  })}
                            </FormGroup>
                          );
                        })
                    : null}
                </CardBody>
              </Card>
            </div>
          );
        })
        .filter((item, indexFilter) => side(indexFilter));
    }
  };

  const parseKeyTitle = (key, keyMaster = "") =>
    parseLabelsForOtherStrings(key, keyMaster) ||
    key
      .split(/([A-Z][a-z]+)/)
      .join(" ")
      .toLowerCase();

  const parseLabelsForOtherStrings = (key, keyMaster) => {
    const keyToCheck =
      keyMaster === "privacy" && key === "interval" ? "specialInterval" : key;
    const newStrings = {
      status: "Enable",
      interval: "Update Interval (in hours)",
      updateInterval: "Update Interval (in hours)",
      otaUpdates: "OTA Updates",
      specialInterval: "Report Interval (in minutes)",
      reportDeviceStatus: "Enable",
      promptUser: "Ask user permission before reporting status",
      appUsageInstalls: "Share app installs with status report",
      keepHistory: "Enable status report to be stored on analytics platform",
      flushHistory: "Allow user to delete status history",
      allowUserToDisable: "Allow users to disable status report",
      mandatoryUserConsent:
        "Ask user permission before start remote assistance",
      apkPathOTA: "Apk path OTA",
      versionCodeOTA: "Version code OTA",
      versionNameOTA: "Version name OTA",
      packageNameOTA: "Package name OTA",
      apkPathHELP: "Apk path HELP",
      versionCodeHELP: "Version code HELP",
      versionNameHELP: "Version name HELP",
      packageNameHELP: "Package name HELP",
      apkPathANALYTICS: "Apk path ANALYTICS",
      versionCodeANALYTICS: "Version code ANALYTICS",
      versionNameANALYTICS: "Version name ANALYTICS",
      packageNameANALYTICS: "Package name ANALYTICS",
      apkPathDeviceControl: "Apk path DEVICE CONTROL",
      versionCodeDeviceControl: "Version code DEVICE CONTROL",
      versionNameDeviceControl: "Version name DEVICE CONTROL",
      packageNameDeviceControl: "Package name DEVICE CONTROL",
      tcUrl: "Terms and conditions Url",
      privacy: "Device Status",
      apps: "App Install and Updates",
      maintenance: "Maintenance Window",
      endTime: "End time ",
      startTime: "Start time ",
      packages: "Apps",
      includeOtaForce: "OTA force update only during this window",
      includeApps: "App installs and updates only during this window  "
    };
    return newStrings[keyToCheck];
  };

  const _renderButton: Function = () => {
    return (
      <Button
        type="submit"
        size="lg mr-2"
        color="primary"
        className={style.addButton}
      >
        <i className="material-icons">save</i>Save All
      </Button>
    );
  };

  const isRequired = key => {
    const arr = ["endTime", "startTime", "updateInterval"];
    return arr.includes(key);
  };

  const onSubmit = async e => {
    const cleanNumVideos = dataFormCloneVideos.filter(
      video => video.name !== ""
    );

    const cleanClonePackages = dataFormClonePackages.filter(
      packageY => packageY.packageName !== ""
    );

    setNumVideos([...dataForm?.videoTutorials?.listOfVideos]);
    setNumPackages([...dataForm?.persistence?.packages]);
    const parseDataForm = {
      ...dataForm,
      videoTutorials: {
        ...dataForm.videoTutorials,
        listOfVideos: [...cleanNumVideos]
      },
      persistence: {
        ...dataForm.persistence,
        packages: [...cleanClonePackages]
      },
      maintenance: {
        ...dataForm.maintenance,
        startTime:
          dataForm.maintenance.startTime || moment("2021-03-23T03:00:24+00:00"),
        endTime:
          dataForm.maintenance.endTime || moment("2021-03-23T06:00:24+00:00")
      }
    };
    await _onEditDeviceProfileConfig(parseDataForm);
  };

  if (loading) {
    return _loading();
  }

  return (
    <div>
      <Formik
        initialValues={configurations}
        enableReinitialize={true}
        onSubmit={onSubmit}
        render={({ handleChange }) => (
          <Form onSubmit={onSubmit}>
            <CardHeader className={style.floatButtons}>
              {localStorage.getItem("acr") !== "OPR_VIEWER" && _renderButton()}
            </CardHeader>
            {_renderAlert()}
            <Row className="p-3">
              <Col md="6">
                {_renderBox({
                  side: num => [0, 1, 2, 3, 9, 11, 5].includes(num),
                  handleChange
                })}
              </Col>
              <Col md="6">
                {_renderBox({
                  side: num => [4, 6, 7, 8, 10].includes(num),
                  handleChange
                })}
              </Col>
            </Row>
            <CardFooter className={style.floatButtons}>
              {localStorage.getItem("acr") !== "OPR_VIEWER" && _renderButton()}
            </CardFooter>
          </Form>
        )}
      />
    </div>
  );
};

export default withRouter<any, any>(DeviceProfileCofig);
