import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Spinner,
  Button,
  CardHeader,
  Alert,
  Table
} from "reactstrap";
import style from "../DeviceProfileDetailsPage.module.scss";
import {
  getDeviceTypesAppsPermissions,
  createDeviceTypesAppPermissions,
  deleteDeviceTypesAppPermissions,
  updateDeviceTypesAppPermissions
} from "../../../common/api/device_types";
import ModalComponent from "../../../app-components/ModalComponent";
import DeleteModal from "../../../app-components/ModalComponent/DeleteModal/DeleteModal";
import AddAppForm from "./AddAppPermissionsForm";
import { Tooltip } from "antd";
import Status from "components/shared/Status";

interface IPropsInterface {
  match: any;
  history: { push(data: any): void };
}

const DeviceProfileAppsPermissions = ({ history, match }: IPropsInterface) => {
  let timeout: any = null;
  const [idDeviceProfile, setIdDeviceProfile] = useState("");
  const [configurations, setConfigurations] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<any>(null);
  const [responseSuccess, setResponseSuccess] = useState<string>("");
  const [idAppToDelete, setIdAppToDelete] = useState<string>("");
  const [appDeleted, setAppDeleted] = useState<boolean>(false);
  const [elementToEdit, setElementToEdit] = useState<any>(null);
  useEffect(() => {
    const {
      params: { ID }
    } = match;

    setIdDeviceProfile(ID);
    const fetchData = async () => {
      const result = await getDeviceTypesAppsPermissions(ID);
      if (result?.status === 200) {
        const apps = result?.data?.resource?.data || [];
        setConfigurations(apps);

        setAppDeleted(false);
      }
    };
    fetchData();
  }, [match, responseSuccess, appDeleted]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [timeout]);

  const _onEditDeviceProfileConfig: any = async (
    data: any
  ): Promise<undefined | object> => {
    const {
      params: { ID }
    } = match;

    try {
      setLoading(true);
      let result: {
        data: any;
        status: number;
        error: any;
        message: string;
      };

      if (!elementToEdit) {
        result = await createDeviceTypesAppPermissions(ID, {
          operator_device_type_id: ID,
          ...data
        });
      } else {
        result = await updateDeviceTypesAppPermissions(elementToEdit?.id, {
          operator_device_type_id: ID,
          ...data
        });
      }

      if (result && result.status === 201) {
        setResponseSuccess("App Permissions Successfully Created!");
        setShowForm(false);
        timeout = setTimeout(() => {
          setResponseSuccess("");
        }, 4000);
      } else if (result && result.status === 200) {
        setResponseSuccess("App Permissions Updated Successfully!");
        setShowForm(false);
        timeout = setTimeout(() => {
          setResponseSuccess("");
        }, 4000);
      } else {
        setResponseError(result?.data || result);
        timeout = setTimeout(() => {
          setResponseError("");
        }, 6000);
      }

      timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      return err;
    }
  };

  const _renderAlert: Function = () => {
    if (responseError) {
      const {
        details
      }: { details: object; message: string } | boolean | any = responseError;

      return (
        <Alert color="warning">
          {!details && responseError && responseError?.message && (
            <p>{responseError?.message || "Error"}</p>
          )}

          {responseError?.error?.details &&
            Object.keys(responseError?.error?.details).map(
              (item: any, index: any) => (
                <div key={index}>
                  <strong>{item}: </strong>
                  {responseError?.error?.details[item]}
                </div>
              )
            )}
        </Alert>
      );
    } else if (responseSuccess) {
      return (
        <Alert in={responseSuccess} color="success" tag="h5" className="mt-3">
          {responseSuccess}
        </Alert>
      );
    }
  };

  const _loading = () => {
    return (
      <div className="d-flex justify-content-center m-5 in">
        <Spinner color="primary" />
      </div>
    );
  };

  const _renderButton: Function = () => {
    return (
      <Button
        type="button"
        size="lg mr-2"
        color="primary"
        className={style.addButton}
        onClick={() => {
          setElementToEdit(null);
          setShowForm(true);
        }}
      >
        <i className="material-icons">add_circle_outline</i>Add New App
        Permissions
      </Button>
    );
  };

  const _onDeleteDeviceType: any = async (id: number): Promise<void> => {
    const result = await deleteDeviceTypesAppPermissions(idAppToDelete);

    if (result && result.status === 204) {
      setAppDeleted(true);
      setTimeout(() => {
        setShowDeleteModal(!showDeleteModal);
      }, 1000);
    } else {
      setResponseSuccess("Error!");
    }
  };

  const _renderTableData = () => {
    return (
      configurations?.length &&
      configurations?.map((app, index: number) => {
        const {
          get_status,
          append_status,
          package_name,
          id,
          get_lock,
          set_lock,
          get_integration_id,
          get_uuid,
          get_provision_id
        } = app;
        return (
          <tr key={id + index}>
            <td>{package_name}</td>
            <td>
              <Status status={get_status} />
            </td>
            <td>
              <Status status={append_status} />
            </td>
            <td>
              <Status status={get_lock} />
            </td>
            <td>
              <Status status={set_lock} />
            </td>
            <td>
              <Status status={get_integration_id} />
            </td>
            <td>
              <Status status={get_uuid} />
            </td>
            <td>
              <Status status={get_provision_id} />
            </td>
            <td>
              <div className={style.buttonsWrapperActions}>
                <Tooltip placement="top" title="Edit">
                  <Button
                    className="material-icons mode_edit"
                    color="primary"
                    size="sm"
                    onClick={(): any => {
                      setElementToEdit(app);
                      setShowForm(true);
                    }}
                  />
                </Tooltip>
                {localStorage.getItem("acr") !== "OPR_VIEWER" && (
                  <Tooltip placement="top" title="Delete">
                    <Button
                      className="material-icons delete_forever"
                      color="danger"
                      size="sm"
                      onClick={(e: any): any => {
                        setIdAppToDelete(id);
                        setShowDeleteModal(true);
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  if (loading) {
    return _loading();
  }

  return (
    <div>
      <ModalComponent
        modalTitle={
          !elementToEdit ? "Add App Permissions" : "Edit App Permissions"
        }
        isOpen={showForm}
        toggle={() => setShowForm(!showForm)}
      >
        <AddAppForm
          typeId={idDeviceProfile}
          responseError={responseError}
          onShowForm={() => setShowForm(!showForm)}
          data={elementToEdit}
          onEditDeviceProfileConfig={_onEditDeviceProfileConfig}
        />
      </ModalComponent>
      <CardHeader className={style.floatButtons}>
        {localStorage.getItem("acr") !== "OPR_VIEWER" && _renderButton()}
      </CardHeader>
      {_renderAlert()}
      {configurations?.length > 0 && (
        <Row className={style.WrapTable}>
          <Col md="12">
            <Table className={style.Table} responsive>
              <thead>
                <tr>
                  <th>Package Name</th>
                  <th>Get Status</th>
                  <th>Append Status</th>
                  <th>Get Lock</th>
                  <th>Set Lock</th>
                  <th>Get Integration Id</th>
                  <th>Get UUID</th>
                  <th>Get Provision Id</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{_renderTableData()}</tbody>
            </Table>
          </Col>
        </Row>
      )}
      <DeleteModal
        onToggle={() => setShowDeleteModal(!showDeleteModal)}
        onDelete={_onDeleteDeviceType}
        id={idAppToDelete}
        isOpen={showDeleteModal}
        modalTitle="Delete App Permissions"
        responseError={responseSuccess}
      >
        This App will be deleted. Please confirm action!
      </DeleteModal>
    </div>
  );
};

export default withRouter<any, any>(DeviceProfileAppsPermissions);
