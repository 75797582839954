import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Spinner,
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Label,
  FormGroup,
  CardFooter,
  Alert
} from "reactstrap";
import { Formik, Form } from "formik";
import { Switch } from "antd";
import style from "../DevicesDetailsPage.module.scss";
import { updateDevice } from "../../../common/api/devices";
import { releaseCycle } from "../../../common/helper/helper";
import moment from "moment";

interface IPropsInterface {
  match: any;
  history: { push(data: any): void };
  lock: boolean | number;
  debug_mode: boolean | number;
  release_cycle: boolean | number;
}

const DeviceProfileCofig = ({
  history,
  match,
  lock,
  release_cycle,
  debug_mode
}: IPropsInterface) => {
  let timeout: any = null;

  const [configurations, setConfigurations] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<any>(null);
  const [responseSuccess, setResponseSuccess] = useState<string>("");
  const [dataForm, setDataForm] = useState<any>({
    lock: lock,
    release_cycle: release_cycle,
    debug_mode: debug_mode
  });

  const [heightBlock, setHeightBlock] = useState<number>(0);
  const refBlock: any = useRef(null);
  const IS_NOT_VIEWER = localStorage.getItem("acr") !== "OPR_VIEWER";

  useEffect(() => {
    if (refBlock) {
      const html: HTMLElement = refBlock?.current;
      setHeightBlock(html.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (!configurations) {
      const lockBoolean = lock === 1;
      setConfigurations({
        ...configurations,
        DeviceLock: { status: lockBoolean },
        ReleaseCycle: {
          selectReleaseCycle: [...releaseCycle]
        },
        debugMode: { enable: debug_mode }
      });
    }
  }, [configurations, lock, debug_mode]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [timeout]);

  const _onEditDeviceProfileConfig: any = async (data: {}): Promise<
    undefined | object | unknown
  > => {
    const {
      params: { ID }
    } = match;

    try {
      setLoading(true);
      const result: {
        status: number;
        error: string;
        message: string;
      } = await updateDevice(ID, data);
      if (result && result.status === 200) {
        setResponseSuccess("Device Config updated!");
        timeout = setTimeout(() => {
          setResponseSuccess("");
        }, 4000);
      } else {
        setResponseError(result?.error || result?.message || "Error");
        timeout = setTimeout(() => {
          setResponseError("");
        }, 6000);
      }

      timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      return err;
    }
  };

  const _renderAlert: Function = () => {
    if (responseSuccess || responseError) {
      return (
        <Alert
          in={responseSuccess || responseError}
          color={responseError ? "danger" : "success"}
          tag="h5"
          className="mt-3"
        >
          {responseSuccess || responseError}
        </Alert>
      );
    }
  };

  const _loading = () => {
    return (
      <div className="d-flex justify-content-center m-5 in">
        <Spinner color="primary" />
      </div>
    );
  };

  const _renderSwitchLabel = ({ keyChild }) => {
    return (
      <>
        <Label className={style.labelSwitch} htmlFor={keyChild}>
          {parseKeyTitle(keyChild)}
        </Label>
        {
          <Switch
            size="small"
            defaultChecked={dataForm.lock}
            onChange={(checked: boolean) => {
              setDataForm({
                ...dataForm,
                lock: checked ? 1 : 0
              });
            }}
          />
        }
      </>
    );
  };

  const _renderSwitchDebugMode = ({ keyChild }) => {
    moment.locale();
    let message: any = "Debug is Disable";
    const momentNow = moment();
    const momentDebug = moment(dataForm.debug_mode);
    const isMoreThan24 = momentNow.diff(momentDebug, "hours") > 24;
    const momentExpireDate = moment(dataForm.debug_mode)
      .add(1, "days")
      .format("DD-MM-YYYY HH:mm:ss");

    if (dataForm.debug_mode) {
      if (!isMoreThan24) {
        message = (
          <>
            Debug for this device will end at:
            <strong className="ml-1">{momentExpireDate}</strong>
          </>
        );
      } else {
        message = `Debug for device has expired`;
      }
    }
    return (
      <>
        <div className={style.rowGroups}>
          <Label className={style.labelSwitch} htmlFor={keyChild}>
            Enable
          </Label>

          <Switch
            className={isMoreThan24 ? style.error : ""}
            size="small"
            defaultChecked={!!dataForm.debug_mode}
            onChange={(checked: boolean) => {
              setDataForm({
                ...dataForm,
                debug_mode: checked
                  ? moment().format("YYYY-MM-DD HH:mm:ss")
                  : null
              });
            }}
          />
        </div>
        <div className={style.messageHelper}>
          <small className={isMoreThan24 ? style.error : ""}>{message}</small>
          <Button
            type="submit"
            size="sm mr-2"
            color="primary"
            className={style.reloadBbutton}
            hidden={!dataForm.debug_mode}
            onClick={() => {
              setDataForm({
                ...dataForm,
                debug_mode: moment().format("YYYY-MM-DD HH:mm:ss")
              });
              onSubmit();
            }}
          >
            <i className="material-icons">loop</i>
          </Button>
        </div>
      </>
    );
  };

  const _renderInputTextLabel = ({ key, keyChild, valueKeyChild, index }) => {
    return (
      <>
        <Label className={style.labelSwitch} htmlFor={keyChild}>
          {parseKeyTitle(keyChild, key)}
        </Label>
        {_renderWrapInput({
          keyChild,
          valueKeyChild,
          index
        })}
      </>
    );
  };

  const _renderWrapInput = ({ keyChild, valueKeyChild, index }) => {
    return (
      <Input
        type="select"
        id={valueKeyChild + index}
        name={keyChild}
        defaultValue={dataForm.release_cycle}
        placeholder={`Enter ${parseKeyTitle(keyChild)}`}
        readOnly={keyChild.indexOf("version") > -1}
        onChange={e => {
          setDataForm({
            ...dataForm,
            release_cycle: parseInt(e.target.value)
          });
        }}
      >
        <option>Select Release Cycle</option>;
        {configurations?.ReleaseCycle?.selectReleaseCycle.map(
          ({ id, name: nameConfig }) => {
            return (
              <option value={id} key={id}>
                {nameConfig}
              </option>
            );
          }
        )}
      </Input>
    );
  };

  const _renderBox = ({ side, handleChange }) => {
    if (configurations) {
      return Object.entries(configurations)
        .map(([key, keyValue], index) => {
          if (key === "debugMode" && !IS_NOT_VIEWER) {
            return <React.Fragment key={key}></React.Fragment>;
          }
          return (
            <div className={style.contentWrapper} key={key + index}>
              <Card>
                <CardHeader>
                  <h3 className={style.title}>
                    {/* <i className="material-icons ">speaker_notes</i> */}
                    <strong>{parseKeyTitle(key)}</strong>
                  </h3>
                </CardHeader>

                {keyValue &&
                  Object
                    //@ts-ignore
                    .entries(keyValue)
                    .map(([keyChild, valueKeyChild], indexChild) => {
                      return (
                        <CardBody key={key + keyChild + index}>
                          <FormGroup row>
                            {typeof valueKeyChild === "boolean"
                              ? _renderSwitchLabel({
                                  keyChild
                                })
                              : key === "debugMode"
                              ? _renderSwitchDebugMode({
                                  keyChild
                                })
                              : _renderInputTextLabel({
                                  key,
                                  keyChild,
                                  valueKeyChild,
                                  index
                                })}
                          </FormGroup>
                        </CardBody>
                      );
                    })}
              </Card>
            </div>
          );
        })
        .filter((item, indexFilter) => side(indexFilter));
    }
  };

  const parseKeyTitle = (key, keyMaster = "") =>
    key.split(/([A-Z][a-z]+)/).join(" ");

  const _renderButton: Function = () => {
    return (
      <Button
        type="submit"
        size="lg mr-2"
        color="primary"
        className={style.addButton}
      >
        <i className="material-icons">save</i>Save All
      </Button>
    );
  };

  const onSubmit = async () => {
    _onEditDeviceProfileConfig({ ...dataForm });
  };

  if (loading) {
    return _loading();
  }

  return (
    <div ref={refBlock}>
      <Formik
        initialValues={configurations}
        enableReinitialize={true}
        onSubmit={onSubmit}
        render={({ handleChange }) => (
          <Form>
            <CardHeader
              hidden={heightBlock < 700}
              className={style.floatButtons}
            >
              {localStorage.getItem("acr") !== "OPR_VIEWER" && _renderButton()}
            </CardHeader>
            {_renderAlert()}
            <Row className="p-3">
              <Col md="6">
                {_renderBox({
                  side: num => num % 2 === 0,
                  handleChange
                })}
              </Col>
              <Col md="6">
                {_renderBox({
                  side: num => num % 2 !== 0,
                  handleChange
                })}
              </Col>
            </Row>
            <CardFooter className={style.floatButtons}>
              {localStorage.getItem("acr") !== "OPR_VIEWER" && _renderButton()}
            </CardFooter>
          </Form>
        )}
      />
    </div>
  );
};

export default withRouter<any, any>(DeviceProfileCofig);
