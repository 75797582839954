import React, { useState, memo } from "react";
import { CardBody, FormGroup, Label, Input, Col } from "reactstrap";
import style from "../AdminConfigPage.module.scss";
import { Switch } from "antd";

interface IPropsInterface {
  values: any;
  validationOptions: [];
  handleChange: any;
}

const Handshake = ({
  values,
  validationOptions,
  handleChange
}: IPropsInterface) => {
  const [status, setStatus] = useState(values.status);

  const generateOptions = () => {
    return validationOptions.map((item, key) => (
      <option value={key} key={key}>
        {item}
      </option>
    ));
  };

  return (
    <div className={style.contentWrapper}>
      <CardBody>
        <FormGroup row>
          <Col md="12">
            <Label htmlFor="reportInterval">Validation</Label>
            <Input
              type="select"
              id="key"
              name="key"
              required={status}
              value={values.validation ? values.validation : ""}
              onChange={e => {
                handleChange({
                  validation: e.target.value
                });
              }}
            >
              {generateOptions()}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="4">
            <Label htmlFor="apkPath">Validate Additional Mac Addresses</Label>
          </Col>
          <Col xs="8" md="8">
            <Switch
              style={{ float: "right" }}
              size="small"
              defaultChecked={values.validateAdditionalMacAddresses}
              onChange={(checked: boolean) => {
                setStatus(checked);
                handleChange({
                  validateAdditionalMacAddresses: checked
                });
              }}
            />
          </Col>
        </FormGroup>
      </CardBody>
    </div>
  );
};

export default memo(Handshake);
