import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { fetchAuthRulesAction } from "redux/actions";
import { AuthorizationHOC } from "HOCs/AuthorizationHOC";
import DashboardPage from "pages/DashboardPage";
import UsersListPage from "pages/UsersListPage";
import OperatorAdministratorsListPage from "pages/OperatorsListPage";
import OperatorAdministratorDetailsPage from "pages/OperatorDetailsPage";
import UserDetails from "pages/UserDetails";
import DeviceProfilesListPage from "pages/DeviceProfilesListPage";
import DeviceProfileDetailsPage from "pages/DeviceProfileDetailsPage";
import RomsDetailsPage from "pages/RomsDetailsPage";
import RomsListPage from "pages/RomsListPage";
import OperatorConfigPage from "pages/OperatorConfig";
import AdminConfigPage from "pages/AdminConfig";
import { checkAuthorization } from "common/api/authorization";
import { any } from "prop-types";
import DevicesListPage from "pages/DevicesListPage";
import AlarmPage from "pages/AlarmPage";
import DevicesDetailsPage from "pages/DevicesDetailsPage";
import RemoteAssistancesListPage from "pages/RemoteAssistancesListPage";
import RemoteAssistancesActionsPage from "pages/RemoteAssistancesActionsPage";
import RemoteAssistancesActionsPageAntd from "pages/RemoteAssistancesActionsPageAntd";
import ProfilePage from "pages/ProfilePage";
import ErrorPage from "pages/ErrorPage";

interface IPropsInterface {
  isAuthenticated: boolean;
  fetchAuthRules: Function;
  roles: any;
  currentUser: any;
}

interface IStateInterface {
  isAuthorized: boolean | null;
  itemObject: any;
}

class ProtectedRoutes extends Component<IPropsInterface, IStateInterface> {
  constructor(props: any) {
    super(props);
    this.state = {
      isAuthorized: null,
      itemObject: any
    };
  }

  async componentDidMount() {
    const { roles } = this.props;
    if (roles && roles.length < 1) {
      this.props.fetchAuthRules();
    }

    const checkAuthorizationResult = await checkAuthorization();
    if (checkAuthorizationResult && checkAuthorizationResult.status === 200) {
      this.setState({
        isAuthorized: true
      });
    } else {
      this.setState({
        isAuthorized: false
      });
    }
  }

  render() {
    const { isAuthorized } = this.state;

    if (isAuthorized === null) {
      return null;
    } else if (isAuthorized === false) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="app">
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          <Route
            exact
            path="/dashboard"
            name="dashboard"
            component={AuthorizationHOC(
              DashboardPage,
              "auth.auth.check_authentication"
            )}
          />

          <Route
            exact
            path="/manage/profile"
            name="Profile"
            component={AuthorizationHOC(ProfilePage, "auth.auth.profile")}
          />

          {/*USERS*/}

          <Route
            exact
            path="/users/create"
            name="users-create"
            component={AuthorizationHOC(UsersListPage, "auth.users.list")}
          />

          <Route
            exact
            path="/users/"
            name="users"
            component={AuthorizationHOC(UsersListPage, "auth.users.list")}
          />
          <Route
            exact
            path="/users/page/:pageNumber"
            name="users"
            component={AuthorizationHOC(UsersListPage, "auth.users.list")}
          />

          <Route
            exact
            path="/user/:ID"
            name="users-delete"
            component={AuthorizationHOC(UserDetails, "auth.users.read")}
          />
          <Route
            exact
            path="/user/:ID"
            name="users-edit"
            component={AuthorizationHOC(UserDetails, "auth.users.update")}
          />

          {/*OPERATORS*/}
          <Route
            exact
            path="/operators/create"
            name="operator-create"
            component={AuthorizationHOC(
              OperatorAdministratorsListPage,
              "auth.auth.check_authentication"
            )}
          />
          <Route
            exact
            path="/operators"
            name="operators"
            component={AuthorizationHOC(
              OperatorAdministratorsListPage,
              "auth.auth.check_authentication"
            )}
          />

          <Route
            exact
            path="/operators/page/:pageNumber"
            name="operators"
            component={AuthorizationHOC(
              OperatorAdministratorsListPage,
              "auth.auth.check_authentication"
            )}
          />

          <Route
            exact
            path="/operator/:ID"
            name="operator-delete"
            component={AuthorizationHOC(
              OperatorAdministratorDetailsPage,
              "auth.users.read"
            )}
          />
          <Route
            exact
            path="/operator/:ID"
            name="operator-edit"
            component={AuthorizationHOC(
              OperatorAdministratorDetailsPage,
              "auth.users.update"
            )}
          />

          {/*ADMIN CONFIG*/}
          <Route
            exact
            path="/admin-configurations"
            name="admin-configurations"
            component={AuthorizationHOC(
              AdminConfigPage,
              "core.admin-configurations"
            )}
          />

          {/*DEVICES*/}
          <Route
            exact
            path="/device/create"
            name="device-create"
            component={AuthorizationHOC(DevicesListPage, "core.devices.create")}
          />
          <Route
            exact
            path="/devices"
            name="device-list"
            component={AuthorizationHOC(DevicesListPage, "core.devices.list")}
          />
          <Route
            exact
            path="/alarms"
            name="alarms-list"
            component={AuthorizationHOC(AlarmPage, "core.devices.list")}
          />
          <Route
            exact
            path="/devices/page/:pageNumber"
            name="device-list"
            component={AuthorizationHOC(DevicesListPage, "core.devices.list")}
          />

          <Route
            exact
            path="/device/:ID"
            name="device-delete"
            component={AuthorizationHOC(
              DevicesDetailsPage,
              "core.devices.list"
            )}
          />
          <Route
            exact
            path="/device/:ID"
            name="device-edit"
            component={AuthorizationHOC(
              DevicesDetailsPage,
              "core.devices.create"
            )}
          />

          {/*DEVICE-PROFILES*/}
          <Route
            exact
            path="/device-profiles/create"
            name="device-profile-create"
            component={AuthorizationHOC(
              DeviceProfilesListPage,
              "core.device-profiles.create"
            )}
          />

          <Route
            exact
            path="/device-profiles"
            name="device-profile-list"
            component={AuthorizationHOC(
              DeviceProfilesListPage,
              "auth.auth.check_authentication"
            )}
          />

          <Route
            exact
            path="/device-profiles/page/:pageNumber"
            name="device-profile-list"
            component={AuthorizationHOC(
              DeviceProfilesListPage,
              "auth.auth.check_authentication"
            )}
          />

          <Route
            exact
            path="/device-profiles/:ID"
            name="device-profile-delete"
            component={AuthorizationHOC(
              DeviceProfileDetailsPage,
              "core.device-profiles.read"
            )}
          />
          <Route
            exact
            path="/device-profiles/:ID"
            name="device-profile-edit"
            component={AuthorizationHOC(
              DeviceProfileDetailsPage,
              "core.device-profiles.read"
            )}
          />

          {/*ROMS*/}
          <Route
            exact
            path="/roms/create"
            name="roms-create"
            component={AuthorizationHOC(
              RomsListPage,
              "auth.auth.check_authentication"
            )}
          />

          <Route
            exact
            path="/roms"
            name="roms-list"
            component={AuthorizationHOC(
              RomsListPage,
              "auth.auth.check_authentication"
            )}
          />

          <Route
            exact
            path="/roms/page/:pageNumber"
            name="roms-list"
            component={AuthorizationHOC(
              RomsListPage,
              "auth.auth.check_authentication"
            )}
          />

          {/* <Route
            exact
            path="/rom/:ID"
            name="rom-delete"
            component={AuthorizationHOC(RomsDetailsPage, "core.roms.delete")}
          />
          <Route
            exact
            path="/rom/:ID"
            name="rom-edit"
            component={AuthorizationHOC(RomsDetailsPage, "core.roms.update")}
          /> */}
          <Route
            exact
            path="/rom/:ID"
            name="rom-list"
            component={AuthorizationHOC(RomsDetailsPage, "core.roms.read")}
          />

          {/*REMOTE-ASSISTANCE*/}
          <Route
            exact
            path="/remote-assistance/create"
            name="remote-assistance-create"
            component={AuthorizationHOC(
              DeviceProfilesListPage,
              "core.device-profiles.create"
            )}
          />

          <Route
            exact
            path="/remote-assistance"
            name="remote-assistance-list"
            component={AuthorizationHOC(
              RemoteAssistancesListPage,
              "core.remote-assistance.read"
            )}
          />

          <Route
            exact
            path="/configurations"
            name="configurations"
            component={AuthorizationHOC(
              OperatorConfigPage,
              "core.remote-assistance.read"
            )}
          />

          <Route
            exact
            path="/remote-assistance/page/:pageNumber"
            name="remote-assistance-list"
            component={AuthorizationHOC(
              RemoteAssistancesListPage,
              "core.remote-assistance.read"
            )}
          />

          <Route
            exact
            path="/remote-assistance/:ID"
            name="remote-assistance-action"
            component={AuthorizationHOC(
              RemoteAssistancesActionsPage,
              "core.remote-assistance.read"
            )}
          />

          <Route
            exact
            path="/device-profile/:ID"
            name="device-profile-edit"
            component={AuthorizationHOC(
              DeviceProfileDetailsPage,
              "core.device-profiles.update"
            )}
          />

          <Route
            exact
            path="/error"
            name="error-page"
            component={AuthorizationHOC(
              ErrorPage,
              "auth.auth.check_authentication"
            )}
          />

          <Route
            exact
            path="/device-profile/:ID"
            name="device-profile-edit"
            component={AuthorizationHOC(
              DeviceProfileDetailsPage,
              "core.device-profiles.update"
            )}
          />
          <Route
            exact
            path="/remote-assistance-actions/:ID"
            name="remote-assistance-actions-antd"
            component={AuthorizationHOC(
              RemoteAssistancesActionsPageAntd,
              "core.remote-assistance.read"
            )}
          />
          <Route render={() => <Redirect to="/error" />} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthorized: state.auth.isAuthorized,
    roles: state.roles.roles,
    currentUser: state.auth.currentUser
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAuthRules: () => dispatch(fetchAuthRulesAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoutes);
