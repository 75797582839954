import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Upload, Tag } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import style from "./ModalRemoteAction.module.scss";
import { postApkFile } from "common/api/remote-assistances";

type iProps = {
  title: string;
  showModal: boolean;
  emitEventSocket: any;
  placeHolder: string;
  toggleModal: any;
  nameButton: string;
  upload?: boolean;
  typeForm?: string;
  cleanString?: any;
  defaultValue?: string;
};

const ModalRemoteAction = ({
  title,
  showModal,
  emitEventSocket,
  placeHolder,
  toggleModal,
  nameButton,
  upload,
  typeForm,
  cleanString,
  defaultValue
}: iProps) => {
  const [fileToupload, setFileToupload] = useState<any>(null);
  const [valueInput, setValueInput] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);

  const removeErrors = () => {
    setErrorMsg(null);
    setFileToupload(null);
    setError(false);
  };
  //@ts-ignore
  useEffect(() => {
    let isSubscribed = true;
    if (fileToupload && !error) {
      const sendUpkFile = async () => {
        const result = await postApkFile(fileToupload);
        if (result?.status === 200 && isSubscribed) {
          setValueInput("");
          setValueInput(result?.data?.resource.url);
          setError(false);
        } else {
          setError(true);
          setErrorMsg(`${result?.data?.message} ${result.status}`);
        }
        setLoading(false);
      };
      sendUpkFile();
    }
    return () => (isSubscribed = false);
  }, [fileToupload, error]);

  const propsUpload = {
    beforeUpload: file => {
      if (file.type !== "application/vnd.android.package-archive") {
        setErrorMsg(`${file.name} is not a apk file`);
        setFileToupload(null);
        setError(true);
        return true;
      }
      removeErrors();
      setLoading(true);
      setFileToupload(file);
      return false;
    },
    onRemove: () => {
      removeErrors();
    }
  };

  return (
    <Modal
      className={style.Modal}
      title={title}
      visible={showModal}
      onCancel={toggleModal}
      footer={[
        cleanString && (
          <Button
            key="delete"
            onClick={cleanString}
            icon={
              <i className={`material-icons ${style.Icon}`}>
                keyboard_backspace
              </i>
            }
          >
            Delete
          </Button>
        ),
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            emitEventSocket(valueInput);
          }}
          disabled={error || !valueInput}
        >
          {nameButton}
        </Button>
      ]}
    >
      <div className="d-flex">
        {typeForm === "number" && title === "Download LOG" ? (
          <>
            <Input
              type={typeForm || "text"}
              onChange={e => {
                const value = parseInt(e.target.value);
                if (value > 1 && value < 99999) {
                  setValueInput(value.toString());
                }
              }}
              defaultValue="0"
              min="1"
              max="99999"
              placeholder={placeHolder}
            />
            <Button className="ml-3" onClick={() => emitEventSocket("99999")}>
              All lines
            </Button>
          </>
        ) : (
          <Input
            type={typeForm || "text"}
            onChange={e => setValueInput(e.target.value)}
            placeholder={placeHolder}
            defaultValue={defaultValue}
          />
        )}
      </div>
      {upload && (
        <>
          <span className={style.Divider}>OR</span>
          <Tag color="red" hidden={!errorMsg} className="mb-3">
            {errorMsg}
          </Tag>
          <Upload
            multiple={false}
            key="upload"
            {...propsUpload}
            className={style.ButtonUpload}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </>
      )}
    </Modal>
  );
};

export default ModalRemoteAction;
