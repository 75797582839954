import React, { ChangeEvent, Component, Fragment } from "react";
import { find, get } from "lodash";
import {
  IDeviceTypesInterface,
  ISingleDeviceTypesInterface,
  ISingleRomInterface
} from "../../../common/interfaces";
import moment from "moment";
import TimeAgo from "react-timeago";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  Alert
} from "reactstrap";
import { Formik } from "formik";
import FormInputs from "../../Forms/FormInputs";
import Select from "react-select";
import style from "./DeviceProfilesTable.module.scss";
import { withRouter } from "react-router-dom";
import DeleteModal from "../../ModalComponent/DeleteModal";
import AppTable from "../AppTable";
import {
  renderPagination,
  generateColButtons,
  renderTableFieldNames,
  ButtonToForm
} from "../../../common/helper/helper";

interface IPropsInterface {
  deviceProfiles: IDeviceTypesInterface;
  deviceProfilesFilter: IDeviceTypesInterface;
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
  filteredDevicesProfile(data: any): void;
  onShowForm(): void;
  onFilter(data: { orderBy: string; direction?: string }): void;
  onDeleteDeviceProfile(id: string | number): void;
  onDeleteDeviceProfiles(ids: Array<string | number>): void;
  history: { push(data: any): void };
  responseError: { details: object; message: string } | boolean | any;

  toggleErrorResponse(): void;
  responseItemMessage: null;
  statuses: Array<{ id: number; name: string }>;
  roms: Array<ISingleRomInterface>;
  playbackSupports: Array<{ id: number; name: string }>;
}

interface IStateInterface {
  selectedRows: Array<number>;
  selectAll: boolean;
  showDeleteModal: boolean;
  deviceProfileId: number | null;
  manufactureSelectedOption: Array<{ value: number; label: string }>;
  modelSelectedOption: Array<{ value: number; label: string }>;
  loading: boolean;
  romSelectedOption: Array<{ value: number; label: string }>;
  playbackSupportsSelectedOption: Array<{ value: number; label: string }>;
  valuesForFilter: any;
}

const filteredValues: any = {
  current_page: "",
  orderBy: "",
  direction: "",
  id: "",
  manufacturer: "",
  created_at: "",
  updated_at: ""
};

const initialValues: any = {};

class DeviceProfilesTable extends Component<IPropsInterface, IStateInterface> {
  private timer;

  constructor(props: IPropsInterface) {
    super(props);

    this.state = {
      selectedRows: [],
      selectAll: false,
      showDeleteModal: false,
      deviceProfileId: null,
      manufactureSelectedOption: [],
      modelSelectedOption: [],
      romSelectedOption: [],
      playbackSupportsSelectedOption: [],
      loading: false,
      valuesForFilter: {}
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IPropsInterface): void {
    const { deviceProfiles }: IPropsInterface = this.props;

    if (deviceProfiles && nextProps.deviceProfiles) {
      const {
        deviceProfiles: { current_page }
      }: IPropsInterface = this.props;
      const {
        deviceProfiles: { current_page: nextCurrentPage }
      }: IPropsInterface = nextProps;

      if (current_page && nextCurrentPage) {
        this.setState({
          selectedRows: [],
          selectAll: false
        });
      }
    }
  }

  componentWillUnmount() {
    setTimeout(this.timer);
  }

  _onRedirectToDetails = (id: number): void => {
    const { history }: IPropsInterface = this.props;
    history.push(`/device-profiles/${id}`);
  };

  _onChange: any = (page: number) => {
    const { valuesForFilter }: any = this.state;
    this._onSubmit(valuesForFilter, page);
  };

  _onCheckboxClick: (event: ChangeEvent<HTMLInputElement>) => void = ({
    target: { id }
  }: any) => {
    this.setState((state: IStateInterface) => ({
      selectedRows: !state.selectedRows.includes(id)
        ? [...state.selectedRows, id]
        : state.selectedRows.filter((item: any) => item !== id)
    }));
  };

  _onSubmit: any = async (values, page = 1): Promise<void> => {
    const { filteredDevicesProfile, filtering } = this.props;
    const {
      manufactureSelectedOption,
      modelSelectedOption,
      romSelectedOption,
      playbackSupportsSelectedOption
    } = this.state;

    this.setState({ loading: true });
    let modelSelectedOptionIds: Array<number> | null = [];
    if (modelSelectedOption && modelSelectedOption.length > 0) {
      for (let i: number = 0; i < modelSelectedOption.length; i++) {
        modelSelectedOptionIds.push(modelSelectedOption[i].value);
      }
    } else {
      modelSelectedOptionIds = null;
    }
    let romIds: Array<number> | null = [];
    if (romSelectedOption && romSelectedOption.length > 0) {
      for (let i: number = 0; i < romSelectedOption.length; i++) {
        romIds.push(romSelectedOption[i].value);
      }
    } else {
      romIds = null;
    }

    let manufactureSelectedOptionId: Array<number> | null = [];
    if (manufactureSelectedOption && manufactureSelectedOption.length > 0) {
      for (let i: number = 0; i < manufactureSelectedOption.length; i++) {
        manufactureSelectedOptionId.push(manufactureSelectedOption[i].value);
      }
    } else {
      manufactureSelectedOptionId = null;
    }
    let playbackSupportsSelectedOptionId: Array<number> | null = [];
    if (
      playbackSupportsSelectedOption &&
      playbackSupportsSelectedOption.length > 0
    ) {
      for (let i: number = 0; i < playbackSupportsSelectedOption.length; i++) {
        playbackSupportsSelectedOptionId.push(
          playbackSupportsSelectedOption[i].value
        );
      }
    } else {
      playbackSupportsSelectedOptionId = null;
    }
    filteredValues.current_page = Number.isInteger(page) ? page : 1;
    filteredValues.pageNumber = Number.isInteger(page) ? page : 1;
    filteredValues.orderBy = filtering.orderBy;
    filteredValues.direction = filtering.direction;
    filteredValues.id = values.id;
    filteredValues.model = modelSelectedOptionIds;
    filteredValues.manufacturer = manufactureSelectedOptionId;
    filteredValues.created_at = values.created_at?.format("YYYY-MM-DD");
    filteredValues.updated_at = values.updated_at?.format("YYYY-MM-DD");
    filteredValues.name = values.name;
    filteredValues.romIds = romIds;
    filteredValues.playback_support_id = playbackSupportsSelectedOptionId;

    try {
      await filteredDevicesProfile(filteredValues);
    } catch (e) {
      // console.log(e);
    } finally {
      this.timer = setTimeout(() => {
        this.setState({
          loading: false,
          valuesForFilter: {
            ...values,
            orderBy: filtering.orderBy,
            direction: filtering.direction
          }
        });
      }, 200);
    }
  };

  _renderTableFieldNames = (): Array<JSX.Element> => {
    const { onFilter, filtering }: IPropsInterface = this.props;
    const keys: Array<{ name: string; keyId: string }> = [
      { name: "Name", keyId: "name" },
      { name: "Manufacturer", keyId: "manufacturer" },
      { name: "Model", keyId: "model" },
      {
        name: "Device Type",
        keyId: "playback_support_id"
      },
      { name: "Updated", keyId: "updated_at" },
      { name: "Status", keyId: "status" }
    ];

    return renderTableFieldNames({ onFilter, filtering, keys });
  };

  _renderStatuses: any = (status: number): JSX.Element | undefined => {
    const { statuses }: IPropsInterface = this.props;
    if (statuses && statuses.length) {
      const item: any = find(statuses, (o: { id: number }) => o.id === status);
      if (item) {
        switch (status) {
          case 0:
            return <Badge color="secondary">{item.name}</Badge>;
          case 1:
            return <Badge color="success">{item.name}</Badge>;
          case 2:
            return <Badge color="danger">{item.name}</Badge>;
          default:
            return;
        }
      }
    }

    return;
  };

  _generateRoms = list =>
    list?.length &&
    list
      .map(opr => opr?.operator_rom && opr.operator_rom)
      .map((item: ISingleRomInterface) =>
        item?.id ? { value: item?.id, label: `${item?.name}` } : false
      );

  _handleRomChange: any = (
    romSelectedOption: Array<{ value: number; label: string }>
  ): void => {
    this.setState({ romSelectedOption });
  };

  _renderResultRows = (): React.ReactNode => {
    const {
      deviceProfiles,
      deviceProfilesFilter
    }: IPropsInterface = this.props;
    const { selectedRows }: IStateInterface = this.state;

    if (get(deviceProfiles, "data")) {
      const deviceForFilter = deviceProfilesFilter
        ? get(deviceProfilesFilter, "data")
        : get(deviceProfiles, "data");

      return deviceForFilter.map((item: ISingleDeviceTypesInterface) => {
        const {
          id,
          name,
          status,
          manufacturer,
          model,
          playback_support,
          updated_at
        }: ISingleDeviceTypesInterface = item;

        const updatedDate: string = moment(parseInt(updated_at) * 1000).format(
          "MM DD YYYY HH:mm"
        );

        return (
          <tr key={id}>
            <td>{name}</td>
            <td>{manufacturer}</td>
            <td>{model}</td>
            <td>{get(playback_support, "name")}</td>
            <td>
              <TimeAgo date={updatedDate} live={false} />
            </td>
            <td>{this._renderStatuses(status)}</td>

            {generateColButtons({
              id,
              selectedRows,
              onRedirectToDetails: this._onRedirectToDetails,
              toggleDeleteModal: this._toggleDeleteModal,
              onCheckboxClick: this._onCheckboxClick,
              setIdOnClick: () => this.setState({ deviceProfileId: id })
            })}
          </tr>
        );
      });
    }
  };
  _renderEmtpyCells = (numberOfCells: number): React.ReactNode => {
    return Array.apply(
      null,
      Array(numberOfCells)
    ).map((item: any, index: any) => <td key={index} />);
  };

  _onSelectAll: (event: ChangeEvent<HTMLInputElement>) => void = (): void => {
    const {
      deviceProfiles: { data }
    }: IPropsInterface = this.props;
    this.setState((state: IStateInterface) => ({
      selectAll: !state.selectAll,
      selectedRows: !state.selectAll
        ? data.map(({ id }: ISingleDeviceTypesInterface) => id)
        : []
    }));
  };

  _generateManufacture = list => {
    return list.map(item => {
      return {
        value: item.manufacturer,
        label: item.manufacturer
      };
    });
  };

  _generateDeviceType = list => {
    return list.map(item => {
      return {
        value: item.id,
        label: item.name
      };
    });
  };

  _generateModel = list => {
    return list.map(item => {
      return {
        value: item.model,
        label: item.model
      };
    });
  };

  _handleManufactureChange: any = (
    manufactureSelectedOption: Array<{ value: number; label: string }>
  ): void => {
    this.setState({ manufactureSelectedOption });
  };
  _handlePlayBack: any = (
    playbackSupportsSelectedOption: Array<{ value: number; label: string }>
  ): void => {
    this.setState({ playbackSupportsSelectedOption });
  };

  _handleModelSelectedOptionChange: any = (
    modelSelectedOption: Array<{ value: number; label: string }>
  ): void => {
    this.setState({ modelSelectedOption });
  };

  _toggleDeleteModal: any = (): void => {
    const { showDeleteModal }: IStateInterface = this.state;
    this.setState({
      showDeleteModal: !showDeleteModal
    });
  };

  _resetIds = () => {
    const { deviceProfileId, selectedRows } = this.state;
    if (deviceProfileId) {
      this.setState({
        deviceProfileId: null
      });
    } else if (selectedRows && selectedRows.length > 0) {
      this.setState({
        selectedRows: []
      });
    }
  };

  render(): JSX.Element {
    const {
      deviceProfiles: { data },
      onShowForm,
      onDeleteDeviceProfile,
      onDeleteDeviceProfiles,
      responseError,
      toggleErrorResponse,
      responseItemMessage,
      deviceProfilesFilter,
      playbackSupports
    }: IPropsInterface = this.props;
    const {
      selectAll,
      selectedRows,
      deviceProfileId,
      showDeleteModal,
      manufactureSelectedOption,
      modelSelectedOption,
      loading,
      playbackSupportsSelectedOption
    }: IStateInterface = this.state;

    const currentElementToDelete =
      data && data.find(el => el.id === deviceProfileId);
    const modalMessage: string | JSX.Element =
      deviceProfileId && selectedRows.length === 0 ? (
        <>
          <strong>{currentElementToDelete?.name}</strong>
          <span> will be deleted. Please confirm action!</span>
        </>
      ) : (
        "Selected device profiles will be deleted. Please confirm action!"
      );

    return (
      <div className={style.administratorsTableWrapper}>
        <Row>
          <div className="card-title col-12">
            <h1>
              <i className="material-icons fingerprint" />
              Device Profiles
            </h1>
            <ButtonToForm value="Create Device Profile" click={onShowForm} />
          </div>
        </Row>

        <Card>
          <CardBody>
            {responseItemMessage && <Alert>{responseItemMessage}</Alert>}
            <Formik
              initialValues={initialValues}
              onSubmit={this._onSubmit}
              render={({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset
              }: any): any => {
                return (
                  <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                    <Row className={style.filteringRow}>
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="name"
                        value={values.name}
                        id="name"
                        labelTitle="Name"
                        placeholder="Name"
                        onBlur={handleBlur}
                        name="name"
                        errors={errors.name}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />
                      <FormGroup className={style.selectInput}>
                        <Label htmlFor="manufacturer_id">Manufacturer</Label>
                        <Select
                          name="manufacturer_id"
                          id="manufacturer_id"
                          autosize={false}
                          options={this._generateManufacture(data)}
                          isMulti
                          value={manufactureSelectedOption}
                          onChange={this._handleManufactureChange}
                        />
                        <FormFeedback>
                          {errors.manufactureSelectedOption}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup className={style.selectInput}>
                        <Label htmlFor="device_type">Device Type</Label>
                        <Select
                          name="device_type"
                          id="device_type"
                          autosize={false}
                          options={this._generateDeviceType(playbackSupports)}
                          isMulti
                          value={playbackSupportsSelectedOption}
                          onChange={this._handlePlayBack}
                        />
                        <FormFeedback>
                          {errors.manufactureSelectedOption}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup className={style.selectInput}>
                        <Label htmlFor="model_id">Model</Label>
                        <Select
                          name="model_id"
                          id="model_id"
                          autosize={false}
                          options={this._generateModel(data)}
                          isMulti
                          value={modelSelectedOption}
                          onChange={this._handleModelSelectedOptionChange}
                        />
                        <FormFeedback>
                          {errors.modelSelectedOption}
                        </FormFeedback>
                      </FormGroup>
                      <FormInputs
                        type="date"
                        htmlFor="created_at"
                        id="created_at"
                        labelTitle="Created Date"
                        name="created_at"
                        placeholder="Enter Created Date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.created_at}
                        errors={errors.created_at}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        type="date"
                        htmlFor="updated_at"
                        id="updated_at"
                        labelTitle="Updated"
                        name="updated_at"
                        placeholder="Enter Updated Date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.updated_at}
                        errors={errors.updated_at}
                        rowInputClassName={style.filteringInput}
                      />
                      <div>
                        <Button
                          className={style.filteringButton}
                          type="submit"
                          color="primary"
                          size="md"
                        >
                          Apply filters
                        </Button>
                        <Button
                          className={style.filteringButton}
                          color="danger"
                          size="md"
                          type="reset"
                          onClick={() => {
                            this.setState({
                              modelSelectedOption: [],
                              manufactureSelectedOption: [],
                              romSelectedOption: [],
                              playbackSupportsSelectedOption: []
                            });
                            handleReset(values);
                            handleSubmit();
                          }}
                        >
                          Reset filters
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            />
            <br />
            <Fragment>
              {deviceProfilesFilter && deviceProfilesFilter?.data?.length ? (
                <AppTable
                  renderTableFieldNames={this._renderTableFieldNames}
                  renderResultRows={this._renderResultRows}
                  toggleDeleteModal={this._toggleDeleteModal}
                  selectAll={selectAll}
                  onSelectAll={this._onSelectAll}
                  selectedRows={selectedRows}
                  loading={loading}
                />
              ) : (
                <Alert color="danger">No results found</Alert>
              )}

              {deviceProfilesFilter &&
                renderPagination(deviceProfilesFilter, this._onChange)}
            </Fragment>
          </CardBody>
        </Card>
        <DeleteModal
          selectedRows={selectedRows}
          onToggle={this._toggleDeleteModal}
          onDelete={onDeleteDeviceProfile}
          onMultipleDelete={onDeleteDeviceProfiles}
          id={deviceProfileId}
          isOpen={showDeleteModal}
          modalTitle="Delete Device Profile"
          responseError={responseError}
          toggleResponseError={toggleErrorResponse}
          resetIds={this._resetIds}
        >
          {modalMessage}
        </DeleteModal>
      </div>
    );
  }
}

export default withRouter<any, any>(DeviceProfilesTable);
