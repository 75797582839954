import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  ICreateDeviceTypeRoms,
  ICreateDeviceTypesInterface,
  IRomsInterface,
  ISingleDeviceTypesInterface
} from "../../common/interfaces";
import { get } from "lodash";
import { Row, Container, CardBody, Card, Spinner } from "reactstrap";
import ModalComponent from "../../app-components/ModalComponent";
import {
  getDeviceTypeByID,
  updateDeviceType,
  deleteDeviceType
} from "../../common/api/device_types";
import { getPlaybackSupports } from "../../common/api/common";
import style from "./DeviceProfileDetailsPage.module.scss";
import DeviceProfilesForm from "../../app-components/Forms/DeviceProfilesForm";
import TabsDeviceProfile from "./TabsDeviceProfile";
import { getRoms } from "../../common/api/roms";
import { updateDeviceTypeRoms } from "../../common/api/operator_type_roms";
import DeleteModal from "../../app-components/ModalComponent/DeleteModal";

interface IPropsInterface {
  match: any;
  history: { push(data: any): void };
}

interface IStateInterface {
  deviceProfile: ISingleDeviceTypesInterface | null;
  statuses: Array<{ id: number; name: string }>;
  playbackSupports: Array<{ id: number; name: string }>;
  roms: IRomsInterface | null;
  showForm: boolean;
  deleted: boolean | null;
  showDeleteModal: boolean;
  errorResponse: string | null;
}

class DeviceProfileDetailsPage extends Component<
  IPropsInterface,
  IStateInterface
> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      deviceProfile: null,
      statuses: [
        {
          id: 0,
          name: "Inactive"
        },
        {
          id: 1,
          name: "Active"
        }
      ],
      playbackSupports: [],
      roms: null,
      showForm: false,
      deleted: null,
      showDeleteModal: false,
      errorResponse: null
    };
  }

  async componentDidMount(): Promise<void> {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    try {
      const playbackSupports = await getPlaybackSupports();
      if (playbackSupports) {
        this.setState({
          playbackSupports: playbackSupports.data
        });
      }

      const operatorRoms = await getRoms({});

      if (operatorRoms) {
        this.setState({
          roms: operatorRoms
        });
      }

      const deviceTypeByID = await getDeviceTypeByID(ID);

      if (get(deviceTypeByID, "data.resource")) {
        this.setState({
          deviceProfile: get(deviceTypeByID, "data.resource")
        });
      } else if (get(deviceTypeByID, "data.error")) {
        const { message, status } = get(deviceTypeByID, "data");
        setTimeout(() => {
          this.props.history.push({
            pathname: "/error",
            state: { message, status }
          });
        }, 2000);
      }
    } catch (e) {
      // console.log(e);
    }
  }

  _getDeviceType: any = async () => {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    try {
      const result: {
        data: { resource: string };
      } = await getDeviceTypeByID(ID);

      if (result && get(result, "data.resource")) {
        this.setState({
          deviceProfile: get(result, "data.resource")
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _onShowForm: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = (): void => {
    this.setState(
      (state: IStateInterface) => ({
        showForm: !state.showForm
      }),
      (): void => {
        const { showForm }: IStateInterface = this.state;
        if (showForm) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  _onEditDeviceProfile: any = async (
    data: ICreateDeviceTypesInterface
  ): Promise<undefined | object | unknown> => {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    try {
      const result: { status: number } = await updateDeviceType(ID, data);

      if (result && result.status === 200) {
        this._getDeviceType();
        this.setState({
          showForm: false
        });
      }
      return result;
    } catch (err) {
      return err;
    }
  };

  _onEditDeviceTypeRom: any = async (
    data: ICreateDeviceTypeRoms
  ): Promise<undefined | object | unknown> => {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;
    try {
      const result: { status: number } = await updateDeviceTypeRoms(ID, data);

      if (result && result.status === 200) {
        return result;
      }
    } catch (err) {
      return err;
    }
  };

  _onDeleteDeviceType: any = async (id: number): Promise<void> => {
    const result: { status: number; request: any } = await deleteDeviceType(id);
    if (result && result.status === 204) {
      this.setState({
        deleted: true
      });
      setTimeout(() => {
        this.props.history.push({
          pathname: "/device-profiles",
          state: { itemDeleted: true }
        });
      }, 2000);
    } else {
      this.setState({
        deleted: false,
        errorResponse: JSON.parse(result.request.response).error.message
      });
    }
  };

  _toggleDeleteModal: any = (): void => {
    const { showDeleteModal }: IStateInterface = this.state;
    this.setState({
      showDeleteModal: !showDeleteModal
    });
  };

  render(): JSX.Element {
    const {
      deviceProfile,
      showForm,
      statuses,
      playbackSupports,
      roms,
      showDeleteModal,
      errorResponse
    }: IStateInterface = this.state;

    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    let modalMessage: string = ID
      ? "This device profile will be deleted. Please confirm action!"
      : "Selected device profiles will be deleted. Please confirm action!";

    if (!deviceProfile) {
      return (
        <div className="d-flex justify-content-center col-12 mt-5 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <main className={"main admin-details-page-wrapper in"}>
        <Container fluid>
          <Row>
            <div className="card-title col-12">
              <h1>
                <i className="material-icons fingerprint" />
                Device Profile » {deviceProfile.name || "no name"}
              </h1>
            </div>
          </Row>

          <Card>
            <CardBody className={style.contentWrapper}>
              <TabsDeviceProfile
                loading={false}
                details={deviceProfile}
                onShowForm={this._onShowForm}
                toggleDeleteModal={this._toggleDeleteModal}
              />
            </CardBody>
          </Card>

          <ModalComponent
            modalTitle="Edit Device Profile"
            isOpen={showForm}
            toggle={this._onShowForm}
          >
            <DeviceProfilesForm
              data={deviceProfile}
              edit={true}
              statuses={statuses}
              playbackSupports={playbackSupports}
              roms={roms}
              title="Edit Device Profile"
              onShowForm={this._onShowForm}
              onEditDeviceProfile={this._onEditDeviceProfile}
              onEditDeviceTypeRom={this._onEditDeviceTypeRom}
            />
          </ModalComponent>
          <DeleteModal
            onToggle={this._toggleDeleteModal}
            onDelete={this._onDeleteDeviceType}
            id={ID}
            isOpen={showDeleteModal}
            modalTitle="Delete Device Profile"
            responseError={errorResponse}
          >
            {modalMessage}
          </DeleteModal>
        </Container>
      </main>
    );
  }
}

export default withRouter<any, any>(DeviceProfileDetailsPage);
