import React, { Component } from "react";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Container, Row, Spinner } from "reactstrap";
import { IUserProfile } from "common/interfaces";
import { getStatuses } from "common/api/common";
import {
  parseRolesIdToRolesName,
  renderStatuses,
  timeAgo
} from "common/helper/helper";
import { getUserProfile } from "common/api/authorization";
import Details from "app-components/Details";

interface IPropsInterface {
  match: any;
}

interface IStateInterface {
  userProfile: IUserProfile | null;
  showForm: boolean;
  statuses: Array<{ id: number; name: string }>;
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
}

class ProfilePage extends Component<IPropsInterface, IStateInterface> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      userProfile: null,
      showForm: false,
      statuses: [],
      filtering: {
        orderBy: null,
        direction: null
      }
    };
  }

  async componentDidMount(): Promise<void> {
    try {
      const statuses: {
        data: { resource: { data: string } };
      } = await getStatuses("Model");

      if (get(statuses, "data.resource.data")) {
        this.setState({ statuses: get(statuses, "data.resource.data") });
      }

      const {
        data: { resource }
      }: any = await getUserProfile();

      this.setState({
        userProfile: resource
      });
    } catch (e) {
      // console.log(e);
    }
  }

  _renderData: any = (userProfile: IUserProfile) => {
    return [
      { name: "Status", value: renderStatuses(userProfile.status) },
      { name: "External Id", value: userProfile.external_id },
      { name: "Email", value: userProfile.email },
      {
        name: "Role Name",
        value: parseRolesIdToRolesName(userProfile?.role_id)
      },
      { name: "Login At", value: timeAgo(userProfile.login_at) },
      { name: "Created At", value: timeAgo(userProfile.created_at) },
      {
        name: "Operator",
        value: userProfile?.operator?.name || "No operator"
      }
    ];
  };

  render(): JSX.Element {
    const { userProfile }: IStateInterface = this.state;

    if (!userProfile) {
      return (
        <div className="d-flex justify-content-center col-12 mt-5 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <main className={"main admin-details-page-wrapper in"}>
        <Container fluid>
          <Row>
            <div className="card-title col-12">
              <h1>
                <i className="material-icons ">face</i>
                Profile » {userProfile.name}
              </h1>
            </div>
          </Row>
          <Card>
            <CardBody>
              <Details data={this._renderData(userProfile)} />
            </CardBody>
          </Card>
        </Container>
      </main>
    );
  }
}

export default withRouter<any, any>(ProfilePage);
