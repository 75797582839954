import React from "react";
import { Table, Input, Button, Space, Popconfirm, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import style from "./Tables.module.scss";
import { ISingleInstalledApplicationInterface } from "common/interfaces";

type iProps = {
  data: Array<ISingleInstalledApplicationInterface>;
  openApp: Function;
  updateApp: Function;
  onClearData: Function;
  onDelete?: Function | null | undefined;
  loading: boolean;
  type?: "system" | "apps";
};

let searchInput: any;

const getColumnSearchProps = dataIndex => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(confirm)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false });
          }}
        >
          Filter
        </Button>
      </Space>
    </div>
  ),
  filterIcon: filtered => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : "",
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
  render: text => text
  // this.state.searchedColumn === dataIndex ? (
  //   <Highlighter
  //     highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
  //     searchWords={[searchText]}
  //     autoEscape
  //     textToHighlight={text ? text.toString() : ''}
  //   />
  // ) : (
  //   text
  // ),
});

const columns = [
  {
    title: <p className={style.TextTooLong}>Application Name</p>,
    dataIndex: "application_name",
    key: 0,
    align: "center",
    ...getColumnSearchProps("application_name"),
    render: (text: string) => {
      return <p className={style.TextTooLong}>{text}</p>;
    }
  },
  {
    title: "Package Name",
    dataIndex: "package_name",
    key: 1,
    align: "center",
    ...getColumnSearchProps("package_name")
  },
  {
    title: "Version Code",
    dataIndex: "version_code",
    key: 2,
    align: "center",
    ...getColumnSearchProps("version_code")
  },
  {
    title: <p>Actions</p>,
    dataIndex: "actions",
    align: "center",
    render: (
      _,
      record: {
        package_name: string;
        openApp: Function;
        updateApp: Function;
        onClearData: Function;
        onDelete: Function | null | undefined;
        type: string;
      }
    ) => (
      <div className={style.ButtonsActions}>
        {record.onDelete && (
          <Tooltip
            placement="top"
            title={`Uninstall ${record.type === "system" ? "updates" : ""}`}
          >
            <Popconfirm
              title={`Sure to ${
                record.type === "system" ? "uninstall updates" : "delete"
              }`}
              onConfirm={() =>
                record.onDelete && record.onDelete(record.package_name)
              }
            >
              <Button type="primary" size="small" danger>
                <i className="material-icons">delete</i>
              </Button>
            </Popconfirm>
          </Tooltip>
        )}
        <Tooltip placement="top" title="Update">
          <Button
            onClick={() => record.updateApp(record.package_name)}
            type="primary"
            size="small"
          >
            <i className="material-icons">update</i>
          </Button>
        </Tooltip>
        <Tooltip placement="top" title="Launch">
          <Button
            type="primary"
            size="small"
            className={style.ButtonPurple}
            onClick={() => record.openApp(record.package_name)}
          >
            <i className="material-icons">launch</i>
          </Button>
        </Tooltip>
        <Tooltip placement="top" title="Clear Data">
          <Button
            type="primary"
            size="small"
            className={style.ButtonPurple}
            onClick={() => record.onClearData(record.package_name)}
          >
            <i className="material-icons">clear</i>
          </Button>
        </Tooltip>
      </div>
    )
  }
];

const handleData = (
  remoteAssistanceActionsHistory: Array<ISingleInstalledApplicationInterface>,
  { openApp, updateApp, onDelete, onClearData, type }
) => {
  return (
    remoteAssistanceActionsHistory &&
    remoteAssistanceActionsHistory.map(
      ({ id, application_name, package_name, version_code }, i: number) => {
        const packageName = id || package_name;
        return {
          key: i,
          application_name: id || application_name,
          package_name: packageName,
          version_code,
          openApp: (packageName: string) => openApp(packageName),
          updateApp: (url: string) => updateApp(url),
          onClearData: (packageName: string) => onClearData(packageName),
          onDelete: onDelete
            ? (packageName: string) => onDelete(packageName)
            : null,
          type
        };
      }
    )
  );
};

const handleSearch = confirm => {
  confirm();
};

const handleReset = clearFilters => {
  clearFilters();
};

const TableApps = ({
  data,
  openApp,
  updateApp,
  onDelete,
  loading,
  onClearData,
  type
}: iProps) => {
  return (
    <>
      <Table
        //@ts-ignore
        columns={columns}
        //@ts-ignore
        dataSource={handleData(data, {
          openApp,
          updateApp,
          onDelete,
          onClearData,
          type
        })}
        pagination={{ position: ["bottomCenter"] }}
        loading={loading}
        className={style.Table}
      />
    </>
  );
};

export default TableApps;
