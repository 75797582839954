import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Spinner,
  Button,
  CardHeader,
  Alert,
  Table
} from "reactstrap";
import style from "../DeviceProfileDetailsPage.module.scss";
import {
  createDeviceTypesApps,
  getDeviceTypesApps,
  deleteDeviceTypesApp,
  updateDeviceTypesApp
} from "../../../common/api/device_types";
import ModalComponent from "../../../app-components/ModalComponent";
import DeleteModal from "../../../app-components/ModalComponent/DeleteModal";
import AddAppForm from "./AddAppForm";
import moment from "moment";
import ImgNotFound from "../images/ic_launcher.png";
import { CORE_API_DOMAIN_UPLOAD } from "../../../API_routes";
import classNames from "classnames";
import { Tooltip } from "antd";

interface IPropsInterface {
  match: any;
  history: { push(data: any): void };
}

const DeviceProfileApps = ({ history, match }: IPropsInterface) => {
  let timeout: any = null;

  const [configurations, setConfigurations] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<any>(null);
  const [responseSuccess, setResponseSuccess] = useState<string>("");
  const [idAppToDelete, setIdAppToDelete] = useState<string>("");
  const [appDeleted, setAppDeleted] = useState<boolean>(false);
  const [elementToEdit, setElementToEdit] = useState<any>(null);
  useEffect(() => {
    const {
      params: { ID }
    } = match;
    const fetchData = async () => {
      const result = await getDeviceTypesApps(ID);
      if (result?.data?.data) {
        const apps = result?.data?.data?.apps || [];
        setConfigurations(apps);

        setAppDeleted(false);
      }
    };
    fetchData();
  }, [match, responseSuccess, appDeleted]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [timeout]);

  const _onEditDeviceProfileConfig: any = async (
    data
  ): Promise<undefined | object> => {
    const {
      params: { ID }
    } = match;

    try {
      setLoading(true);
      let result: {
        data: any;
        status: number;
        error: any;
        message: string;
      };

      if (!elementToEdit) {
        result = await createDeviceTypesApps(ID, {
          operator_device_type_id: ID,
          ...data
        });
      } else {
        result = await updateDeviceTypesApp(elementToEdit?.id, {
          operator_device_type_id: ID,
          ...data
        });
      }

      if (result && result.status === 201) {
        setResponseSuccess("App Successfully Created!");
        setShowForm(false);
        timeout = setTimeout(() => {
          setResponseSuccess("");
        }, 4000);
      } else if (result && result.status === 200) {
        setResponseSuccess("App Updated Successfully!");
        setShowForm(false);
        timeout = setTimeout(() => {
          setResponseSuccess("");
        }, 4000);
      } else {
        setResponseError(
          result?.data?.error ||
            result?.data?.error?.message ||
            result?.message ||
            result?.error?.message ||
            "Error"
        );
        timeout = setTimeout(() => {
          setResponseError("");
        }, 10000);
      }

      timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      return err;
    }
  };

  const _renderAlert: Function = () => {
    if (responseSuccess || responseError) {
      return (
        <Alert
          in={responseSuccess || responseError}
          color={responseError ? "danger" : "success"}
          tag="h5"
          className="mt-3"
        >
          {responseSuccess || responseError.message || ""}
        </Alert>
      );
    }
  };

  const _loading = () => {
    return (
      <div className="d-flex justify-content-center m-5 in">
        <Spinner color="primary" />
      </div>
    );
  };

  const _renderButton: Function = () => {
    return (
      <Button
        type="button"
        size="lg mr-2"
        color="primary"
        className={style.addButton}
        onClick={() => {
          setElementToEdit(null);
          setShowForm(true);
        }}
      >
        <i className="material-icons">add_circle_outline</i>Add New App
      </Button>
    );
  };

  const _onDeleteDeviceType: any = async (id: number): Promise<void> => {
    const result = await deleteDeviceTypesApp(idAppToDelete);

    if (result && result.status === 204) {
      setAppDeleted(true);
      setTimeout(() => {
        setShowDeleteModal(!showDeleteModal);
      }, 1000);
    } else {
      setResponseSuccess("Error!");
    }
  };

  const addDefaultSrc = ev => (ev.target.src = ImgNotFound);

  const _renderTableData = () => {
    return (
      configurations?.length &&
      configurations?.map((app, index) => {
        const {
          logo,
          end_date,
          launch_date,
          version_name,
          version_code,
          name,
          id,
          prod,
          qa,
          rc,
          alpha,
          beta,
          dev
        } = app; //destructuring
        const updatedDate = date => moment(date).format("DD/MM/YYYY");
        const launchDate = updatedDate(launch_date);
        const endDate = end_date ? updatedDate(end_date) : "";
        const now = moment();

        const statusNow =
          end_date && moment(end_date) < now ? style.red : style.green;

        const classesStatus = classNames(style.status, statusNow);
        return (
          <tr key={id + index}>
            <td>
              <div className={classesStatus}></div>
            </td>
            <td>
              <img
                src={`${CORE_API_DOMAIN_UPLOAD}/${logo}`.replace("///", "/")}
                onError={addDefaultSrc}
                alt="Apk path"
                className={style.logoImg}
              />
            </td>
            <td>{name}</td>
            <td>{version_code}</td>
            <td>{version_name}</td>
            <td>{launchDate}</td>
            <td>{endDate}</td>
            <td>
              {!!prod && (
                <span className="badge-success text-white badge-pill mr-1">
                  R
                </span>
              )}
              {!!rc && (
                <span className="btn-primary text-white badge-pill mr-1">
                  RC
                </span>
              )}
              {!!beta && (
                <span className="btn-primary text-white badge-pill mr-1">
                  B
                </span>
              )}
              {!!alpha && (
                <span className="btn-primary text-white badge-pill mr-1">
                  A
                </span>
              )}
              {!!qa && (
                <span className="bg-purple text-white badge-pill mr-1">QA</span>
              )}
              {!!dev && (
                <span className="bg-purple text-white badge-pill ">DEV</span>
              )}
            </td>
            <td>
              <div className={style.buttonsWrapperActions}>
                <Tooltip placement="top" title="Edit">
                  <Button
                    className="material-icons mode_edit"
                    color="primary"
                    size="sm"
                    onClick={(e: any): any => {
                      setElementToEdit(app);
                      setShowForm(true);
                    }}
                  />
                </Tooltip>
                {localStorage.getItem("acr") !== "OPR_VIEWER" && (
                  <Tooltip placement="top" title="Delete">
                    <Button
                      className="material-icons delete_forever"
                      color="danger"
                      size="sm"
                      onClick={(e: any): any => {
                        setIdAppToDelete(id);
                        setShowDeleteModal(true);
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  if (loading) {
    return _loading();
  }

  return (
    <div>
      <ModalComponent
        modalTitle="App details"
        isOpen={showForm}
        toggle={() => setShowForm(!showForm)}
      >
        <AddAppForm
          responseError={responseError}
          onShowForm={() => setShowForm(!showForm)}
          data={elementToEdit}
          onEditDeviceProfileConfig={_onEditDeviceProfileConfig}
          imgLogo={logo =>
            `${CORE_API_DOMAIN_UPLOAD}/${logo}`.replace("///", "/")
          }
        />
      </ModalComponent>
      <CardHeader className={style.floatButtons}>
        {localStorage.getItem("acr") !== "OPR_VIEWER" && _renderButton()}
      </CardHeader>
      {_renderAlert()}
      {configurations?.length > 0 && (
        <Row className={style.WrapTable}>
          <Col md="12">
            <Table className={style.Table} responsive>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Icon</th>
                  <th>Name</th>
                  <th>Version Code</th>
                  <th>Version Name</th>
                  <th>Launch Date</th>
                  <th>End Date</th>
                  <th>Availability</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{_renderTableData()}</tbody>
            </Table>
          </Col>
        </Row>
      )}
      <DeleteModal
        onToggle={() => setShowDeleteModal(!showDeleteModal)}
        onDelete={_onDeleteDeviceType}
        id={idAppToDelete}
        isOpen={showDeleteModal}
        modalTitle="Delete App"
        responseError={responseSuccess}
      >
        This App will be deleted. Please confirm action!
      </DeleteModal>
    </div>
  );
};

export default withRouter<any, any>(DeviceProfileApps);
