import React, { Component } from "react";
import {
  ICreateDevicesInterface,
  IDevicesInterface,
  IFilteredDevices,
  ISingleDeviceInterface,
  ISingleRomInterface
} from "../../common/interfaces";
import { getStatuses } from "../../common/api/common";
import { get } from "lodash";
import {
  createDevice,
  deleteDevice,
  deleteDevices,
  getDevices
} from "../../common/api/devices";
import { Container, Spinner, ModalFooter, Button } from "reactstrap";
import ModalComponent from "../../app-components/ModalComponent";
import { withRouter, Link } from "react-router-dom";
import { getRoms } from "../../common/api/roms";
import { getDeviceTypes } from "../../common/api/device_types";
import DevicesForm from "../../app-components/Forms/DevicesForm";
import DevicesTable from "../../app-components/Tables/DevicesTable";
import { Alert } from "reactstrap";
import { showAndHideAlertItem } from "../../common/helper/helper";

interface IPropsInterface {
  match: any;
  history: any;
}

interface IStateInterface {
  devices: IDevicesInterface | null;
  showCreateDeviceForm: boolean;
  statuses: Array<{ id: number; name: string }>;
  deviceTypes: Array<{ id: number; model: string; manufacturer: string }>;
  roms: Array<ISingleRomInterface> | null;
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
  responseError: { details: object; message: string } | boolean | any;
  responseItemMessage: null | string;
}

class DevicesListPage extends Component<IPropsInterface, IStateInterface> {
  private readonly showAndHideAlertItem;
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      devices: null,
      showCreateDeviceForm: false,
      statuses: [
        {
          id: 0,
          name: "Inactive"
        },
        {
          id: 1,
          name: "Active"
        }
      ],
      deviceTypes: [],
      filtering: {
        orderBy: null,
        direction: null
      },
      roms: null,
      responseError: null,
      responseItemMessage: null
    };
    this.showAndHideAlertItem = showAndHideAlertItem.bind(this);
  }

  async UNSAFE_componentWillReceiveProps(
    nextProps: IPropsInterface
  ): Promise<void> {
    const {
      filtering: { orderBy, direction }
    }: IStateInterface = this.state;
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;
    const {
      match: {
        params: { pageNumber: nextPageNumber }
      }
    }: IPropsInterface = nextProps;

    if (nextPageNumber && pageNumber !== nextPageNumber) {
      try {
        await this._getDevices(nextPageNumber, orderBy, direction);
      } catch (e) {
        // console.log(e);
      }
    }
  }

  async componentDidMount(): Promise<void> {
    const {
      match: {
        params: { pageNumber }
      },
      history: { location }
    }: IPropsInterface = this.props;
    try {
      const statuses = await getStatuses();

      if (get(statuses, "data.resource.data")) {
        this.setState({ statuses: get(statuses, "data.resource.data") });
      }
      if (get(location, "state.itemDeleted")) {
        this.showAndHideAlertItem(this.props);
      }

      const deviceTypes: {
        data: { resource: { data: string } };
      } = await getDeviceTypes(1, null, null, 10000);

      if (get(deviceTypes, "data.resource.data")) {
        this.setState({ deviceTypes: get(deviceTypes, "data.resource.data") });
      }

      const roms = await getRoms({ pageNumber: 1, limit: 10000 });
      if (get(roms, "data")) {
        this.setState({
          roms: get(roms, "data") || []
        });
      }
      const result = await getDevices({ current_page: pageNumber });
      if (result) {
        this.setState({ devices: result });
      }
    } catch (e) {
      // console.log(e);
    }
  }

  _getDevices = async (
    current_page: number | string,
    orderBy?: string | null,
    direction?: string | null
  ): Promise<void> => {
    try {
      const result = await getDevices({ current_page, orderBy, direction });
      if (result) {
        this.setState({ devices: result });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _getFilteredDevices = async (data: IFilteredDevices): Promise<void> => {
    try {
      const result = await getDevices({ ...data });

      if (result) {
        this.setState({ devices: result });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _onFilter: Function = (data: {
    orderBy: string;
    direction: string;
  }): void => {
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;

    if (data) {
      const {
        orderBy,
        direction
      }: { orderBy: string; direction: string } = data;
      if (orderBy) {
        this.setState(
          {
            filtering: {
              orderBy,
              direction: direction || null
            }
          },
          async () => {
            const {
              filtering: { orderBy, direction }
            }: IStateInterface = this.state;
            try {
              await this._getDevices(pageNumber, orderBy, direction);
            } catch (e) {
              // console.log(e);
            }
          }
        );
      }
    }
  };

  _createNewDevice: any = async (
    data: ICreateDevicesInterface
  ): Promise<undefined | object | unknown> => {
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;
    try {
      const result: { status: number } = await createDevice(data);

      if (result && result.status === 201) {
        await this._getDevices(pageNumber);
        this.setState({
          showCreateDeviceForm: false
        });
      }

      return result;
    } catch (er) {
      return er;
    }
  };

  _onDeleteDevice = async (id: number | null): Promise<boolean> => {
    const { devices } = this.state;
    const result: { message: string; status: number } = await deleteDevice(id);
    if (result && result.status === 204) {
      if (devices) {
        this.setState({
          devices: {
            ...devices,
            data: [
              ...devices.data.filter(
                (device: ISingleDeviceInterface) => device.id !== id
              )
            ]
          }
        });
      }
      this.showAndHideAlertItem(this.props);
      return true;
    } else {
      this.setState({
        responseError:
          get(result, "data.error.message") ||
          get(result, "response.data.message") ||
          get(result, "data.message") ||
          result?.message
      });

      return false;
    }
  };

  _onDeleteDevices = async (ids: Array<number> | null): Promise<boolean> => {
    const { devices } = this.state;
    const result: { message: any; status: number } = await deleteDevices(ids);
    if (result && result.status === 204) {
      if (devices) {
        this.setState({
          devices: {
            ...devices,
            data: [
              ...devices.data.filter(
                (device: ISingleDeviceInterface) => !ids?.includes(device.id)
              )
            ]
          }
        });
      }
      this.showAndHideAlertItem(this.props);

      return true;
    } else {
      this.setState({
        responseError:
          get(result, "data.error.message") ||
          get(result, "response.data.message") ||
          get(result, "data.message") ||
          result?.message
      });

      return false;
    }
  };

  _onShowForm: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = (): void => {
    this.setState(
      (state: IStateInterface) => ({
        showCreateDeviceForm: !state.showCreateDeviceForm
      }),
      (): void => {
        const { showCreateDeviceForm }: IStateInterface = this.state;
        if (showCreateDeviceForm) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  _toggleErrorResponse = (): void => {
    this.setState({
      responseError: null
    });
  };

  render(): JSX.Element {
    const {
      showCreateDeviceForm,
      devices,
      filtering,
      statuses,
      deviceTypes,
      roms,
      responseError,
      responseItemMessage
    }: IStateInterface = this.state;

    if (!devices) {
      return (
        <div className="d-flex justify-content-center col-12 mt-5 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <main className="users-table-wrapper in main">
        <Container fluid>
          <DevicesTable
            devices={devices}
            filtering={filtering}
            statuses={statuses}
            deviceTypes={deviceTypes}
            roms={roms}
            onShowForm={this._onShowForm}
            onDeleteDevice={this._onDeleteDevice}
            onDeleteDevices={this._onDeleteDevices}
            onFilter={this._onFilter}
            filteredDevices={this._getFilteredDevices}
            responseError={responseError}
            toggleErrorResponse={this._toggleErrorResponse}
            responseItemMessage={responseItemMessage}
          />
          <ModalComponent
            modalTitle="Add Device"
            toggle={this._onShowForm}
            isOpen={showCreateDeviceForm}
          >
            {deviceTypes && deviceTypes.length ? (
              <DevicesForm
                title="Add Device"
                statuses={statuses}
                deviceTypes={deviceTypes}
                onShowForm={this._onShowForm}
                createNewDevice={this._createNewDevice}
                devices={this._getDevices}
              />
            ) : (
              <>
                <Alert color="warning">
                  You need to create a Device Profile before adding devices.
                </Alert>
                <ModalFooter>
                  <Button
                    type="button"
                    color="danger"
                    size="md"
                    onClick={this._onShowForm}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    size="md"
                    tag={Link}
                    to="device-profiles"
                    //disabled={!edit && !isValid}
                  >
                    Go to Device Profiles
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalComponent>
        </Container>
      </main>
    );
  }
}

export default withRouter<any, any>(DevicesListPage);
