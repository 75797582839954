import React, { useState } from "react";
import ReactJson from "react-json-view";
import { ISingleRemoteAssistanceInterface } from "common/interfaces";
import style from "./TabsActionsRemoteAntd.module.scss";
import {
  RemoteAssistanceActionHistoryTableAntd,
  TableApps
} from "../TablesRemoteActions";
import { Tabs, Button, Tooltip, Spin, Row, Col, Card, Typography } from "antd";
import { useEffect } from "react";
import GaugeChart from "react-gauge-chart";

interface IPropsInterface {
  deviceStatus: any | null;
  getStatus: any;
  remoteAssistance: ISingleRemoteAssistanceInterface;
  remoteAssistanceClosed: boolean;
  loading: boolean;
  getRemoteAssistanceActionHistory(pageNumber): void;
  installApp(): void;
  openApp: any;
  updateApp: any;
  onDelete: any;
  onClearData: any;
  showUninstallApkModal: boolean;
  speedTest: any;
  speedTestIsEnable: any;
  reloadHistory: boolean;
  emitEventSocket: (values: any) => void;
}

function percentage(partialValue: string, max: number) {
  if (partialValue && max) {
    const calc =
      ((100 * parseInt(partialValue.replaceAll(",", "."))) / max) * 0.01;
    return calc >= 1 ? 1 : calc;
  } else {
    return 0;
  }
}

const _renderDeviceStatus = (deviceStatus: any): JSX.Element => {
  return (
    <ReactJson
      style={{ padding: "1rem" }}
      theme="monokai"
      collapsed={1}
      src={
        deviceStatus && typeof deviceStatus === "object"
          ? deviceStatus
          : { error: "Could not retrieve status. Please Refresh" }
      }
    />
  );
};

const TabsActionsRemoteAntd = ({
  deviceStatus,
  remoteAssistance,
  remoteAssistanceClosed,
  openApp,
  updateApp,
  onDelete,
  onClearData,
  loading,
  speedTest,
  speedTestIsEnable,
  reloadHistory,
  emitEventSocket
}: IPropsInterface) => {
  const [loadingSpeedTest, setLoadingSpeedTest] = useState(false);
  const [percent, setPercent] = useState(0);

  const appsInstalled =
    deviceStatus?.DeviceDetails?.installed_applications ||
    deviceStatus?.apps?.apps;
  const appsSystemInstalled =
    deviceStatus?.DeviceDetails?.system_applications ||
    deviceStatus?.apps?.system_apps_list;

  const onGetStatus = () => {
    emitEventSocket({
      remote_action_type: 16,
      remote_action_id: "GET_STATUS"
    });
  };

  useEffect(() => {
    let data = percent;
    if (!percent && speedTest) {
      const interval = setInterval(function() {
        if (data >= 1) {
          clearInterval(interval);
        } else {
          data += 0.2;
          setPercent(data);
        }
      }, 500);
    }
  }, [speedTest, percent]);

  useEffect(() => {
    if (loadingSpeedTest) {
      emitEventSocket({
        remote_action_type: 14,
        remote_action_id: "TEST_SPEED"
      });
    }
  }, [loadingSpeedTest, emitEventSocket]);

  useEffect(() => {
    if (speedTest) {
      setLoadingSpeedTest(false);
    }
  }, [speedTest]);

  const speedTestConfig = () => {
    if (!speedTestIsEnable) {
      return (
        <Col span={12} className={style.colName}>
          <strong>
            {speedTest?.error_message ||
              "Speed test is disabled or configurations are incorrect; Check Operator Configurations."}
          </strong>
        </Col>
      );
    } else if (loadingSpeedTest) {
      return (
        <div className={style.Spin}>
          <Spin />
        </div>
      );
    } else {
      return (
        <>
          <Button
            className={style.getStatusButton}
            onClick={() => {
              setLoadingSpeedTest(true);
            }}
            hidden={speedTest?.code !== 106}
          >
            <Spin className="mt-1" />
          </Button>
          <Button
            className={style.getStatusButton}
            onClick={() => {
              setLoadingSpeedTest(true);
            }}
            hidden={speedTest?.code === 106}
          >
            {speedTest?.code === 200 ? "Re-run test" : "Run test"}
          </Button>
          {speedTest?.code <= 200 ? (
            <>
              <Col span={12} className={style.colName}>
                <GaugeChart
                  id="gauge-chart1"
                  nrOfLevels={20}
                  arcWidth={0.2}
                  textColor="#000000d9"
                  colors={["#f93751", "#5e5ce6", "#0fa0db"]}
                  formatTextValue={() =>
                    (speedTest?.extra_content?.download
                      ? speedTest?.extra_content?.download
                      : 0) + " mbps"
                  }
                  percent={percentage(speedTest?.extra_content?.download, 500)}
                />
                <Card title="Jitter" style={{ textAlign: "center" }}>
                  {speedTest?.extra_content?.jitter} ms
                </Card>
              </Col>
              <Col span={12} className={style.colName}>
                <GaugeChart
                  id="gauge-chart2"
                  nrOfLevels={10}
                  arcWidth={0.2}
                  textColor="#000000d9"
                  colors={["#f93751", "#5e5ce6", "#0fa0db"]}
                  formatTextValue={() =>
                    (speedTest?.extra_content?.upload
                      ? speedTest?.extra_content?.upload
                      : 0) + " mbps"
                  }
                  percent={percentage(speedTest?.extra_content?.upload, 100)}
                />
                <Card title="Ping" style={{ textAlign: "center" }}>
                  {speedTest?.extra_content?.ping} ms
                </Card>
              </Col>
              <Col
                span={24}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Typography.Title level={5}>
                  <span style={{ margin: "1rem" }}>
                    {speedTest?.extra_content?.ip_info}
                  </span>
                </Typography.Title>
              </Col>
            </>
          ) : (
            <Col span={18} className={style.colName}>
              <strong>
                {speedTest?.code && speedTest?.code !== 200
                  ? speedTest?.error_message || "Error try again"
                  : "Press Run Test to start!"}
              </strong>
            </Col>
          )}
        </>
      );
    }
  };

  return (
    <div className="card-container">
      <Tabs defaultActiveKey={remoteAssistanceClosed ? "3" : "1"}>
        <Tabs.TabPane
          tab="Installed apps"
          key="1"
          className={style.TabContent}
          disabled={remoteAssistanceClosed}
        >
          <div className={style.InfoButtons}>
            <Tooltip placement="top" title="Install App">
              <Button
                onClick={() => updateApp("Install App")}
                type="primary"
                size="small"
              >
                <i className="material-icons">add_circle_outline</i>
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Refresh Installed Apps">
              <Button
                className={style.getStatusButtonApps}
                onClick={onGetStatus}
                type="primary"
                size="small"
              >
                <i className="material-icons">update</i>
              </Button>
            </Tooltip>
          </div>
          <TableApps
            type="apps"
            data={appsInstalled}
            openApp={openApp}
            updateApp={updateApp}
            onDelete={onDelete}
            onClearData={onClearData}
            loading={reloadHistory}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="System Apps"
          key="2"
          className={style.TabContent}
          disabled={remoteAssistanceClosed}
        >
          <div className={style.InfoButtons}>
            <Tooltip placement="top" title="Install App">
              <Button
                onClick={() => updateApp("Install")}
                type="primary"
                size="small"
              >
                <i className="material-icons">add_circle_outline</i>
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Refresh System Apps ">
              <Button
                className={style.getStatusButtonApps}
                onClick={onGetStatus}
                type="primary"
                size="small"
              >
                <i className="material-icons">update</i>
              </Button>
            </Tooltip>
          </div>
          <TableApps
            type="system"
            data={appsSystemInstalled}
            openApp={openApp}
            updateApp={updateApp}
            onClearData={onClearData}
            loading={reloadHistory}
            onDelete={onDelete}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Actions History"
          key="3"
          className={style.TabContent}
        >
          <RemoteAssistanceActionHistoryTableAntd
            ID={remoteAssistance.id}
            loading={reloadHistory}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab="Device Status"
          key="4"
          className={style.TabContent}
          disabled={remoteAssistanceClosed}
        >
          <Tooltip placement="top" title="Update Status">
            <Button
              className={style.getStatusButton}
              onClick={onGetStatus}
              icon={<i className="material-icons">update</i>}
            />
          </Tooltip>
          {_renderDeviceStatus(deviceStatus)}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Test Speed" key="5" className={style.TabContent}>
          <Row style={{ height: "100%" }}>{speedTestConfig()}</Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Device info" key="6" className={style.TabContent}>
          {_renderDeviceStatus({
            name: remoteAssistance?.operator_device?.name,
            mac_address: remoteAssistance?.mac_address,
            provision_id: remoteAssistance?.provision_id,
            uid: remoteAssistance?.operator_device?.uid,
            serial_number: remoteAssistance?.serial_number,
            service_desk_id: remoteAssistance?.service_desk_id,
            sequential_id: remoteAssistance?.sequential_id,
            release_cycle: remoteAssistance?.operator_device?.release_cycle,
            lock: remoteAssistance?.operator_device?.lock,
            consent_status: remoteAssistance?.operator_device?.consent_status,
            versions: {
              rom_version: remoteAssistance?.operator_device?.rom_version,
              device_control_version:
                remoteAssistance?.operator_device?.device_control_version
            }
          })}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default TabsActionsRemoteAntd;
