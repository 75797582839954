import React, { useState, useContext, createContext, useEffect } from "react";
import { getRemoteAssistanceByID } from "common/api/remote-assistances";
import { ISingleRemoteAssistanceInterface } from "common/interfaces";
import { getDeviceTypesConfig } from "common/api/device_types";

type StatusBox = {
  screenShot: string;
  deviceStatus: any;
  on: boolean;
  userRejected: boolean;
  remoteAssistanceClosed: boolean;
};

type RemoteAssistanceState = {
  remoteAssistance: null | ISingleRemoteAssistanceInterface;

  remoteAssistanceError: string | null;
  statusBox: StatusBox | null;
  typeConfig: any;
  updatedStatusBox: (values: Partial<StatusBox>) => void;
  updatedRemoteAssitance: (
    values: Partial<ISingleRemoteAssistanceInterface>
  ) => void;
  onReloadRemoteAssistance: () => void;
};

const contextDefaultValues: RemoteAssistanceState = {
  remoteAssistance: null,

  remoteAssistanceError: null,
  statusBox: {
    screenShot: "",
    deviceStatus: null,
    on: false,
    userRejected: false,
    remoteAssistanceClosed: false
  },
  typeConfig: null,
  updatedStatusBox: (values: Partial<StatusBox>) => {},
  updatedRemoteAssitance: (
    values: Partial<ISingleRemoteAssistanceInterface>
  ) => {},
  onReloadRemoteAssistance: () => {}
};

export const RemoteAssistanceContext = createContext<RemoteAssistanceState>(
  contextDefaultValues
);

export const RemoteAssistanceProvider = ({ id, children }) => {
  const [
    remoteAssistance,
    setRemoteAssistance
  ] = useState<null | ISingleRemoteAssistanceInterface>(null);
  const [typeConfig, setTypeConfig] = useState<null | any>(null);
  const [statusBox, setStatusBox] = useState({
    screenShot: "",
    deviceStatus: null,
    on: false,
    userRejected: false,
    remoteAssistanceClosed: false
  });
  const [remoteAssistanceError, setRemoteAssistanceError] = useState<
    null | string
  >(null);
  const [reloadRemoteAssistance, setReloadRemoteAssistance] = useState(true);

  useEffect(() => {
    const getRemoteAssistance = async () => {
      if (remoteAssistanceError) return;
      const {
        data: {
          resource: remoteAssistanceResult,
          status: statusRemoteAssistance,
          resource: {
            device_status,
            closed,
            operator_device: { operator_device_type_id }
          }
        }
      } = await getRemoteAssistanceByID(id);

      const {
        data: { data: resultDataConfig },
        status: statusDeviceType
      } = await getDeviceTypesConfig(operator_device_type_id);

      if (statusRemoteAssistance === 200 && statusDeviceType === 200) {
        setStatusBox(prevstate => ({
          ...prevstate,
          deviceStatus: JSON.parse(device_status),
          remoteAssistanceClosed: closed ? true : false
        }));

        setRemoteAssistance(remoteAssistanceResult);
        setTypeConfig(resultDataConfig);
      } else {
        setRemoteAssistanceError("Error");
      }
    };
    if (reloadRemoteAssistance) {
      getRemoteAssistance();
    }
    setReloadRemoteAssistance(false);
  }, [id, remoteAssistanceError, reloadRemoteAssistance]);

  const updatedRemoteAssitance = newValues => {
    setRemoteAssistance({ ...remoteAssistance, ...newValues });
  };

  const updatedStatusBox = (newValues: Partial<StatusBox>) => {
    setStatusBox(prevState => ({
      ...prevState,
      ...newValues
    }));
  };

  const onReloadRemoteAssistance = () => setReloadRemoteAssistance(true);

  return (
    <RemoteAssistanceContext.Provider
      value={{
        remoteAssistance,
        remoteAssistanceError,
        statusBox,
        typeConfig,
        updatedStatusBox,
        onReloadRemoteAssistance,
        updatedRemoteAssitance
      }}
    >
      {children}
    </RemoteAssistanceContext.Provider>
  );
};

export const useRemoteAssistance = () => {
  return useContext(RemoteAssistanceContext);
};
