import React, { useState, useEffect } from "react";
import style from "../../DeviceProfileDetailsPage.module.scss";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Label,
  CardFooter
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const _generateOptionsList = (list: any) => {
  if (list && list.length) {
    return list.map(
      (item: { value: string | undefined; name: React.ReactNode }, index) => (
        <option value={item.value} key={item.value + index}>
          {item.name}
        </option>
      )
    );
  }
};

const ListOfPersistence = ({
  list,
  dataFormClone,
  setDataFormClone,
  disabled
}) => {
  const [dataForm, setDataForm] = useState<any>([]);
  useEffect(() => {
    if (list && list.length > 0) {
      setDataForm([...list]);
      setDataFormClone([...list]);
    }
  }, [list, setDataFormClone]);

  return (
    <div className={style.smallBox}>
      {dataForm?.length > 0
        ? dataForm.map((item, indexMap: number) => {
            const lessCurrentItem = dataFormClone.filter(
              itemDataForm => itemDataForm.id !== item.id
            );
            const currentItem = dataFormClone.find(
              itemDataForm => itemDataForm.id === item.id
            );

            return (
              <Card className="mb-2" key={item.id || indexMap}>
                <CardHeader>
                  <strong>
                    {item.packageName
                      ? "Package: " + item.packageName
                      : "New app"}{" "}
                  </strong>
                </CardHeader>
                <CardBody>
                  <Label className={style.labelSwitch}> Package Name</Label>
                  <Input
                    type="text"
                    id={`name ${item.id}`}
                    name={`name ${item.id}`}
                    defaultValue={item.packageName}
                    className="mb-2"
                    placeholder="Eg: com.company.app"
                    pattern="^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$"
                    required={true}
                    onChange={e => {
                      const launchType = dataFormClone.find(
                        dataFilter => dataFilter.id === item.id
                      ).launchType;
                      const launchParameter = dataFormClone.find(
                        dataFilter => dataFilter.id === item.id
                      ).launchParameter;
                      setDataFormClone([
                        ...lessCurrentItem,
                        {
                          ...item,
                          launchType,
                          packageName: e.target.value,
                          launchParameter
                        }
                      ]);
                    }}
                  />
                  <Label className={style.labelSwitch}>Launch type</Label>

                  <Input
                    type="select"
                    id={`Launchtype_${item.id}`}
                    name={`Launchtype_${item.id}`}
                    defaultValue={item.launchType}
                    placeholder="Enter Url Persistence"
                    onChange={e => {
                      const packageName = dataFormClone.find(
                        dataFilter => dataFilter.id === item.id
                      ).packageName;
                      const launchParameter = dataFormClone.find(
                        dataFilter => dataFilter.id === item.id
                      ).launchParameter;
                      setDataFormClone([
                        ...lessCurrentItem,
                        {
                          ...item,
                          packageName,
                          launchParameter:
                            e.target.value === "0" ? "" : launchParameter,
                          launchType: e.target.value
                        }
                      ]);
                    }}
                  >
                    <option value="">Select Launch Type</option>
                    {_generateOptionsList([
                      {
                        name: "Launcher",
                        value: 0
                      },
                      {
                        name: "Activity",
                        value: 1
                      },
                      {
                        name: "Service",
                        value: 2
                      }
                    ])}
                  </Input>
                  {parseInt(currentItem.launchType) ? (
                    <>
                      <Label className={style.labelSwitch}>
                        Launch Parameter
                      </Label>
                      <Input
                        type="text"
                        id={`name ${item.id}`}
                        name={`name ${item.id}`}
                        defaultValue={item.launchParameter}
                        className="mb-2"
                        placeholder="Enter Launch Parameter"
                        disabled={
                          parseInt(currentItem.launchType) > 0 ? false : true
                        }
                        onChange={e => {
                          const launchType = dataFormClone.find(
                            dataFilter => dataFilter.id === item.id
                          ).launchType;
                          const packageName = dataFormClone.find(
                            dataFilter => dataFilter.id === item.id
                          ).packageName;

                          setDataFormClone([
                            ...lessCurrentItem,
                            {
                              ...item,
                              launchType,
                              packageName,
                              launchParameter: e.target.value
                            }
                          ]);
                        }}
                      />
                    </>
                  ) : null}
                </CardBody>
                <CardFooter>
                  <Button
                    color="danger"
                    className={style.addButton}
                    disabled={disabled}
                    onClick={() => {
                      setDataForm([
                        ...dataFormClone.filter(
                          itemToDelete => itemToDelete.id !== item.id
                        )
                      ]);
                      setDataFormClone([
                        ...dataFormClone.filter(
                          itemToDelete => itemToDelete.id !== item.id
                        )
                      ]);
                    }}
                  >
                    <i className="material-icons">delete</i>Remove
                  </Button>
                </CardFooter>
              </Card>
            );
          })
        : "Please add a package"}
      <Button
        color="primary"
        className={style.addButton}
        disabled={dataForm.length >= 20 || disabled}
        onClick={() => {
          const id = uuidv4();
          setDataForm([
            ...dataForm,
            {
              id,
              packageName: "",
              launchType: null,
              launchParameter: ""
            }
          ]);
          setDataFormClone([
            ...dataFormClone,
            {
              id,
              packageName: "",
              launchType: null,
              launchParameter: ""
            }
          ]);
        }}
      >
        <i className="material-icons">add</i>Add Package
      </Button>
    </div>
  );
};

export default ListOfPersistence;
