import axios from "../axiosInstance";
import { CORE_API_DOMAIN } from "../../API_routes";
import { ICreateDeviceTypesInterface } from "../interfaces";

const apiPrefix: string = `${CORE_API_DOMAIN}/operator-device-types`;
const apiPrefixConfig: string = `${CORE_API_DOMAIN}/operator-device-type-configs`;
const apiPrefixDeviceProfile: string = `${CORE_API_DOMAIN}/device-profile`;

export const getDeviceTypes: any = (
  pageNumber?: number | string | null,
  orderBy?: string | null,
  direction?: string | null,
  limit?: number | null
): any =>
  axios.get(
    `${apiPrefix}?page=${pageNumber}${limit ? "&limit=" + limit : ""}${
      orderBy ? "&order=" + orderBy : ""
    }${direction ? "&direction=" + direction : ""}`.trim()
  );

export const getDeviceTypesFilter: any = ({
  current_page,
  orderBy,
  direction,
  id,
  manufacturer,
  model,
  created_at,
  updated_at,
  limit,
  name,
  romIds,
  playback_support_id
}): any =>
  axios.get(
    `${apiPrefix}?page=${current_page}${limit ? "&limit=" + limit : ""}${
      direction ? "&direction=" + direction : ""
    }${id ? "&filter[id]=" + id : ""}${
      manufacturer ? "&filter[manufacturer]=" + manufacturer : ""
    }${model ? "&filter[model]=" + model : ""}${
      romIds ? "&filter[operator_rom_id]=" + romIds : ""
    }${name ? "&filter[name]=" + name : ""}${
      created_at ? "&filter[created_at]=" + created_at : ""
    }${
      playback_support_id
        ? "&filter[playback_support_id]=" + playback_support_id
        : ""
    }${updated_at ? "&filter[updated_at]=" + updated_at : ""}`.trim()
  );

export const createDeviceType: any = (data: ICreateDeviceTypesInterface): any => {
  const formData: any = new FormData();

  const {
    key_type,
    manufacturer,
    model,
    playback_support_id,
    unidirectional,
    operator_rom,
    operator_rom_id,
    status,
    name,
    profile_picture,
  } = data;

  formData.append("profile_picture", profile_picture);
  formData.set("key_type", key_type);
  formData.set("manufacturer", manufacturer);
  formData.set("model", model);
  formData.set("playback_support_id", playback_support_id);
  formData.set("operator_rom", operator_rom);
  formData.set("operator_rom_id", operator_rom_id);
  formData.set("unidirectional", unidirectional);
  formData.set("status", status);
  formData.set("name", name);


  return axios.post(`${apiPrefix}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export const getDeviceTypeByID: any = (ID: number | string): any =>
  axios.get(`${apiPrefix}/${ID}`);

export const deleteDeviceType: any = (id: number | string): any =>
  axios.delete(`${apiPrefix}/${id}`);

export const deleteDeviceTypes: any = (ids: Array<number | string>): any => {
  const deviceTypes: number | string = ids
    .map((item: number | string, index: number) => `ids[${index}]=${item}`)
    .join("&");

  return axios.delete(`${apiPrefix}?${deviceTypes}`);
};

export const updateDeviceType: any = (
  id: number | string,
  data: ICreateDeviceTypesInterface
): any => {
  const formData: any = new FormData();

  const {
    key_type,
    manufacturer,
    model,
    playback_support_id,
    unidirectional,
    operator_rom,
    operator_rom_id,
    status,
    name,
    profile_picture,
  } = data;

  formData.append("profile_picture", profile_picture);
  formData.set("key_type", key_type);
  formData.set("manufacturer", manufacturer);
  formData.set("model", model);
  formData.set("playback_support_id", playback_support_id);
  formData.set("operator_rom", operator_rom);
  formData.set("operator_rom_id", operator_rom_id);
  formData.set("unidirectional", unidirectional);
  formData.set("status", status);
  formData.set("name", name);


  return axios.post(`${apiPrefix}/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export const getDeviceTypesConfig: any = (id: string): any =>
  axios.get(`${apiPrefixConfig}/${id}`);

export const updateDeviceTypesConfig: any = (id: string, data: {}): any =>
  axios.put(`${apiPrefixConfig}/${id}`, { ...data });

export const getDeviceTypesRoms: any = (id: string): any =>
  axios.get(`${apiPrefixDeviceProfile}/${id}/roms`);

export const updateDeviceTypesRoms: any = (id: string, data: any): any =>
  axios.put(`${apiPrefixDeviceProfile}/${id}/roms`, {
    ...data
  });

export const getDeviceTypesApps: any = (id: string): any =>
  axios.get(
    `${apiPrefixDeviceProfile}/apps?filter[operator_device_type_id]=${id}`
  );

export const createDeviceTypesApps: any = (id: string, data: any): any =>
  axios.post(`${apiPrefixDeviceProfile}/apps`, {
    ...data
  });
export const deleteDeviceTypesApp: any = (id: string): any =>
  axios.delete(`${apiPrefixDeviceProfile}/apps/${id}`);

export const updateDeviceTypesApp: any = (id: string, data: any): any =>
  axios.put(`${apiPrefixDeviceProfile}/apps/${id}`, {
    ...data
  });

export const getDeviceTypesAppsConfig = (id: string): any =>
  axios.get(
    `${apiPrefixDeviceProfile}/apps/configs?filter[operator_device_type_id]=${id}`
  );

export const createDeviceTypesAppsConfig: any = (id: string, data: any): any =>
  axios.post(`${apiPrefixDeviceProfile}/apps/configs`, {
    ...data
  });
export const deleteDeviceTypesAppsConfig: any = (id: string): any =>
  axios.delete(`${apiPrefixDeviceProfile}/apps/configs/${id}`);

export const updateDeviceTypesAppsConfig: any = (id: string, data: any): any =>
  axios.put(`${apiPrefixDeviceProfile}/apps/configs/${id}`, {
    ...data
  });

export const getDeviceTypesAppsPermissions = (id: string): any =>
  axios.get(
    `${apiPrefixDeviceProfile}/apps/permissions?filter[operator_device_type_id]=${id}`
  );

export const createDeviceTypesAppPermissions: any = (id: string, data: any): any =>
  axios.post(`${apiPrefixDeviceProfile}/apps/permissions`, {
    ...data
  });
export const deleteDeviceTypesAppPermissions: any = (id: string): any =>
  axios.delete(`${apiPrefixDeviceProfile}/apps/permissions/${id}`);

export const updateDeviceTypesAppPermissions: any = (id: string, data: any): any =>
  axios.put(`${apiPrefixDeviceProfile}/apps/permissions/${id}`, {
    ...data
  });
