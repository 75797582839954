import axios from "../axiosInstance";
import { CORE_API_DOMAIN } from "../../API_routes";

export type GetFailedProps = {
  type: string;
  device_id?: string;
  acknowledge?: 0 | 1;
  error_code?: string;
  error_data?: string;
  created_at?: string;
  current_page?: string | number;
  orderBy?: string;
  direction?: string;
  id?: string;
};

export const getFailed = ({
  type,
  device_id,
  error_data,
  created_at,
  acknowledge,
  error_code,
  current_page,
  orderBy,
  direction,
  id
}: GetFailedProps) =>
  axios.get(
    `${CORE_API_DOMAIN}/failures${
      type ? "?type=" + type : "?page=" + current_page
    }${current_page ? "&page=" + current_page : "&page=1"}${
      orderBy ? "&order=" + orderBy : ""
    }${direction ? "&direction=" + direction : ""}${
      id ? "&filter[id]=" + id : ""
    }${device_id ? "&device_id=" + device_id : ""}${
      error_data ? "&error_data=" + error_data : ""
    }${error_code ? "&error_code=" + error_code : ""}${
      created_at ? "&filter[created_at]=" + created_at : ""
    }${
      typeof acknowledge !== "object" && acknowledge
        ? "&acknowledge=" + acknowledge
        : ""
    }`
  );

export const updateAcknowledge = (ids: Array<string>) =>
  axios.put(`${CORE_API_DOMAIN}/failures/acknowledge`, {
    ids: [...ids]
  });
