import React from "react";
import locale from "rc-pagination/lib/locale/en_US";
import Rcagination from "rc-pagination";

const Pagination = ({
  onChange,
  current,
  total,
  defaultPageSize
}: {
  onChange: (e: any) => any;
  current: number;
  total: number;
  defaultPageSize: number;
}) => {
  return (
    <Rcagination
      onChange={onChange}
      current={current}
      total={total}
      defaultPageSize={defaultPageSize}
      showTitle={true}
      locale={locale}
    />
  );
};

export default Pagination;
