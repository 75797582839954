import React, { useState, useEffect } from "react";
import classNames from "classnames";
import style from "./DpadAntd.module.scss";
import { Tooltip, Button, Layout, Radio } from "antd";
import { Slider } from "antd";

interface IPropsInterface {
  onRemoteBack?(): void;
  onRemoteApps?(): void;
  onRemoteOk(): void;
  onRemoteLeft(): void;
  onRemoteUp(): void;
  onRemoteDown(): void;
  onRemoteRight(): void;
  deviceStatus?: any;
  onVolumeDown?(): void;
  onVolumeUp?(): void;
  onRemoteHome?(): void;
  onMute?(): void;
  currentUser?: string;
  emitEventSocket: Function;
  onCustomInput?(): void;
  onKeyboardInput?(): void;
}

const DpadAntd = ({
  onRemoteBack,
  onRemoteOk,
  onRemoteApps,
  onRemoteHome,
  onCustomInput,
  onKeyboardInput,
  onRemoteLeft,
  onRemoteUp,
  onRemoteDown,
  onRemoteRight,
  deviceStatus,
  onMute,
  emitEventSocket
}: IPropsInterface) => {
  const [statusSound, setStatusSound] = useState(0);
  const sounds = deviceStatus?.sound?.current || deviceStatus?.sound?.volume;

  const onChangeSlider = (volume_index: number) => {
    //setStatusSound(volume_index);
    emitEventSocket({
      remote_action_type: 23,
      remote_action_id: "KEYCODE_VOLUME_CONTROL",
      volume_index
    });
  };

  const onSettings = () => {
    emitEventSocket({
      remote_action_type: 28,
      remote_action_id: "KEYCODE_SETTINGS"
    });
  };

  useEffect(() => {
    if (sounds) {
      //console.log(deviceStatus?.sound);
      setStatusSound(sounds);
    }
  }, [sounds]);

  return (
    <Layout.Content color="remote" className={style.Wrap}>
      <div className={style.btnsBottom}>
        <Tooltip placement="top" title="Custom Input">
          <Button className={style.btnBottom} onClick={onCustomInput}>
            <i className="material-icons">web_asset</i>
          </Button>
        </Tooltip>
        <Tooltip placement="top" title="Keyboard Input">
          <Button className={style.btnBottom} onClick={onKeyboardInput}>
            <i className="material-icons">keyboard</i>
          </Button>
        </Tooltip>
        <Tooltip placement="top" title="Settings">
          <Button className={style.btnBottom} onClick={onSettings}>
            <i className="material-icons">settings</i>
          </Button>
        </Tooltip>
      </div>
      <div className={classNames(style.imgDiv, style.imgVolume)}>
        <div className={style.buttonsWrapVolume}>
          <Radio.Group>
            <Tooltip placement="top" title={statusSound ? "Mute" : "Sound"}>
              <Button onClick={onMute}>
                <i className="material-icons">
                  {statusSound ? "volume_off" : "volume_up"}
                </i>
              </Button>
            </Tooltip>
          </Radio.Group>
          {typeof statusSound === "number" && (
            <Slider
              min={0}
              max={deviceStatus?.sound?.max}
              className={style.slider}
              handleStyle={{ width: 20, height: 20 }}
              //disabled={!statusSound}
              onChange={(volume: number) => setStatusSound(volume)}
              value={statusSound}
              onAfterChange={onChangeSlider}
            />
          )}
        </div>
      </div>
      <div className={style.Control}>
        <div
          onClick={onRemoteUp}
          className={classNames(style.Side, style.SideTop)}
        >
          <i className="material-icons">keyboard_arrow_up</i>
        </div>
        <div
          onClick={onRemoteRight}
          className={classNames(style.Side, style.SideRight)}
        >
          <i className="material-icons">keyboard_arrow_right</i>
        </div>
        <div
          onClick={onRemoteLeft}
          className={classNames(style.Side, style.SideLeft)}
        >
          <i className="material-icons">keyboard_arrow_left</i>
        </div>
        <div
          onClick={onRemoteDown}
          className={classNames(style.Side, style.SideBottom)}
        >
          <i className="material-icons">keyboard_arrow_down</i>
        </div>
        <div onClick={onRemoteOk} className={style.SideMenu}></div>
      </div>

      <div className={style.btnsBottom}>
        <Tooltip placement="top" title="Backspace">
          <Button className={style.btnBottom} onClick={onRemoteBack}>
            <i className="material-icons">keyboard_backspace</i>
          </Button>
        </Tooltip>
        <Tooltip placement="top" title="Home">
          <Button className={style.btnBottom} onClick={onRemoteHome}>
            <i className="material-icons">panorama_fish_eye</i>
          </Button>
        </Tooltip>
        <Tooltip placement="top" title="Apps">
          <Button className={style.btnBottom} onClick={onRemoteApps}>
            <i className="material-icons">apps</i>
          </Button>
        </Tooltip>
      </div>
    </Layout.Content>
  );
};

export default DpadAntd;
