import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { find, get } from "lodash";
import { Badge, Button, Card, CardBody, Form, Row, Alert } from "reactstrap";
import {
  IFilteredOperators,
  IOperatorsInterface,
  ISingleOperatorInterface
} from "../../../common/interfaces";
import { Formik } from "formik";
import "rc-pagination/assets/index.css";
import FormInputs from "../../Forms/FormInputs";
import style from "./OperatorsTable.module.scss";
import DeleteModal from "../../ModalComponent/DeleteModal";
import AppTable from "../AppTable";
import {
  renderPagination,
  generateColButtons,
  renderTableFieldNames,
  ButtonToForm
} from "../../../common/helper/helper";

interface IPropsInterface {
  onShowForm(): void;
  onFilter(data: { orderBy: string; direction?: string }): void;
  operators: IOperatorsInterface;
  onDeleteOperator(id: string | number): Promise<boolean>;
  onDeleteOperators(ids: Array<string | number>): Promise<boolean>;
  history: { push(data): void };
  statuses: Array<{ id: number; name: string }>;
  types: Array<{ id: number; name: string }>;
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };

  refCountryValue: any;
  filteredOperators(data: IFilteredOperators): IOperatorsInterface;
  responseError: { details: object; message: string } | boolean | any;
  toggleErrorResponse(): void;
  countries: [];
  responseItemMessage: null;
}

interface IStateInterface {
  selectedRows: Array<number>;
  selectAll: boolean;
  showDeleteModal: boolean;
  operatorId: number | null;
  selectedCountryId: any;
  loading: boolean;
  valuesForFilter: any;
}

const filteredValues: any = {
  current_page: "",
  limit: "",
  orderBy: "",
  direction: "",
  type_id: "",
  name: "",
  status: ""
};

const initialValues: any = {
  type_id: "",
  name: "",
  status: ""
};

class OperatorsTable extends Component<IPropsInterface, IStateInterface> {
  private timer;

  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      selectedRows: [],
      selectAll: false,
      showDeleteModal: false,
      operatorId: null,
      selectedCountryId: null,
      loading: false,
      valuesForFilter: {}
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IPropsInterface): void {
    const { operators }: IPropsInterface = this.props;

    if (operators && nextProps.operators) {
      const {
        operators: { current_page }
      }: IPropsInterface = this.props;
      const {
        operators: { current_page: nextCurrentPage }
      }: IPropsInterface = nextProps;

      if (current_page && nextCurrentPage) {
        this.setState({
          selectedRows: [],
          selectAll: false
        });
      }
    }
  }

  _onRedirectToDetails = (id, nameCountry) => {
    const { history } = this.props;
    history.push({
      pathname: `/operator/${id}`,
      state: nameCountry
    });
  };

  componentWillUnmount() {
    setTimeout(this.timer);
  }

  _onChange: any = (page: number) => {
    const { valuesForFilter }: any = this.state;
    this._onSubmit(valuesForFilter, page);
  };

  __onChangeSelectedCountryId = idCountry =>
    this.setState({ selectedCountryId: idCountry });

  _onCheckboxClick = e => {
    const {
      operators: { data }
    } = this.props;

    data.forEach(operator => {
      if (operator.id === e.target.id) {
        this.setState((state: IStateInterface) => ({
          selectedRows: !state.selectedRows.includes(operator.id)
            ? [...state.selectedRows, operator.id]
            : state.selectedRows.filter((item: any) => item !== operator.id)
        }));
      }
    });
  };

  _onSelectAll = () => {
    const {
      operators: { data }
    } = this.props;

    this.setState(state => ({
      selectAll: !state.selectAll,
      selectedRows: !state.selectAll ? data.map(({ id }) => id) : []
    }));
  };

  _toggleDeleteModal: any = (): void => {
    const { showDeleteModal }: IStateInterface = this.state;

    this.setState({
      showDeleteModal: !showDeleteModal
    });
  };

  _renderStatuses = (status: number): JSX.Element | undefined => {
    const { statuses } = this.props;

    if (statuses && statuses.length) {
      const item = find(statuses, o => o.id === status);
      if (item) {
        switch (status) {
          case 0:
            return <Badge color="secondary">{item.name}</Badge>;
          case 1:
            return <Badge color="success">{item.name}</Badge>;
          case 2:
            return <Badge color="danger">{item.name}</Badge>;
          default:
            return;
        }
      }

      return;
    }

    return;
  };

  _renderResultRows = () => {
    const { operators, countries } = this.props;
    const { selectedRows } = this.state;
    if (operators) {
      const { data } = operators;

      const newElements = data.map(item => {
        const nameCountries = find(countries, function(o: any) {
          return o.id === Number(item.country_id);
        });

        if (nameCountries) {
          item.nameCountry = nameCountries.name;
        }

        return item;
      });

      return newElements.map((item: ISingleOperatorInterface) => {
        const { id, name, type, service_type, status, nameCountry } = item;

        // const datetime: string = moment(parseInt(updated_at) * 1000).format(
        //   "MM DD YYYY HH:mm"
        // );

        return (
          <tr key={name + id}>
            <td>{name}</td>
            <td>{get(type, "name")}</td>
            <td>{get(service_type, "name")}</td>
            <td>{this._renderStatuses(status)}</td>
            {generateColButtons({
              id,
              nameCountry,
              selectedRows,
              onRedirectToDetails: this._onRedirectToDetails,
              toggleDeleteModal: this._toggleDeleteModal,
              onCheckboxClick: this._onCheckboxClick,
              setIdOnClick: () => this.setState({ operatorId: id })
            })}
          </tr>
        );
      });
    }
  };

  _renderTableFieldNames = () => {
    const { onFilter, filtering } = this.props;
    const keys = [
      { name: "Name", keyId: "Name" },
      { name: "Type", keyId: "Type" },
      { name: "Service Type", keyId: "service_type_id" },
      { name: "Status", keyId: "Status" }
    ];
    return renderTableFieldNames({ keys, onFilter, filtering });
  };

  _generateTypeOptions: Function = (
    types: Array<{ name: string; id: number }>
  ) => {
    return (
      types &&
      types.map((item: { id: number; name: string }) => (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      ))
    );
  };

  _resetIds = () => {
    const { operatorId, selectedRows } = this.state;
    if (operatorId) {
      this.setState({
        operatorId: null
      });
    } else if (selectedRows && selectedRows.length > 0) {
      this.setState({
        selectedRows: []
      });
    }
  };

  _generateStatusOptions: Function = (
    statusList: Array<{ name: string; id: number }>
  ) => {
    return statusList.map((item: { id: number; name: string }) => (
      <option value={item.id} key={item.id}>
        {item.name}
      </option>
    ));
  };

  _onSubmit: any = async (
    values: {
      type_id: number;
      name: string;
      status: number | string;
    },
    page = 1
  ): Promise<void> => {
    const { filteredOperators, filtering } = this.props;
    this.setState({ loading: true });
    filteredValues.current_page = Number.isInteger(page) ? page : 1;
    filteredValues.limit = null;
    filteredValues.orderBy = filtering.orderBy;
    filteredValues.direction = filtering.direction;
    filteredValues.type_id = values.type_id;
    filteredValues.name = values.name;
    filteredValues.status = values.status;

    try {
      await filteredOperators(filteredValues);
    } catch (e) {
      // console.log(e);
    } finally {
      this.timer = setTimeout(() => {
        this.setState({
          loading: false,
          valuesForFilter: {
            ...values,
            orderBy: filtering.orderBy,
            direction: filtering.direction
          }
        });
      }, 200);
    }
  };

  render() {
    const {
      onShowForm,
      operators,
      onDeleteOperators,
      onDeleteOperator,
      types,
      responseError,
      toggleErrorResponse,
      statuses,
      responseItemMessage
    } = this.props;
    const {
      selectAll,
      selectedRows,
      showDeleteModal,
      operatorId,
      loading
    } = this.state;

    const currentElementToDelete =
      operators &&
      operators.data &&
      operators.data.find(el => el.id === operatorId);

    let modalMessage: string | JSX.Element =
      operatorId && selectedRows.length === 0 ? (
        <>
          <strong>{currentElementToDelete?.name}</strong>
          <span> will be deleted. Please confirm action!</span>
        </>
      ) : (
        "Selected operators will be deleted. Please confirm action!"
      );

    return (
      <div className={style.operatorsTableWrapper}>
        <Row>
          <div className="card-title col-12">
            <h1>
              <i className="material-icons perm_data_setting" />
              Operators
            </h1>
            <ButtonToForm value="Create Operator" click={onShowForm} />
          </div>
        </Row>
        <Card>
          <CardBody>
            {responseItemMessage && <Alert>{responseItemMessage}</Alert>}
            <Formik
              initialValues={initialValues}
              onSubmit={this._onSubmit}
              render={({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isValid,
                handleReset
              }: any): any => {
                return (
                  <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                    <Row className={style.filteringRow}>
                      <FormInputs
                        onChange={(event: any): any => {
                          handleChange(event);
                          setFieldValue("type_id", event.target.value);
                        }}
                        htmlFor="type_id"
                        value={values.type_id}
                        id="type_id"
                        labelTitle="Type"
                        name="type_id"
                        errors={errors.type_id}
                        type="select"
                        option={
                          <option value="" label="Select Type">
                            Select Type
                          </option>
                        }
                        renderData={this._generateTypeOptions(types)}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="name"
                        value={values.name}
                        id="name"
                        labelTitle="Name"
                        placeholder="Enter Name"
                        onBlur={handleBlur}
                        name="name"
                        errors={errors.name}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="status"
                        value={values.status}
                        id="status"
                        labelTitle="Status"
                        placeholder="Enter status"
                        onBlur={handleBlur}
                        name="status"
                        errors={errors.status}
                        type="select"
                        rowInputClassName={style.filteringInput}
                        option={
                          <option value={100} label="Select Status">
                            Select Status
                          </option>
                        }
                        renderData={this._generateStatusOptions(statuses)}
                      />
                      <div>
                        <Button
                          className={style.filteringButton}
                          type="submit"
                          color="primary"
                          size="md"
                        >
                          Apply filters
                        </Button>
                        <Button
                          className={style.filteringButton}
                          color="danger"
                          size="md"
                          type="reset"
                          onClick={() => {
                            handleReset(values);
                            handleSubmit();
                          }}
                        >
                          Reset filters
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            />
            <br />
            <Fragment>
              {operators &&
              get(operators, "data") &&
              get(operators, "data").length > 0 ? (
                <AppTable
                  renderTableFieldNames={this._renderTableFieldNames}
                  renderResultRows={this._renderResultRows}
                  numberOfCells={6}
                  toggleDeleteModal={this._toggleDeleteModal}
                  selectAll={selectAll}
                  onSelectAll={this._onSelectAll}
                  selectedRows={selectedRows}
                  loading={loading}
                />
              ) : (
                <Alert color="danger">No results found</Alert>
              )}
              {operators && renderPagination(operators, this._onChange)}
            </Fragment>
          </CardBody>
        </Card>
        <DeleteModal
          selectedRows={selectedRows}
          onToggle={this._toggleDeleteModal}
          onDelete={onDeleteOperator}
          onMultipleDelete={onDeleteOperators}
          id={operatorId}
          isOpen={showDeleteModal}
          modalTitle="Delete Operator"
          responseError={responseError}
          toggleResponseError={toggleErrorResponse}
          resetIds={this._resetIds}
        >
          {modalMessage}
        </DeleteModal>
      </div>
    );
  }
}

export default withRouter<any, any>(OperatorsTable);
