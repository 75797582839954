import { combineReducers } from "redux";
import auth from "./auth";
import roles from "./roles";
import isForbidden from "./forbidden";

const rootReducer = combineReducers({
  auth: auth,
  roles: roles,
  isForbidden: isForbidden
});

export default rootReducer;
