import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  Label,
  FormGroup,
  Badge
} from "reactstrap";
import { Form } from "formik";
import style from "../DevicesDetailsPage.module.scss";

const BadgeConfig = ({ value }: { value: string | null }) => {
  const isNull = value === null;
  let info = {
    value: value.toString() === "1" ? "success" : "danger",
    text: value.toString() === "1" ? "Active" : "Disabled"
  };
  if (isNull) {
    info = {
      value: "info",
      text: "Not seted"
    };
  }

  return <Badge color={info.value}>{info.text}</Badge>;
};

const DeviceCofigVersion = ({ versions }) => {
  const parseKeyTitle = (key: string) => key.split("_").join(" ");

  const _renderBox = () => {
    if (versions) {
      return versions.map((el: { name: string; value: [] }, index: number) => {
        return (
          <Col md="6" key={el.name}>
            <div className={style.contentWrapper}>
              <Card>
                <CardHeader>
                  <h3 className={style.title}>
                    {/* <i className="material-icons ">speaker_notes</i> */}
                    <strong>{parseKeyTitle(el.name)}</strong>
                  </h3>
                </CardHeader>
                <CardBody>
                  {el.value.map(
                    (
                      input: { name: string; value: string; status?: boolean },
                      index: number
                    ) => (
                      <FormGroup className="mb-2 w-100" key={index}>
                        {input.status ? (
                          <>
                            <Label htmlFor={input.name} className="mr-2">
                              {input.name}:
                            </Label>
                            <BadgeConfig value={input.value} />
                          </>
                        ) : (
                          <>
                            {" "}
                            <Label htmlFor={input.name}>{input.name}</Label>
                            <Input
                              type="text"
                              id={input.name}
                              name={input.name}
                              defaultValue={input.value || "Not set"}
                              readOnly={true}
                            />
                          </>
                        )}
                      </FormGroup>
                    )
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        );
      });
    }
  };

  return (
    <Form>
      <Row className="p-3">{_renderBox()}</Row>
    </Form>
  );
};

export default DeviceCofigVersion;
