import React, { ChangeEvent, Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { find, get } from "lodash";
import AppTable from "../AppTable";
import { Badge, Button, CardBody, Card, Row, Form, Alert } from "reactstrap";
import {
  IOperatorsInterface,
  ISingleUser,
  IUsers
} from "../../../common/interfaces";
import style from "./UserTable.module.scss";
import DeleteModal from "../../ModalComponent/DeleteModal";
import { Formik } from "formik";
import FormInputs from "../../Forms/FormInputs";
import {
  renderPagination,
  generateColButtons,
  renderTableFieldNames,
  ButtonToForm,
  parseRolesIdToRolesName
} from "../../../common/helper/helper";

interface IPropsInterface {
  filterUser(data: {}, pag?: number): any;
  onShowForm(): void;
  onFilter(data: { orderBy: string; direction?: string }): void;
  users: IUsers;
  operators: IOperatorsInterface;
  onDeleteUser(id: string | number): void;
  onDeleteUsers(ids: Array<string | number>): void;
  history: any;
  statuses: Array<{ id: number; name: string }>;
  roles: any;
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
  currentOperator: string | null;
  responseError: { details: object; message: string } | boolean | any;
  toggleErrorResponse(): void;
  idCurrentProfile: string | null;
  responseItemMessage: null;
}

interface IStateInterface {
  selectedRows: Array<number | string>;
  selectAll: boolean;
  showDeleteModal: boolean;
  userId: number | null;
  loading: boolean;
  pagination: number | null;
  valuesForFilter: any;
}

const initialValues: any = {
  name: "",
  email: "",
  role: "",
  status: ""
};

class UserTable extends Component<IPropsInterface, IStateInterface> {
  private timer;
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      selectedRows: [],
      selectAll: false,
      showDeleteModal: false,
      userId: null,
      loading: false,
      pagination: null,
      valuesForFilter: {}
    };
  }

  componentDidMount() {
    const { users } = this.props;

    this.setState({ pagination: users.current_page });
  }

  componentWillUnmount() {
    setTimeout(this.timer);
  }

  _onRedirectToDetails = (id: number): void => {
    const { history }: IPropsInterface = this.props;
    history.push(`/user/${id}`);
  };

  _onChange: any = async (page: number) => {
    const { valuesForFilter }: any = this.state;

    this._onSubmit(valuesForFilter, page);
  };

  _onSubmit: any = async (
    values: {
      name: string;
      email: string;
      operator_id: string;
      role: number | string;
      status: number | string;
    },
    page: number
  ): Promise<void> => {
    const { filterUser, filtering } = this.props;
    this.setState({ loading: true });

    try {
      await filterUser(values, Number.isInteger(page) ? page : 1);
    } catch (e) {
      // console.log(e);
    } finally {
      this.timer = setTimeout(() => {
        this.setState({
          loading: false,
          valuesForFilter: {
            ...values,
            orderBy: filtering.orderBy,
            direction: filtering.direction
          }
        });
      }, 200);
    }
  };

  _renderRoles = (role: {
    id: number;
    name: string;
  }): JSX.Element | undefined => {
    if (role && role.name) {
      switch (role.id) {
        case 1:
          return <Badge color="warning">{role.name}</Badge>;
        case 2:
          return <Badge color="success">{role.name}</Badge>;
        case 3:
          return <Badge color="danger">{role.name}</Badge>;
        default:
          return;
      }
    }

    return;
  };

  _generateTypeOptions: Function = (
    types: Array<{ name: string; id: number }>
  ) => {
    return (
      types &&
      types.map((item: { id: number; name: string }) => (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      ))
    );
  };

  _onCheckboxClick: any = (e: any) => {
    const {
      users: { data }
    } = this.props;

    data.forEach(user => {
      if (user.id === e.target.id) {
        this.setState((state: IStateInterface) => ({
          selectedRows: !state.selectedRows.includes(user.id)
            ? [...state.selectedRows, user.id]
            : state.selectedRows.filter((item: any) => item !== user.id)
        }));
      }
    });
  };

  _onSelectAll: (event: ChangeEvent<HTMLInputElement>) => void = (): void => {
    const {
      users: { data }
    }: IPropsInterface = this.props;
    this.setState((state: IStateInterface) => ({
      selectAll: !state.selectAll,
      selectedRows: !state.selectAll ? data.map(({ id }: any) => id) : []
    }));
  };

  _setIdOnClick: any = (id: number) => {
    this.setState({ userId: id });
  };

  _renderStatuses: any = (status: number): JSX.Element | undefined => {
    const { statuses }: IPropsInterface = this.props;
    if (statuses && statuses.length) {
      const item: { id: number; name: string } | any = find(
        statuses,
        (o: { id: number }) => o.id === status
      );
      if (item) {
        switch (status) {
          case 0:
            return <Badge color="secondary">{item.name}</Badge>;
          case 1:
            return <Badge color="success">{item.name}</Badge>;
          case 2:
            return <Badge color="danger">{item.name}</Badge>;
          default:
            return;
        }
      }

      return;
    }
  };

  _renderResultRows = (): React.ReactNode => {
    const {
      users,
      idCurrentProfile,
      currentOperator
    }: IPropsInterface = this.props;
    const { selectedRows }: IStateInterface = this.state;

    if (get(users, "data")) {
      const { data: usersArr }: IUsers = users;
      return usersArr.map((item: ISingleUser) => {
        const {
          id,
          name,
          email,
          operator,
          role_id,
          status
        }: ISingleUser = item;

        return (
          <tr key={id}>
            <td>{name}</td>
            <td>{email}</td>
            {currentOperator === "SYS_ADMIN" && <td>{operator?.name}</td>}
            <td>{parseRolesIdToRolesName(role_id)}</td>
            <td>{this._renderStatuses(status)}</td>

            {generateColButtons({
              id,
              idCurrentProfile,
              selectedRows,
              onRedirectToDetails: this._onRedirectToDetails,
              toggleDeleteModal: this._toggleDeleteModal,
              onCheckboxClick: this._onCheckboxClick,
              setIdOnClick: this._setIdOnClick
            })}
          </tr>
        );
      });
    }
  };

  _renderTableFieldNames = (): Array<JSX.Element> => {
    const {
      onFilter,
      filtering,
      currentOperator
    }: IPropsInterface = this.props;

    const keys: Array<{ name: string; keyId: string }> = [
      {
        name: "Name",
        keyId: "name"
      },
      {
        name: "Email",
        keyId: "email"
      },
      {
        name: "Operator",
        keyId: "operator_id"
      },
      {
        name: "Role",
        keyId: "role_id"
      },
      {
        name: "Status",
        keyId: "status"
      }
    ];

    const validateKeys = keys.filter(item => {
      if (currentOperator !== "SYS_ADMIN" && item.keyId === "operator_id") {
        return false;
      }
      return item;
    });

    return renderTableFieldNames({ keys: validateKeys, onFilter, filtering });
  };
  _toggleDeleteModal: any = (): void => {
    const { showDeleteModal }: IStateInterface = this.state;
    this.setState({
      showDeleteModal: !showDeleteModal
    });
  };

  _resetIds = () => {
    const { userId, selectedRows } = this.state;
    if (userId) {
      this.setState({
        userId: null
      });
    } else if (selectedRows && selectedRows.length > 0) {
      this.setState({
        selectedRows: []
      });
    }
  };

  render(): JSX.Element {
    const {
      users,
      onShowForm,
      onDeleteUser,
      onDeleteUsers,
      responseError,
      toggleErrorResponse,
      roles,
      statuses,
      operators,
      currentOperator,
      responseItemMessage
    }: IPropsInterface = this.props;
    const {
      selectAll,
      selectedRows,
      userId,
      showDeleteModal,
      loading
    }: IStateInterface = this.state;

    const currentElementToDelete =
      users && users.data && users.data.find(el => el.id === userId);

    let modalMessage: string | JSX.Element =
      userId && selectedRows.length <= 1 ? (
        <>
          <strong>{currentElementToDelete?.name}</strong>
          <span> will be deleted. Please confirm action!</span>
        </>
      ) : (
        "Selected users will be deleted. Please confirm action!"
      );

    return (
      <div className={style.administratorsTableWrapper}>
        <Row>
          <div className="card-title col-12">
            <h1>
              <i className="material-icons face" />
              Users
            </h1>
            <ButtonToForm
              value="Create Users"
              click={onShowForm}
              disabled={localStorage.getItem("acr") === "OPR_EDITOR"}
            />
          </div>
        </Row>
        <Card>
          <CardBody>
            {responseItemMessage && <Alert>{responseItemMessage}</Alert>}
            <Formik
              initialValues={initialValues}
              onSubmit={this._onSubmit}
              render={({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                handleReset
              }: any): any => {
                return (
                  <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                    <Row className={style.filteringRow}>
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="name"
                        value={values.name}
                        id="name"
                        labelTitle="Name"
                        placeholder="Enter Name"
                        onBlur={handleBlur}
                        name="name"
                        errors={errors.name}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="email"
                        value={values.email}
                        id="email"
                        labelTitle="Email"
                        placeholder="Enter Email"
                        onBlur={handleBlur}
                        name="email"
                        errors={errors.email}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />
                      {currentOperator &&
                        (currentOperator === "SYS_OWNER" ||
                          currentOperator === "SYS_ADMIN") && (
                          <FormInputs
                            onChange={(event: any): any => {
                              handleChange(event);
                              setFieldValue("operators", event.target.value);
                            }}
                            htmlFor="operators"
                            value={values.operators}
                            id="operators"
                            labelTitle="Operators"
                            name="operators"
                            errors={errors.operators}
                            type="select"
                            option={
                              <option value="" label="Select Operators">
                                Select Operators
                              </option>
                            }
                            renderData={this._generateTypeOptions(operators)}
                            rowInputClassName={style.filteringInput}
                          />
                        )}
                      <FormInputs
                        onChange={(event: any): any => {
                          handleChange(event);
                          setFieldValue("role_id", event.target.value);
                        }}
                        htmlFor="role_id"
                        value={values.role_id}
                        id="role_id"
                        labelTitle="Role"
                        name="role_id"
                        errors={errors.role_id}
                        type="select"
                        option={
                          <option value="" label="Select Role">
                            Select Role
                          </option>
                        }
                        renderData={this._generateTypeOptions(roles)}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={(event: any): any => {
                          handleChange(event);
                          setFieldValue("status", event.target.value);
                        }}
                        htmlFor="status"
                        value={values.statuses}
                        id="statuses"
                        labelTitle="Status"
                        name="status"
                        errors={errors.statuses}
                        type="select"
                        option={
                          <option value="" label="Select Satus">
                            Select Satus
                          </option>
                        }
                        renderData={this._generateTypeOptions(statuses)}
                        rowInputClassName={style.filteringInput}
                      />
                      <div>
                        <Button
                          className={style.filteringButton}
                          type="submit"
                          color="primary"
                          size="md"
                        >
                          Apply filters
                        </Button>
                        <Button
                          className={style.filteringButton}
                          color="danger"
                          size="md"
                          type="reset"
                          onClick={() => {
                            handleReset(values);
                            handleSubmit();
                          }}
                        >
                          Reset filters
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            />
            <br />
            <Fragment>
              {users && get(users, "data") && get(users, "data").length > 0 ? (
                <AppTable
                  renderTableFieldNames={this._renderTableFieldNames}
                  renderResultRows={this._renderResultRows}
                  toggleDeleteModal={this._toggleDeleteModal}
                  selectAll={selectAll}
                  onSelectAll={this._onSelectAll}
                  selectedRows={selectedRows}
                  loading={loading}
                />
              ) : (
                <Alert color="danger">No results found</Alert>
              )}
              {users && renderPagination(users, this._onChange)}
            </Fragment>
          </CardBody>
        </Card>
        <DeleteModal
          selectedRows={selectedRows}
          onToggle={this._toggleDeleteModal}
          onDelete={onDeleteUser}
          onMultipleDelete={onDeleteUsers}
          id={userId}
          isOpen={showDeleteModal}
          modalTitle="Delete User"
          responseError={responseError}
          toggleResponseError={toggleErrorResponse}
          resetIds={this._resetIds}
        >
          {modalMessage}
        </DeleteModal>
      </div>
    );
  }
}

export default withRouter<any, any>(UserTable);
