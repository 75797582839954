import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ICreateRomInterface, ISingleRomInterface } from "common/interfaces";
import { getOperatingSystems } from "common/api/common";
import { get, find } from "lodash";
import { Row, Container, CardBody, Card, Spinner, Alert } from "reactstrap";
import moment from "moment";
import ModalComponent from "app-components/ModalComponent";
import style from "./RomsDetailsPage.module.scss";
import { deleteRom, getRomsByID, updateRom } from "common/api/roms";
import RomsForm from "app-components/Forms/RomsForm";
import DeleteModal from "app-components/ModalComponent/DeleteModal";
import {
  renderStatuses,
  ButtonToForm,
  showAndHideAlertItem
} from "common/helper/helper";
import Details from "app-components/Details";

interface IPropsInterface {
  match: any;
  history: { push(data: any): void };
}

interface IStateInterface {
  rom: ISingleRomInterface | null;
  statuses: Array<{ id: number; name: string }>;
  operatingSystems: Array<{ id: number; name: string }>;
  showForm: boolean;
  deleted: boolean | null;
  showDeleteModal: boolean;
  errorResponse: string | null;
  responseItemMessage: string | null;
}

class RomsDetailsPage extends Component<IPropsInterface, IStateInterface> {
  private timerHandle;
  private readonly showAndHideAlertItem;
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      rom: null,
      statuses: [
        {
          id: 0,
          name: "Inactive"
        },
        {
          id: 1,
          name: "Active"
        }
      ],
      operatingSystems: [],
      showForm: false,
      deleted: null,
      showDeleteModal: false,
      errorResponse: null,
      responseItemMessage: null
    };
    this.showAndHideAlertItem = showAndHideAlertItem.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    try {
      const operatingSystems: {
        data: { resource: { data: string } };
      } = await getOperatingSystems();
      if (get(operatingSystems, "data.resource.data")) {
        this.setState({
          operatingSystems: get(operatingSystems, "data.resource.data")
        });
      }

      const dataRomsId = await getRomsByID(ID);

      if (get(dataRomsId, "data.resource")) {
        this.setState({
          rom: get(dataRomsId, "data.resource")
        });
      } else if (get(dataRomsId, "data.error")) {
        const { message, status } = get(dataRomsId, "data");
        this.timerHandle = setTimeout(() => {
          this.props.history.push({
            pathname: "/error",
            state: { message, status }
          });
        }, 2000);
      }
    } catch (e) {
      // console.log(e);
    }
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  }

  _getRom: any = async () => {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;
    try {
      const result: {
        data: { resource: string };
      } = await getRomsByID(ID);

      if (result && get(result, "data.resource")) {
        this.setState({
          rom: get(result, "data.resource")
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _onShowForm: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = (): void => {
    this.setState(
      (state: IStateInterface) => ({
        showForm: !state.showForm
      }),
      (): void => {
        const { showForm }: IStateInterface = this.state;
        if (showForm) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  _onEditRom: any = async (
    data: ICreateRomInterface
  ): Promise<undefined | object | unknown> => {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    try {
      const result: { status: number } = await updateRom(ID, data);

      if (result && result.status === 200) {
        this._getRom();
        this.setState({
          showForm: false
        });
        this.showAndHideAlertItem(this.props, "ROM  Updated Successfully");

        return result;
      } else if (result && result.status !== 200) {
        return result;
      }
    } catch (err) {
      return err;
    }
  };

  _onDeleteRom: any = async (id: number): Promise<void> => {
    const result: { status: number; request: any } = await deleteRom(id);
    if (result && result.status === 204) {
      this.setState({
        deleted: true
      });
      setTimeout(() => {
        this.props.history.push({
          pathname: "/roms",
          state: { itemDeleted: true }
        });
      }, 2000);
    } else {
      this.setState({
        deleted: false,
        errorResponse: JSON.parse(result.request.response).error.message
      });
    }
  };

  _renderOperatingSystem: any = (operatingSystem: string) => {
    const { operatingSystems }: IStateInterface = this.state;

    if (operatingSystems && operatingSystems.length) {
      const item: { id: number; name: string } | undefined = find(
        operatingSystems,
        (o: { name: string; id: number }) => o.name === operatingSystem
      );
      if (item) {
        return item.name;
      }
    }
  };

  _renderData: any = (rom: ISingleRomInterface) => {
    const date = (date: any) => {
      const datetime: string = moment(parseInt(date) * 1000).format(
        "DD/MM/YYYY"
      );
      return datetime !== "Invalid date" ? <span>{datetime}</span> : null;
    };

    const showBoolean = (boolean: number | boolean | string) =>
      boolean ? "true" : "false";

    return [
      { name: "Status", value: renderStatuses(rom.status) },
      { name: "Operation System", value: rom.operation_system },
      { name: "Operation System Version", value: rom.operation_system_version },
      { name: "Build Number", value: rom.build_number },
      { name: "Kernel Version", value: rom.kernel_version },
      { name: "Baseband Version", value: rom.baseband_version },
      { name: "Relase Note", value: rom.relase_note },
      { name: "Scheduled Update", value: date(rom.release_date) },
      { name: "Package Path", value: rom.package_path },
      { name: "Recovery Path", value: rom.recovery_path },
      { name: "Ui Version", value: rom.ui_version },
      { name: "Cascade build link", value: rom?.cascade_link },
      { name: "Wipe Data", value: showBoolean(rom.wipe_data) },
      { name: "Force Update", value: showBoolean(rom.force_update) },
      { name: "Is Incremental", value: showBoolean(rom.is_incremental) },
      { name: "A/B", value: showBoolean(rom?.a_b) },
      { name: "Created By", value: rom?.created_by?.name },
      { name: "Updated By", value: rom?.updated_by?.name },
      { name: "Created At", value: date(rom.created_at) },
      { name: "Updated At", value: date(rom.updated_at) }
    ];
  };

  _renderBottomButtons: any = () => {
    return (
      <div className={style.buttonsWrapper}>
        <ButtonToForm click={this._onShowForm} value="Edit" />
        <ButtonToForm
          color="danger"
          click={this._toggleDeleteModal}
          value="Delete"
        />
      </div>
    );
  };

  _toggleDeleteModal: any = (): void => {
    const { showDeleteModal }: IStateInterface = this.state;
    this.setState({
      showDeleteModal: !showDeleteModal
    });
  };

  render(): JSX.Element {
    const {
      rom,
      showForm,
      statuses,
      showDeleteModal,
      operatingSystems,
      errorResponse,
      responseItemMessage
    }: IStateInterface = this.state;

    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    let modalMessage: string = ID
      ? "This ROM will be deleted. Please confirm action!"
      : "Selected ROMS will be deleted. Please confirm action!";

    if (!rom) {
      return (
        <div className="d-flex justify-content-center col-12 mt-5 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <main className={"main admin-details-page-wrapper in"}>
        <Container fluid>
          <Row>
            <div className="card-title col-12">
              <h1>
                <i className="material-icons android" />
                ROM » {rom.name}
              </h1>
              {this._renderBottomButtons()}
            </div>
          </Row>
          <Card>
            <CardBody>
              {responseItemMessage && <Alert>{responseItemMessage}</Alert>}
              <Details
                data={this._renderData(rom)}
                buildList={[
                  { name: "Whitelist", value: rom.whitelist },
                  { name: "Blacklist", value: rom.blacklist }
                ]}
              />
            </CardBody>
          </Card>
          <ModalComponent
            modalTitle="Edit ROM"
            isOpen={showForm}
            toggle={this._onShowForm}
          >
            <RomsForm
              data={rom}
              edit={true}
              statuses={statuses}
              operatingSystems={operatingSystems}
              title="Edit ROM"
              roms={rom}
              onShowForm={this._onShowForm}
              onEditRom={this._onEditRom}
            />
          </ModalComponent>
          <DeleteModal
            onToggle={this._toggleDeleteModal}
            onDelete={this._onDeleteRom}
            id={ID}
            isOpen={showDeleteModal}
            modalTitle="Delete ROM"
            responseError={errorResponse}
          >
            {modalMessage}
          </DeleteModal>
        </Container>
      </main>
    );
  }
}

export default withRouter<any, any>(RomsDetailsPage);
