import React, { useEffect, useState } from "react";
import { CardHeader, Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import style from "../../../app-components/Tables/AppTable.module.scss";
import TimeAgo from "react-timeago";
import { getRemoteAssistances } from "../../../common/api/remote-assistances";
import moment from "moment";
import {
  IRemoteAssistanceInterface,
  ISingleRemoteAssistanceInterface
} from "../../../common/interfaces";
import {
  renderPagination,
  generateColButtons
} from "../../../common/helper/helper";
interface IPropsInterface {
  buttonRemote: any;
  mac_address: string;
  history: any;
}

const RemoteSection = ({
  buttonRemote,
  mac_address,
  history
}: IPropsInterface) => {
  const [
    remoteAssistances,
    setRemoteAssistances
  ] = useState<IRemoteAssistanceInterface | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [filtering, setFiltering] = useState<{
    orderBy: string | null;
    direction?: string | null;
  }>({
    orderBy: null,
    direction: null
  });

  useEffect(() => {
    const fetchRemoteAssistances = async () => {
      await _getRemoteAssistances(pageNumber, mac_address);
    };
    fetchRemoteAssistances();
  }, [mac_address, pageNumber]);

  useEffect(() => {
    if (filtering.orderBy) {
      const { orderBy, direction } = filtering;
      const fetchRemoteAssistances = async () => {
        await _getRemoteAssistances(
          pageNumber,
          mac_address,
          orderBy,
          direction
        );
      };
      fetchRemoteAssistances();
    }
  }, [filtering, mac_address, pageNumber]);

  const _getRemoteAssistances = async (
    pageNumber: number | string = 1,
    mac_address: string,
    orderBy?: string | null,
    direction?: string | null
  ): Promise<void> => {
    try {
      const result = await getRemoteAssistances({
        current_page: pageNumber,
        orderBy,
        direction,
        mac_address
      });

      if (result && result?.data?.resource) {
        setRemoteAssistances(result?.data?.resource);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const _onFilter: any = (data: {
    orderBy: string;
    direction: string;
  }): void => {
    if (data) {
      const {
        orderBy,
        direction
      }: { orderBy: string; direction: string } = data;
      if (orderBy) {
        setFiltering({
          orderBy,
          direction: direction || null
        });
      }
    }
  };

  const _onRedirectToActionDetails: any = (id: number) => {
    history.push(`/remote-assistance/${id}`);
  };

  const _renderResultRows = (): React.ReactNode => {
    if (remoteAssistances) {
      const { data }: IRemoteAssistanceInterface = remoteAssistances;
      return data.map((item: ISingleRemoteAssistanceInterface) => {
        const {
          id,
          service_desk_id,
          created_at,
          closed,
          sequential_id
        }: ISingleRemoteAssistanceInterface = item;

        const datetime: string = moment(
          created_at ? parseInt(created_at) * 1000 : ""
        ).format("MM DD YYYY HH:mm");

        return (
          <tr key={id}>
            <td>{sequential_id}</td>
            <td>{service_desk_id}</td>
            <td>
              <TimeAgo date={datetime} live={false} />
            </td>
            <td>{closed ? "Closed" : "Open"}</td>
            {localStorage.getItem("acr") !== "OPR_VIEWER" &&
              generateColButtons({
                id,
                onRedirectToDetails: _onRedirectToActionDetails
              })}
          </tr>
        );
      });
    }
  };

  const _onChange: any = (numPage: number) => {
    setPageNumber(numPage);
    //history.push(`${pageNumber}`);
  };

  const renderTableFieldNames = () => {
    const keys: Array<{ name; id }> = [
      { name: "Session id", id: "sequential_id" },
      { name: "ServiceDesk id", id: "ServiceDesk id" },
      { name: "Created at", id: "Created at" },
      { name: "Status", id: "Closed" },
      { name: "Actions", id: "Actions" }
    ];
    return keys.map((item: any) => (
      <th
        style={{ textTransform: "capitalize", userSelect: "none" }}
        key={item.id}
        onClick={(): any => {
          item.id !== "Actions" &&
            _onFilter({
              orderBy: item.id,
              direction:
                filtering?.orderBy !== item.id
                  ? "asc"
                  : !filtering?.direction
                  ? "asc"
                  : filtering?.direction && filtering?.direction === "asc"
                  ? "desc"
                  : "asc"
            });
        }}
      >
        {item.name}
        {(filtering.orderBy &&
          filtering.orderBy === item.id &&
          filtering.direction &&
          filtering.direction === "asc" && (
            <i style={{ marginLeft: 5 }} className="fa fa-sort-desc" />
          )) ||
          (filtering.orderBy &&
            filtering.orderBy === item.id &&
            filtering.direction &&
            filtering.direction === "desc" && (
              <i style={{ marginLeft: 5 }} className="fa fa-sort-asc" />
            )) || (
            <i
              style={{
                marginLeft: 5,
                opacity: 0.4,
                display: item.id === "Actions" ? "none" : "inline"
              }}
              className="fa fa-sort"
            />
          )}
      </th>
    ));
  };
  return (
    <>
      <CardHeader
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <strong>Device Remote Assistance</strong>
        {buttonRemote()}
      </CardHeader>
      {remoteAssistances ? (
        <>
          <Table hover bordered striped responsive size="sm">
            <thead>
              <tr className={style.tableFieldsWrapper}>
                {renderTableFieldNames()}
              </tr>
              {_renderResultRows()}
            </thead>
          </Table>
          <div style={{ padding: 5 }}>
            {renderPagination(remoteAssistances, _onChange)}
          </div>
        </>
      ) : (
        "Not Available!!!"
      )}
    </>
  );
};
export default withRouter<any, any>(RemoteSection);
