import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  ICreateDeviceInterface,
  ISingleDeviceInterface
} from "../../common/interfaces";
import { get } from "lodash";
import { Row, Container, CardBody, Card, Spinner } from "reactstrap";
import ModalComponent from "../../app-components/ModalComponent";
import { getDeviceTypes } from "../../common/api/device_types";
import {
  deleteDevice,
  getDevicesByID,
  updateDevice,
  getDeviceStatus,
  getDeviceStatusV2
} from "../../common/api/devices";
import style from "./DevicesDetailsPage.module.scss";
import DeleteModal from "../../app-components/ModalComponent/DeleteModal";
import DevicesForm from "../../app-components/Forms/DevicesForm";
import RemoteAssistanceModal from "../../app-components/ModalComponent/RemoteAssistanceModal";
import TabsDevice from "./TabsDevice";
import { ButtonToForm } from "../../common/helper/helper";

interface IPropsInterface {
  match: any;
  history: { push(data: any): void };
}

interface IStateInterface {
  device: ISingleDeviceInterface | null;
  statuses: Array<{ id: number; name: string }>;
  deviceTypes: Array<{ id: number; name: string }>;
  showForm: boolean;
  deleted: boolean | null;
  showDeleteModal: boolean;
  errorResponse: string | null;
  showRemoteAssistanceModal: boolean;
  remoteAssistanceAuthorized: boolean;
  currentStatus: boolean;
  deviceStatus: object;
}

class DevicesDetailsPage extends Component<IPropsInterface, IStateInterface> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      device: null,
      statuses: [
        {
          id: 0,
          name: "Inactive"
        },
        {
          id: 1,
          name: "Active"
        }
      ],
      deviceTypes: [],
      showForm: false,
      deleted: null,
      showDeleteModal: false,
      errorResponse: null,
      showRemoteAssistanceModal: false,
      remoteAssistanceAuthorized: false,
      currentStatus: false,
      deviceStatus: {}
    };
  }

  async componentDidMount(): Promise<void> {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    try {
      const deviceTypes: {
        data: { resource: { data: string } };
      } = await getDeviceTypes(1, null, null, 10000);
      if (get(deviceTypes, "data.resource.data")) {
        this.setState({ deviceTypes: get(deviceTypes, "data.resource.data") });
      }

      const dataDevicesByID = await getDevicesByID(ID);
      if (get(dataDevicesByID, "data.resource")) {
        const resultStatusV2 = await getDeviceStatusV2(ID);

        const currentDeviceStatus = get(resultStatusV2, "data.resource");

        if (currentDeviceStatus) {
          this.setState({
            deviceStatus: currentDeviceStatus
          });
        } else {
          const resultStatus = await getDeviceStatus(
            get(dataDevicesByID, "data.resource.mac_address")
          );
          this.setState({
            deviceStatus: get(resultStatus, "data.resource.data[0]")
          });
        }
        this.setState({
          device: get(dataDevicesByID, "data.resource")
        });
      } else if (get(dataDevicesByID, "data.error")) {
        const { message, status } = get(dataDevicesByID, "data");
        setTimeout(() => {
          this.props.history.push({
            pathname: "/error",
            state: { message, status }
          });
        }, 2000);
      }
    } catch (e) {
      // console.log(e);
    }
  }

  _getDevice: any = async () => {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    try {
      const result: {
        data: { resource: string };
      } = await getDevicesByID(ID);

      if (result && get(result, "data.resource")) {
        this.setState({
          device: get(result, "data.resource")
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _onShowForm: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = (): void => {
    this.setState(
      (state: IStateInterface) => ({
        showForm: !state.showForm
      }),
      (): void => {
        const { showForm }: IStateInterface = this.state;
        if (showForm) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  _onEditDevice: any = async (
    data: ICreateDeviceInterface
  ): Promise<undefined | object | unknown> => {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    try {
      // if (get(data, "id") && get(data, "device_id")) {
      //   data.id = data.device_id;
      // }
      const result: { status: number } = await updateDevice(ID, data);
      if (result && result.status === 200) {
        this._getDevice();
        this.setState({
          showForm: false
        });

        return result;
      } else if (result && result.status === 400) {
        return result;
      }
    } catch (err) {
      return err;
    }
  };

  _onDeleteDevice: any = async (id: number): Promise<void> => {
    const result: { status: number; request: any } = await deleteDevice(id);
    if (result && result.status === 204) {
      this.setState({
        deleted: true
      });
      setTimeout(() => {
        this.props.history.push({
          pathname: "/devices",
          state: { itemDeleted: true }
        });
      }, 2000);
    } else {
      this.setState({
        deleted: false,
        errorResponse: JSON.parse(result.request.response).error.message
      });
    }
  };

  _renderData: any = device => {
    const { deviceTypes } = this.state;
    const {
      name,
      status,
      updated_at,
      serial_number,
      uid,
      provision_id,
      mac_address,
      operator_device_type_id
    } = device;

    const profileNameDevice = deviceTypes.find(
      device => device.id === operator_device_type_id
    );

    const objToRender = [
      {
        name: "Device Profile",
        value: profileNameDevice?.name
      },
      {
        name: "Name",
        value: name
      },
      {
        name: "Uid",
        value: uid
      },
      {
        name: "Mac Address",
        value: mac_address
      },
      {
        name: "Provision Id",
        value: provision_id
      },
      {
        name: "Serial Number",
        value: serial_number
      },
      {
        name: "Last Updated Time",
        value: updated_at
      },
      {
        name: "Status",
        value: status
      }
    ];

    return objToRender;
  };

  _renderBottomButtons: any = () => {
    const { device } = this.state;

    return (
      <div className={style.buttonsWrapper}>
        <ButtonToForm
          className="buttonPurple material-icons desktop_windows"
          color="purple"
          size="md"
          click={this._toggleRemoteAssistanceModal}
          disabled={device && !device?.status}
        />
      </div>
    );
  };

  _toggleDeleteModal: any = (): void => {
    const { showDeleteModal }: IStateInterface = this.state;
    this.setState({
      showDeleteModal: !showDeleteModal
    });
  };

  _toggleRemoteAssistanceModal: any = (): void => {
    const { showRemoteAssistanceModal }: IStateInterface = this.state;
    this.setState({
      showRemoteAssistanceModal: !showRemoteAssistanceModal
    });
  };

  render(): JSX.Element {
    const {
      device,
      showForm,
      statuses,
      deviceTypes,
      showDeleteModal,
      errorResponse,
      showRemoteAssistanceModal,
      remoteAssistanceAuthorized,
      deviceStatus
    }: IStateInterface = this.state;

    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    let modalMessage: string = ID
      ? "This device will be deleted. Please confirm action!"
      : "Selected devices will be deleted. Please confirm action!";

    if (!device) {
      return (
        <div className="d-flex justify-content-center col-12 mt-5 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <main className={"main admin-details-page-wrapper in"}>
        <Container fluid>
          <Row>
            <div className="card-title col-12">
              <h1>
                <i className="material-icons router" />
                Device » {device.name || "No name"}
              </h1>
            </div>
          </Row>
          <Card>
            <CardBody className={style.contentWrapper}>
              <TabsDevice
                loading={false}
                details={device}
                onShowForm={this._onShowForm}
                toggleDeleteModal={this._toggleDeleteModal}
                deviceTypes={deviceTypes}
                authorized={remoteAssistanceAuthorized}
                operatorDeviceId={ID}
                buttonRemote={this._renderBottomButtons}
                deviceStatus={deviceStatus}
              />
            </CardBody>
          </Card>

          <ModalComponent
            modalTitle="Edit Device"
            isOpen={showForm}
            toggle={this._onShowForm}
          >
            <DevicesForm
              data={device}
              edit={true}
              statuses={statuses}
              deviceTypes={deviceTypes}
              title="Edit Device"
              onShowForm={this._onShowForm}
              onEditDevice={this._onEditDevice}
            />
          </ModalComponent>
          <DeleteModal
            onToggle={this._toggleDeleteModal}
            onDelete={this._onDeleteDevice}
            id={ID}
            isOpen={showDeleteModal}
            modalTitle="Delete Device"
            responseError={errorResponse}
          >
            {modalMessage}
          </DeleteModal>
          {device?.operator_device_type_id && showRemoteAssistanceModal && (
            <RemoteAssistanceModal
              toggle={this._toggleRemoteAssistanceModal}
              authorized={remoteAssistanceAuthorized}
              operatorDeviceId={ID}
              isOpen={showRemoteAssistanceModal}
              operatorProfileId={device?.operator_device_type_id}
            />
          )}
        </Container>
      </main>
    );
  }
}

export default withRouter<any, any>(DevicesDetailsPage);
