import React from "react";
import { find, get } from "lodash";
import style from "./helper.module.scss";
import { Badge, Button, CustomInput } from "reactstrap";
import { Tooltip } from "antd";
import Pagination from "app-components/ui/Pagination";
import moment from "moment";

export const renderTableFieldNames = ({ onFilter, filtering, keys }): any => {
  return keys.map(item => {
    let { name, keyId } = item;
    if (!get(item, "name")) {
      name = item;
      keyId = item;
    }

    return (
      <th
        className={style.thTable}
        key={keyId}
        onClick={() => {
          if (!item?.noFilter) {
            onFilter({
              orderBy: keyId,
              direction:
                get(filtering, "orderBy") !== keyId
                  ? "asc"
                  : !get(filtering, "direction")
                  ? "asc"
                  : get(filtering, "direction") &&
                    get(filtering, "direction") === "asc"
                  ? "desc"
                  : "asc"
            });
          }
        }}
      >
        <div>
          {name}
          {!item?.noFilter &&
            ((get(filtering, "orderBy") &&
              get(filtering, "orderBy") === keyId &&
              get(filtering, "direction") &&
              get(filtering, "direction") === "asc" && (
                <i style={{ marginLeft: 5 }} className="fa fa-sort-desc" />
              )) ||
              (get(filtering, "orderBy") &&
                get(filtering, "orderBy") === keyId &&
                get(filtering, "direction") &&
                get(filtering, "direction") === "desc" && (
                  <i style={{ marginLeft: 5 }} className="fa fa-sort-asc" />
                )) || (
                <i
                  style={{ marginLeft: 5, opacity: 0.4 }}
                  className="fa fa-sort"
                />
              ))}
        </div>
      </th>
    );
  });
};

export const handleOrderBy = orderBy => {
  switch (orderBy) {
    case "Type":
      orderBy = "type_id";
      break;
    case "Last Activity":
      orderBy = "updated_at";
      break;
    case "Created at":
      orderBy = "created_at";
      break;
    case "Service Type":
      orderBy = "service_type_id";
      break;
    case "Operator":
      orderBy = "operator_id";
      break;
    case "Role":
      orderBy = "role_id";
      break;
    case "Operating System":
      orderBy = "operation_system";
      break;
    case "Build Number":
      orderBy = "build_number";
      break;
    case "Kernel Version":
      orderBy = "kernel_version";
      break;
    case "Baseband Version":
      orderBy = "baseband_version";
      break;
    case "UI Version":
      orderBy = "baseband_version";
      break;
    case "Forced Update":
      orderBy = "force_update";
      break;
    case "Release Date":
      orderBy = "created_at";
      break;
    case "Session id":
      orderBy = "id";
      break;
    case "Device id":
      orderBy = "operator_device_id";
      break;
    case "ServiceDesk id":
      orderBy = "service_desk_id";
      break;
    case "User id":
      orderBy = "service_desk_id";
      break;
    default:
      break;
  }

  return orderBy;
};

export const generateColButtons: any = ({
  id,
  selectedRows,
  idCurrentProfile,
  onDelete,
  onToggleRemoteAssistanceModal,
  setIdOnClick,
  onRedirectToDetails,
  toggleDeleteModal,
  onCheckboxClick,
  nameCountry = null,
  status,
  closed,
  remoteAssitance
}): JSX.Element => {
  const IS_NOT_VIEWER = localStorage.getItem("acr") !== "OPR_VIEWER";

  return (
    <td className={style.buttonsWrapper}>
      <div className={style.wrapActionButtons}>
        {onToggleRemoteAssistanceModal && IS_NOT_VIEWER && (
          <Tooltip placement="top" title="Start session">
            <Button
              className="material-icons desktop_windows buttonPurple"
              color="purple"
              size="sm"
              disabled={!status}
              onClick={(e: any): any => {
                setIdOnClick(id);

                //this is for starting new session on devices table
                onToggleRemoteAssistanceModal();
              }}
            />
          </Tooltip>
        )}
        <Tooltip placement="top" title="Info">
          <Button
            id="info"
            className="material-icons info_outline"
            color="primary"
            size="sm"
            disabled={!IS_NOT_VIEWER && !closed && remoteAssitance}
            onClick={(e: any): any => {
              e.stopPropagation();
              onRedirectToDetails(id, nameCountry);
            }}
          />
        </Tooltip>

        {setIdOnClick && IS_NOT_VIEWER && (
          <Tooltip placement="top" title="Delete">
            <Button
              className="material-icons delete_forever"
              color="danger"
              size="sm"
              onClick={(): any => {
                setIdOnClick(id);
                toggleDeleteModal();
              }}
            />
          </Tooltip>
        )}
        {selectedRows && IS_NOT_VIEWER && (
          <CustomInput
            type="checkbox"
            id={id.toString()}
            required
            checked={selectedRows.includes(id)}
            onChange={onCheckboxClick}
          />
        )}
      </div>
    </td>
  );
};

export const renderEmtpyCells = (numberOfCells: number): React.ReactNode => {
  return Array.apply(
    null,
    Array(numberOfCells)
  ).map((item: any, index: any) => <td key={index} />);
};

export const renderPagination: any = (users, onChange): JSX.Element => {
  return (
    <div className={style.bottomPaginationWrapper}>
      <div className={style.total}>
        <p>
          <strong>{users.from}</strong> -
        </p>
        <p>
          <strong>{users.to}</strong>
        </p>
        <p>
          <span>&nbsp;of </span>
          <strong>{users.total}</strong>
        </p>
      </div>
      <Pagination
        onChange={onChange}
        current={Number(users.current_page)}
        total={users.total}
        defaultPageSize={Number(users.per_page)}
      />
    </div>
  );
};

export const renderStatuses: any = (
  status: number | string
): JSX.Element | undefined => {
  const numberStatus = Number(status);
  const statuses = [
    {
      id: 0,
      name: "Inactive"
    },
    {
      id: 1,
      name: "Active"
    }
  ];

  const item = find(
    statuses,
    (o: { id: number; name: string }) => o.id === numberStatus
  );
  if (item) {
    switch (numberStatus) {
      case 0:
        return <Badge color="secondary">{item.name}</Badge>;
      case 1:
        return <Badge color="success">{item.name}</Badge>;
      case 2:
        return <Badge color="danger">{item.name}</Badge>;
      default:
        return;
    }
  }

  return;
};

export const generateDropDownList: Function = (
  dropDownData: Array<{ name: string; id: number }>
) => {
  return dropDownData.map((item: { id: number; name: string }) => (
    <option value={item.id} key={item.id}>
      {item.name}
    </option>
  ));
};

export const getRoles = (): Array<{ id: string; name: string }> => {
  const roles = [
    { id: "SYS_ADMIN", name: "System Admin" },
    { id: "SYS_OWNER", name: "System Owner" },
    { id: "OPR_OWNER", name: "Operator Owner" },
    { id: "OPR_ADMIN", name: "Operator Admin" },
    { id: "OPR_VIEWER", name: "Operator Viewer" },
    { id: "OPR_SUPPORT_LINE_1", name: "Operator Suporte Line 1" },
    { id: "OPR_SUPPORT_LINE_2", name: "Operator Suporte Line 2" },
    { id: "OPR_EDITOR", name: "Operator Editor" }
  ];

  return roles;
};

export const parseRolesIdToRolesName = (
  nameRole: string | number = ""
): string => {
  const roles = [...getRoles()];

  return (
    get(
      roles.find(item => nameRole === item.id),
      "name"
    ) || ""
  );
};

export const generateRoles: Function = (): any => {
  const roles = [
    { id: 0, name: "System User" },
    { id: 1, name: "Operator Administrator" },
    { id: 2, name: "Operator Moderator" },
    { id: 3, name: "Operator Viewer" },
    { id: 4, name: "Operator Suporte Line 1" },
    { id: 5, name: "Operator Suporte Line 2" }
  ];

  roles.map((item: { id: number; name: string }) => {
    return (
      <option value={item.id} key={item.id}>
        {item.name}
      </option>
    );
  });
};

export const showAndHideAlertItem = function(
  this: any,
  props: { history: any },
  message = "Item successfully deleted"
) {
  const {
    history: { location },
    history
  } = props;

  this.setState(
    {
      responseItemMessage: message
    },
    () => {
      window.scrollTo(0, 0);
      setTimeout(() => {
        if (get(location, "state.itemDeleted")) {
          const urlRedirect = get(location, "pathname") || "/";
          // @ts-ignore
          history.push(urlRedirect);
        }
        // @ts-ignore
        this.setState({
          responseItemMessage: null
        });
      }, 3000);
    }
  );
};

export const parseDetailsData = ({ name, value }) => {
  const nameLower = name.toLowerCase();
  if (nameLower === "status") {
    return renderStatuses(value);
  } else if (
    nameLower === "created at" ||
    nameLower === "updated at" ||
    nameLower === "release date"
  ) {
    return (
      moment(parseInt(value) * 1000).format("DD/MM/YYYY") || "Invalid Time"
    );
  }

  return value;
};

export const renderPageDetailsBox: any = obj => {
  return (
    <div className={style.detailRow}>
      {obj.map((item, index) => (
        <p key={index + item.name}>
          <strong>{item.name}</strong>
          <span>{parseDetailsData(item)}</span>
        </p>
      ))}
    </div>
  );
};

export const ButtonToForm = ({
  value,
  click,
  color = "primary",
  disabled,
  className = ""
}: any) => {
  const IS_NOT_VIEWER = localStorage.getItem("acr") !== "OPR_VIEWER";

  return IS_NOT_VIEWER ? (
    <Tooltip placement="top" title={value}>
      <Button
        color={color || "primary"}
        size="md"
        onClick={click}
        disabled={disabled}
        className={className}
      >
        {value}
      </Button>
    </Tooltip>
  ) : (
    <div />
  );
};

export const releaseCycle = [
  { id: 5, name: "Release" },
  { id: 4, name: "Release Candidate" },
  { id: 3, name: "Beta" },
  { id: 2, name: "Alpha" },
  { id: 1, name: "Quality Assurance" },
  { id: 0, name: "Development" }
];

export const timeAgo = (date: any) => {
  const datetime: string = moment(parseInt(date) * 1000).format("DD/MM/YYYY");
  return datetime !== "Invalid date" ? <span>{datetime}</span> : null;
};

export const parseKeyToCapitalize = (key: string): string => {
  if (key) {
    let i: number;
    let frags: any = key.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  } else {
    return key;
  }
};
