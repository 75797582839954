import React, { PureComponent } from "react";
import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import { validate } from "../../common/validation";
import { withRouter } from "react-router-dom";
import style from "./Recovery.module.scss";
import {
  doRecovery
} from "../../common/api/recovery";
import { AppNavbarBrand } from "@coreui/react";
import logo from "../../assets/img/brand/wbc-white.svg";
import background from "../../assets/img/brand/background.png";
import shapeRed from "../../assets/img/brand/shape-red.svg";
import shapeYellow from "../../assets/img/brand/shape-yellow.svg";

const initialValues = {
  apiKey: "",
  macAddress: "",
  serialNumber: ""
};

const validationSchema = function (values) {
  return Yup.object().shape({
    apiKey: Yup.string()
      .required("Api Key is required"),
    macAddress: Yup.string().required("Mac address is required"),
    serialNumber: Yup.string().required("Serial number is required")
  });
};

interface IPropsInterface {
  history: { push(data: string): void };
  location: any;
}

interface IStateInterface {
  apiKey: string | null;
  macAddress: string | null;
  serialNumber: string | null;
  loading: boolean;
  loadingBeforeFetch: boolean;
  errorMessage: any;
  successMessage: string | null;
}

class RecoveryPage extends PureComponent<IPropsInterface, IStateInterface> {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: null,
      macAddress: null,
      serialNumber: null,
      loading: false,
      errorMessage: null,
      successMessage: null,
      loadingBeforeFetch: true,
    };
  }

  async UNSAFE_componentWillMount() {
    this.setState({ loadingBeforeFetch: false });
  }

  _onSubmit = async (values) => {
    this.setState({
      loadingBeforeFetch: true,
    });

    if (values && values.apiKey) {
      const result = await doRecovery(values);

      if (result && result.status === 200) {
        this.setState({
          successMessage: get(result, "data.resource.recovery_path"),
          loadingBeforeFetch: false
        });
      } else {
        this.setState({
          errorMessage: get(result, "response.data.error.message"),
          loadingBeforeFetch: false
        });
      }
    }
  };

  render() {
    const { errorMessage, successMessage, loadingBeforeFetch } = this.state;

    return (
      <div className={`app flex-row align-items-center ${style.recoveryBackground}`} style={{ backgroundImage: `url('${background}')` }}>
        <Container>
          <AppNavbarBrand
            full={{ src: logo, width: 340, alt: "WBC LOGO" }}
            style={{ cursor: "pointer" }}
            className={style.logoImg}
          />
          <Row className="justify-content-center">
            <Col xs={12} sm={8} md={6} lg={5}>
              <CardGroup md={6}>
                <Card className={`p-6 ${style.recoveryCard}`}>
                  <CardBody>
                    {loadingBeforeFetch && (
                      <div>
                        <div className={style.recoveryAlert}>
                          CHECKING FOR UPDATES
                        </div>
                        <Spinner color="primary" />
                      </div>
                    )}
                    {errorMessage && (
                      <div>
                        <div className={style.recoveryAlert}>
                          {errorMessage.toUpperCase()}
                        </div>
                        <div className={style.recoveryAlert}>
                          <img src={shapeRed} alt="" />
                        </div>
                        <button className={style.recoveryButton} type="reset"
                          onClick={() => {
                            this.setState({
                              errorMessage: null,
                              successMessage: null,
                            });
                          }}>
                          RETRY
                        </button>
                      </div>
                    )}
                    {successMessage && (
                      <div>
                        <div className={style.recoveryAlert}>
                          UPDATE FOUND
                        </div>
                        <div className={style.recoveryAlert}>
                          <img src={shapeYellow} alt="" />
                        </div>
                        <button className={style.recoveryButton} type="reset"
                          onClick={() => {
                            this.setState({
                              errorMessage: null,
                              successMessage: null,
                            });
                          }}>
                          GO BACK
                        </button>
                        <button className={style.recoveryButton} type="reset"
                          onClick={() => {
                            window.location.href = successMessage;
                            this.setState({
                              errorMessage: null,
                              successMessage: null,
                            });
                          }}>
                          DOWNLOAD
                        </button>
                      </div>
                    )}
                    {!errorMessage && !successMessage && !loadingBeforeFetch && (
                      <Formik
                        initialValues={initialValues}
                        onSubmit={this._onSubmit}
                        render={({
                          values,
                          errors,
                          touched,
                          status,
                          dirty,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <Form
                            onSubmit={handleSubmit}
                            validate={validate(validationSchema)}
                            name="simpleForm"
                          >
                            <h2 className={style.formTitle}>DEVICE UPDATE</h2>
                            <div className={style.recoveryGroup}>
                              <Label htmlFor="apiKey">Operator public API key</Label>
                              <Input
                                type="text"
                                id="apiKey"
                                name="apiKey"
                                placeholder="a4db08b7-5729-4ba9-8c08-f2df493465a1"
                                invalid={!!errors.apiKey}
                                required
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.apiKey}
                              />
                            </div>

                            <div className={style.recoveryGroup}>
                              <Label htmlFor="macAddress">Mac Address</Label>
                              <Input
                                type="text"
                                id="macAddress"
                                name="macAddress"
                                placeholder="00FF00FF00FF"
                                invalid={!!errors.macAddress}
                                required={!values.serialNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.macAddress}
                              />
                            </div>

                            <div className={style.recoveryGroup}>
                              <Label htmlFor="serialNumber">Serial Number</Label>
                              <Input
                                type="text"
                                id="serialNumber"
                                name="serialNumber"
                                placeholder="00000000"
                                invalid={!!errors.serialNumber}
                                required={!values.macAddress}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.serialNumber}
                              />
                            </div>


                            <Row>
                              <Col xs="12" className="text-center">
                                <button className={style.recoveryButton} type="submit">
                                  CHECK FOR UPDATES
                                  </button>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      />
                    )}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter<any, any>(RecoveryPage);
