import React from "react";
import { Component } from "react";
import Navbar from "../../app-components/Navbar";
import navigation from "../../_sidebarNav";
import { connect } from "react-redux";
import { AppHeader, AppSidebar, AppSidebarNav } from "@coreui/react";
import style from "./Layout.module.scss";
import { wbcVersion } from "API_routes";
import { uiVersion } from "config";

interface IPropsInterface {
  currentUser: any;
}

class AppLayout extends Component<IPropsInterface, any> {
  private list;
  constructor(props) {
    super(props);
    this.state = {
      navItems: {
        items: []
      }
    };
  }

  componentDidMount() {
    const { currentUser } = this.props;
    this.list = React.createRef();

    const currentUserRole = currentUser
      ? currentUser
      : localStorage.getItem("acr");
    if (currentUserRole) {
      const navItemsForUser = navigation.items.filter(item =>
        item.allowedRoles.includes(currentUserRole)
      );
      this.setState({
        navItems: {
          items: navItemsForUser
        }
      });
    }
  }

  public render() {
    const { currentUser } = this.props;
    const currentUserRole = currentUser
      ? currentUser
      : localStorage.getItem("acr");
    return (
      <div>
        <AppHeader fixed>
          <Navbar numItems={this.state.navItems.items?.length} />
        </AppHeader>
        <div className="app-body" ref={this.list}>
          {currentUserRole && !currentUserRole.includes("OPR_SUPPORT_LINE") && (
            <AppSidebar fixed display="lg">
              <AppSidebarNav navConfig={this.state.navItems} />
              <span className={style.Version}>UI Version: {uiVersion}</span>
              <span className={style.Version}>WeBoxControl: {wbcVersion}</span>
            </AppSidebar>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    doRedirect: state.auth.doRedirect,
    roles: state.roles.roles,
    currentUser: state.auth.currentUser?.roles[0]
  };
};

export default connect(mapStateToProps, null)(AppLayout);
