import React, { useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import { Container, Row, Col, Button } from "reactstrap";

// interface IPropsInterface {
//   status: any;
//   message: { push (data: any): void };
// }

const ErrorPage = ({
  history: {
    location: { state },
  },
  history,
}) => {
  useEffect(() => {
    let redirect;
    if (history) {
      redirect = setTimeout(() => {
        history.push("/");
      }, 10000);
    }

    return () => {
      clearTimeout(redirect);
    };
  }, [history]);

  return (
    <div
      className="app flex-row align-items-center"
      style={{ height: "85vh", minHeight: "85vh" }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="clearfix">
              <h1 className="float-left display-2 mr-4">
                {state ? state.status : "404"}
              </h1>
              <h4 className="pt-3">Oops! You're lost.</h4>
              <p className="text-muted float-left">
                {state
                  ? state.message
                  : "The page you are looking for was not found"}
              </p>
              <Link to="/">
                <Button color="primary" size="sm" className="float-right">
                  Back to home
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter<any, any>(ErrorPage);
