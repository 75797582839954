import React, { useRef } from "react";
import classNames from "classnames";
import style from "./ScreenRemote.module.scss";
import { Card, Button, UncontrolledTooltip, Spinner } from "reactstrap";

interface IPropsInterface {
  onLock(): void;
  onUnlock(): void;
  onDisplayOn(): void;
  onDisplayOff(): void;
  onReboot(): void;
  onForceUpdate(): void;
  onShowScreenshotModal(): void;
  onLogRequest(): void;
  onScreenshot(): void;
  screenshot: any;
  loadingImg: boolean;
  remoteAssistance: any;
  remoteAssistanceClosed: any;
  onScreenshotError(): void;
  noErrorLoadImg(): any;
  displayStatus: string;
  currentUser: string;
}

const ScreenRemote = ({
  onLock,
  onUnlock,
  onDisplayOn,
  onDisplayOff,
  onReboot,
  onForceUpdate,
  onShowScreenshotModal,
  onLogRequest,
  onScreenshot,
  screenshot,
  loadingImg,
  remoteAssistance,
  remoteAssistanceClosed,
  onScreenshotError,
  noErrorLoadImg,
  displayStatus,
  currentUser
}: IPropsInterface) => {
  const buttonDisplayOff = useRef(null);
  const buttonDisplayOn = useRef(null);
  return (
    <Card
      color="remote"
      className={classNames(style.screenshotCard, style.wrapImg)}
    >
      <div className={classNames(style.WrapHelpersButtons, style.Top)}>
        <Button
          className={style.buttonStyle}
          color="remote-2"
          size="md"
          onClick={onLock}
          id="lock"
          disabled={remoteAssistance && remoteAssistanceClosed ? true : false}
        >
          <i className="material-icons">lock</i>
          <UncontrolledTooltip target="lock">Lock</UncontrolledTooltip>
        </Button>

        <Button
          className={style.buttonStyle}
          color="remote-2"
          size="md"
          onClick={onUnlock}
          id="onUnlock"
          disabled={remoteAssistance && remoteAssistanceClosed ? true : false}
        >
          <i className="material-icons">lock_open</i>
          <UncontrolledTooltip target="onUnlock">Unlock</UncontrolledTooltip>
        </Button>
        <Button
          className={style.buttonStyle}
          color="remote-2"
          style={{ display: displayStatus === "0" ? "flex" : "none" }}
          size="md"
          onClick={onDisplayOn}
          id="onDisplayOn"
          disabled={remoteAssistance && remoteAssistanceClosed ? true : false}
          ref={buttonDisplayOn}
        >
          <i className="material-icons">phonelink</i>
        </Button>
        <Button
          style={{ display: displayStatus === "0" ? "none" : "flex" }}
          className={style.buttonStyle}
          color="remote-2"
          size="md"
          onClick={onDisplayOff}
          id="onDisplayOff"
          disabled={remoteAssistance && remoteAssistanceClosed ? true : false}
          ref={buttonDisplayOff}
        >
          <i className="material-icons">phonelink_off</i>
        </Button>

        <UncontrolledTooltip target="onDisplayOff">
          Turn display Off
        </UncontrolledTooltip>
        <UncontrolledTooltip target="onDisplayOn">
          Turn display On
        </UncontrolledTooltip>

        <Button
          className={style.buttonStyle}
          size="md"
          onClick={onReboot}
          id="onReboot"
          disabled={remoteAssistance && remoteAssistanceClosed ? true : false}
        >
          <i className="material-icons">settings_backup_restore</i>
          <UncontrolledTooltip target="onReboot">Reboot</UncontrolledTooltip>
        </Button>

        <Button
          className={style.buttonStyle}
          size="md"
          onClick={onForceUpdate}
          id="onForceUpdate"
          disabled={remoteAssistance && remoteAssistanceClosed ? true : false}
        >
          <i className="material-icons">system_update</i>
          <UncontrolledTooltip target="onForceUpdate">
            Force Update
          </UncontrolledTooltip>
        </Button>
      </div>
      <div className={style.ImgWrap}>
        <img
          onClick={onShowScreenshotModal}
          src={screenshot.src}
          onError={onScreenshotError}
          alt="screenshot"
          onLoad={noErrorLoadImg}
          style={{ opacity: displayStatus === "0" ? "0.2" : "1" }}
        />
        {loadingImg && (
          <div className={style.SpinnerFloater}>
            <Spinner
              style={{
                borderWidth: "0.55em",
                width: "4rem",
                height: "4rem"
              }}
              color="white"
            />
          </div>
        )}
      </div>

      <div className={style.WrapHelpersButtons}>
        <Button
          className={style.buttonStyle}
          size="md"
          href={screenshot.src}
          id="adb"
          disabled={true}
          style={{
            display: currentUser === "OPR_SUPPORT_LINE_1" ? "none" : "flex"
          }}
        >
          <i className="material-icons">adb</i>
          <UncontrolledTooltip target="adb">
            Send command to ADB
          </UncontrolledTooltip>
        </Button>
        <Button
          className={style.buttonStyle}
          size="md"
          href={screenshot.src}
          id="shell"
          disabled={true}
          style={{
            display: currentUser === "OPR_SUPPORT_LINE_1" ? "none" : "flex"
          }}
        >
          <i className="material-icons">settings_ethernet</i>
          <UncontrolledTooltip target="shell">
            Send command to SHELL
          </UncontrolledTooltip>
        </Button>
        <Button
          onClick={onLogRequest}
          rel="noopener noreferrer"
          download
          className={style.buttonStyle}
          size="md"
          id="savelog"
          disabled={screenshot.src.indexOf("placeholder") > 0}
        >
          <i className="material-icons">cloud_download</i>
          <UncontrolledTooltip target="savelog">Save Log</UncontrolledTooltip>
        </Button>
        <Button
          className={style.buttonStyle}
          size="md"
          onClick={onScreenshot}
          id="onScreenshot"
          disabled={remoteAssistance && remoteAssistanceClosed ? true : false}
        >
          <i className="material-icons">loop</i>
          <UncontrolledTooltip target="onScreenshot">
            Refresh
          </UncontrolledTooltip>
        </Button>
        <Button
          className={style.buttonStyle}
          size="md"
          href={screenshot.src}
          download="screenshotSTB.jpg"
          target="_blank"
          id="screenshotSTB"
        >
          <i className="material-icons">save</i>
          <UncontrolledTooltip target="screenshotSTB">
            Save Image
          </UncontrolledTooltip>
        </Button>
      </div>
    </Card>
  );
};

export default ScreenRemote;
