import React from "react";
import {
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";
import style from "./FloatingAntd.module.scss";

interface IPropsInterface {
  screenshot: any;
  onError(): void;
  loading: boolean;
  deviceStatus: any | null;
  closeModal: any;
  onScreenshot(): void;
  disabled: boolean;
}

const FloatingAntd = ({
  screenshot,
  onError,
  loading,
  deviceStatus,
  closeModal,
  disabled,
  onScreenshot
}: IPropsInterface) => {
  return (
    <div className={style.Floating}>
      <Card color="remote" className="wrap">
        <CardBody>
          <Button
            onClick={closeModal}
            className={style.BtnClose}
            size="sm"
            id="close"
          >
            <i className="material-icons">close</i>
            <UncontrolledTooltip target="close">Close</UncontrolledTooltip>
          </Button>
          <div className={style.wrapImageFloaring}>
            <img src={screenshot.src} onError={onError} alt="screenshot" />
            {loading && deviceStatus && (
              <div className={style.SpinnerFloater}>
                <Spinner color="primary" />
              </div>
            )}
          </div>
          <div className={style.wrapButtonsFloater}>
            <Button
              color="remote-2"
              onClick={onScreenshot}
              id="reloadImgFloating"
              disabled={disabled}
            >
              <i className="material-icons">loop</i>
              <UncontrolledTooltip target="reloadImgFloating">
                Reload
              </UncontrolledTooltip>
            </Button>
            <Button
              color="remote-2"
              size="md"
              href={screenshot.src}
              download="screenshotSTB.jpg"
              target="_blank"
              id="saveImgFloating"
            >
              <i className="material-icons">save</i>
              <UncontrolledTooltip target="saveImgFloating">
                Save Image
              </UncontrolledTooltip>
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default FloatingAntd;
