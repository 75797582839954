import { AUTH_BASE_URL, AUTHORIZATION_URL } from "../../API_routes";
import axios from "axios";
import axiosInstance from "../axiosInstance";
import { get } from "lodash";

const apiPrefix: string = `${AUTH_BASE_URL}/auth`;

interface ILogin {
  email: string;
  password: string;
}

export interface IResetPassword {
  password: string;
  password_confirmation: string;
  token: string;
}

interface IAuthToken {
  data: {
    resource: {
      access_token: string;
      refresh_token: string;
      acr: string | null;
    };
  };
  status?: number;
}

export const loginFunction = async ({ url, email, password }) => {
  if (url && email && password) {
    const encodedCredentials = btoa(`${email}:${password}`);

    return axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: `Basic ${encodedCredentials}`,
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      )
      .then(response => {
        return response;
      })
      .catch(err => {
        throw err;
      });
  }
};

export const getRbac = async () => {
  try {
    const result: { status: number } = await axiosInstance.get(
      `${AUTHORIZATION_URL}`
    );

    if (result && result.status === 200) {
      return result;
    }
  } catch (err) {
    return err;
  }
};

export const saveAuthToken: Function = (result: IAuthToken): void => {
  const {
    data: {
      resource: { access_token, refresh_token, acr }
    }
  }: IAuthToken = result;

  localStorage.clear();

  localStorage.setItem("access_token", access_token);
  acr && localStorage.setItem("acr", acr);
  localStorage.setItem("refresh_token", refresh_token);
};

export const doLogin: Function = async ({
  email,
  password
}: ILogin): Promise<IAuthToken | Error | void> => {
  try {
    const result: IAuthToken = await axios.post(
      `${apiPrefix}/login`,
      {},
      { headers: { Authorization: `Basic ${btoa(`${email}:${password}`)}` } }
    );

    if (result.status === 200) {
      saveAuthToken(result);

      return result;
    }
  } catch (err) {
    return err;
  }
};

export const forgotPassword: Function = async ({
  email
}: {
  email: string;
}): Promise<{ status: number } | Error | string | void> => {
  try {
    const result: {
      status: number;
    } = await axios.put(`${apiPrefix}/password/forgot`, { email });
    if (result && result.status === 200) {
      return result;
    }
  } catch (err) {
    if (get(err, "response.data.message")) {
      return get(err, "response.data");
    }

    return err;
  }
};

export const resetPassword: Function = async ({
  password,
  password_confirmation,
  token
}: IResetPassword): Promise<any> => {
  try {
    const result: { status: number } = await axios.put(
      `${apiPrefix}/password/reset`,
      {
        password,
        password_confirmation
        // token
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (result && result.status === 200) {
      return result;
    }
  } catch (err) {
    return err;
  }
};

export const unlockAccount: Function = async (
  token: string
): Promise<{ status: number } | Error | void> => {
  try {
    const result: { status: number } = await axios.put(
      `${apiPrefix}/unlock`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (result && result.status === 200) {
      return result;
    }
  } catch (err) {
    return err;
  }
};

export const checkAuthorization: Function = async (): Promise<any> => {
  try {
    const result: { status: number } = await axiosInstance.get(
      `${apiPrefix}/check-authentication`
    );

    if (result && result.status === 200) {
      return result;
    }
  } catch (err) {
    return err;
  }
};

export const doRefreshToken: Function = async (
  onSuccess: Function
): Promise<Function | Error | undefined> => {
  try {
    const result: { status: number } = await axios.post(
      `${apiPrefix}/refresh-token`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("refresh_token")}`
        }
      }
    );

    if (result.status === 200) {
      saveAuthToken(result);

      if (onSuccess && typeof onSuccess === "function") {
        return onSuccess();
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      document.location.reload();
    }
  }
};

export const getUserProfile: any = (): any =>
  axiosInstance.get(`${apiPrefix}/profile`);

export const doLogout: any = async (): Promise<
  { status: number } | Error | void
> => {
  try {
    const result: { status: number } = await axiosInstance.get(
      `${apiPrefix}/logout`
    );

    if (result && result.status === 200) {
      localStorage.clear();
      document.location.reload();
    }
  } catch (err) {
    // console.log(err);
    // return err;
  }
};

export const doLoginAccessCode: Function = async (
  accessCode
): Promise<IAuthToken | Error | void> => {
  try {
    const result: IAuthToken = await axios.post(
      `${apiPrefix}/login/${accessCode}`
    );
    if (result.status === 200) {
      saveAuthToken(result);
      return result;
    }
  } catch (err) {
    return err;
  }
};

export const loginOptions: Function = async (
  accessCode
): Promise<IAuthToken | Error | void> => {
  try {
    const result: IAuthToken = await axios.get(
      `${AUTH_BASE_URL}/public/login/options`
    );
    if (result.status === 200) {
      //saveAuthToken(result);
      return result;
    }
  } catch (err) {
    return err;
  }
};
