import axios from "../axiosInstance";
import { CORE_API_DOMAIN } from "../../API_routes";
import { AUTH_BASE_URL } from "../../API_routes";
import { get } from "lodash";

export const getStatuses: any = (model?: string, id?: number | string): any =>
  axios.get(
    `${CORE_API_DOMAIN}/column-statuses/${model ? model : "Model"}/status${
      id ? `/${id}` : ""
    }`
  );

export const getRoles: any = (): any =>
  axios.get(`${AUTH_BASE_URL}/list/roles`);

export const getDefaultRoles: any = (): any =>
  axios.get(`${AUTH_BASE_URL}/list/roles`)
    .then(resp => {
      resp.data.resource = resp.data.resource.filter(item => item['id'] !== 'SYS_ADMIN');
      return resp;
    });

export const getOperatingSystems: any = (id?: number): any =>
  axios
    .get(`${CORE_API_DOMAIN}/column-statuses/OperatorRom/operating_systems`)
    .then(resp => {
      if (get(resp, "data.resource")) {
        return resp;
        //console.log("resp.data.resource", resp.data.resource);
      } else {
        //  setTimeout(() => {
        //    return getOperatingSystems();
        //  }, 2000);
      }
    });

export const getPlaybackSupports: any = (): any =>
  axios
    .get(
      `${CORE_API_DOMAIN}/column-statuses/OperatorDeviceType/playback_supports`
    )
    .then(resp => {
      if (get(resp, "data.resource")) {
        return get(resp, "data.resource");
        //console.log("resp.data.resource", resp.data.resource);
      }
    });

export const getStatics: any = (): any =>
  axios.get(`${CORE_API_DOMAIN}/dashboard/statistics`);

export const getUsers: any = (): any =>
  axios.get(`${AUTH_BASE_URL}/dashboard/statistics`);

export const getDeviceLogs: any = (deviceId: number): any =>
  axios.get(`${CORE_API_DOMAIN}/device-logs/${deviceId}`);

export const getDashboardProfiles: any = (): any =>
  axios.get(`${CORE_API_DOMAIN}/dashboard/deviceProfiles`);
export const getDashboardControlVersion: any = (): any =>
  axios.get(`${CORE_API_DOMAIN}/dashboard/deviceControlVersion`);
export const getDashboardRom: any = (): any =>
  axios.get(`${CORE_API_DOMAIN}/dashboard/rom`);
