import React, { useState, memo } from "react";
import {
  CardBody,
  FormGroup,
  Label,
  Input,
  Col,
  CardFooter,
  Button,
  Spinner,
} from "reactstrap";
import style from "../OperatorConfigPage.module.scss";
import { Switch } from "antd";

interface IPropsInterface {
  values: any;
  handleChange: any;
  onReset(values: any): any;
  loading: boolean;
}

const PublicAPI = ({
  values,
  handleChange,
  onReset,
  loading,
}: IPropsInterface) => {
  const [status, setStatus] = useState(values.status);
  return (
    <div className={style.contentWrapper}>
      <CardBody>
        <FormGroup row>
          <Col xs="4">
            <Label htmlFor="apkPath">Enable</Label>
          </Col>
          <Col xs="8" md="8">
            <Switch
              style={{ float: "right" }}
              size="small"
              defaultChecked={values.status}
              onChange={(checked: boolean) => {
                setStatus(checked);
                if (checked && !values.key) {
                  onReset(values);
                }
                handleChange({
                  status: checked,
                });
              }}
            />
          </Col>
        </FormGroup>
        {status && (
          <FormGroup row>
            <Col md="12">
              <Label htmlFor="reportInterval">API key</Label>
              {loading ? (
                <div className="d-flex justify-content-center m-5 in">
                  <Spinner color="primary" />
                </div>
              ) : (
                <Input
                  type="textarea"
                  style={{ height: values.key && "170px" }}
                  id="key"
                  name="key"
                  placeholder="API key"
                  value={values.key ? values.key : ""}
                  onChange={handleChange}
                  readOnly
                />
              )}
            </Col>
          </FormGroup>
        )}
      </CardBody>
      <CardFooter className={style.floatButtons}>
        <Button
          onClick={onReset}
          size="lg mr-2"
          color="danger"
          disabled={!status || localStorage.getItem("acr") === "OPR_VIEWER"}
        >
          <i className="fa fa-user" /> Reset Key
        </Button>
      </CardFooter>
    </div>
  );
};

export default memo(PublicAPI);
