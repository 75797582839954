import axios from "../axiosInstance";
import { AUTH_BASE_URL } from "../../API_routes";
import { ICreateUser } from "../interfaces";
import { handleOrderBy } from "../helper/helper";

const apiPrefix: string = `${AUTH_BASE_URL}/users`;

export const getUsers: any = (
  pageNumber: number | string,
  orderBy?: string | null,
  direction?: string | null,
  name?: string | null,
  email?: string | null,
  operator_id?: string | null,
  role_id?: string | null,
  status?: string | null
): any => {
  return axios.get(
    `${apiPrefix}?page=${pageNumber}${
      orderBy ? "&order=" + handleOrderBy(orderBy) : ""
    }${direction ? "&direction=" + direction : ""}${
      name ? "&filter[name]=" + name : ""
    }${email ? "&filter[email]=" + email : ""}${
      operator_id ? "&filter[operator_id]=" + operator_id : ""
    }${role_id ? "&filter[role_id]=" + role_id : ""}${
      status ? "&filter[status]=" + status : ""
    }`.trim()
  );
};

export const getUserByID: any = (ID: number | string): any =>
  axios.get(`${apiPrefix}/${ID}`);

export const createUser: any = (data: ICreateUser): any =>
  axios.post(`${apiPrefix}`, { ...data });

export const deleteUser: any = (id: number | string): any =>
  axios.delete(`${apiPrefix}/${id}`);

export const deleteUsers: any = (ids: Array<number | string>): any => {
  return axios.delete(
    `${apiPrefix}?${ids
      .map((item: number | string, index: number) => `ids[${index}]=${item}`)
      .join("&")}`
  );
};
export const updateUser = (id: number | string, data: ICreateUser) =>
  axios.put(`${apiPrefix}/${id}`, { ...data });
