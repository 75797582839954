import React, { useState, memo } from "react";
import { CardBody, FormGroup, Label, Input, Col } from "reactstrap";
import style from "../OperatorConfigPage.module.scss";
import { Switch } from "antd";

interface IPropsInterface {
  values: any;
  handleChange: any;
}

const PushNotifications = ({ values, handleChange }: IPropsInterface) => {
  const [status, setStatus] = useState(values.status);

  const generateOptions = () => {
    return ["Firebase", "Pushy"].map((item) => (
      <option value={item.toLocaleLowerCase()} key={item}>
        {item}
      </option>
    ));
  };

  return (
    <div className={style.contentWrapper}>
      <CardBody>
        <FormGroup row>
          <Col xs="4">
            <Label htmlFor="apkPath">Enable</Label>
          </Col>
          <Col xs="8" md="8">
            <Switch
              style={{ float: "right" }}
              size="small"
              defaultChecked={values.status}
              onChange={(checked: boolean) => {
                setStatus(checked);
                handleChange({
                  status: checked,
                });
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="12">
            <Label htmlFor="reportInterval">Plataform</Label>
            <Input
              type="select"
              id="key"
              name="key"
              disabled={!status}
              required={status}
              defaultValue={values.platform ? values.platform : ""}
              onChange={(e) => {
                handleChange({
                  platform: e.target.value,
                });
              }}
            >
              {/* <option value="">Select Plataform</option> */}
              {generateOptions()}
            </Input>
          </Col>
        </FormGroup>
        {values.platform === "pushy" &&
        <FormGroup row>
          <Col md="12">
            <Label htmlFor="reportInterval">
              Pushy Secret
            </Label>
            <Input
              type="text"
              id="key"
              name="key"
              required={status && values.platform === "pushy"}
              placeholder="Enter Pushy Secret Key"
              defaultValue={(values.platform === "pushy" && values.secretKey) ? values.secretKey : ""}
              onChange={(e) => {
                handleChange({
                  secretKey: e.target.value,
                });
              }}
              disabled={!status}
            />
          </Col>
        </FormGroup>}

      </CardBody>
    </div>
  );
};

export default memo(PushNotifications);
