import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";

const installApkUrl: { installApkUrl: string } = {
  installApkUrl: ""
};

interface IPropsInterface {
  modalTitle: string;
  isOpen: boolean;
  onToggle(): void;
  onInstallApk(url): void;
}

class UpdateApkModal extends Component<IPropsInterface, any> {
  constructor(props) {
    super(props);
    this.state = {
      installApkUrl: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IPropsInterface): void {
    if (this.props !== nextProps) {
      this.setState({
        installApkUrl: null
      });
    }
  }

  _onInstallApk: any = () => {
    const { onInstallApk } = this.props;
    const { installApkUrl } = this.state;
    onInstallApk(installApkUrl);
  };

  render(): React.ReactNode {
    const { isOpen, onToggle, modalTitle }: IPropsInterface = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={onToggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={installApkUrl}
            validate={() => {}}
            onSubmit={this._onInstallApk}
            render={({
              values,
              errors,
              touched,
              status,
              dirty,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              handleReset,
              setTouched
            }): any => (
              <Row>
                <Col lg="6">
                  <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                    <FormGroup>
                      <Label for="install_apk_url" />
                      <Input
                        type="text"
                        name="installApkUrl"
                        id="installApkUrl"
                        placeholder="Enter URL to update APP"
                        required
                        onChange={(event: any): any => {
                          handleChange(event);
                          this.setState({
                            installApkUrl: event.target.value
                          });
                        }}
                        onBlur={handleBlur}
                        value={values.installApkUrl}
                      />
                      <FormFeedback>{errors.installApkUrl}</FormFeedback>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            )}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={onToggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={this._onInstallApk}>
            Update App
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withRouter<any, any>(UpdateApkModal);
