import React from "react";
import { withRouter } from "react-router-dom";
import { HistoryProvider } from "context/historyActions";
import BlockRemoteAssitances from "./BlockRemoteAssitances";
import { RemoteAssistanceProvider } from "context/remoteAssistance";

const RemoteAssistancesActionsPageAntd = props => {
  const {
    history,
    match: {
      params: { ID }
    }
  } = props;

  return (
    <RemoteAssistanceProvider id={ID}>
      <HistoryProvider id={ID}>
        <BlockRemoteAssitances history={history} />
      </HistoryProvider>
    </RemoteAssistanceProvider>
  );
};

export default withRouter<any, any>(RemoteAssistancesActionsPageAntd);
