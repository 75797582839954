import React, { useEffect, useCallback, useState, memo, useMemo } from "react";
import { Table, Tag, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import TimeAgo from "react-timeago";
import moment from "moment";
import style from "./Tables.module.scss";
import { useHistory } from "context/historyActions";
import ModalRemoteActions from "../ModalRemoteAction/ModalRemoteActions";

const columns = [
  {
    title: "Action",
    dataIndex: "action",
    align: "center"
  },
  {
    title: "Response Code",
    dataIndex: "response_code",
    align: "center"
    // sorter: {
    //   compare: (a, b) => a.chinese - b.chinese,
    //   multiple: 3
    // }
  },
  {
    title: "Response Data",
    dataIndex: "response_data",
    align: "center"
    // sorter: {
    //   compare: (a, b) => a.math - b.math,
    //   multiple: 2
    // }
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    align: "center"
    // sorter: {
    //   compare: (a, b) => a.english - b.english,
    //   multiple: 1
    // }
  }
];

const parseToNewFormat = (action: string) => {
  return action
    .split("")
    .map((chr, i) => (i === 0 ? chr : chr.toLowerCase()))
    .join("")
    .replaceAll("_", " ");
};

const parseStringToNewAction = (action: string) => {
  const newNamesAction = {
    TEST_SPEED: "Intenet speed test",
    GET_STATUS: "Status",
    ALLOW_REMOTE_ASSISTANT: "Request permission",
    FIRST_PING: "Ping",
    PING: "Ping",
    TAKE_SCREENSHOT: "Screenshot"
  };
  return newNamesAction[action]
    ? newNamesAction[action]
    : parseToNewFormat(action);
};

const updatedDate = (created_at: string) =>
  moment(parseInt(created_at) * 1000).format("MM DD YYYY HH:mm");

const RemoteAssistanceActionHistoryTableAntd = ({ ID, loading }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageConfig, setPageConfig] = useState<any>(null);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [msgFeedback, setMsgFeedback] = useState<string>("");

  const {
    historyActions,
    historyError,
    updateHistoryupdaActionsPage
  } = useHistory();

  const isMoreThan30Seconds = useCallback((created_at: any) => {
    const momentNow = moment();
    const momentDebug = moment(parseInt(created_at) * 1000);
    return momentNow.diff(momentDebug, "seconds") > 30;
  }, []);

  const renderTags = useMemo(
    () => (code: number, created_at: number) => {
      if (code === 200) {
        return <Tag color="#87d068">{code}</Tag>;
      } else if (code === 400 || code === 500) {
        return <Tag color="#f50">{code}</Tag>;
      } else {
        return isMoreThan30Seconds(created_at) ? (
          <Tag color="#f50">408</Tag>
        ) : (
          <Tag color="#b8b52a">{code}</Tag>
        );
      }
    },
    [isMoreThan30Seconds]
  );

  const renderData = useMemo(
    () => (code: number, created_at: number, data: string) => {
      let titleBtn = "";
      if (code === 200) {
        titleBtn = "Success";
      } else if (code === 400 || code === 500) {
        titleBtn = "Error";
      } else {
        titleBtn = isMoreThan30Seconds(created_at) ? "Timeout" : "Sent";
      }
      return (
        <>
          <Button
            className="d-inline-block"
            onClick={() => {
              setMsgFeedback(
                isMoreThan30Seconds(created_at)
                  ? "The action was submitted but not executed."
                  : data
              );
              setShowPopover(!showPopover);
            }}
          >
            <InfoCircleOutlined
              style={{
                fontSize: "16px",
                color: "#08c",
                transform: "translate(-2px, -1px)"
              }}
            />
            {titleBtn}
          </Button>
        </>
      );
    },
    [isMoreThan30Seconds, showPopover]
  );

  const handleData = useCallback(
    remoteAssistanceActionsHistory => {
      return remoteAssistanceActionsHistory.map(
        ({ action, response_code, response_data, created_at }, i: number) => ({
          key: i,
          action: parseStringToNewAction(action),
          response_code: renderTags(response_code, created_at),
          response_data: renderData(response_code, created_at, response_data),
          created_at: <TimeAgo date={updatedDate(created_at)} live={false} />
        })
      );
    },
    [renderData, renderTags]
  );

  useEffect(() => {
    if (currentPage && historyActions) {
      setPageConfig({
        current: currentPage,
        pageSize: historyActions.per_page,
        total: historyActions.total,
        position: ["bottomCenter"]
      });
    }
  }, [currentPage, historyActions, loading]);

  const onChange = pagination => {
    updateHistoryupdaActionsPage(pagination.current);
    setCurrentPage(pagination.current);
  };

  return !historyError ? (
    <>
      <Table
        className={style.Table}
        //@ts-ignore
        columns={columns}
        dataSource={handleData(historyActions?.data || [])}
        onChange={onChange}
        pagination={{ ...pageConfig }}
      />
      <ModalRemoteActions
        showModal={showPopover}
        message={msgFeedback}
        onClose={() => setShowPopover(false)}
      />
    </>
  ) : (
    <small>{historyError}</small>
  );
};

export default memo(RemoteAssistanceActionHistoryTableAntd);
