import React from "react";
import style from "./Status.module.scss";

type StatusProps = {
  status: string | number;
};

const Status = ({ status }: StatusProps) => {
  return (
    <div className={`${style.Status} ${status ? style.Green : style.Red}`}>
      {status}
    </div>
  );
};

export default Status;
