import React, { memo, useMemo, useState } from "react";
import {
  CardBody,
  FormGroup,
  Label,
  Input,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Button
} from "reactstrap";
import { Switch } from "antd";
import { v4 as uuidv4 } from "uuid";
import style from "../OperatorConfigPage.module.scss";

interface IPropsInterface {
  values: any;
  handleChange: any;
  handshakeKeys: boolean;

  onRemove: (newValue: any) => void;
}

const Form = ({ value, handleChange, status }) => {
  return (
    <div className={style.contentWrapper}>
      <CardBody>
        <FormGroup row>
          <Col md="12">
            <Label htmlFor="name">Key</Label>
            <Input
              type="text"
              name="key"
              disabled={!status}
              placeholder="Insert key value here"
              defaultValue={value?.key}
              onChange={e => {
                handleChange({
                  key: e.target.value
                });
              }}
            />
          </Col>
        </FormGroup>
      </CardBody>
    </div>
  );
};

const Handshake = ({
  values,
  handleChange,
  onRemove,
  handshakeKeys
}: IPropsInterface) => {
  const [config, setConfig] = useState(values);
  const [status, setStatus] = useState(handshakeKeys);
  const form = useMemo(
    () =>
      config.map((value, i) => (
        <Card key={i}>
          <CardHeader>
            <strong>{`Config ${i + 1}`} </strong>
          </CardHeader>
          {/* {console.log({ value })} */}
          <CardBody>
            <Form
              value={value}
              status={status}
              handleChange={newValue =>
                handleChange({
                  // ...config.find(c => c.id === value.id),
                  id: value.id,
                  ...newValue
                })
              }
            />
          </CardBody>
          <CardFooter>
            <Button
              color="danger"
              className={style.addButton}
              onClick={() => {
                setConfig([
                  ...config.filter(itemToDelete => itemToDelete.id !== value.id)
                ]);

                onRemove({
                  ...config.filter(itemToDelete => itemToDelete.id !== value.id)
                });
              }}
            >
              <i className="material-icons">delete</i>Remove
            </Button>
          </CardFooter>
        </Card>
      )),
    [config, handleChange, onRemove, status]
  );

  return (
    <div className="row form-group mt-0 m-4">
      <div className="w-100">
        <FormGroup row>
          <Col xs="4">
            <Label htmlFor="status">Status</Label>
          </Col>
          <Col xs="8" md="8">
            <Switch
              style={{ float: "right" }}
              size="small"
              defaultChecked={handshakeKeys}
              onChange={(checked: boolean) => {
                setStatus(checked);
                handleChange({
                  handshakeKeys: checked
                });
              }}
            />
          </Col>
        </FormGroup>
        {form}
        <Button
          color="primary"
          className={style.addButton}
          onClick={() => {
            const id = uuidv4();

            setConfig([
              ...config,
              {
                id,
                key: ""
              }
            ]);
          }}
        >
          <i className="material-icons">add</i>Add New Config
        </Button>
      </div>
    </div>
  );
};

export default memo(Handshake);
