import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  Input,
  FormGroup,
  CardFooter,
  Alert,
} from "reactstrap";
import { Formik, Form } from "formik";
import style from "../DevicesDetailsPage.module.scss";
import { updateDevice } from "../../../common/api/devices";

interface IPropsInterface {
  match: any;
  history: { push(data: any): void };
  consent_status: boolean | number;
}

const DeviceProfileUserSettings = ({
  history,
  match,
  consent_status,
}: IPropsInterface) => {
  let timeout: any = null;

  const [configurations, setConfigurations] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<any>(null);
  const [responseSuccess, setResponseSuccess] = useState<string>("");
  const [dataForm] = useState<any>({
    consent_status,
  });

  useEffect(() => {
    if (!configurations) {
      const consentStatus =
        consent_status === 1 ? "Yes" : consent_status === 0 ? "No" : "Not set";
      setConfigurations({
        ...configurations,
        DeviceConsentStatus: { status: consentStatus },
      });
    }
  }, [configurations, consent_status]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [timeout]);

  const _onEditDeviceProfileConfig: any = async (data: {}): Promise<
    undefined | object
  > => {
    const {
      params: { ID },
    } = match;

    try {
      setLoading(true);
      const result: {
        status: number;
        error: string;
        message: string;
      } = await updateDevice(ID, data);

      if (result && result.status === 200) {
        setResponseSuccess("Device Config updated!");
        timeout = setTimeout(() => {
          setResponseSuccess("");
        }, 4000);
      } else {
        setResponseError(result?.error || result?.message || "Error");
        timeout = setTimeout(() => {
          setResponseError("");
        }, 6000);
      }

      timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      return err;
    }
  };

  const _renderAlert: Function = () => {
    if (responseSuccess || responseError) {
      return (
        <Alert
          in={responseSuccess || responseError}
          color={responseError ? "danger" : "success"}
          tag="h5"
          className="mt-3"
        >
          {responseSuccess || responseError}
        </Alert>
      );
    }
  };

  const _loading = () => {
    return (
      <div className="d-flex justify-content-center m-5 in">
        <Spinner color="primary" />
      </div>
    );
  };

  const _renderInputTextLabel = ({
    key,
    keyChild,
    valueKeyChild,
    index,
    handleChange,
  }) => {
    return (
      <>
        {_renderWrapInput({
          key,
          keyChild,
          valueKeyChild,
          index,
          handleChange,
        })}
      </>
    );
  };

  const _renderWrapInput = ({
    key,
    keyChild,
    valueKeyChild,
    index,
    handleChange,
  }) => {
    return (
      <Input
        type="text"
        id={valueKeyChild + index}
        name={keyChild}
        defaultValue={valueKeyChild}
        placeholder={`${parseKeyTitle(keyChild)}`}
        readOnly={true}
      />
    );
  };

  const _renderBox = ({ side, handleChange }) => {
    if (configurations) {
      return Object.entries(configurations)
        .map(([key, keyValue], index) => {
          return (
            <div className={style.contentWrapper} key={key + index}>
              <Card>
                <CardHeader>
                  <h3 className={style.title}>
                    {/* <i className="material-icons ">speaker_notes</i> */}
                    <strong>{parseKeyTitle(key)}</strong>
                  </h3>
                </CardHeader>

                {keyValue &&
                  Object
                    //@ts-ignore
                    .entries(keyValue)
                    .map(([keyChild, valueKeyChild], indexChild) => {
                      return (
                        <CardBody key={key + keyChild + index}>
                          <FormGroup row>
                            {_renderInputTextLabel({
                              key,
                              keyChild,
                              valueKeyChild,
                              index,
                              handleChange,
                            })}
                          </FormGroup>
                        </CardBody>
                      );
                    })}
              </Card>
            </div>
          );
        })
        .filter((item, indexFilter) => side(indexFilter));
    }
  };

  const parseKeyTitle = (key, keyMaster = "") =>
    key.split(/([A-Z][a-z]+)/).join(" ");

  const onSubmit = async () => {
    _onEditDeviceProfileConfig({ ...dataForm });
  };

  if (loading) {
    return _loading();
  }

  return (
    <div>
      <Formik
        initialValues={configurations}
        enableReinitialize={true}
        onSubmit={onSubmit}
        render={({
          values,
          errors,
          touched,
          status,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          handleReset,
          setTouched,
          resetForm,
        }) => (
          <Form>
            <CardHeader className={style.floatButtons}></CardHeader>
            {_renderAlert()}
            <Row className="p-3">
              <Col md="6">
                {_renderBox({
                  side: (num) => num % 2 === 0,
                  handleChange,
                })}
              </Col>
              <Col md="6">
                {_renderBox({
                  side: (num) => num % 2 !== 0,
                  handleChange,
                })}
              </Col>
            </Row>
            <CardFooter className={style.floatButtons}></CardFooter>
          </Form>
        )}
      />
    </div>
  );
};

export default withRouter<any, any>(DeviceProfileUserSettings);
