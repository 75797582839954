import React from "react";
import connect from "react-redux/es/connect/connect";
import { get } from "lodash";
import { fetchAuthRulesAction } from "../../redux/actions";
import AppLayout from "../../routesWrapper/Layout/Layout";
import { Redirect } from "react-router";

export const AuthorizationHOC = (Component, resource) => {
  interface IPropsInterface {
    doRedirect: string;
    isAuthorized: boolean;
    checkAuthorizationAction: Function;
    fetchAuthRules: Function;
    roles: any;
    currentUser: any;
    history: { push(data): void };
  }

  interface IStateInterface {
    userHasAccess: boolean;
    loading: boolean;
    roles: string;
  }

  class Authorization extends React.Component<
    IPropsInterface,
    IStateInterface
  > {
    constructor(props) {
      super(props);
      this.state = {
        userHasAccess: false,
        loading: true,
        roles: ""
      };
    }

    componentDidMount() {
      const { currentUser } = this.props;

      if (this.props.roles && this.props.roles.length !== 0) {
        localStorage.setItem("roles", this.props.roles);
        const findRole = this.props.roles.find(role => role.route === resource);
        const resourceRoles = get(findRole, "roles");
        if (resourceRoles) {
          const currentUserRoles =
            get(currentUser, "roles[0]") !== null
              ? get(currentUser, "roles")
              : [localStorage.getItem("acr")];
          const userHasAccess =
            !!currentUserRoles.find(currentUserRole => {
              return resourceRoles.some(
                resourceRole => resourceRole === currentUserRole
              );
            }) || get(resourceRoles, "[0]") === "*";

          this.setState({
            userHasAccess: userHasAccess,
            loading: false,
            roles: currentUserRoles[0]
          });
        }
      } else {
        this.props.fetchAuthRules();
      }
    }

    render() {
      const { userHasAccess, loading } = this.state;

      if (!loading && !userHasAccess) {
        return <Redirect exact from="/" to="/dashboard" />;
      }

      return (
        <React.Fragment>
          <AppLayout />
          <Component />
        </React.Fragment>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      doRedirect: state.auth.doRedirect,
      roles: state.roles.roles,
      currentUser: state.auth.currentUser
    };
  };

  const mapDispatchToProps = dispatch => ({
    fetchAuthRules: () => dispatch(fetchAuthRulesAction())
  });

  return connect(mapStateToProps, mapDispatchToProps)(Authorization);
};
