import React from "react";
import DragAndDrop from "../../../DragAndDrop";
import {
  Form,
  Button,
  Card,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Label,
  Progress,
  CardBody,
  FormFeedback
} from "reactstrap";
import style from "../DevicesForm.module.scss";
import { Switch } from "antd";
import { Formik } from "formik";

const ImportDevices = ({
  fileDeviceType,
  generateDeviceTypes,
  progressPercentage,
  animated,
  onChangeDeviceId,
  generateReleaseCycle,
  onLock,
  file,
  handleCsvUpload,
  onShowForm,
  handleDrop,
  validate,
  errorFile
}) => {
  return (
    <Formik
      initialValues={{
        operator_device_type_id_import: "",
        operator_device_release_cycle:
          fileDeviceType.operator_device_release_cycle || ""
      }}
      validate={validate}
      onSubmit={handleCsvUpload}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit
      }: any): any => {
        return (
          <Form
            onSubmit={handleSubmit}
            className="col-12"
            noValidate
            name="simpleForm"
          >
            <FormGroup row className="my-0">
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="operator_device_type_id_import">
                    Device Profile
                  </Label>
                  <Input
                    type="select"
                    name="operator_device_type_id_import"
                    id="operator_device_type_id_import"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(e);
                      onChangeDeviceId(name, value);
                    }}
                    valid={
                      touched.operator_device_type_id_import &&
                      !errors.operator_device_type_id_import
                    }
                    invalid={
                      touched.operator_device_type_id_import &&
                      !!errors.operator_device_type_id_import
                    }
                    defaultValue={values.operator_device_type_id_import}
                    required
                  >
                    <option value="" label="Select device profile">
                      Select device profile
                    </option>
                    {generateDeviceTypes}
                  </Input>
                  <FormFeedback>
                    {errors.operator_device_type_id_import}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label htmlFor="operator_device_release_cycle">
                    Release Cycle
                  </Label>
                  <Input
                    type="select"
                    name="operator_device_release_cycle"
                    id="operator_device_release_cycle"
                    onChange={e => {
                      const { name, value } = e.target;
                      handleChange(e);
                      onChangeDeviceId(name, value);
                    }}
                    valid={
                      touched.operator_device_release_cycle &&
                      !errors.operator_device_release_cycle
                    }
                    invalid={
                      touched.operator_device_release_cycle &&
                      !!errors.operator_device_release_cycle
                    }
                    defaultValue={fileDeviceType.operator_device_release_cycle}
                    required
                  >
                    <option value="" label="Select release cycle">
                      Select release cycle
                    </option>
                    {generateReleaseCycle}
                  </Input>
                  <FormFeedback>
                    {errors.operator_device_release_cycle}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col className="no-gutters row">
                <Col xs="3">
                  <Label
                    className={style.labelSwitch}
                    htmlFor="operator_device_lock"
                  >
                    Device Lock
                  </Label>
                </Col>
                <Col xs="6">
                  <Switch
                    size="small"
                    defaultChecked={false}
                    onChange={(checked: boolean) => {
                      onLock(checked);
                    }}
                  />
                </Col>
              </Col>
            </FormGroup>
            <Card className={style.cardSize}>
              <CardBody className={style.cardBody}>
                <DragAndDrop handleDrop={handleDrop}>
                  <div>
                    {!file && (
                      <div className={style.addIcon}>
                        <i className="icon-plus icons font-2xl d-block mt-4" />
                        <span>Select or drag your csv file here</span>
                      </div>
                    )}
                    {errorFile && (
                      <span className="text-danger font-weight-bold">
                        {errorFile}
                      </span>
                    )}
                    <div>{file && file.name}</div>
                  </div>
                </DragAndDrop>
              </CardBody>
            </Card>
            <div>
              <Progress
                animated={animated}
                color={
                  progressPercentage && progressPercentage === 100
                    ? "success"
                    : "danger"
                }
                value={progressPercentage}
              />
            </div>
            <CardFooter className={style.footer}>
              <Button
                type="submit"
                color="primary"
                size="md"
                className={style.marginButton}
                disabled={!file || !fileDeviceType}
              >
                Import
              </Button>
              <Button
                type="button"
                color="danger"
                size="md"
                onClick={onShowForm}
              >
                Close
              </Button>
            </CardFooter>
          </Form>
        );
      }}
    />
  );
};

export default ImportDevices;
