import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  Alert,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { Formik, Form } from "formik";
import style from "../../DeviceProfileDetailsPage.module.scss";
import { Switch } from "antd";
import * as Yup from "yup";
import moment from "moment";
import ImgNotFound from "../../images/ic_launcher.png";

const initialValues = {
  name: "",
  //apk_path: "https://wbc-rom-download.s3-eu-west-1.amazonaws.com/igor-test.apk",
  apk_path: "",
  version_name: "",
  version_code: "",
  launch_date: "",
  package_name: "",
  end_date: null,
  dev: 0,
  qa: 0,
  alpha: 0,
  beta: 0,
  rc: 0,
  prod: 0,
  uninstall_previous: 0,
  clear_data: 0,
  replace_existing: 0,
  allow_downgrade: 0,
  allow_test_package: 0
};

const validationSchema: any = function(values: any): any {
  //const passwordValue = get(values, "password");
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    apk_path: Yup.string().required("Apk Path is required"),
    launch_date: Yup.string().required("Launch Date is required")
  });
};
const validate: any = (getValidationSchema: any): any => {
  return (values: any): any => {
    const validationSchema: any = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError: any = (validationError: any): any => {
  const FIRST_ERROR: any = 0;

  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
};

const AddAppForm = ({
  data,
  onShowForm,
  onEditDeviceProfileConfig,
  responseError,
  imgLogo
}) => {
  const READ_ONLY = [
    "id",
    "version_name",
    "version_code",
    "logo",
    "package_name"
  ];
  const NOT_SHOW = [
    "id",
    "operator_device_type_id",
    "operator_id",
    "created_at",
    "updated_at",
    "updated_by",
    "created_by",
    "deleted_at",
    "logo"
  ];
  const TIME = ["created_at", "updated_at", "launch_date", "end_date"];
  const SWITCH = ["dev", "qa", "alpha", "beta", "rc", "prod"];
  const ADDITIONAL_SWITCH = [
    "uninstall_previous",
    "clear_data",
    "replace_existing",
    "allow_downgrade",
    "allow_test_package"
  ];

  const [switchItems, setSwitchItems] = useState({
    dev: 0,
    qa: 0,
    alpha: 0,
    beta: 0,
    rc: 0,
    prod: 0
  });

  useEffect(() => {
    if (data) {
      const { dev, qa, alpha, beta, rc, prod } = data;
      setSwitchItems({ dev, qa, alpha, beta, rc, prod });
    }
  }, [data]);

  const _renderAlert: Function = () => {
    if (responseError) {
      const {
        details
      }: { details: object; message: string } | boolean | any = responseError;

      return (
        <Alert color="warning">
          {responseError && responseError.message && (
            <strong>{responseError.message}</strong>
          )}

          {details &&
            Object.keys(details).map((item: any, index: any) => (
              <div key={index}>{`${details[item]}`}</div>
            ))}
        </Alert>
      );
    }
  };

  const _renderSwitchLabel = ({ key, keyValue, handleChange, errors }) => {
    return (
      <>
        <Label className={style.labelSwitch} htmlFor={key}>
          {parseKeyTitle(key)}:
        </Label>
        <Switch
          className={style.fieldSwitch}
          size="small"
          defaultChecked={keyValue}
          onChange={(checked: boolean) => {
            const valueToSend = checked ? 1 : 0;
            setSwitchItems({ ...switchItems, [key]: valueToSend });
          }}
        />
        <FormFeedback>{errors.name}</FormFeedback>
      </>
    );
  };

  const addDefaultSrc = ev => (ev.target.src = ImgNotFound);

  const _renderInputLabel = ({
    key,
    keyValue,
    handleChange,
    errors,
    touched
  }) => {
    return (
      <>
        <Label className={style.labelSwitch} htmlFor={key}>
          {parseKeyTitle(key)}
        </Label>
        {key !== "logo" ? (
          <Input
            type={TIME.includes(key) ? "date" : "text"}
            id={key}
            name={key}
            defaultValue={TIME.includes(key) ? parseToData(keyValue) : keyValue}
            placeholder={
              READ_ONLY.includes(key)
                ? `${parseKeyTitle(key)}`
                : `Enter ${parseKeyTitle(key)}`
            }
            readOnly={READ_ONLY.includes(key)}
            onChange={handleChange}
            valid={touched[key] && !errors[key]}
            invalid={touched[key] && !!errors[key]}
          />
        ) : (
          <img
            alt="Img logo"
            width="50px"
            onError={addDefaultSrc}
            src={imgLogo(keyValue)}
          />
        )}
        <FormFeedback>{errors[key]}</FormFeedback>
      </>
    );
  };

  const _renderField = valuesForm => {
    return Object.entries(valuesForm?.values)
      .filter(
        ([key]) => !NOT_SHOW.includes(key) && valuesForm.switchFilter(key)
      )
      .map(([key, keyValue], index) => {
        return (
          <Col xs="6" key={key + index}>
            <FormGroup>
              {valuesForm.renderFieldType({ key, keyValue, ...valuesForm })}
            </FormGroup>
          </Col>
        );
      });
  };

  const _onSubmit = async data => {
    await onEditDeviceProfileConfig({ ...data, ...switchItems });
  };

  const parseKeyTitle = key =>
    parseLabelsForOtherStrings(key) || key.split("_").join(" ");

  const parseLabelsForOtherStrings = key => {
    const newStrings = {
      qa: "Quality Assurance",
      dev: "Development",
      alpha: "Alpha",
      beta: "Beta",
      rc: "Release Candidate",
      prod: "Release",
      logo: "Icon"
    };
    return newStrings[key];
  };

  const parseToData = data => {
    if (!data) {
      return;
    }
    return moment(data).format("YYYY-MM-DD");
  };

  return (
    <div>
      {_renderAlert()}
      <Formik
        initialValues={data || initialValues}
        validate={validate(validationSchema)}
        onSubmit={_onSubmit}
        render={({ values, errors, touched, handleChange }: any): any => {
          return (
            <Form>
              <FormGroup row className={style.FormAddApp}>
                {_renderField({
                  values,
                  handleChange,
                  errors,
                  touched,
                  switchFilter: key =>
                    !SWITCH.includes(key) && !ADDITIONAL_SWITCH.includes(key),
                  renderFieldType: values => _renderInputLabel(values)
                })}
                {values.logo && (
                  <Col xs="6">
                    <FormGroup>
                      <Label
                        className={style.labelSwitch}
                        htmlFor={values.logo}
                      >
                        Icon
                      </Label>
                      <img
                        alt="Img logo"
                        width="50px"
                        src={imgLogo(values.logo)}
                        onError={addDefaultSrc}
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col xs="12" className={style.TitleAvailable}>
                  <ModalHeader>Available for</ModalHeader>
                </Col>

                {_renderField({
                  values,
                  handleChange,
                  errors,
                  touched,
                  switchFilter: key => SWITCH.includes(key),
                  renderFieldType: values => _renderSwitchLabel(values)
                })}

                <Col xs="12" className={style.TitleAvailable}>
                  <ModalHeader>Additional actions</ModalHeader>
                </Col>

                {_renderField({
                  values,
                  handleChange,
                  errors,
                  touched,
                  switchFilter: key => ADDITIONAL_SWITCH.includes(key),
                  renderFieldType: values => _renderSwitchLabel(values)
                })}
              </FormGroup>
              <ModalFooter className={style.footerPadding}>
                <Button
                  type="button"
                  color="danger"
                  size="md"
                  onClick={onShowForm}
                >
                  Cancel
                </Button>
                {localStorage.getItem("acr") !== "OPR_VIEWER" && (
                  <Button
                    type="submit"
                    color="primary"
                    size="md"
                    //disabled={!edit && !isValid}
                  >
                    Save
                  </Button>
                )}
              </ModalFooter>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default AddAppForm;
