import axios from "../axiosInstance";
import { CORE_API_DOMAIN } from "../../API_routes";
import { ICreateDeviceInterface, ICreateRomInterface } from "../interfaces";
import { handleOrderBy } from "../helper/helper";
import { get } from "lodash";

const apiPrefix: string = `${CORE_API_DOMAIN}/operator-roms`;

export const getRoms: any = ({
  pageNumber,
  limit,
  orderBy,
  direction,
  id,
  operation_system,
  build_number,
  kernel_version,
  baseband_version,
  created_at,
  updated_at,
  release_date,
  force_update,
  is_incremental,
  status,
  name
}): any => {
  let filters = `${pageNumber ? "?page=" + pageNumber : "?page=1"}${
    limit ? "&limit=" + limit : "".replace(/\s/g, "")
  }${orderBy ? "&order=" + handleOrderBy(orderBy) : "".replace(/\s/g, "")}${
    direction ? "&direction=" + direction : "".replace(/\s/g, "")
  }${id ? "&filter[id]=" + id : "".replace(/\s/g, "")}${
    operation_system
      ? "&filter[operation_system]=" + operation_system
      : "".replace(/\s/g, "")
  }${
    build_number
      ? "&filter[build_number]=" + build_number
      : "".replace(/\s/g, "")
  }${
    kernel_version
      ? "&filter[kernel_version]=" + kernel_version
      : "".replace(/\s/g, "")
  }${
    is_incremental
      ? "&filter[is_incremental]=" + is_incremental
      : "".replace(/\s/g, "")
  }${
    baseband_version
      ? "&filter[baseband_version]=" + baseband_version
      : "".replace(/\s/g, "")
  }${status ? "&filter[status]=" + status : "".replace(/\s/g, "")}${
    created_at ? "&filter[created_at]=" + created_at : "".replace(/\s/g, "")
  }${updated_at ? "&filter[updated_at]=" + updated_at : "".replace(/\s/g, "")}${
    name ? "&filter[name]=" + name : "".replace(/\s/g, "")
  }${
    release_date
      ? "&filter[release_date]=" + release_date
      : "".replace(/\s/g, "")
  }${
    force_update
      ? "&filter[force_update]=" + force_update
      : "".replace(/\\s/g, "")
  }`;

  return axios.get(`${apiPrefix}${filters}`).then(resp => {
    if (get(resp, "data.resource")) {
      return get(resp, "data.resource");
    }
  });
};

export const getRomsByID: any = (ID: number | string): any =>
  axios.get(`${apiPrefix}/${ID}`);

export const createRom: any = (data: ICreateRomInterface): any =>
  axios.post(`${apiPrefix}`, { ...data });

export const deleteRom: any = (id: number | string): any =>
  axios.delete(`${apiPrefix}/${id}`);

export const deleteRoms: any = (ids: Array<number | string>): any => {
  const roms: number | string = ids
    .map((item: number | string, index: number) => `ids[${index}]=${item}`)
    .join("&");

  return axios.delete(`${apiPrefix}?${roms}`);
};

export const updateRom: any = (
  id: number | string,
  data: ICreateDeviceInterface
): any => axios.put(`${apiPrefix}/${id}`, { ...data });
