import React from "react";
import classNames from "classnames";
import style from "./Dpad.module.scss";
import { Card, Button, Progress, UncontrolledTooltip } from "reactstrap";

interface IPropsInterface {
  onRemoteBack(): void;
  onRemoteOk(): void;
  onRemoteApps(): void;
  disabled: boolean;
  onRemoteLeft(): void;
  onRemoteUp(): void;
  onRemoteDown(): void;
  onRemoteRight(): void;
  deviceStatus: any;
  onVolumeDown(): void;
  onVolumeUp(): void;
  onMute(): void;
  currentUser: string;
}

const Dpad = ({
  onRemoteBack,
  onRemoteOk,
  onRemoteApps,
  disabled,
  onRemoteLeft,
  onRemoteUp,
  onRemoteDown,
  onRemoteRight,
  deviceStatus,
  onVolumeDown,
  onVolumeUp,
  onMute,
  currentUser
}: IPropsInterface) => {
  return (
    <Card color="remote" className={style.Wrap}>
      <div className={classNames(style.imgDiv, style.imgVolume)}>
        <Progress
          className={style.ProgressWrap}
          color="remote-2"
          value={deviceStatus?.sound?.current || 0}
          max={deviceStatus?.sound?.max || 0}
        >
          <strong>{deviceStatus?.sound?.current || 0}</strong>
        </Progress>
        <UncontrolledTooltip style={{ zIndex: 1000000 }} target="onVolumeDown">
          Volume Down
        </UncontrolledTooltip>
        <div className={style.buttonsWrapVolume}>
          <Button
            className={style.buttonStyle}
            size="md"
            onClick={onVolumeDown}
            disabled={disabled}
            id="onVolumeDown"
          >
            <i className="material-icons">volume_down</i>
            <UncontrolledTooltip target="onVolumeDown">
              Volume Down
            </UncontrolledTooltip>
          </Button>
          <Button
            className={style.buttonStyle}
            size="md"
            onClick={onVolumeUp}
            disabled={disabled}
            id="onVolumeUp"
          >
            <i className="material-icons">volume_up</i>
            <UncontrolledTooltip target="onVolumeUp">
              Volume Up
            </UncontrolledTooltip>
          </Button>
          <Button
            className={style.buttonStyle}
            size="md"
            onClick={onMute}
            id="onMute"
            disabled={disabled}
          >
            <i className="material-icons">volume_off</i>
            <UncontrolledTooltip target="onMute">Mute</UncontrolledTooltip>
          </Button>
        </div>
      </div>
      <div className={style.Control}>
        <div
          onClick={onRemoteUp}
          className={classNames(style.Side, style.SideTop)}
        >
          <i className="material-icons">keyboard_arrow_up</i>
        </div>
        <div
          onClick={onRemoteRight}
          className={classNames(style.Side, style.SideRight)}
        >
          <i className="material-icons">keyboard_arrow_right</i>
        </div>
        <div
          onClick={onRemoteLeft}
          className={classNames(style.Side, style.SideLeft)}
        >
          <i className="material-icons">keyboard_arrow_left</i>
        </div>
        <div
          onClick={onRemoteDown}
          className={classNames(style.Side, style.SideBottom)}
        >
          <i className="material-icons">keyboard_arrow_down</i>
        </div>
        <div onClick={onRemoteOk} className={style.SideMenu}></div>
      </div>
      <div className={style.btnsBottom}>
        <Button
          disabled={disabled}
          onClick={onRemoteBack}
          className={style.btnBottom}
          id="onRemoteBack"
        >
          <i className="material-icons">keyboard_backspace</i>
          <UncontrolledTooltip target="onRemoteBack">
            Backspace
          </UncontrolledTooltip>
        </Button>
        <Button
          disabled={true}
          onClick={onRemoteApps}
          className={style.btnBottom}
          id="onRemoteHome"
        >
          <i className="material-icons">panorama_fish_eye</i>
          <UncontrolledTooltip target="onRemoteHome">Home</UncontrolledTooltip>
        </Button>
        <Button
          disabled={disabled}
          onClick={onRemoteApps}
          className={style.btnBottom}
          id="onRemoteApps"
        >
          <i className="material-icons">apps</i>
          <UncontrolledTooltip target="onRemoteApps">Apps</UncontrolledTooltip>
        </Button>
        <Button
          disabled={true}
          onClick={onRemoteApps}
          className={style.btnBottom}
          id="onRemoteApps2"
          style={{
            display: currentUser === "OPR_SUPPORT_LINE_1" ? "none" : "flex"
          }}
        >
          <i className="material-icons">keyboard</i>
          <UncontrolledTooltip target="onRemoteApps2">
            Keyboard
          </UncontrolledTooltip>
        </Button>
      </div>
    </Card>
  );
};

export default Dpad;
