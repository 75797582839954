import React, { ChangeEvent, Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import moment, { Moment } from "moment";
import TimeAgo from "react-timeago";
import { get, find } from "lodash";
import { Badge, Button, CardBody, Card, Row, Form, Alert } from "reactstrap";
import {
  IFilteredRoms,
  IRomsInterface,
  ISingleRomInterface
} from "../../../common/interfaces";
import style from "./RomsTable.module.scss";
import "rc-pagination/assets/index.css";
import DeleteModal from "../../ModalComponent/DeleteModal";
import { Formik } from "formik";
import FormInputs from "../../Forms/FormInputs";
import AppTable from "../AppTable";
import {
  renderPagination,
  generateColButtons,
  renderTableFieldNames,
  ButtonToForm
} from "../../../common/helper/helper";

interface IPropsInterface {
  onShowForm(): void;
  onFilter(data: { orderBy: string; direction?: string }): void;
  roms: IRomsInterface;
  onDeleteRom(id: string | number): void;
  onDeleteRoms(ids: Array<string | number>): void;
  history: { push(data: any): void };
  statuses: Array<{ id: number; name: string }>;
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
  operatingSystems: Array<{ id: number; name: string }>;
  filteredRoms(data: IFilteredRoms): IRomsInterface;
  responseError: { details: object; message: string } | boolean | any;

  toggleErrorResponse(): void;
  responseItemMessage: null;
}

interface IStateInterface {
  selectedRows: Array<number>;
  selectAll: boolean;
  showDeleteModal: boolean;
  romId: number | null;
  loading: boolean;
  valuesForFilter: any;
}

const filteredValues: any = {
  current_page: "",
  orderBy: "",
  direction: "",
  id: "",
  operation_system: "",
  build_number: "",
  kernel_version: "",
  baseband_version: "",
  created_at: "",
  updated_at: "",
  release_date: "",
  force_update: "",
  status: "",
  is_incremental: ""
};

const initialValues: any = {};

class RomsTable extends Component<IPropsInterface, IStateInterface> {
  private timer;
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      selectedRows: [],
      selectAll: false,
      showDeleteModal: false,
      romId: null,
      loading: false,
      valuesForFilter: {}
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IPropsInterface): void {
    const { roms }: IPropsInterface = this.props;

    if (roms && nextProps.roms) {
      const {
        roms: { current_page }
      }: IPropsInterface = this.props;
      const {
        roms: { current_page: nextCurrentPage }
      }: IPropsInterface = nextProps;

      if (current_page && nextCurrentPage) {
        this.setState({
          selectedRows: [],
          selectAll: false
        });
      }
    }
  }

  componentWillUnmount() {
    setTimeout(this.timer);
  }

  _onRedirectToDetails = (id: number): void => {
    const { history }: IPropsInterface = this.props;
    history.push(`/rom/${id}`);
  };

  _onChange: any = (page: number) => {
    const { valuesForFilter }: any = this.state;
    this._onSubmit(valuesForFilter, page);
  };

  _onCheckboxClick: any = ({ target: { id } }: any) => {
    this.setState((state: any) => ({
      selectedRows: !state.selectedRows.includes(id)
        ? [...state.selectedRows, id]
        : state.selectedRows.filter((item: any) => item !== id)
    }));
  };

  _onSelectAll: (event: ChangeEvent<HTMLInputElement>) => void = (): void => {
    const {
      roms: { data }
    }: IPropsInterface = this.props;
    this.setState((state: IStateInterface) => ({
      selectAll: !state.selectAll,
      selectedRows: !state.selectAll ? data.map(({ id }: any) => id) : []
    }));
  };

  _toggleDeleteModal: any = (): void => {
    const { showDeleteModal }: IStateInterface = this.state;
    if (showDeleteModal) {
      this._resetIds();
    }
    this.setState({
      showDeleteModal: !showDeleteModal
    });
  };

  _renderResultRows = (): React.ReactNode => {
    const { roms }: IPropsInterface = this.props;
    const { selectedRows }: IStateInterface = this.state;

    if (get(roms, "data")) {
      const { data }: IRomsInterface = roms;

      return data.map((item: ISingleRomInterface) => {
        const {
          id,
          name,

          build_number,
          kernel_version,
          baseband_version,
          status,
          ui_version,
          force_update,
          release_date,
          updated_at,
          wipe_data,
          is_incremental
        }: ISingleRomInterface = item;

        const updated_datetime: string = moment(
          parseInt(updated_at) * 1000
        ).format("MM DD YYYY HH:mm");
        const release_datetime: string = moment(
          parseInt(release_date) * 1000
        ).format("MM DD YYYY HH:mm");

        return (
          <tr key={id}>
            <td>{name}</td>
            <td>{build_number}</td>
            <td>{kernel_version}</td>
            <td>{baseband_version}</td>
            <td>{ui_version}</td>
            <td>{force_update ? "enabled" : "disabled"}</td>
            <td>{wipe_data ? "yes" : "no"}</td>
            <td>{is_incremental ? "yes" : "no"}</td>
            <td>{this._renderStatuses(status)}</td>
            <td>
              <TimeAgo date={release_datetime} live={false} />
            </td>
            <td>
              <TimeAgo date={updated_datetime} live={false} />
            </td>
            {generateColButtons({
              id,
              selectedRows,
              onRedirectToDetails: this._onRedirectToDetails,
              toggleDeleteModal: this._toggleDeleteModal,
              onCheckboxClick: this._onCheckboxClick,
              setIdOnClick: () => this.setState({ romId: id })
            })}
          </tr>
        );
      });
    }
  };

  _renderStatuses: any = (status: number): JSX.Element | undefined => {
    const { statuses }: IPropsInterface = this.props;
    if (statuses && statuses.length) {
      const item: { id: number; name: string } | any = find(
        statuses,
        (o: { id: number }) => o.id === status
      );
      if (item) {
        switch (status) {
          case 0:
            return <Badge color="secondary">{item.name}</Badge>;
          case 1:
            return <Badge color="success">{item.name}</Badge>;
          case 2:
            return <Badge color="danger">{item.name}</Badge>;
          default:
            return;
        }
      }
    }

    return;
  };

  _renderTableFieldNames = (): Array<JSX.Element> => {
    const { onFilter, filtering }: IPropsInterface = this.props;
    const keys: Array<{ name: string; keyId: string }> = [
      { name: "Name", keyId: "name" },
      { name: "Build", keyId: "build_number" },
      { name: "Kernel", keyId: "kernel_version" },
      { name: "Baseband", keyId: "baseband_version" },
      { name: "UI", keyId: "ui_version" },
      { name: "Force Update", keyId: "force_update" },
      { name: "Wipe Data", keyId: "wipe_data" },
      { name: "Incremental", keyId: "is_incremental" },
      { name: "Status", keyId: "status" },
      { name: "Scheduled", keyId: "release_date" },
      { name: "Updated", keyId: "updated_at" }
    ];

    return renderTableFieldNames({ onFilter, filtering, keys });
  };

  _generateOperatingSystemOptions: Function = (
    operatingSystemList: Array<{ name: string; id: number }>
  ) => {
    return (
      operatingSystemList &&
      operatingSystemList.map((item: { id: number; name: string }) => (
        <option value={item.name} key={item.id}>
          {item.name}
        </option>
      ))
    );
  };

  _generateTypeOptions: Function = (
    types: Array<{ name: string; id: number }>
  ) => {
    return (
      types &&
      types.map((item: { id: number; name: string }) => (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      ))
    );
  };

  _onSubmit: any = async (
    values: {
      id: number;
      operation_system: string;
      build_number: string;
      force_update: number;
      kernel_version: string;
      baseband_version: string;
      created_at: Moment;
      updated_at: Moment;
      release_date: Moment;
      statuses: number;
      status: number;
      name: string | null;
      is_incremental: number;
    },
    page = 1
  ): Promise<void> => {
    const { filteredRoms, filtering } = this.props;

    this.setState({ loading: true });
    filteredValues.current_page = Number.isInteger(page) ? page : 1;
    filteredValues.limit = null;
    filteredValues.orderBy = filtering.orderBy;
    filteredValues.direction = filtering.direction;
    filteredValues.id = values.id;
    filteredValues.operation_system = values.operation_system;
    filteredValues.build_number = values.build_number;
    filteredValues.kernel_version = values.kernel_version;
    filteredValues.baseband_version = values.baseband_version;
    filteredValues.status = values.status;
    filteredValues.created_at = values.created_at?.format("YYYY-MM-DD");
    filteredValues.updated_at = values.updated_at?.format("YYYY-MM-DD");
    filteredValues.release_date = values.release_date?.format("YYYY-MM-DD");
    filteredValues.name = values.name;
    filteredValues.force_update = values.force_update ? 1 : 0;
    filteredValues.is_incremental = values.is_incremental ? 1 : 0;
    try {
      await filteredRoms(filteredValues);
    } catch (e) {
      // console.log(e);
    } finally {
      this.timer = setTimeout(() => {
        this.setState({
          loading: false,
          valuesForFilter: {
            ...values,
            orderBy: filtering.orderBy,
            direction: filtering.direction
          }
        });
      }, 200);
    }
  };

  _resetIds = () => {
    const { romId, selectedRows } = this.state;
    if (romId) {
      this.setState({
        romId: null
      });
    } else if (selectedRows && selectedRows.length > 0) {
      this.setState({
        selectedRows: []
      });
    }
  };

  render(): JSX.Element {
    const {
      roms,
      onShowForm,
      onDeleteRoms,
      onDeleteRom,
      responseError,
      toggleErrorResponse,
      statuses,
      responseItemMessage
    }: IPropsInterface = this.props;
    const {
      showDeleteModal,
      romId,
      selectAll,
      selectedRows,
      loading
    }: IStateInterface = this.state;

    const currentElementToDelete =
      roms &&
      roms.data &&
      roms.data.find(
        el => el.id === romId || (selectedRows.length === 1 && selectedRows[0])
      );

    const selectedRowsNum = selectedRows.length;

    const modalMessage: string | JSX.Element =
      romId || selectedRowsNum === 0 ? (
        <>
          <strong>{currentElementToDelete?.name}</strong>
          <span> will be deleted. Please confirm action!</span>
        </>
      ) : (
        `Selected ${
          selectedRowsNum === 1 ? "ROM" : "ROMS"
        } will be deleted. Please confirm action!`
      );

    return (
      <div className={style.administratorsTableWrapper}>
        <Row>
          <div className="card-title col-12">
            <h1>
              <i className="material-icons android" />
              ROMs
            </h1>
            <ButtonToForm value="Create ROMs" click={onShowForm} />
          </div>
        </Row>
        <Card>
          <CardBody>
            {responseItemMessage && <Alert>{responseItemMessage}</Alert>}
            <Formik
              initialValues={initialValues}
              onSubmit={this._onSubmit}
              render={({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                handleReset
              }: any): any => {
                return (
                  <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                    <Row className={style.filteringRow}>
                      <FormInputs
                        onChange={handleChange}
                        htmlFor="name"
                        value={values.name}
                        id="name"
                        labelTitle="Name"
                        placeholder="Name"
                        onBlur={handleBlur}
                        name="name"
                        errors={errors.name}
                        type="text"
                        rowInputClassName={style.filteringInput}
                      />

                      <FormInputs
                        type="text"
                        id="build_number"
                        labelTitle="Build Number"
                        htmlFor="build_number"
                        name="build_number"
                        placeholder="Enter Build Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.build_number}
                        errors={errors.build_number}
                        rowInputClassName={style.filteringInput}
                      />

                      <FormInputs
                        type="text"
                        id="kernel_version"
                        labelTitle="Kernel Version"
                        htmlFor="kernel_version"
                        name="kernel_version"
                        placeholder="Enter Kernel Version"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.kernel_version}
                        errors={errors.kernel_version}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        onChange={(event: any): any => {
                          handleChange(event);
                          setFieldValue("status", event.target.value);
                        }}
                        htmlFor="status"
                        value={values.statuses}
                        id="statuses"
                        labelTitle="Status"
                        name="status"
                        errors={errors.statuses}
                        type="select"
                        option={
                          <option value="" label="Select Satus">
                            Select Satus
                          </option>
                        }
                        renderData={this._generateTypeOptions(statuses)}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        type="text"
                        id="baseband_version"
                        labelTitle="Baseband Version"
                        htmlFor="baseband_version"
                        name="baseband_version"
                        placeholder="Enter Baseband Version"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.baseband_version}
                        errors={errors.baseband_version}
                        rowInputClassName={style.filteringInput}
                      />

                      <FormInputs
                        type="date"
                        htmlFor="created_at"
                        id="created_at"
                        labelTitle="Created Date"
                        name="created_at"
                        placeholder="Enter Created Date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.created_at}
                        errors={errors.created_at}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        type="date"
                        htmlFor="updated_at"
                        id="updated_at"
                        labelTitle="Updated"
                        name="updated_at"
                        placeholder="Enter Updated Date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.updated_at}
                        errors={errors.updated_at}
                        rowInputClassName={style.filteringInput}
                      />
                      <FormInputs
                        type="date"
                        id="release_date"
                        labelTitle="Scheduled Date"
                        htmlFor="release_date"
                        name="release_date"
                        placeholder="Enter Scheduled Date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.release_date}
                        errors={errors.release_date}
                        rowInputClassName={style.filteringInput}
                      />

                      <FormInputs
                        type="checkbox"
                        id="force_update"
                        labelTitle="Force Update: "
                        htmlFor="force_update"
                        name="force_update"
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                        value={values.force_update}
                        errors={errors.force_update}
                        rowInputClassName={style.filteringInput}
                        inputClassName={style.checkboxFilteringInput}
                        romCheckboxFiltering={style.romCheckboxFiltering}
                      />

                      <FormInputs
                        type="checkbox"
                        id="incremental_update"
                        labelTitle="Incremental: "
                        htmlFor="incremental"
                        name="is_incremental"
                        onChange={setFieldValue}
                        onBlur={handleBlur}
                        value={values.is_incremental}
                        errors={errors.is_incremental}
                        rowInputClassName={style.filteringInput}
                        inputClassName={style.checkboxFilteringInput}
                        romCheckboxFiltering={style.romCheckboxFiltering}
                      />

                      <div>
                        <Button
                          className={style.filteringButton}
                          type="submit"
                          color="primary"
                          size="md"
                        >
                          Apply filters
                        </Button>
                        <Button
                          className={style.filteringButton}
                          color="danger"
                          size="md"
                          type="reset"
                          onClick={() => {
                            handleReset(values);
                            handleSubmit();
                          }}
                        >
                          Reset filters
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            />
            <br />

            <Fragment>
              {roms && get(roms, "data") && get(roms, "data").length > 0 ? (
                <AppTable
                  renderTableFieldNames={this._renderTableFieldNames}
                  renderResultRows={this._renderResultRows}
                  numberOfCells={10}
                  toggleDeleteModal={this._toggleDeleteModal}
                  selectAll={selectAll}
                  onSelectAll={this._onSelectAll}
                  selectedRows={selectedRows}
                  loading={loading}
                />
              ) : (
                <Alert color="danger">No results found</Alert>
              )}
              {roms && renderPagination(roms, this._onChange)}
            </Fragment>
          </CardBody>
        </Card>
        <DeleteModal
          selectedRows={selectedRows}
          onToggle={this._toggleDeleteModal}
          onDelete={onDeleteRom}
          onMultipleDelete={onDeleteRoms}
          id={romId}
          isOpen={showDeleteModal}
          modalTitle="Delete ROM"
          responseError={responseError}
          toggleResponseError={toggleErrorResponse}
          resetIds={this._resetIds}
        >
          {modalMessage}
        </DeleteModal>
      </div>
    );
  }
}

export default withRouter<any, any>(RomsTable);
