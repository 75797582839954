import React, { Component, memo } from "react";
import * as Yup from "yup";
import { withRouter } from "react-router";
import { ICreateUser, ISingleUser } from "../../../common/interfaces";
import { Formik } from "formik";
import { get } from "lodash";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Alert,
  ModalFooter,
  Spinner
} from "reactstrap";
import style from "./UsersForm.module.scss";
import FormInputs from "../FormInputs";

const validationSchema: any = function(values: any): any {
  //const passwordValue = get(values, "password");
  values.operator_id =
    get(values, "operator_id") === undefined ? "1" : values.operator_id;

  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    password: Yup.string().min(
      values.password.length > 0 ? 6 : 0,
      `Password name has to be at least 6 characters`
    ),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .when("password", {
        is: password => password.length > 0,
        then: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm password is required")
      }),
    operator_id: Yup.string()
      .nullable(true)
      .when("role_id", {
        is: role_id => role_id !== "SYS_ADMIN",
        then: Yup.string()
          .min(1)
          .required("Operator is required")
      }),
    role_id: Yup.string()
      .min(1)
      .required("Role is required"),
    status: Yup.string()
      .max(1, "Status is required")
      .required("Status is required")
  });
};

const validationEditSchema: any = function(values: any): any {
  //const passwordValue = get(values, "password");
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    password: Yup.string().min(
      values.password.length > 0 ? 6 : 0,
      `Password name has to be at least 6 characters`
    ),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .when("password", {
        is: password => password.length > 0,
        then: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm password is required")
      }),

    operator_id: Yup.string()
      .nullable(true)
      .when("role_id", {
        is: role_id => role_id !== "SYS_ADMIN",
        then: Yup.string()
          .min(1)
          .required("Operator is required")
      }),
    role_id: Yup.string()
      .min(1)
      .required("Role is required"),
    status: Yup.string()
      .max(1, "Status is required")
      .required("Status is required")
  });
};

const validate: any = (getValidationSchema: any): any => {
  return (values: any): any => {
    const validationSchema: any = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError: any = (validationError: any): any => {
  const FIRST_ERROR: any = 0;

  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
};

const initialValues: ICreateUser = {
  name: "",
  email: "",
  password_confirmation: "",
  password: "",
  operator_id: "",
  role_id: "",
  status: ""
};

interface IPropsInterface {
  onShowForm(): void;
  createNewUser?(data: ICreateUser): void;
  onEditUser?(data: ICreateUser): void;
  statuses: Array<{ id: number; name: string }>;
  roles: Array<{ id: string; name: string }>;
  operators: Array<{ id: number; name: string }>;
  title: string;
  data?: ISingleUser;
  match: any;
  edit?: boolean;
  roleName: string | null;
}

interface IStateInterface {
  responseError: { details: object; message: string } | boolean | null;
  operator: number | null;
  loading: boolean;
}

class UserForm extends Component<IPropsInterface, IStateInterface> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      responseError: null,
      operator: null,
      loading: false
    };
  }

  _generateStatusOptions: Function = (
    statusList: Array<{ name: string; id: number }>
  ) => {
    return statusList.map((item: { id: number; name: string }) => (
      <option value={item.id} key={item.id}>
        {item.name}
      </option>
    ));
  };

  _generateList = (
    list: Array<{ id: number; name: string }>
  ): Array<JSX.Element> => {
    return (
      list &&
      list.map((item: { id: number; name: string }) => (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      ))
    );
  };

  _generateRolesList = (
    list: Array<{ id: string; name: string }>
  ): Array<JSX.Element> => {
    const { operators } = this.props;
    return (
      list &&
      list.map((item: { id: string; name: string }) => (
        <option
          value={item.id}
          key={item.id}
          disabled={operators?.length <= 0 && item.id === "OPR_ADMIN"}
        >
          {item.name}
        </option>
      ))
    );
  };

  _onSubmit: any = async (
    values: any,
    { setSubmitting, setErrors, resetForm }: any
  ): Promise<void> => {
    const { createNewUser, onEditUser, edit }: IPropsInterface = this.props;
    this.setState({ loading: true });
    if (values.role_id === "SYS_ADMIN") {
      values.operator_id = null;
    }

    if (edit && onEditUser) {
      const result: any = await onEditUser(values);
      if (
        get(result, "data.error") ||
        get(result, "response.data.error") ||
        !result
      ) {
        this.setState({
          responseError:
            get(result, "data.error") ||
            get(result, "response.data.error") ||
            "Error"
        });
      } else {
        this.setState({
          responseError: false
        });
      }
      this.setState({ loading: false });
    } else if (createNewUser) {
      const result: any = await createNewUser(values);
      if (
        get(result, "data.error") ||
        get(result, "response.data.error") ||
        !result
      ) {
        this.setState({
          responseError:
            get(result, "data.error") ||
            get(result, "response.data.error") ||
            "Error"
        });
      } else {
        this.setState({
          responseError: false
        });
        resetForm();
      }
      this.setState({ loading: false });
    }
  };

  _renderAlert: Function = () => {
    const { responseError }: IStateInterface = this.state;
    if (responseError) {
      const {
        details
      }: { details: object; message: string } | boolean | any = responseError;

      return (
        <Alert color="warning">
          {!details && responseError && get(responseError, "message") && (
            <p>{get(responseError, "message")}</p>
          )}

          {details &&
            Object.keys(details).map((item: any, index: any) => (
              <div key={index}>{`${item}: ${details[item]}`}</div>
            ))}
        </Alert>
      );
    }
  };

  render(): JSX.Element {
    const {
      onShowForm,
      edit,
      statuses,
      operators,
      roles,
      data
    }: {
      onShowForm(): void;
      data?: ISingleUser;
      edit?: boolean;
      statuses: Array<{ id: number; name: string }>;
      operators: Array<{ id: number; name: string }>;
      roles: Array<{ id: string; name: string }>;
    } = this.props;
    const { loading }: IStateInterface = this.state;
    if ((operators && operators.length) || (roles && roles.length)) {
      initialValues.operator_id = get(operators[0], "id").toString();
      initialValues.role_id = get(roles[0], "id");
      initialValues.status = get(statuses[1], "id");
    } else {
      return (
        <div className="d-flex justify-content-center mt-3 in">
          <Spinner color="primary" />
        </div>
      );
    }

    if (loading) {
      return (
        <div className="d-flex justify-content-center mt-3 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <div>
        {this._renderAlert()}
        <Formik
          initialValues={
            edit
              ? {
                  ...data,
                  password_confirmation: "",
                  password: ""
                }
              : initialValues
          }
          validate={
            edit ? validate(validationEditSchema) : validate(validationSchema)
          }
          onSubmit={this._onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue
          }: any): any => {
            return (
              <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                <FormGroup row className="my-0">
                  <Col xs="6">
                    <FormInputs
                      labelTitle="Name"
                      type="text"
                      id="name_form"
                      name="name"
                      autoComplete="new-name"
                      placeholder="Enter name"
                      valid={touched.name && !errors.name}
                      invalid={touched.name && !!errors.name}
                      onChange={handleChange}
                      errors={errors.name}
                      value={values.name}
                    />
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <FormInputs
                        labelTitle="Email"
                        type="email"
                        id="email_form"
                        name="email"
                        placeholder="Enter email"
                        required
                        valid={touched.email && !errors.email}
                        invalid={touched.email && !!errors.email}
                        onChange={handleChange}
                        value={values.email}
                        errors={errors.email}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <FormInputs
                        labelTitle="Password"
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Enter password"
                        required
                        valid={touched.password && !errors.password}
                        invalid={touched.password && !!errors.password}
                        onChange={handleChange}
                        errors={errors.password}
                        value={values.password}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <FormInputs
                        labelTitle="Confirm password"
                        type="password"
                        id="password_confirmation"
                        name="password_confirmation"
                        placeholder="Confirm password"
                        required
                        valid={
                          touched.password_confirmation &&
                          !errors.password_confirmation
                        }
                        invalid={
                          touched.password_confirmation &&
                          !!errors.password_confirmation
                        }
                        onChange={handleChange}
                        errors={errors.password_confirmation}
                        value={values.password_confirmation}
                      />
                    </FormGroup>
                  </Col>

                  <Col hidden={roles?.length === 0} xs="6">
                    <FormGroup>
                      <FormInputs
                        labelTitle="Role"
                        type="select"
                        name="role_id"
                        id="role_id_form"
                        required
                        valid={touched.role_id && !errors.role_id}
                        invalid={touched.role_id && !!errors.role_id}
                        onChange={handleChange}
                        value={values.role_id}
                        option={
                          <option value="" label="Select role">
                            Select role
                          </option>
                        }
                        renderData={this._generateRolesList(roles)}
                        errors={errors.role_id}
                      />
                    </FormGroup>
                  </Col>

                  {values.role_id !== "SYS_ADMIN" &&
                  operators &&
                  get(operators[0], "name") ? (
                    <Col xs="6" hidden={operators?.length === 0}>
                      <FormGroup>
                        <FormInputs
                          labelTitle="Operator"
                          type="select"
                          name="operator_id"
                          id="operator_id"
                          required
                          valid={touched.operator_id && !errors.operator_id}
                          invalid={touched.operator_id && !!errors.operator_id}
                          onChange={handleChange}
                          //onBlur={handleBlur}
                          value={values.operator_id}
                          errors={errors.operator_id}
                          options={
                            <option value="" label="Select operator">
                              Select operator
                            </option>
                          }
                          renderData={this._generateList(operators)}
                        />
                      </FormGroup>
                    </Col>
                  ) : (
                    <Input
                      type="hidden"
                      name="operator_id"
                      id="operator_id"
                      value={operators ? get(operators[0], "id") : ""}
                    />
                  )}

                  <Col xs="6">
                    <FormGroup>
                      <FormInputs
                        labelTitle="Status"
                        type="select"
                        id="status"
                        name="status"
                        required
                        valid={touched.status && !errors.status}
                        invalid={touched.status && !!errors.status}
                        onChange={(event: any): any => {
                          handleChange(event);
                          setFieldValue("status", parseInt(event.target.value));
                        }}
                        //onBlur={handleBlur}
                        option={
                          <option value={100} label="Select status">
                            Select status
                          </option>
                        }
                        renderData={this._generateStatusOptions(statuses)}
                        value={values.status}
                        errors={errors.status}
                      />
                      <FormFeedback>{errors.status}</FormFeedback>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <ModalFooter className={style.footerPadding}>
                  <Button
                    type="button"
                    color="danger"
                    size="md"
                    onClick={onShowForm}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    size="md"
                    //disabled={!edit && !isValid}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter<any, any>(memo(UserForm));
