import React, { useState } from "react";
import { Modal, Button, Radio, RadioChangeEvent } from "antd";
import style from "./ModalRemoteAction.module.scss";

const ModalResetDevice = ({
  title,
  showModal,
  emitEventSocket,
  toggleModal
}) => {
  const [value, setValue] = useState(0);

  const onChange = (event: RadioChangeEvent) => {
    setValue(event.target.value);
  };

  return (
    <Modal
      className={style.Modal}
      title={title}
      visible={showModal}
      onCancel={toggleModal}
      footer={[
        <Button key="back" onClick={toggleModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            emitEventSocket(value);
          }}
        >
          Send
        </Button>
      ]}
    >
      <div className="d-flex">
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={0}>Soft Reset</Radio>
          <Radio value={1}>Full Reset</Radio>
        </Radio.Group>
      </div>
    </Modal>
  );
};

export default ModalResetDevice;
