import { CORE_API_DOMAIN } from "../../API_routes";
import axios from "axios";

const apiPrefix: string = `${CORE_API_DOMAIN}public`;

interface IRecovery {
  apiKey: string;
  macAddress: string;
  serialNumber: string;
}

interface IRecoveryPath {
  data: {
    resource: {
      recovery_path: string;
    };
  };
  status?: number;
}

export const doRecovery: Function = async ({
  apiKey,
  macAddress,
  serialNumber
}: IRecovery): Promise<IRecoveryPath | Error | void> => {
  try {
    const result: IRecoveryPath = await axios.get(
      `${apiPrefix}/recovery-rom?${macAddress ? "macAddress=" + macAddress : ""}${(serialNumber && macAddress) ? "&" : ""}${serialNumber ? "serialNumber=" + serialNumber : ""}`,
      { headers: { Authorization: `Bearer ${apiKey}` } }
    );

    if (result.status === 200) {
      return result;
    }
  } catch (err) {
    return err;
  }
};
