import React, { Component, memo } from "react";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import moment from "moment";
import TimeAgo from "react-timeago";
import Pagination from "app-components/ui/Pagination";
import style from "./RemoteAssistanceActionsHistoryTable.module.scss";
import { Table } from "reactstrap";
import {
  IFilteredRemoteAssistancesSessions,
  ISingleActionInterface
} from "../../../common/interfaces";

interface IPropsInterface {
  match: any;
  remoteAssistanceActionsHistory: any | null;
  getRemoteAssistanceActionHistory(pageNumber): void;
}

interface IStateInterface {
  remoteAssistanceActionsHistory: IFilteredRemoteAssistancesSessions | null;
}

class ActionsHistoryTable extends Component<IPropsInterface, IStateInterface> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      remoteAssistanceActionsHistory: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (
      nextProps.remoteAssistanceActionsHistory !==
      this.state.remoteAssistanceActionsHistory
    ) {
      this.setState({
        remoteAssistanceActionsHistory: nextProps.remoteAssistanceActionsHistory
      });
    }
  }
  // @ts-nocheck
  async componentDidMount(): Promise<void> {
    // const {
    //   match: {
    //     params: { ID }
    //   }
    // }: IPropsInterface = this.props;

    try {
      this._getRemoteAssistanceActionHistory(1);
    } catch (e) {
      //console.info(e);
    }
  }

  _parseDataTitle = nameAction => {
    if (nameAction === "INSTALL_APK") {
      return "INSTALL APP";
    }
    return nameAction.replace(/_/g, " ");
  };

  _renderResultRows = (
    remoteAssistanceActionsHistory: IFilteredRemoteAssistancesSessions | null
  ): React.ReactNode => {
    const data: any = get(remoteAssistanceActionsHistory, "data");

    if (data) {
      return data.map((item: ISingleActionInterface) => {
        let createdDate: string = item.created_at
          ? moment(parseInt(item.created_at) * 1000).format(
              "MM DD YYYY HH:mm:ss"
            )
          : "";

        const updatedDate: string = item.updated_at
          ? moment(parseInt(item.updated_at) * 1000).format(
              "MM DD YYYY HH:mm:ss"
            )
          : "";

        return (
          <tr key={item.id}>
            <td>{this._parseDataTitle(item.action)}</td>
            <td>
              <TimeAgo date={createdDate} live={false} />
            </td>
            <td>
              <TimeAgo date={updatedDate} live={false} />
            </td>
          </tr>
        );
      });
    }
  };

  _getRemoteAssistanceActionHistory = async (pageNumber: number) => {
    if (pageNumber) {
      const { getRemoteAssistanceActionHistory } = this.props;
      getRemoteAssistanceActionHistory(pageNumber);
    }
  };

  _renderPagination: any = (
    remoteAssistanceActionsHistory: IFilteredRemoteAssistancesSessions | null
  ): JSX.Element => {
    return (
      <div className={style.bottomPaginationWrapper}>
        <Pagination
          onChange={e => {
            this._getRemoteAssistanceActionHistory(e);
          }}
          current={get(remoteAssistanceActionsHistory, "current_page")}
          // @ts-ignore
          total={parseInt(get(remoteAssistanceActionsHistory, "total"))}
          defaultPageSize={parseInt(
            // @ts-ignore
            get(remoteAssistanceActionsHistory, "per_page")
          )}
        />
      </div>
    );
  };

  render() {
    const { remoteAssistanceActionsHistory } = this.state;

    if (
      remoteAssistanceActionsHistory?.data?.length &&
      remoteAssistanceActionsHistory?.data?.length < 0
    ) {
      return <span>No actions history info at the moment</span>;
    }

    return (
      <>
        <Table hover bordered striped responsive size="sm">
          <thead>
            <tr key="remote_assistance_action_history_thead">
              <th>Action</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {this._renderResultRows(remoteAssistanceActionsHistory)}
          </tbody>
        </Table>
        {remoteAssistanceActionsHistory &&
          this._renderPagination(remoteAssistanceActionsHistory)}
      </>
    );
  }
}

export default withRouter<any, any>(memo(ActionsHistoryTable));
