import React, { Component } from "react";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { Container, Spinner } from "reactstrap";
import {
  ICreateRomInterface,
  IFilteredRoms,
  IRomsInterface,
  ISingleRomInterface
} from "../../common/interfaces";
import { getOperatingSystems } from "../../common/api/common";
import ModalComponent from "../../app-components/ModalComponent";
import "./RomsListPage.scss";
import {
  createRom,
  deleteRom,
  deleteRoms,
  getRoms
} from "../../common/api/roms";
import RomsTable from "../../app-components/Tables/RomsTable";
import RomsForm from "../../app-components/Forms/RomsForm";
import { showAndHideAlertItem } from "../../common/helper/helper";

interface IPropsInterface {
  match: any;
  history: any;
}

interface IStateInterface {
  roms: IRomsInterface | null;
  showCreateRomForm: boolean;
  statuses: Array<{ id: number; name: string }>;
  operatingSystems: Array<{ id: number; name: string }>;
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
  responseError: { details: object; message: string } | boolean | any;
  responseItemMessage: null | string;
}

class RomsListPage extends Component<IPropsInterface, IStateInterface> {
  private readonly showAndHideAlertItem;
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      roms: null,
      showCreateRomForm: false,
      statuses: [
        {
          id: 0,
          name: "Inactive"
        },
        {
          id: 1,
          name: "Active"
        }
      ],
      operatingSystems: [],
      filtering: {
        orderBy: null,
        direction: null
      },
      responseError: null,
      responseItemMessage: null
    };
    this.showAndHideAlertItem = showAndHideAlertItem.bind(this);
  }

  async UNSAFE_componentWillReceiveProps(
    nextProps: IPropsInterface
  ): Promise<void> {
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;
    const {
      match: {
        params: { pageNumber: nextPageNumber }
      }
    }: IPropsInterface = nextProps;

    if (nextPageNumber && pageNumber !== nextPageNumber) {
      try {
        // await this._getRoms(nextPageNumber, null, orderBy, direction);
        await this._getRoms(nextPageNumber, null, null, null);
      } catch (e) {
        // console.log(e);
      }
    }
  }

  async componentDidMount(): Promise<void> {
    const {
      match: {
        params: { pageNumber }
      },
      history: { location }
    }: IPropsInterface = this.props;

    try {
      if (get(location, "state.itemDeleted")) {
        this.showAndHideAlertItem(this.props);
      }

      const operatingSystems: {
        data: { resource: { data: string } };
      } = await getOperatingSystems();
      if (get(operatingSystems, "data.resource.data")) {
        this.setState({
          operatingSystems: get(operatingSystems, "data.resource.data")
        });
      }

      await this._getRoms(pageNumber);
    } catch (e) {
      // console.log(e);
    }
  }

  _createNewRom: Function = async (
    data: ICreateRomInterface
  ): Promise<undefined | object | unknown> => {
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;

    try {
      const result: { status: number } = await createRom(data);
      if (result && result.status === 201) {
        await this._getRoms(pageNumber);
        this.setState({
          showCreateRomForm: false
        });
        this.showAndHideAlertItem(this.props, "ROM is Created Successfully");
      }

      return result;
    } catch (err) {
      return err;
    }
  };

  _getRoms = async (
    pageNumber: number | string = 1,
    limit?: number | null,
    orderBy?: string | null,
    direction?: string | null
  ): Promise<void> => {
    try {
      const roms = await getRoms({ pageNumber, limit, orderBy, direction });

      if (get(roms, "data")) {
        this.setState({
          roms: roms
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _onDeleteRom = async (id: number): Promise<boolean> => {
    const { roms } = this.state;

    const result: { status: number } = await deleteRom(id);

    if (result && result.status === 204) {
      this.showAndHideAlertItem(this.props);
      if (roms) {
        this.setState({
          roms: {
            ...roms,
            data: [
              ...roms.data.filter((rom: ISingleRomInterface) => rom.id !== id)
            ]
          }
        });
      }

      this.showAndHideAlertItem(this.props);

      return true;
    } else {
      this.setState({
        responseError:
          get(result, "data.error.message") ||
          get(result, "response.data.message") ||
          get(result, "data.message") ||
          get(result, "message")
      });

      return false;
    }
  };

  _onDeleteRoms = async (ids: Array<number>): Promise<boolean> => {
    const { roms } = this.state;

    const result: { status: number } = await deleteRoms(ids);

    if (result && result.status === 204) {
      this.showAndHideAlertItem(this.props);
      if (roms) {
        this.setState({
          roms: {
            ...roms,
            data: [
              ...roms.data.filter(
                (rom: ISingleRomInterface) => !ids.includes(rom.id)
              )
            ]
          }
        });
      }

      return true;
    } else {
      this.setState({
        responseError:
          get(result, "data.error.message") ||
          get(result, "response.data.message") ||
          get(result, "data.message") ||
          get(result, "message")
      });

      return false;
    }
  };

  _onFilter: any = (data: { orderBy: string; direction: string }): void => {
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;

    if (data) {
      const {
        orderBy,
        direction
      }: { orderBy: string; direction: string } = data;
      if (orderBy) {
        this.setState(
          {
            filtering: {
              orderBy,
              direction: direction || null
            }
          },
          async () => {
            const {
              filtering: { orderBy, direction }
            }: IStateInterface = this.state;
            try {
              await this._getRoms(pageNumber, null, orderBy, direction);
            } catch (e) {
              // console.log(e);
            }
          }
        );
      }
    }
  };

  _onShowForm: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = (): void => {
    this.setState(
      (state: IStateInterface) => ({
        showCreateRomForm: !state.showCreateRomForm
      }),
      () => {
        const { showCreateRomForm }: IStateInterface = this.state;
        if (showCreateRomForm) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  _getFilteredRoms = async (data: IFilteredRoms): Promise<void> => {
    try {
      const roms = await getRoms({
        pageNumber: data.current_page,
        limit: data.limit,
        orderBy: data.orderBy,
        direction: data.direction,
        id: data.id,
        status: data.status,
        operation_system: data.operation_system,
        build_number: data.build_number,
        kernel_version: data.kernel_version,
        baseband_version: data.baseband_version,
        created_at: data.created_at,
        updated_at: data.updated_at,
        release_date: data.release_date,
        force_update: data.force_update,
        name: data.name,
        is_incremental: data.is_incremental
      });

      if (get(roms, "data")) {
        this.setState({
          roms: roms
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _toggleErrorResponse = (): void => {
    this.setState({
      responseError: null
    });
  };

  render(): JSX.Element {
    const {
      showCreateRomForm,
      roms,
      filtering,
      statuses,
      operatingSystems,
      responseError,
      responseItemMessage
    }: IStateInterface = this.state;

    if (!roms) {
      return (
        <div className="d-flex justify-content-center col-12 mt-5 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <main className="users-table-wrapper in main">
        <Container fluid>
          <RomsTable
            roms={roms}
            filtering={filtering}
            onShowForm={this._onShowForm}
            onDeleteRom={this._onDeleteRom}
            onDeleteRoms={this._onDeleteRoms}
            onFilter={this._onFilter}
            filteredRoms={this._getFilteredRoms}
            operatingSystems={operatingSystems}
            responseError={responseError}
            toggleErrorResponse={this._toggleErrorResponse}
            statuses={statuses}
            responseItemMessage={responseItemMessage}
          />
          <ModalComponent
            modalTitle="Create ROM"
            toggle={this._onShowForm}
            isOpen={showCreateRomForm}
          >
            <RomsForm
              title="Create ROM"
              onShowForm={this._onShowForm}
              createNewRom={this._createNewRom}
              statuses={statuses}
              operatingSystems={operatingSystems}
              roms={roms}
            />
          </ModalComponent>
        </Container>
      </main>
    );
  }
}

export default withRouter<any, any>(RomsListPage);
