import { ICreateDeviceTypesInterface } from "../interfaces";
import axios from "../axiosInstance";
import { CORE_API_DOMAIN } from "../../API_routes";

const apiPrefix: string = `${CORE_API_DOMAIN}/operator-device-type-roms`;

export const createDeviceTypeRoms: any = (
  data: ICreateDeviceTypesInterface
): any => axios.post(`${apiPrefix}`, { ...data });

export const updateDeviceTypeRoms: any = (
  id: number | string,
  data: ICreateDeviceTypesInterface
): any => axios.put(`${apiPrefix}/${id}`, { ...data });
