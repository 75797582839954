import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Input, Spin, Tag } from "antd";
import style from "./ModalRemoteAction.module.scss";
// import Keyboard from "react-simple-keyboard";
// import "react-simple-keyboard/build/css/index.css";
import styleRoot from "../ScreenRemoteAntd/ScreenRemoteAntd.module.scss";

type iProps = {
  title: string;
  showModal: boolean;
  emitEventSocket: any;
  placeHolder: string;
  toggleModal: any;
  nameButton: string;
  upload?: boolean;
  typeForm?: string;
  cleanString?: any;
  image: string;
  onLeft: any;
  onRight: any;
  setLoading: Function;
  loading: boolean;
};

const ModalTextInput = ({
  title,
  showModal,
  emitEventSocket,
  placeHolder,
  toggleModal,
  nameButton,
  typeForm,
  cleanString,
  image,
  onLeft,
  onRight,
  setLoading,
  loading
}: iProps) => {
  const [valueInput, setValueInput] = useState("");
  const [errorMsg, setErrorMsg] = useState<null | string>(null);
  const textInput = useRef<any>();
  const clearInput = () => (textInput.current.value = "");

  useEffect(() => {
    function isValid(str: string) {
      return !/^[a-z]+$/g.test(str.replaceAll(" ", "").trim());
    }
    if (valueInput && isValid(valueInput)) {
      setErrorMsg("Special characters cannot be allowed.");
    } else {
      setErrorMsg(null);
    }
  }, [valueInput]);

  return (
    <Modal
      className={style.Modal}
      title={title}
      visible={showModal}
      onCancel={toggleModal}
      width={1000}
      footer={[
        <Button
          key="delete"
          onClick={cleanString}
          icon={<i className={`material-icons ${style.Icon}`}>backspace</i>}
        ></Button>,
        <Button
          key="navigate_before"
          onClick={onLeft}
          icon={
            <i className={`material-icons ${style.Icon}`}>navigate_before</i>
          }
        ></Button>,
        <Button
          key="navigate_next"
          onClick={onRight}
          icon={<i className={`material-icons ${style.Icon}`}>navigate_next</i>}
        ></Button>,
        <Button
          key="submit"
          type="primary"
          loading={false}
          icon={<i className={`material-icons ${style.Icon}`}>send</i>}
          disabled={!!errorMsg}
          onClick={e => {
            e.preventDefault();
            emitEventSocket(valueInput);
            clearInput();
            setValueInput("");
          }}
        ></Button>
      ]}
    >
      <Spin spinning={loading} className={styleRoot.loadingWrap}>
        <div className={style.WrapImage}>
          <img className={style.image} src={image} alt="Screenshot box" />
        </div>
      </Spin>
      <Input
        type={typeForm || "text"}
        value={valueInput}
        onChange={e => setValueInput(e.target.value)}
        placeholder={placeHolder}
        ref={textInput}
      />
      <Tag color="red" hidden={!errorMsg} className="mb-3">
        {errorMsg}
      </Tag>
      <Button
        className={styleRoot.reloadButton}
        onClick={() => {
          emitEventSocket({
            remote_action_type: 4,
            remote_action_id: "TAKE_SCREENSHOT"
          });
          setLoading(true);
        }}
        icon={<i className="material-icons">update</i>}
      />
    </Modal>
  );
};

export default ModalTextInput;
