import React, { PureComponent } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
  NavLink
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import { validate } from "../../common/validation";
import { Link, withRouter } from "react-router-dom";
import style from "./Login.module.scss";
import {
  doLogin,
  doLoginAccessCode,
  loginOptions
} from "../../common/api/authorization";
import queryString from "query-string";
import { AppNavbarBrand } from "@coreui/react";
import logo from "../../assets/img/brand/wbc.svg";
import { FormFeedback } from "reactstrap/lib";

const initialValues = {
  email: "",
  password: ""
};

const validationSchema = function(values) {
  return Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid")
      .required("Email is required"),
    password: Yup.string().required("Password is required")
  });
};

interface IPropsInterface {
  history: { push(data: string): void };
  location: any;
}

interface IStateInterface {
  email: string | null;
  password: string | null;
  loading: boolean;
  loadingBeforeFetch: boolean;
  loginErrorMessage: any;
  loginSSO: any;
}

class LoginPage extends PureComponent<IPropsInterface, IStateInterface> {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      loading: false,
      loginErrorMessage: null,
      loginSSO: null,
      loadingBeforeFetch: true
    };
  }

  async UNSAFE_componentWillMount() {
    const resultOption = await loginOptions();
    const { accessCode } = queryString.parse(this.props.location.search);
    if (accessCode) {
      const result = await doLoginAccessCode(accessCode);
      if (result && result.status === 200) {
        if (result.data?.resource?.acr.includes("OPR_SUPPORT_LINE")) {
          this.props.history.push("/remote-assistance");
        } else {
          this.props.history.push("/dashboard");
        }
      } else {
        this.setState({
          loginErrorMessage: get(result, "response.data.error.message")
        });
      }
    }

    if (resultOption && resultOption.status === 200) {
      if (resultOption?.data?.resource) {
        this.setState({
          loginSSO: resultOption?.data?.resource
        });
      }
    }
    this.setState({ loadingBeforeFetch: false });
  }

  _onSubmit = async values => {
    if (values && values.email && values.password) {
      const result = await doLogin(values);

      if (result && result.status === 200) {
        if (result.data?.resource?.acr.includes("OPR_SUPPORT_LINE")) {
          this.props.history.push("/remote-assistance");
        } else {
          this.props.history.push("/dashboard");
        }
      } else {
        this.setState({
          loginErrorMessage: get(result, "response.data.error.message")
        });
      }
    }
  };

  render() {
    const { loginErrorMessage, loginSSO, loadingBeforeFetch } = this.state;

    if (loadingBeforeFetch) {
      return (
        <div className="d-flex justify-content-center mt-3 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <AppNavbarBrand
            full={{ src: logo, width: 340, alt: "WBC LOGO" }}
            style={{ cursor: "pointer" }}
            className={style.logoImg}
          />
          <Row className="justify-content-center">
            <Col xs={12} sm={8} md={6} lg={4}>
              <CardGroup md={6}>
                <Card className="p-4">
                  <CardBody>
                    {loginErrorMessage && (
                      <Alert color="warning" className={style.loginAlert}>
                        {loginErrorMessage}
                      </Alert>
                    )}
                    <Formik
                      initialValues={initialValues}
                      validate={validate(validationSchema)}
                      onSubmit={this._onSubmit}
                      render={({
                        values,
                        errors,
                        touched,
                        status,
                        dirty,
                        handleChange,
                        handleBlur,
                        handleSubmit
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          noValidate
                          name="simpleForm"
                        >
                          <h2>Credentials Login</h2>
                          <p className="text-muted">
                            Sign In with your WeBoxControl account
                          </p>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-user" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              id="email"
                              name="email"
                              autoComplete="username"
                              placeholder="Email"
                              invalid={!!errors.email}
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <FormFeedback>{errors.email}</FormFeedback>
                          </InputGroup>

                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="password"
                              id="password"
                              name="password"
                              placeholder="Password"
                              autoComplete="current-password"
                              invalid={!!errors.password}
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            <FormFeedback>{errors.password}</FormFeedback>
                          </InputGroup>
                          <Row>
                            <Col xs="6">
                              <Button
                                type="submit"
                                color="primary"
                                className="px-4"
                              >
                                Login
                              </Button>
                            </Col>
                            <Col xs="6" className="text-right">
                              <Link to={"/auth/password/forgot"}>
                                Forgot password?
                              </Link>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    />
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
            {loginSSO?.length && (
              <Col xs={12} sm={8} md={6} lg={4}>
                <CardGroup>
                  <Card className="p-4">
                    <CardBody>
                      <h2>SSO Login</h2>
                      <p className="text-muted">
                        Sign In with your Operator account
                      </p>
                      {loginSSO.map((log, index) => (
                        <Col xs="12" className="mt-2" key={log.name + index}>
                          <strong>{log.name}</strong>
                          <NavLink
                            className="buttonPurple btn btn-purple mt-1"
                            color="purple"
                            style={{ width: "100%" }}
                            href={log.redirect_url}
                            target="blank"
                            disabled={!log.redirect_url || !log.status}
                          >
                            {log.redirect_label || "No Name"}
                          </NavLink>
                        </Col>
                      ))}
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter<any, any>(LoginPage);
