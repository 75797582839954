import axios, { AxiosRequestConfig } from "axios";
import { doLogout, doRefreshToken } from "../api/authorization";
import { get } from "lodash";
const axiosInstance = axios.create({
  timeout: 10000,
  headers: {
    Accept: "application/json"
  }
});

axiosInstance.interceptors.request.use(
  (req: AxiosRequestConfig): AxiosRequestConfig => {
    if (localStorage.getItem("access_token")) {
      req.headers.Authorization = `Bearer ${localStorage.getItem(
        "access_token"
      )}`;
    }

    // console.log("Renew");

    return req;
  },
  (error: any) => {
    //console.log("logout");

    //do logout
    doLogout();
  }
);

axiosInstance.interceptors.response.use(
  (res: any) => {
    return res;
  },
  (error: any) => {
    const currentRefreshToken: string | null = localStorage.getItem(
      "refresh_token"
    );

    if (get(error, "response.status") === 401) {
      if (currentRefreshToken) {
        const retryRequest: Function = (): any => {
          return axiosInstance(error.config);
        };

        return doRefreshToken(retryRequest);
      } else {
        localStorage.clear();
        document.location.href = "/login";
      }
    } else if (get(error, "response.status") === 404) {
      document.location.href = "/error";
    } else if (get(error, "response.status") === 403) {
      //document.location.href = "/remote-assistance";
    }

    return get(error, "response") ? get(error, "response") : error;
  }
);

export default axiosInstance;
