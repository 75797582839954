import React, { useRef } from "react";
import classNames from "classnames";
import style from "./ScreenRemoteAntd.module.scss";
import { Card } from "reactstrap";
import noScreenshot from "../../../RemoteAssistancesActionsPage/actionImages/placeholder_2.png";
import { ActionButtonAntd } from "..";
import { Action } from "common/interfaces";
import { actionsBottom, actionsTop } from "../../helpers/ActionsRender";
import { Spin } from "antd";

interface IPropsInterface {
  remoteAssistance: any;
  onLogRequest?(): void;
  onScreenshot?(): void;
  onScreenshotError?(): void;
  noErrorLoadImg?(): any;
  screenShot?: any;
  loading?: boolean;
  remoteAssistanceClosed?: any;
  displayStatus?: string;
  emitEventSocket: Function;
  deviceStatus: any;
  showModal: Function;
  setLoading: Function;
  handleLock: Function;
}

export const ActionButtons = ({
  position,
  emitEventSocket,
  actions,
  showModal,
  setLoading,
  handleLock
}) => {
  return actions
    .filter((el: Action) => el.position === position)
    .map((buttonAction: Action, index: number) => (
      <ActionButtonAntd
        key={index}
        buttonAction={buttonAction}
        emitEventSocket={emitEventSocket}
        showModal={showModal}
        setLoading={setLoading}
        handleLock={handleLock}
      />
    ));
};

const ScreenRemoteAntd = ({
  remoteAssistance,
  screenShot,
  loading,
  onScreenshotError,
  noErrorLoadImg,
  displayStatus,
  emitEventSocket,
  deviceStatus,
  showModal,
  setLoading,
  handleLock
}: IPropsInterface) => {
  const inputRef = useRef(null);

  return (
    <Card
      color="remote"
      className={classNames(style.screenshotCard, style.wrapImg)}
    >
      <div className={classNames(style.WrapHelpersButtons, style.Top)}>
        {deviceStatus ? (
          <ActionButtons
            position="top"
            emitEventSocket={null}
            actions={actionsTop(deviceStatus)}
            showModal={showModal}
            setLoading={setLoading}
            handleLock={handleLock}
          />
        ) : null}
      </div>
      <Spin spinning={loading || !screenShot} className={style.loadingWrap}>
        <div className={style.ImgWrap}>
          <img
            ref={inputRef}
            onMouseDown={(event: any) => {
              //@ts-ignore
              const rect = inputRef.current.getBoundingClientRect();
              const naturalWidth = event.currentTarget.naturalWidth;
              const naturalHeight = event.currentTarget.naturalHeight;
              const x = parseInt(
                (
                  (event.pageX - rect.x) *
                  (naturalWidth / rect.width)
                ).toString()
              );
              const y = parseInt(
                (
                  (event.pageY - rect.y) *
                  (naturalHeight / rect.height)
                ).toString()
              );
              emitEventSocket({
                remote_action_type: 1,
                remote_action_id: "TAP_SCREEN",
                x,
                y
              });
              setLoading(true);
            }}
            src={screenShot || noScreenshot}
            onError={onScreenshotError}
            alt="screenshot"
            onLoad={noErrorLoadImg}
            style={{ opacity: displayStatus === "0" ? "0.2" : "1" }}
          />
        </div>
      </Spin>

      <div className={style.WrapHelpersButtons}>
        <ActionButtons
          position="bottom"
          emitEventSocket={emitEventSocket}
          actions={actionsBottom(remoteAssistance?.operator_device)}
          showModal={showModal}
          setLoading={setLoading}
          handleLock={handleLock}
        />
      </div>
    </Card>
  );
};

export default ScreenRemoteAntd;
