import React, { useState, useEffect } from "react";
import {
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  UncontrolledTooltip,
  Button,
  Spinner
} from "reactstrap";
import classNames from "classnames";
import ReactJson from "react-json-view";
import {
  ISingleInstalledApplicationInterface,
  ISingleRemoteAssistanceInterface
} from "../../../../common/interfaces";
import { get, chunk } from "lodash";
import style from "./TabsRemote.module.scss";
import Pagination from "app-components/ui/Pagination";
import DeleteModal from "../../../../app-components/ModalComponent/DeleteModal";
import ActionsHistoryTable from "../../../../app-components/Tables/RemoteAssistanceActionsHistoryTable";

interface IPropsInterface {
  device_status: any | null;
  getStatus: any;
  onUninstallApk: any;
  remoteAssistance: ISingleRemoteAssistanceInterface | null;
  remoteAssistanceClosed: boolean;
  loading: boolean;
  remoteAssistanceActionsHistory: any | null;
  getRemoteAssistanceActionHistory(pageNumber): void;
  installApp(): void;
  updateApp(): void;
  showUninstallApkModal: boolean;
}

const Tabs = ({
  device_status,
  getStatus,
  onUninstallApk,
  remoteAssistance,
  remoteAssistanceClosed,
  loading,
  remoteAssistanceActionsHistory,
  getRemoteAssistanceActionHistory,
  installApp,
  showUninstallApkModal,
  updateApp
}: IPropsInterface) => {
  const [activeTab, setActiveTab] = useState(
    !remoteAssistanceClosed ? "1" : "4"
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [installedApps, setInstalledApps] = useState({});
  const [installedSystemApps, setInstalledSystemApps] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectAPPtoDelete, setSelectAPPtoDelete] = useState({
    application_name: "",
    package_name: ""
  });
  useEffect(() => {
    if (typeof device_status === "object") {
      setInstalledApps(
        get(device_status, "DeviceDetails.installed_applications")
      );
      setInstalledSystemApps(
        get(device_status, "DeviceDetails.system_applications")
      );
    }
  }, [device_status]);

  useEffect(() => {
    setShowDeleteModal(showUninstallApkModal);
  }, [showUninstallApkModal]);

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const _renderInstalledApplications = (
    installedApps: any,
    type: string
  ): JSX.Element => {
    if (installedApps && installedApps?.length > 0) {
      const newDevicesPagination = {
        // @ts-ignore
        pages: chunk(installedApps, [15]),
        numberPage: installedApps.length > 15 ? currentPage : 0
      };
      const paramPagination =
        newDevicesPagination.pages && newDevicesPagination.pages.length > 0
          ? newDevicesPagination.pages[newDevicesPagination.numberPage]
          : [];

      return (
        <Table hover bordered striped responsive size="sm">
          <thead>
            <tr key="installed_application_thead">
              <th>Application Name</th>
              <th>Package Name</th>
              <th>Version Code</th>
              {type === "installedApps" && <th>Actions</th>}
            </tr>
          </thead>
          {/* <tbody>{this._renderResultRows(device_status)}</tbody> */}
          <tbody>{_renderResultRows(paramPagination, type)}</tbody>
        </Table>
      );
    }
    return <span>No installed applications at the moment</span>;
  };

  const _renderResultRows = (
    applications: any,
    type?: string
  ): React.ReactNode => {
    return applications.map(
      (item: ISingleInstalledApplicationInterface, index) => {
        return (
          <tr key={item.package_name + index}>
            <td>{item.application_name}</td>
            <td>{item.package_name}</td>
            <td>{item.version_code}</td>
            {type === "installedApps" && (
              <td className="d-flex justify-content-center">
                <Button
                  className={style.ButtonDelete}
                  color="primary"
                  size="xs"
                  disabled={type !== "installedApps"}
                  id="updateApp"
                  onClick={updateApp}
                >
                  <i className="material-icons">update</i>
                  <UncontrolledTooltip target="updateApp">
                    Update
                  </UncontrolledTooltip>
                </Button>
                <Button
                  className={style.ButtonDelete}
                  color="danger"
                  size="xs"
                  disabled={type !== "installedApps"}
                  id="deleteApp"
                  onClick={() => {
                    setSelectAPPtoDelete({
                      application_name: item.application_name,
                      package_name: item.package_name
                    });
                    setShowDeleteModal(!showDeleteModal);
                  }}
                >
                  <i className="material-icons">delete</i>
                  <UncontrolledTooltip target="deleteApp">
                    Uninstall
                  </UncontrolledTooltip>
                </Button>
              </td>
            )}
          </tr>
        );
      }
    );
  };

  const _renderButtonStatus = (getStatus): JSX.Element => (
    <Button
      className={style.ButtonStatus}
      color="remote-2"
      onClick={getStatus}
      disabled={remoteAssistance && remoteAssistanceClosed ? true : false}
      id="reloadSmall"
    >
      <UncontrolledTooltip target="reloadSmall">Reload</UncontrolledTooltip>
      <i className="material-icons">loop</i>
    </Button>
  );

  const _renderButtonAddApp = (): JSX.Element => (
    <Button
      className={style.ButtonStatus}
      color="remote-2"
      onClick={installApp}
      id="installNewApp"
    >
      <UncontrolledTooltip target="installNewApp">
        Install New App
      </UncontrolledTooltip>
      <i className="material-icons">add_circle_outline</i>
    </Button>
  );

  const _renderDeviceStatus = (device_status: any): JSX.Element => {
    if (
      typeof device_status === "object" &&
      device_status?.device_id.length > 0
    ) {
      return <ReactJson collapsed={true} src={device_status} />;
    }

    return <span>No device status info at the moment</span>;
  };

  const _renderPagination: any = (deviceStatusJson): JSX.Element => {
    return (
      <div className={style.bottomPaginationWrapper}>
        <Pagination
          onChange={e => {
            setCurrentPage(e - 1);
          }}
          current={currentPage + 1}
          total={deviceStatusJson && deviceStatusJson.length}
          defaultPageSize={15}
        />
      </div>
    );
  };

  const _toggleDeleteModal: any = (): void => {
    if (installedApps) {
      setShowDeleteModal(false);
    }
  };

  return (
    <Col xs="12" md="12" className="mb-4">
      {remoteAssistanceClosed ? (
        <h5>
          Squential ID: <strong>{remoteAssistance?.sequential_id}</strong>
        </h5>
      ) : null}
      <Nav tabs>
        {!remoteAssistanceClosed && (
          <>
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === "1"
                })}
                onClick={() => {
                  toggle("1");
                }}
              >
                Installed apps
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === "2"
                })}
                onClick={() => {
                  toggle("2");
                }}
              >
                System Apps
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === "3"
                })}
                onClick={() => {
                  toggle("3");
                }}
              >
                Device Status
              </NavLink>
            </NavItem>
          </>
        )}
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === "4"
            })}
            onClick={() => {
              toggle("4");
            }}
          >
            Actions History
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === "5"
            })}
            onClick={() => {
              toggle("5");
            }}
          >
            Device info
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className={style.TabContent}>
        <Col sm="12">
          {activeTab !== "4" &&
            activeTab !== "5" &&
            _renderButtonStatus(getStatus)}
          {activeTab === "1" && _renderButtonAddApp()}
        </Col>
        {loading ? (
          <div className={style.progressCircle}>
            <Spinner color="primary" />
          </div>
        ) : (
          <>
            <TabPane tabId="1">
              {_renderInstalledApplications(installedApps, "installedApps")}
            </TabPane>
            <TabPane tabId="2">
              {_renderInstalledApplications(
                installedSystemApps,
                "installedSystemApps"
              )}
              {installedSystemApps && _renderPagination(installedSystemApps)}
            </TabPane>
            <TabPane tabId="3">{_renderDeviceStatus(device_status)}</TabPane>
            <TabPane tabId="4">
              {
                <ActionsHistoryTable
                  remoteAssistanceActionsHistory={
                    remoteAssistanceActionsHistory
                  }
                  getRemoteAssistanceActionHistory={
                    getRemoteAssistanceActionHistory
                  }
                />
              }
            </TabPane>
            <TabPane tabId="5">
              <Col sm="12">
                {get(remoteAssistance, "operator_device.name") && (
                  <p>
                    <strong>Device ID: </strong>
                    {get(remoteAssistance, "operator_device.name")}
                  </p>
                )}
                <p>
                  <strong>MAC Address: </strong>
                  {get(remoteAssistance, "operator_device.mac_address")}
                </p>
                <p>
                  <strong>Provision ID: </strong>
                  {get(remoteAssistance, "operator_device.provision_id")}
                </p>
                <p>
                  <strong>UID: </strong>
                  {get(remoteAssistance, "operator_device.uid")}
                </p>
                <p>
                  <strong>Serial Number: </strong>
                  {get(remoteAssistance, "operator_device.serial_number")}
                </p>
                {get(remoteAssistance, "service_desk_id") && (
                  <p>
                    <strong>Service Desk Id: </strong>
                    {get(remoteAssistance, "service_desk_id")}
                  </p>
                )}
              </Col>
            </TabPane>
          </>
        )}
      </TabContent>
      <DeleteModal
        installedApps={installedApps}
        onToggle={_toggleDeleteModal}
        onDelete={onUninstallApk}
        id={selectAPPtoDelete.package_name}
        isOpen={showDeleteModal}
        modalTitle="Uninstall APP"
        setShowDeleteModal={() => setShowDeleteModal(false)}
      >
        Do you want to uninstall the APP:{" "}
        <strong>
          {selectAPPtoDelete.application_name || selectAPPtoDelete.package_name}
        </strong>
      </DeleteModal>
    </Col>
  );
};

export default Tabs;
