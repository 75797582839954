import React, { Component } from "react";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { Container, Spinner } from "reactstrap";
import { ISingleUser, IUsers } from "../../common/interfaces";
import {
  deleteUser,
  deleteUsers,
  getUsers,
  createUser
} from "../../common/api/users";
import { getRoles } from "../../common/api/common";
import ModalComponent from "../../app-components/ModalComponent";
import UserForm from "../../app-components/Forms/UserForm";
import { getOperatorsList } from "../../common/api/operators";
import { getUserProfile } from "../../common/api/authorization";

import { fetchIsForbidden } from "../../redux/actions";
import { connect } from "react-redux";
import UserTable from "../../app-components/Tables/UserTable";
import { showAndHideAlertItem } from "../../common/helper/helper";

interface IPropsInterface {
  match: any;
  fetchIsForbidden: any;
  isForbidden: any;
  history: any;
}

interface IStateInterface {
  users: IUsers | null;
  operators: any;
  showCreateUserForm: boolean;
  statuses: Array<{ id: number; name: string }>;
  roles: Array<{ id: number; name: string }>;
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
  responseError: { details: object; message: string } | boolean | any;
  currentOperator: string | null;
  idCurrentProfile: string | null;
  responseItemMessage: null | string;
}

class UsersListPage extends Component<IPropsInterface, IStateInterface> {
  private readonly showAndHideAlertItem;
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      users: null,
      operators: null,
      showCreateUserForm: false,
      statuses: [
        {
          id: 0,
          name: "Inactive"
        },
        {
          id: 1,
          name: "Active"
        }
      ],
      roles: [],
      currentOperator: null,
      filtering: {
        orderBy: null,
        direction: null
      },
      responseError: null,
      idCurrentProfile: null,
      responseItemMessage: null
    };
    this.showAndHideAlertItem = showAndHideAlertItem.bind(this);
  }

  async UNSAFE_componentWillReceiveProps(
    nextProps: IPropsInterface
  ): Promise<void> {
    const {
      filtering: { orderBy, direction }
    }: IStateInterface = this.state;
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;
    const {
      match: {
        params: { pageNumber: nextPageNumber }
      }
    }: IPropsInterface = nextProps;

    if (nextPageNumber && pageNumber !== nextPageNumber) {
      try {
        await this._getUsers(nextPageNumber, orderBy, direction);
      } catch (e) {
        // console.log(e);
      }
    }
  }

  async componentDidMount(): Promise<void> {
    const {
      match: {
        params: { pageNumber }
      },
      history: { location }
    }: IPropsInterface = this.props;

    try {
      if (get(location, "state.itemDeleted")) {
        this.showAndHideAlertItem(this.props);
      }

      const {
        data: { resource }
      }: any = await getUserProfile();

      if (get(resource, "id")) {
        this.setState({
          idCurrentProfile: get(resource, "id")
        });
      }

      if (get(resource, "role_id") !== "OPR_ADMIN") {
        const operators: {
          data: { resource: { data: any } };
        } = await getOperatorsList();

        if (get(operators, "data.resource")) {
          this.setState({
            currentOperator: get(resource, "role_id"),
            operators: get(operators, "data.resource")
          });
        }
      } else {
        const { operator_id } = resource;

        this.setState({
          currentOperator: get(resource, "role_id"),
          operators: [
            {
              id: operator_id
            }
          ]
        });
      }

      const roles: {
        data: { resource: { data: string } };
      } = await getRoles();

      if (get(roles, "data.resource")) {
        this.setState({ roles: get(roles, "data.resource") });
      }

      await this._getUsers(pageNumber);
      this.props.fetchIsForbidden();
    } catch (e) {
      // console.log(e);
    }
  }

  _createNewUser: Function = async (
    data: ISingleUser
  ): Promise<undefined | object | unknown> => {
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;

    try {
      const result: { status: number } = await createUser(data);
      if (result && result.status === 201) {
        await this._getUsers(pageNumber);
        this.setState({
          showCreateUserForm: false
        });
      }

      return result;
    } catch (er) {
      return er;
    }
  };

  _getUsers = async (
    pageNumber: number | string = 1,
    orderBy?: string | null,
    direction?: string | null
  ): Promise<void> => {
    try {
      const result: {
        data: { resource: [] };
        status: number;
      } = await getUsers(pageNumber, orderBy, direction);
      if (result && get(result, "data.resource")) {
        this.setState({ users: get(result, "data.resource") });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _filterUser = async (
    data2: {
      name: string;
      email: string;
      operators: string;
      role_id: number | string;
      status: number | string;
    },
    page = 1
  ): Promise<void> => {
    try {
      const { name, email, operators, role_id, status } = data2;
      const result: {
        data: { resource: string };
        status: number;
      } = await getUsers(
        page,
        null,
        null,
        name,
        email,
        operators,
        role_id,
        status
      );
      if (result && get(result, "data.resource")) {
        this.setState({ users: get(result, "data.resource") });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _onDeleteUser = async (id: number): Promise<boolean> => {
    const { users } = this.state;
    const result: { status: number } = await deleteUser(id);

    if (result && result.status === 204) {
      this.showAndHideAlertItem(this.props);
      if (users) {
        this.setState({
          users: {
            ...users,
            data: [...users.data.filter((user: ISingleUser) => user.id !== id)]
          }
        });
      }
      return true;
    } else {
      this.setState({
        responseError:
          get(result, "data.error.message") ||
          get(result, "response.data.message") ||
          get(result, "data.message") ||
          get(result, "message")
      });

      return false;
    }
  };

  _onDeleteUsers = async (ids: Array<number | string>): Promise<boolean> => {
    const { users } = this.state;

    const result: { status: number } = await deleteUsers(ids);

    if (result && result.status === 204) {
      this.showAndHideAlertItem(this.props);
      if (users) {
        this.setState({
          users: {
            ...users,
            data: [
              ...users.data.filter(
                (user: ISingleUser) => !ids.includes(user.id)
              )
            ]
          }
        });
      }

      return true;
    } else {
      this.setState({
        responseError:
          get(result, "data.error.message") ||
          get(result, "response.data.message") ||
          get(result, "data.message") ||
          get(result, "message")
      });

      return false;
    }
  };

  _onFilter: any = (data: { orderBy: string; direction: string }): void => {
    const {
      match: {
        params: { pageNumber }
      }
    }: IPropsInterface = this.props;

    if (data) {
      const {
        orderBy,
        direction
      }: { orderBy: string; direction: string } = data;
      if (orderBy) {
        this.setState(
          {
            filtering: {
              orderBy,
              direction: direction || null
            }
          },
          async () => {
            const {
              filtering: { orderBy, direction }
            }: IStateInterface = this.state;
            try {
              await this._getUsers(pageNumber, orderBy, direction);
            } catch (e) {
              // console.log(e);
            }
          }
        );
      }
    }
  };

  _onShowForm: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = (): void => {
    this.setState(
      (state: IStateInterface) => ({
        showCreateUserForm: !state.showCreateUserForm
      }),
      () => {
        const { showCreateUserForm }: IStateInterface = this.state;
        if (showCreateUserForm) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  _toggleErrorResponse = (): void => {
    this.setState({
      responseError: null
    });
  };

  render(): JSX.Element {
    const {
      showCreateUserForm,
      users,
      filtering,
      statuses,
      roles,
      responseError,
      operators,
      currentOperator,
      idCurrentProfile,
      responseItemMessage
    }: IStateInterface = this.state;

    if (!users) {
      return (
        <div className="d-flex justify-content-center col-12 mt-5 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <main className="users-table-wrapper in main">
        <Container fluid>
          <UserTable
            users={users}
            filtering={filtering}
            statuses={statuses}
            currentOperator={currentOperator}
            roles={roles}
            operators={operators}
            onShowForm={this._onShowForm}
            onDeleteUser={this._onDeleteUser}
            onDeleteUsers={this._onDeleteUsers}
            onFilter={this._onFilter}
            responseError={responseError}
            toggleErrorResponse={this._toggleErrorResponse}
            filterUser={this._filterUser}
            idCurrentProfile={idCurrentProfile}
            responseItemMessage={responseItemMessage}
          />
          <ModalComponent
            modalTitle="Create User"
            toggle={this._onShowForm}
            isOpen={showCreateUserForm}
          >
            <UserForm
              title="Create User"
              statuses={statuses}
              roles={roles}
              operators={operators}
              onShowForm={this._onShowForm}
              createNewUser={this._createNewUser}
            />
          </ModalComponent>
        </Container>
      </main>
    );
  }
}
const mapStateToProps = state => {
  return {
    isForbidden: state.isForbidden.forbidden
  };
};

const mapDispatchToProps = dispatch => ({
  fetchIsForbidden: () => dispatch(fetchIsForbidden())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersListPage)
);
