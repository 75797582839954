import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Progress } from "reactstrap";
import classNames from "classnames";
import { mapToCssModules } from "reactstrap/lib/utils";
import setIcon from "../../assets/img/setbox.svg";
const propTypes = {
  header: PropTypes.number,
  icon: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  invert: PropTypes.bool
};

class Widget04 extends Component {
  render() {
    const {
      className,
      cssModule,
      header,
      icon,
      color,
      value,
      children,
      invert,
      total,
      ...attributes
    } = this.props;

    // demo purposes only
    const progress = { style: "", color: color, total: total };
    const card = { style: "", bgColor: "", icon: icon };
    if (invert) {
      progress.style = "progress-white";
      progress.color = "";
      card.style = "text-white";
      card.bgColor = "bg-" + color;
    }

    const classes = mapToCssModules(
      classNames(className, card.style, card.bgColor),
      cssModule
    );
    progress.style = classNames("progress-xs mt-3 mb-0", progress.style);

    return (
      <Card className={`${classes} widget-padding`} {...attributes}>
        <CardBody>
          <div
            className={`h1 text-white text-center mb-2 widget-header-cover bg-${progress.color}`}
          >
            {card.icon === "setbox" ? (
              <img alt="setbox" src={setIcon} />
            ) : (
              <i className={card.icon} />
            )}
          </div>
          <div className="h4 mb-0">{header}</div>
          <small className="text-muted text-uppercase font-weight-bold">
            {children}
          </small>
          <Progress
            className={progress.style}
            color={progress.color}
            value={progress.total}
          />
        </CardBody>
      </Card>
    );
  }
}

Widget04.propTypes = propTypes;

export default Widget04;
