import { all, call, fork, put, takeLatest } from "redux-saga/effects";

//import { DO_LOGIN_FAIL, DO_LOGIN_SUCCESS } from './reducers/auth';
import { fetchAuthRules } from "./actions/actionTypes";
import {
  fetchAuthRulesFailAction,
  fetchAuthRulesSuccessAction
} from "./actions";
import {
  // loginFunction,
  // saveAuthToken,
  getRbac
} from "../common/api/authorization";

// function* loginSaga ({ payload }: any) {
//   try {
//     const result = yield call(loginFunction, payload);

//     if (result) {
//       saveAuthToken(result);
//       yield put({
//         type: DO_LOGIN_SUCCESS,
//         payload: result
//       });
//     } else {
//       yield put({
//         type: DO_LOGIN_FAIL
//         // payload: payload, // do redirect to login
//       });
//     }
//   } catch (err) {
//     yield put({
//       type: DO_LOGIN_FAIL,
//       payload: payload
//     });
//   }
// }

function* fetchAuthRulesSaga () {
  try {
    const result = yield call(getRbac);

    if (result && result.status === 200) {
      if (result && result.data && result.data.resource) {
        yield put(fetchAuthRulesSuccessAction(result.data.resource));
      }
    } else {
      yield put(fetchAuthRulesFailAction());
      // console.info("this is rule fail", result);
    }
  } catch (err) {
    yield put(fetchAuthRulesFailAction());
  }
}

// function* watchLoginSaga () {
//   yield takeLatest(DO_LOGIN, loginSaga);
// }

function* watchAuthorizationSaga () {
  yield takeLatest(fetchAuthRules, fetchAuthRulesSaga);
}

export default function* rootSaga () {
  yield all([
    // fork(watchLoginSaga),
    fork(watchAuthorizationSaga)
  ]);
}
