import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getCountries } from "../../common/api/countries";
import { get, find } from "lodash";

import {
  Badge,
  Button,
  Row,
  Container,
  CardBody,
  Card,
  Spinner
} from "reactstrap";
import moment from "moment";
import ModalComponent from "../../app-components/ModalComponent";
import style from "./OperatorAdministratorDetailsPage.module.scss";
import DeleteModal from "../../app-components/ModalComponent/DeleteModal";
import {
  ICreateOperatorInterface,
  ISingleOperatorInterface
} from "../../common/interfaces";
import {
  getOperatorByID,
  updateOperator,
  deleteOperator,
  getServiceTypes,
  getTypes
} from "../../common/api/operators";
import OperatorForm from "../../app-components/Forms/OperatorForm";

interface IPropsInterface {
  match: any;
  history: { push(data: any): void };
}

interface IStateInterface {
  operator: ISingleOperatorInterface | null;
  statuses: Array<{ id: number; name: string }>;
  roles: Array<{ id: number; name: string }>;
  showForm: boolean;
  deleted: boolean | null;
  errorResponse: string | null;
  showDeleteModal: boolean;
  serviceTypes: Array<{ id: number; name: string }>;
  types: Array<{ id: number; name: string }>;
  countries: [];
}

class OperatorAdministratorDetailsPage extends Component<
  IPropsInterface,
  IStateInterface
> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      operator: null,
      statuses: [
        {
          id: 0,
          name: "Inactive"
        },
        {
          id: 1,
          name: "Active"
        }
      ],
      roles: [],
      showForm: false,
      deleted: null,
      errorResponse: null,
      showDeleteModal: false,
      serviceTypes: [],
      types: [],
      countries: []
    };
  }

  async componentDidMount(): Promise<void> {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    try {
      // const statuses: {
      //   data: { resource: { data: string } };
      // } = await getStatuses("OperatorAdministrator");

      // if (get(statuses, "data.resource.data")) {
      //   this.setState({
      //     statuses: get(statuses, "data.resource.data")
      //   });
      // }

      const serviceTypes = await getServiceTypes();
      if (get(serviceTypes, "data.resource")) {
        this.setState({ serviceTypes: get(serviceTypes, "data.resource") });
      }

      const types = await getTypes();
      if (get(types, "data.resource")) {
        this.setState({ types: get(types, "data.resource") });
      }

      const dataOperatorId = await getOperatorByID(ID);

      if (get(dataOperatorId, "data.resource")) {
        this.setState({
          operator: get(dataOperatorId, "data.resource")
        });
      } else if (get(dataOperatorId, "data.error")) {
        const { message, status } = get(dataOperatorId, "data");
        setTimeout(() => {
          this.props.history.push({
            pathname: "/error",
            state: { message, status }
          });
        }, 2000);
      }

      const countryResult = await getCountries();

      this.setState({
        countries: get(countryResult, "data.resource")
          ? get(countryResult, "data.resource")
          : []
      });
    } catch (e) {
      // console.log(e);
    }
  }

  _getOperator: any = async () => {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;
    try {
      const result: {
        data: { resource: string };
      } = await getOperatorByID(ID);

      if (result && get(result, "data.resource")) {
        this.setState({
          operator: get(result, "data.resource")
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _onShowForm: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = (): void => {
    this.setState(
      (state: IStateInterface) => ({
        showForm: !state.showForm
      }),
      (): void => {
        const { showForm }: IStateInterface = this.state;
        if (showForm) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  _onEditOperatorAdministrator: any = async (
    data: ICreateOperatorInterface
  ): Promise<undefined | object> => {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    try {
      const result: { status: number } = await updateOperator(ID, data);
      if (result && result.status === 200) {
        this._getOperator();
        this.setState({
          showForm: false
        });
      }

      return result;
    } catch (err) {
      return err;
    }
  };

  _onDeleteOperatorAdministrator: any = async (id: number): Promise<void> => {
    const result: {
      status: number;
      request: any;
    } = await deleteOperator(id);
    if (result && result.status === 204) {
      this.setState({
        deleted: true
      });
      setTimeout(() => {
        this.props.history.push({
          pathname: "/operators",
          state: { itemDeleted: true }
        });
      }, 2000);
    } else {
      this.setState({
        deleted: false,
        errorResponse: JSON.parse(result.request.response).error.message
      });
    }
  };

  _renderRoles: Function = (role: {
    id: number;
    name: string;
  }): JSX.Element | undefined => {
    if (role && role.name) {
      switch (role.id) {
        case 1:
          return <Badge color="warning"> {role.name} </Badge>;
        case 2:
          return <Badge color="success"> {role.name} </Badge>;
        case 3:
          return <Badge color="danger"> {role.name} </Badge>;
        default:
          return;
      }
    }

    return;
  };

  _renderStatuses: any = (status: number): JSX.Element | undefined => {
    const { statuses }: IStateInterface = this.state;

    if (statuses && statuses.length) {
      const item: { id: number; name: string } | undefined = find(
        statuses,
        (o: { id: number; name: string }) => o.id === status
      );
      if (item) {
        switch (status) {
          case 0:
            return <Badge color="secondary">{item.name}</Badge>;
          case 1:
            return <Badge color="success">{item.name}</Badge>;
          case 2:
            return <Badge color="danger">{item.name}</Badge>;
          default:
            return;
        }
      }

      return;
    }
  };

  _renderData: any = operator => {
    const operatorAdministratorKeys = [
      "name",
      "status",
      "created_at",
      "updated_at",
      "type",
      "service_type"
    ];
    return operatorAdministratorKeys.map((key: string) => {
      let badge: object | undefined | null;

      if (key === "status") {
        badge = this._renderStatuses(operator[key]);
      } else if (key === "role") {
        badge = this._renderRoles(operator[key]);
      } else if (
        key === "created_at" ||
        key === "updated_at" ||
        key === "last_login"
      ) {
        const datetime: string = moment(parseInt(operator[key]) * 1000).format(
          "DD/MM/YYYY"
        );
        badge = datetime !== "Invalid date" ? <span>{datetime}</span> : null;
      }

      if (
        key === "city" &&
        typeof operator[key] === "object" &&
        operator[key] !== null
      ) {
        operator[key] = operator[key][0].name;
      }

      return (
        <div key={key}>
          <p>
            <strong style={{ textTransform: "capitalize" }}>
              {key.split("_").join(" ")}:{" "}
            </strong>
            {badge
              ? badge
              : get(operator[key], "name")
              ? operator[key].name
              : operator[key]}
          </p>
        </div>
      );
    });
  };

  _renderBottomButtons: any = () => {
    return (
      <div className={style.buttonsWrapper}>
        <Button color="primary" size="md" onClick={this._onShowForm}>
          Edit
        </Button>
        <Button
          color="danger"
          size="md"
          onClick={(): any => {
            this._toggleDeleteModal();
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  _toggleDeleteModal: any = (): void => {
    const { showDeleteModal }: IStateInterface = this.state;
    this.setState({
      showDeleteModal: !showDeleteModal
    });
  };

  render(): JSX.Element {
    const {
      operator,
      showForm,
      statuses,
      roles,
      showDeleteModal,
      errorResponse,
      countries
    }: IStateInterface = this.state;
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    if (!operator) {
      return (
        <div className="d-flex justify-content-center col-12 mt-5 in">
          <Spinner color="primary" />
        </div>
      );
    }

    let modalMessage: string = ID
      ? "This operator will be deleted. Please confirm action!"
      : "Selected operators will be deleted. Please confirm action!";

    return (
      <main className={"main admin-details-page-wrapper in"}>
        <Container fluid>
          <Row>
            <div className="card-title col-12">
              <h1>
                <i className="material-icons perm_data_setting" />
                Operator
              </h1>
              {this._renderBottomButtons()}
            </div>
          </Row>
          <Card>
            <CardBody>{this._renderData(operator)}</CardBody>
          </Card>

          <ModalComponent
            modalTitle="Edit Operator"
            isOpen={showForm}
            toggle={this._onShowForm}
          >
            <OperatorForm
              data={operator}
              edit={true}
              statuses={statuses}
              roles={roles}
              countries={countries}
              title="Edit Operator"
              onShowForm={this._onShowForm}
              onEditOperator={this._onEditOperatorAdministrator}
              types={this.state.types ? this.state.types : ""}
              serviceTypes={
                this.state.serviceTypes ? this.state.serviceTypes : ""
              }
            />
          </ModalComponent>
          <DeleteModal
            onToggle={this._toggleDeleteModal}
            onDelete={this._onDeleteOperatorAdministrator}
            id={ID}
            isOpen={showDeleteModal}
            modalTitle="Delete Operator"
            responseError={errorResponse}
          >
            {modalMessage}
          </DeleteModal>
        </Container>
      </main>
    );
  }
}

export default withRouter<any, any>(OperatorAdministratorDetailsPage);
