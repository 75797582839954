import * as actionTypes from "./actionTypes";
import { ILoginInterface } from "../../common/interfaces";

export const loginAction = (data: ILoginInterface) => {
  return {
    type: actionTypes.doLogin,
    payload: {
      url: data.url,
      email: data.email,
      password: data.password
    }
  };
};

export const fetchIsForbidden = () => {
  return {
    type: actionTypes.isForbidden
  };
};

export const fetchAuthRulesAction = () => {
  return {
    type: actionTypes.fetchAuthRules
  };
};

export const fetchAuthRulesSuccessAction = payload => {
  return {
    type: actionTypes.fetchAuthRulesSuccess,
    payload
  };
};

export const fetchAuthRulesFailAction = () => {
  return {
    type: actionTypes.fetchAuthRulesFail
  };
};
