import React, { Fragment, useEffect, useState } from "react";
import FormInputs from "app-components/Forms/FormInputs";
import AppTable from "app-components/Tables/AppTable";
import { renderPagination } from "common/helper/helper";
import { Formik } from "formik";
import {
  Col,
  Card,
  CardBody,
  Button,
  Alert,
  Form,
  Row,
  Container,
  Input
} from "reactstrap";
import style from "./AlarmPage.module.scss";
import {
  getFailed,
  GetFailedProps,
  updateAcknowledge
} from "common/api/failure";
import Title from "../../components/Title";
import TimeAgo from "react-timeago";
import moment from "moment";

const initialValues: any = {
  type: "",
  acknowledge: null,
  device_id: "",
  error_code: "",
  error_data: "",
  created_at: ""
};

const keys = [
  {
    value: "type",
    name: "Type"
  },

  {
    value: "error_code",
    name: "Error code"
  },
  {
    value: "error_data",
    name: "Error data"
  },

  {
    value: "created_at",
    name: "Created"
  },
  {
    value: "device_id",
    name: "Device"
  },
  {
    value: "acknowledge",
    name: "Acknowledge"
  }
];

const AlarmPagePage = () => {
  const [isLoading, setIsloading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [activeTable, setActiveTab] = useState(null);
  const [acknowledgeChecked, setAcknowledgeChecked] = useState([]);
  const [valuesForFilter, setValuesForFilter] = useState(null);

  const onSelectAll = () => {
    setSelectAll(!selectAll);
    setAcknowledgeChecked([
      ...acknowledgeChecked,
      ...activeTable?.data.map(el => el.id)
    ]);
  };

  const onUpdateAcknowledge = async () => {
    setIsloading(true);
    await updateAcknowledge([...acknowledgeChecked]);
    onSubmit({ type: "", current_page: 1 });
    setIsloading(false);
  };

  useEffect(() => {
    onSubmit({ type: "", current_page: 1 });
  }, []);

  const renderTableFieldNames = (): Array<JSX.Element> => {
    return keys.map(item => (
      <th style={{ cursor: "default" }} key={item.name}>
        {item.name}
      </th>
    ));
  };

  const renderResultRows = () => {
    return activeTable?.data?.map(
      ({
        id,
        acknowledge,
        created_at,
        device_id,
        error_code,
        error_data,
        type
      }) => (
        <tr key={id}>
          <td>{type}</td>

          <td>{error_code}</td>
          <td>{error_data}</td>
          <td>
            <TimeAgo
              date={moment(created_at).format("MM DD YYYY HH:mm")}
              live={false}
            />
          </td>
          <td>
            {
              <a rel="noreferrer" target="_blank" href={`device/${device_id}`}>
                <button
                  type="button"
                  id="info"
                  className="material-icons info_outline btn btn-primary btn-sm"
                ></button>
              </a>
            }
          </td>
          <td className="d-flex justify-content-center p-0 pt-3">
            {/*  */}
            <Input
              type="checkbox"
              disabled={acknowledge || null}
              checked={selectAll || acknowledge || null}
              onChange={e => {
                if (e.target.checked) {
                  setAcknowledgeChecked([...acknowledgeChecked, id]);
                } else {
                  setAcknowledgeChecked([
                    ...acknowledgeChecked.filter(el => el !== id)
                  ]);
                }
              }}
            />
          </td>
        </tr>
      )
    );
  };

  const onChange: any = (page: any) => {
    // const { valuesForFilter }: any = this.state;
    // console.log({ valuesForFilter });
    onSubmit({ ...valuesForFilter, current_page: page });
  };

  const onSubmit = async ({
    type,
    device_id,
    error_data,
    created_at,
    acknowledge,
    error_code,
    current_page
  }: Partial<GetFailedProps>): Promise<void> => {
    setIsloading(true);

    const result = await getFailed({
      type,
      device_id,
      error_data,
      created_at,
      acknowledge,
      error_code,
      current_page: current_page || 1
    });

    if (result?.data?.resource) {
      setActiveTab(result?.data?.resource);
      setValuesForFilter({
        type,
        device_id,
        error_data,
        created_at,
        acknowledge,
        error_code,
        current_page
      });
      setIsloading(false);
    }
  };

  const generateTypeOptions = (
    types: Array<{ name: string; id: number | string }>
  ) => {
    return (
      types &&
      types.map((item: { id: number; name: string }) => (
        <option value={item.id} key={item.id}>
          {item.name}
        </option>
      ))
    );
  };

  return (
    <main className="main in">
      <Container fluid>
        <Title value="Alarms" icon="icon-exclamation" />
        <Col md={12}>
          <Card>
            <CardBody>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                render={({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset
                }: any): any => {
                  return (
                    <Form onSubmit={handleSubmit} noValidate name="simpleForm">
                      <Row className={style.filteringRow}>
                        <FormInputs
                          onChange={handleChange}
                          htmlFor="table"
                          value={values.table}
                          id="error_table"
                          labelTitle="Type"
                          placeholder="Enter table"
                          onBlur={handleBlur}
                          name="type"
                          errors={errors.table}
                          type="select"
                          rowInputClassName={style.filteringInput}
                          renderData={generateTypeOptions([
                            { name: "All", id: "" },
                            { name: "OTA", id: "OTA" },
                            { name: "Push", id: "push" },
                            { name: "Speedtest", id: "speedtest" },
                            { name: "Install", id: "install" },
                            { name: "Socket", id: "SOCKET" }
                          ])}
                        />
                        <FormInputs
                          onChange={handleChange}
                          htmlFor="Device ID"
                          value={values.table}
                          id="device_id"
                          labelTitle="Device ID"
                          placeholder="Device ID"
                          onBlur={handleBlur}
                          name="device_id"
                          errors={errors.device_id}
                          type="text"
                          rowInputClassName={style.filteringInput}
                        />
                        <FormInputs
                          onChange={handleChange}
                          htmlFor="Error Data"
                          value={values.table}
                          id="error_data"
                          labelTitle="Error Data"
                          placeholder="Error Data"
                          onBlur={handleBlur}
                          name="error_data"
                          errors={errors.error_data}
                          type="text"
                          rowInputClassName={style.filteringInput}
                        />
                        <FormInputs
                          onChange={handleChange}
                          htmlFor="Error Code"
                          value={values.table}
                          id="error_code"
                          labelTitle="Error Code"
                          placeholder="Error Code"
                          onBlur={handleBlur}
                          name="error_code"
                          errors={errors.table}
                          type="text"
                          rowInputClassName={style.filteringInput}
                        />
                        <FormInputs
                          onChange={handleChange}
                          htmlFor="acknowledge"
                          value={values.acknowledge}
                          id="acknowledge"
                          labelTitle="Acknowledge"
                          placeholder="Acknowledge"
                          onBlur={handleBlur}
                          name="acknowledge"
                          errors={errors.acknowledge}
                          type="select"
                          rowInputClassName={style.filteringInput}
                          option={
                            <option value="" label="Select Acknowledge">
                              Select Acknowledge
                            </option>
                          }
                          renderData={generateTypeOptions([
                            { name: "No", id: 0 },
                            { name: "Yes", id: 1 }
                          ])}
                        />

                        {/* <FormInputs
                          type="date"
                          htmlFor="created_at"
                          id="created_at"
                          labelTitle="Created"
                          name="created_at"
                          placeholder="Enter Created At"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.created_at}
                          errors={errors.created_at}
                          rowInputClassName={style.filteringInput}
                        /> */}
                        <Button
                          className={style.filteringButton}
                          type="submit"
                          color="primary"
                          size="md"
                        >
                          Apply Filters
                        </Button>

                        <Button
                          className={style.filteringButton}
                          color="danger"
                          size="md"
                          type="reset"
                          onClick={() => {
                            handleReset(values);
                            handleSubmit();
                          }}
                        >
                          Reset filters
                        </Button>
                      </Row>
                    </Form>
                  );
                }}
              />
              <br />
              <Fragment>
                {activeTable?.data?.length > 0 ? (
                  <AppTable
                    renderTableFieldNames={renderTableFieldNames}
                    renderResultRows={renderResultRows}
                    numberOfCells={7}
                    loading={isLoading}
                    actions={false}
                    section="remote-assistance"
                    isAcknowledge={true}
                    onSelectAll={onSelectAll}
                    onSubmit={onUpdateAcknowledge}
                  />
                ) : (
                  <Alert color="danger">No results found</Alert>
                )}
                {activeTable && renderPagination(activeTable, onChange)}
              </Fragment>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </main>
  );
};

export default AlarmPagePage;
