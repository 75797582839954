import React, { Component, createRef, memo } from "react";
import { REACT_UPLOAD_DOMAIN, enableRemoteAssistence } from "../../API_routes";
import { withRouter } from "react-router-dom";
import { get, debounce } from "lodash";
import { Button, Container, Row, Spinner, Badge, Fade } from "reactstrap";
import {
  IFilteredRemoteAssistancesSessions,
  ISingleDeviceInterface,
  ISingleRemoteAssistanceInterface,
  ISingleRomInterface
} from "../../common/interfaces";
import { ISocketInterface, onCreateSocket } from "../../common/socket.io";
import connect from "react-redux/es/connect/connect";
import { fetchAuthRulesAction } from "../../redux/actions";
import RemoteAssistanceModal from "../../app-components/ModalComponent/RemoteAssistanceModal";

import {
  getRemoteAssistanceByID,
  getRemoteAssistanceSessionActionsBySessionId,
  onAction,
  updateRemoteAssistanceSession
} from "../../common/api/remote-assistances";
import noScreenshot from "./actionImages/placeholder_2.png";
import InstallApkModal from "../../app-components/ModalComponent/InstallApkModal";
import UninstallApkModal from "../../app-components/ModalComponent/UninstallApkModal";
import UpdateApkModal from "../../app-components/ModalComponent/UpdateApkModal";
import ScreenshotModal from "../../app-components/ModalComponent/ScreenshotModal";
import ConfirmModal from "../../app-components/ModalComponent/ConfirmModal";
import style from "./RemoteAssistancesActionsPage.module.scss";
import TabsRemote from "./components/TabsRemote";
import Dpad from "./components/Dpad";
import ScreenRemote from "./components/ScreenRemote";
import Draggable from "react-draggable";
import Floating from "./components/Floating";

interface IPropsInterface {
  match: any;
  history: { push(data: any): void };
  currentUser: any;
}

interface IStateInterface {
  remoteAssistance: ISingleRemoteAssistanceInterface | null;
  device: ISingleDeviceInterface | null;
  roms: Array<ISingleRomInterface> | null;
  remoteAssistanceAuthorized: boolean;
  remoteAssistanceClosed: boolean;
  deviceLocked: boolean;
  showRemoteAssistanceModal: boolean;
  socket: ISocketInterface | null;
  screenshot: {
    src: any;
  };
  device_status: any | null;
  showUninstallApkModal: boolean;
  showInstallApkModal: boolean;
  showUpdateApkModal: boolean;
  showScreenshotModal: boolean;
  installApkUrl: string | null;
  uninstallApkPackage: string | null;
  txt: any;
  remoteAssistanceActionsHistory: IFilteredRemoteAssistancesSessions | null;
  timer: null | boolean;
  loading: boolean;
  loadingImg: boolean;
  loadingTabs: boolean;
  showImgFloating: boolean;
  removeFloating: boolean;
  installedApps: any;
  remoteAssistanceActionsHistoryPage: number | string;
  activeTab: string;
  boxOff: boolean;
  showConfirmModal: boolean;
  fadeOut: boolean;
}
const refScreenshot: any = createRef();
let socket: any = null;
class RemoteAssistancesActionsPage extends Component<
  IPropsInterface,
  IStateInterface
> {
  private subscription;
  private checkLoading;
  private removeTimeout;
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      remoteAssistance: null,
      device: null,
      roms: [],
      remoteAssistanceAuthorized: false,
      remoteAssistanceClosed: false,
      deviceLocked: false,
      showRemoteAssistanceModal: false,
      showUninstallApkModal: false,
      showInstallApkModal: false,
      showUpdateApkModal: false,
      showScreenshotModal: false,
      installApkUrl: null,
      uninstallApkPackage: null,
      socket: null,
      txt: "",
      screenshot: {
        src: noScreenshot
      },
      device_status: null,
      remoteAssistanceActionsHistory: null,
      timer: false,
      loading: true,
      showImgFloating: false,
      remoteAssistanceActionsHistoryPage: 1,
      installedApps: [],
      activeTab: "1",
      removeFloating: false,
      loadingImg: false,
      loadingTabs: false,
      boxOff: false,
      showConfirmModal: false,
      fadeOut: true
    };
  }

  async componentDidMount(): Promise<void> {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;
    const { device_status } = this.state;
    socket = onCreateSocket();
    window.addEventListener("scroll", this.listenToScroll);
    try {
      const {
        data: { resource }
      }: any = await getRemoteAssistanceByID(ID);

      this.setState({
        remoteAssistance: resource,
        remoteAssistanceAuthorized: resource.authorized,
        remoteAssistanceClosed: resource.closed,
        // deviceLocked: resource.operator_device.status,
        deviceLocked: get(resource, "operator_device.status"),
        device_status: JSON.parse(resource.device_status),
        txt: `${REACT_UPLOAD_DOMAIN}opr/${get(
          resource,
          "operator_id"
        )}/devices/${get(
          resource,
          "operator_device_id"
        )}/log.txt?t=${Date.now()}`.trim()
        // screenshot: {
        //   src: `${REACT_UPLOAD_DOMAIN}opr/${get(
        //     resource,
        //     "operator_id"
        //   )}/devices/${get(
        //     resource,
        //     "operator_device_id"
        //   )}/screenshot.jpg?t=${Date.now()}`.trim(),
        // },
      });

      if (!resource.closed) {
        //region Socket Events
        // Whenever the server emits 'user joined', log it in the chat body
        if (!socket) return;
        socket.on("remote_assistance_connected", function(data) {
          if (
            Number(enableRemoteAssistence) === 0 &&
            !resource.authorized &&
            !resource.closed
          ) {
            socket
              .on(get(resource, "operator_device.mac_address"))
              .emit("remote_assistance_command_sent", {
                command: "ALLOW_REMOTE_ASSISTANT",
                options: {
                  callback_params: {
                    remote_assistance_id: get(resource, "id")
                  }
                }
              });
          }
        });

        socket.on("remote_assistance_command_executed", this._handleData);
        socket.on("remote_assistance_command_received", this._handleData);

        // Whenever the server emits 'user left', log it in the chat body
        socket.on("disconnected", function(data) {
          //console.log("disconnected")
        });

        socket.on("disconnect", function() {
          //console.log("disconnected")
        });

        socket.on("reconnect", function() {
          //console.log("socket: reconnect");
          // console.log('you have been reconnected');
          // Tell the server your mac address
          socket.emit("remote_assistance_connect", {
            mac_address: get(resource, "operator_device.mac_address")
          });
        });

        socket.on("reconnect_error", function() {
          //console.log("reconnect_error");
          //console.info("attempt to reconnect has failed");
        });

        socket.on("measurement_sent", this._handleData);

        this.setState({
          remoteAssistance: resource,
          remoteAssistanceAuthorized: resource.authorized,
          remoteAssistanceClosed: resource.closed,
          deviceLocked: resource.operator_device.status
        });

        //endregion

        // region Socket Commands
        // Tell the server your mac address
        socket.emit("remote_assistance_connect", {
          mac_address: get(resource, "operator_device.mac_address")
        });
        if (!device_status) {
          setTimeout(() => {
            this._onScreenshot();
            this._onGetStatus();
          }, 700);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    const { remoteAssistance } = this.state;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    window.removeEventListener("scroll", this.listenToScroll, false);
    if (!socket) return;
    socket.off("remote_assistance_connected");
    socket.off("remote_assistance_command_executed");
    socket.off("measurement_sent");
    socket.off("disconnected");
    socket.off("disconnect");
    socket.off("reconnect_error");
    socket.off("remote_assistance_connect");
    socket.off(get(remoteAssistance, "operator_device.mac_address"));
    clearTimeout(this.checkLoading);
    clearTimeout(this.removeTimeout);
  }

  listenToScroll: any = () => {
    this.setState({
      showImgFloating:
        refScreenshot &&
        refScreenshot.current &&
        refScreenshot.current.offsetParent.offsetTop +
          refScreenshot.current.offsetHeight / 2 <=
          window.pageYOffset
    });
  };

  _handleData: any = async data => {
    let { command } = data;
    const { remoteAssistance } = this.state;

    if (command === "SCREENSHOT") {
      this.setState({
        screenshot: {
          src: `${REACT_UPLOAD_DOMAIN}opr/${get(
            this.state.remoteAssistance,
            "operator_id"
          )}/devices/${get(
            this.state.remoteAssistance,
            "operator_device_id"
          )}/screenshot.jpg?t=${Date.now()}`.trim()
        }
      });
    } else if (command === "LOG_REQUEST") {
      this.setState({
        txt: `${REACT_UPLOAD_DOMAIN}opr/${get(
          this.state.remoteAssistance,
          "operator_id"
        )}/devices/${get(
          this.state.remoteAssistance,
          "operator_device_id"
        )}/log.txt?t=${Date.now()}`.trim()
      });
    } else if (command === "GET_STATUS") {
      if (typeof data.measurement !== "undefined") {
        const {
          match: {
            params: { ID }
          }
        }: IPropsInterface = this.props;
        this.setState({ device_status: data.measurement });
        updateRemoteAssistanceSession(ID, {
          device_status: JSON.stringify(data.measurement),
          operator_device_id:
            get(remoteAssistance, "operator_device_id") ||
            get(remoteAssistance, "operator_device_id")
        });
      }
    } else if (command === "ALLOW_REMOTE_ASSISTANT") {
      this._getRemoteAssistance();
    }
  };

  _getRemoteAssistanceActionHistory: any = async (pageNumber: number) => {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;
    this.setState({ remoteAssistanceActionsHistoryPage: pageNumber });
    const result: IFilteredRemoteAssistancesSessions = await getRemoteAssistanceSessionActionsBySessionId(
      pageNumber,
      ID
    );
    this.setState({
      remoteAssistanceActionsHistory: get(result, "data.resource")
    });
  };

  _getRemoteAssistance: any = async () => {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;

    try {
      const result: {
        data: { resource: string };
      } = await getRemoteAssistanceByID(ID);

      if (result && get(result, "data.resource")) {
        this.setState({
          remoteAssistance: get(result, "data.resource"),
          remoteAssistanceAuthorized: get(result, "data.resource.authorized"),
          remoteAssistanceClosed: get(result, "data.resource.closed")
        });
      }
    } catch (e) {
      // console.log(e);
    }
  };

  _debounceScreenShot = debounce(() => {
    this._onScreenshot();
  }, 2000);

  _onAction: any = async (actionId: string): Promise<void> => {
    const { remoteAssistance } = this.state;
    this.setState({ loading: true });
    if (actionId === "GET_STATUS_TABS") {
      this.setState({ loadingTabs: true });
    }

    try {
      await onAction(
        {
          operator_id:
            get(remoteAssistance, "operator.id") ||
            get(remoteAssistance, "operator_id"),
          operator_remote_assistance_session_id: get(remoteAssistance, "id"),
          operator_device_id: get(remoteAssistance, "operator_device_id"),
          action: actionId
        },
        () => {
          const arrCond = [
            "SCREENSHOT",
            "LOG_REQUEST",
            "GET_STATUS",
            "FORCE_UPDATE",
            "REBOOT",
            "DISPLAY_OFF",
            "DISPLAY_ON",
            "LOG_REQUEST",
            "GET_STATUS_TABS"
          ];
          // send command to retrieve screenshot from STB on any action except screenshot or status action
          if (!arrCond.some(e => e === actionId)) {
            this._debounceScreenShot();
          }
          this._getRemoteAssistanceActionHistory(
            this.state.remoteAssistanceActionsHistoryPage
          );
        }
      );
      //this._activeTimer();
    } catch (e) {
      console.log("Error");
    }
  };

  _onScreenshot: any = async (): Promise<void> => {
    //console.log("_onScreenshot 123");
    //clearTimeout(this.checkLoading);
    try {
      if (!socket) return;
      const { remoteAssistance } = this.state;
      this.setState(state => ({ loadingImg: true }));

      await socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "SCREENSHOT",
          options: null
        });
      //this._resetTimer();
      //this.setState((state) => ({ loading: false }));
    } catch (e) {
      // console.info(e);
    } finally {
      const { loadingImg } = this.state;
      this.checkLoading = setTimeout(() => {
        if (loadingImg) {
          this.setState({ loading: false, loadingImg: false, boxOff: true });
        }
      }, 6000);
    }
  };

  _onScreenshotError: any = async (): Promise<void> => {
    this.setState({
      screenshot: {
        src: noScreenshot
      },
      loadingImg: false
    });
  };

  _onLogRequest: any = async (): Promise<void> => {
    try {
      if (!socket) return;
      this._onAction("LOG_REQUEST");
      const { remoteAssistance, txt } = this.state;
      socket.on(get(remoteAssistance, "operator_device.mac_address")).emit(
        "remote_assistance_command_sent",
        {
          command: "LOG_REQUEST",
          options: null
        },
        setTimeout(() => {
          window.open(txt, "_blank");
        }, 500)
      );
    } catch (e) {
      // console.info(e);
    }
  };

  _onGetStatus: any = async (): Promise<void> => {
    try {
      if (!socket) return;
      this._onAction("GET_STATUS");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "GET_STATUS",
          options: null
        });
    } catch (e) {
      //console.info("erro", e);
    } finally {
      setTimeout(() => {
        this.setState({
          loading: false,
          loadingImg: false
        });
      }, 200);
    }
  };

  _onGetStatusTabs: any = async (): Promise<void> => {
    try {
      if (!socket) return;
      this._onAction("GET_STATUS_TABS");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "GET_STATUS",
          options: null
        });
    } catch (e) {
      //console.info("erro", e);
    } finally {
      setTimeout(() => {
        this.setState({
          loadingTabs: false
        });
      }, 200);
    }
  };

  _onReboot: any = async (): Promise<void> => {
    try {
      if (!socket) return;
      this._onAction("REBOOT");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "REBOOT",
          options: null
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onForceUpdate: any = async (): Promise<void> => {
    try {
      if (!socket) return;
      this._onAction("FORCE_UPDATE");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "FORCE_UPDATE",
          options: null
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onLock: any = async (): Promise<void> => {
    try {
      if (!socket) return;
      this._onAction("LOCK");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "LOCK",
          options: null
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onUnlock: any = async (): Promise<void> => {
    try {
      if (!socket) return;
      this._onAction("UNLOCK");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "UNLOCK",
          options: null
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onRemoteLeft: any = async (): Promise<void> => {
    try {
      this._onAction("REMOTE_LEFT");
      if (!socket) return;
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "REMOTE_NAVIGATION",
          options: { command_name: "REMOTE_LEFT" }
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onRemoteRight: any = async (): Promise<void> => {
    try {
      this._onAction("REMOTE_RIGHT");
      if (!socket) return;
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "REMOTE_NAVIGATION",
          options: { command_name: "REMOTE_RIGHT" }
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onRemoteUp: any = async (): Promise<void> => {
    try {
      this._onAction("REMOTE_UP");
      if (!socket) return;
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "REMOTE_NAVIGATION",
          options: { command_name: "REMOTE_UP" }
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onRemoteDown: any = async (): Promise<void> => {
    try {
      if (!socket) return;
      this._onAction("REMOTE_DOWN");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "REMOTE_NAVIGATION",
          options: { command_name: "REMOTE_DOWN" }
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onInstallApk: any = async (installApkUrl: string): Promise<void> => {
    try {
      if (!socket) return;
      this.setState({
        showInstallApkModal: false
      });
      if (installApkUrl) {
        this._onAction("INSTALL_APK");
        const { remoteAssistance } = this.state;
        socket
          .on(get(remoteAssistance, "operator_device.mac_address"))
          .emit("remote_assistance_command_sent", {
            command: "INSTALL_APK",
            options: { url: installApkUrl }
          });
      }
    } catch (e) {
      // console.info(e);
    }
  };

  _onUninstallApk: any = async (uninstallApkPackage): Promise<void> => {
    try {
      if (uninstallApkPackage) {
        if (!socket) return;
        this._onAction("UNINSTALL_APK");
        const { remoteAssistance } = this.state;
        socket
          .on(get(remoteAssistance, "operator_device.mac_address"))
          .emit("remote_assistance_command_sent", {
            command: "UNINSTALL_APK",
            options: { package: uninstallApkPackage }
          })
          .emit("remote_assistance_command_sent", {
            command: "GET_STATUS",
            options: null
          });
        this.setState({
          showUninstallApkModal: false
        });
      }
    } catch (e) {
      // console.info(e);
    }
  };

  _onShowInstallApkModal: any = async (): Promise<void> => {
    this.setState({
      showInstallApkModal: true
    });
  };

  _onShowUpdateApkModal: any = async (): Promise<void> => {
    this.setState({
      showUpdateApkModal: true
    });
  };

  _onShowUninstallApkModal: any = async (): Promise<void> => {
    this.setState({
      showUninstallApkModal: true
    });
  };

  _onShowScreenshotModal: any = async (): Promise<void> => {
    this.setState({
      showScreenshotModal: true
    });
  };

  _onRemoteBack: any = async (): Promise<void> => {
    try {
      this._onAction("REMOTE_BACK");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "REMOTE_NAVIGATION",
          options: { command_name: "REMOTE_BACK" }
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onRemoteOk: any = async (): Promise<void> => {
    try {
      this._onAction("REMOTE_OK");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "REMOTE_NAVIGATION",
          options: { command_name: "REMOTE_OK" }
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onRemoteApps: any = async (): Promise<void> => {
    try {
      this._onAction("REMOTE_APPS");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "REMOTE_NAVIGATION",
          options: { command_name: "REMOTE_APPS" }
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onDisplayOff: any = async (): Promise<void> => {
    try {
      this._onAction("DISPLAY_OFF");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "DISPLAY_OFF",
          options: { command_name: "REMOTE_APPS" }
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onDisplayOn: any = async (): Promise<void> => {
    try {
      this._onAction("DISPLAY_ON");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "DISPLAY_ON",
          options: { command_name: "REMOTE_APPS" }
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onVolumeDown: any = async (): Promise<void> => {
    try {
      this._onAction("VOLUME_DOWN");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "VOLUME_DOWN",
          options: { command_name: "REMOTE_APPS" }
        })
        .emit("remote_assistance_command_sent", {
          command: "GET_STATUS",
          options: null
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onMute: any = async (): Promise<void> => {
    try {
      this._onAction("VOLUME_TOGGLE_MUTE");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "VOLUME_TOGGLE_MUTE",
          options: { command_name: "REMOTE_APPS" }
        })
        .emit("remote_assistance_command_sent", {
          command: "GET_STATUS",
          options: null
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onVolumeUp: any = async (): Promise<void> => {
    try {
      this._onAction("VOLUME_UP");
      const { remoteAssistance } = this.state;
      socket
        .on(get(remoteAssistance, "operator_device.mac_address"))
        .emit("remote_assistance_command_sent", {
          command: "VOLUME_UP",
          options: { command_name: "REMOTE_APPS" }
        })
        .emit("remote_assistance_command_sent", {
          command: "GET_STATUS",
          options: null
        });
    } catch (e) {
      // console.info(e);
    }
  };

  _onEndSession: any = async (): Promise<void> => {
    const {
      match: {
        params: { ID }
      }
    }: IPropsInterface = this.props;
    const { remoteAssistance, remoteAssistanceAuthorized } = this.state;

    try {
      await updateRemoteAssistanceSession(ID, {
        operator_device_id: get(remoteAssistance, "operator_device_id"),
        service_desk_id: get(remoteAssistance, "service_desk_id"),
        authorized: remoteAssistanceAuthorized,
        closed: 1
      });
      this.setState({ remoteAssistanceClosed: true });
    } catch (e) {
      // console.info(e);
    } finally {
      const { history } = this.props;
      history.push(`/remote-assistance`);
    }
  };

  _toggleInstallApkModal: any = (): void => {
    const { showInstallApkModal }: IStateInterface = this.state;
    this.setState({
      showInstallApkModal: !showInstallApkModal
    });
  };

  _toggleUpdateApkModal: any = (): void => {
    const { showUpdateApkModal }: IStateInterface = this.state;
    this.setState({
      showUpdateApkModal: !showUpdateApkModal
    });
  };

  _toggleUninstallApkModal: any = (): void => {
    const { showUninstallApkModal }: IStateInterface = this.state;
    this.setState({
      showUninstallApkModal: !showUninstallApkModal
    });
  };

  _toggleScreenshotModal: any = (): void => {
    const { showScreenshotModal }: IStateInterface = this.state;
    this.setState({
      showScreenshotModal: !showScreenshotModal
    });
  };

  _toggleConfirmModal: any = (): void => {
    const { showConfirmModal }: IStateInterface = this.state;
    this.setState({
      showConfirmModal: !showConfirmModal
    });
  };

  _toggleRemoteAssistanceModal: any = (): void => {
    const { showRemoteAssistanceModal }: IStateInterface = this.state;
    this.setState({
      showRemoteAssistanceModal: !showRemoteAssistanceModal
    });
  };

  _renderActions = (): JSX.Element => {
    const {
      remoteAssistance,
      remoteAssistanceClosed,
      showInstallApkModal,
      showUninstallApkModal,
      showScreenshotModal,
      screenshot,
      device_status,
      loadingImg,
      showConfirmModal,
      showUpdateApkModal
    } = this.state;
    const { currentUser } = this.props;

    return (
      <div className={`row ${style.actionsPlaceholder}`}>
        <div className="col-xl-8 col-md-8">
          <ScreenRemote
            onLock={this._onLock}
            onUnlock={this._onUnlock}
            onDisplayOn={this._onDisplayOn}
            onDisplayOff={this._onDisplayOff}
            onReboot={this._onReboot}
            onForceUpdate={this._onForceUpdate}
            onShowScreenshotModal={this._onShowScreenshotModal}
            onScreenshotError={this._onScreenshotError}
            onLogRequest={this._onLogRequest}
            onScreenshot={this._onScreenshot}
            screenshot={screenshot}
            loadingImg={loadingImg}
            remoteAssistance={remoteAssistance}
            remoteAssistanceClosed={remoteAssistanceClosed}
            noErrorLoadImg={() => this.setState({ loadingImg: false })}
            displayStatus={get(device_status, "Display.status")}
            currentUser={currentUser}
          />
        </div>

        <div color="remote" className=" col-md-4 col-xl-4">
          <Dpad
            onRemoteBack={this._onRemoteBack}
            onRemoteOk={this._onRemoteOk}
            onRemoteApps={this._onRemoteApps}
            disabled={remoteAssistance && remoteAssistanceClosed ? true : false}
            onRemoteLeft={this._onRemoteLeft}
            onRemoteUp={this._onRemoteUp}
            onRemoteDown={this._onRemoteDown}
            onRemoteRight={this._onRemoteRight}
            deviceStatus={device_status}
            onVolumeDown={this._onVolumeDown}
            onVolumeUp={this._onVolumeUp}
            onMute={this._onMute}
            currentUser={currentUser}
          />
        </div>
        <InstallApkModal
          onToggle={this._toggleInstallApkModal}
          onInstallApk={this._onInstallApk}
          isOpen={showInstallApkModal}
          modalTitle="Install APP"
        />
        <UpdateApkModal
          onToggle={this._toggleUpdateApkModal}
          onInstallApk={this._onInstallApk}
          isOpen={showUpdateApkModal}
          modalTitle="Update APP"
        />
        <UninstallApkModal
          onToggle={this._toggleUninstallApkModal}
          onUninstallApk={this._onUninstallApk}
          isOpen={showUninstallApkModal}
          modalTitle="Uninstall APP"
          installedApps={get(
            device_status,
            "DeviceDetails.installed_applications"
          )}
        />
        <ScreenshotModal
          onToggle={this._toggleScreenshotModal}
          isOpen={showScreenshotModal}
          modalTitle="Screenshot"
          screenshot={this.state.screenshot}
        />
        <ConfirmModal
          onToggle={this._toggleConfirmModal}
          isOpen={showConfirmModal}
          modalTitle="End remote session"
          callBack={this._onEndSession}
        />
      </div>
    );
  };

  _reconnect = async () => {
    this._debounceScreenShot();
    this._onGetStatus();
    this.setState({ fadeOut: true });
  };

  render(): JSX.Element {
    const {
      remoteAssistance,
      loading,
      remoteAssistanceClosed,
      device_status,
      remoteAssistanceActionsHistory,
      showImgFloating,
      screenshot,
      removeFloating,
      showUninstallApkModal,
      fadeOut,
      loadingImg,
      loadingTabs,
      remoteAssistanceAuthorized,
      showRemoteAssistanceModal
    }: //boxOff,
    IStateInterface = this.state;

    if (!remoteAssistance) {
      return (
        <div className={style.progressCircle}>
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <main className="users-table-wrapper in main">
        {!remoteAssistanceClosed &&
          (!device_status || screenshot.src.indexOf("placeholder") > 0) && (
            <div className={style.LayerWaiting}>
              <h1>
                Please wait while we connect to the device and get all
                permissions from user!
              </h1>
              <div
                className={style.progressCircle}
                style={{
                  height: "4rem"
                }}
              >
                <Fade
                  in={fadeOut}
                  onEnter={() =>
                    (this.removeTimeout = setTimeout(() => {
                      this.setState({ fadeOut: false });
                    }, 15000))
                  }
                >
                  <Spinner
                    style={{
                      borderWidth: "0.55em",
                      width: "4rem",
                      height: "4rem"
                    }}
                    color="white"
                  />
                </Fade>
              </div>
              <Fade
                className={style.WrapButtonsWaitScreenOff}
                in={!device_status || screenshot.src.indexOf("placeholder") > 0}
                timeout={15000}
              >
                <h2>Device seems to be offline!</h2>
                <Button
                  className={style.buttonStyle}
                  size="md"
                  onClick={() => {
                    this._reconnect();
                  }}
                  id="refreshLayer"
                >
                  <i className="material-icons">loop</i>
                  <span>Refresh</span>
                </Button>
              </Fade>
              <div className={style.WrapButtonsWaitScreen}>
                <Button
                  color="danger"
                  className={style.buttonStyle}
                  size="md"
                  onClick={() => this.setState({ showConfirmModal: true })}
                  id="endSectionLayer"
                >
                  <i className="material-icons">power_settings_new</i>
                  <span>End session</span>
                  {/* <UncontrolledTooltip target="endSectionLayer">
                  End session
                </UncontrolledTooltip> */}
                </Button>
              </div>
            </div>
          )}
        <Container fluid>
          <Row className={!remoteAssistanceClosed ? style.rowClass : ""}>
            <div className="card-title col-md-6 col-sm-12">
              <h1>
                <i className="icon-screen-desktop" />
                Remote Session Status :{" "}
                {!(remoteAssistance && remoteAssistanceClosed) ? (
                  <Badge color="success">Open</Badge>
                ) : (
                  <Badge color="danger">Closed</Badge>
                )}
              </h1>
            </div>
            {!remoteAssistanceClosed && (
              <div className="col-md-6 col-sm-12 text-right">
                <Button
                  className={style.TurnOffButton}
                  color="danger"
                  size="md"
                  onClick={() => this.setState({ showConfirmModal: true })}
                >
                  <i className="material-icons">power_settings_new</i>
                  End Session
                </Button>
              </div>
            )}
            {remoteAssistanceClosed ? (
              <div className="card-title col-md-6 col-sm-12">
                <Button
                  className="buttonPurple icon-btn  btn btn-purple"
                  hidden={localStorage.getItem("acr") === "OPR_VIEWER"}
                  onClick={this._toggleRemoteAssistanceModal}
                >
                  Start a new remote session
                </Button>
              </div>
            ) : null}
          </Row>
          {!remoteAssistanceClosed && (
            <div ref={refScreenshot}>{this._renderActions()}</div>
          )}
          <Row>
            <TabsRemote
              device_status={device_status}
              getStatus={this._onGetStatusTabs}
              remoteAssistance={remoteAssistance}
              remoteAssistanceClosed={remoteAssistanceClosed}
              onUninstallApk={this._onUninstallApk}
              loading={loadingTabs}
              remoteAssistanceActionsHistory={remoteAssistanceActionsHistory}
              getRemoteAssistanceActionHistory={
                this._getRemoteAssistanceActionHistory
              }
              installApp={this._onShowInstallApkModal}
              updateApp={this._onShowUpdateApkModal}
              showUninstallApkModal={showUninstallApkModal}
            />
            <Draggable>
              <Fade
                in={showImgFloating && !removeFloating}
                unmountOnExit={true}
                timeout={400}
              >
                <Floating
                  closeModal={() => this.setState({ removeFloating: true })}
                  screenshot={screenshot}
                  onError={this._onScreenshotError}
                  onScreenshot={this._onScreenshot}
                  loading={loadingImg}
                  deviceStatus={device_status}
                  disabled={
                    (remoteAssistance && remoteAssistanceClosed) || loading
                      ? true
                      : false
                  }
                />
              </Fade>
            </Draggable>
            {showRemoteAssistanceModal && (
              <RemoteAssistanceModal
                toggle={this._toggleRemoteAssistanceModal}
                authorized={remoteAssistanceAuthorized}
                operatorDeviceId={remoteAssistance.operator_device_id}
                isOpen={showRemoteAssistanceModal}
              />
            )}
          </Row>
        </Container>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser?.roles[0] || localStorage.getItem("acr")
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAuthRules: () => dispatch(fetchAuthRulesAction())
});

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(memo(RemoteAssistancesActionsPage))
);
