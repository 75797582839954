import React, { Fragment } from "react";
import { Col } from "reactstrap";
import { get } from "lodash";
import Widget04 from "../Widgets/Widget04";
interface IPropsInterface {
  generateDashboard: Array<{
    values: any;
    name: String;
    clickToRedirect: Function | null;
    color: String;
    icon: String;
  }>;
}

const CardsDashBoard = (props: IPropsInterface) => {
  const { generateDashboard } = props;
  const _renderDashBoard = itemsDashBoard => {
    return itemsDashBoard.map((item, index: number) => {
      const { values, name, clickToRedirect, color, icon } = item;

      return (
        <Col key={index}>
          <Widget04
            style={{ cursor: "pointer" }}
            icon={icon}
            color={color}
            header={get(values, "active")}
            total={(get(values, "active") / get(values, "total")) * 100}
            onClick={clickToRedirect}
          >
            {name}
          </Widget04>
        </Col>
      );
    });
  };

  return <Fragment>{_renderDashBoard(generateDashboard)}</Fragment>;
};

export default CardsDashBoard;
