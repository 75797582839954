import React, { useState, useEffect } from "react";
import {
  ScreenRemoteAntd,
  TabsActionsRemoteAntd,
  DpadAntd,
  ModalRemoteAction,
  ModalTextInput
} from "../";
import style from "../../RemoteAssistancesActionsPageAntd.module.scss";
import { Button, Row, Col, Layout, Tag, Typography, Spin, Space } from "antd";
import { DesktopOutlined, RedoOutlined } from "@ant-design/icons";
import RemoteAssistanceModal from "app-components/ModalComponent/RemoteAssistanceModal";
import { getOperatorConfig } from "common/api/operators-config";
import ModalResetDevice from "../ModalRemoteAction/ModalResetDevice";

const MainContent = ({
  remoteAssistance,
  emitEventSocket,
  reloadHistory,
  statusBox,
  loading,
  onEndSession,
  setLoading,
  speedTest,
  handleLock,
  errorMessage,
  onRetry,
  mandatoryUserConsent
}) => {
  const [showElements, setShowElements] = useState(false);
  const [showModalInstallApp, setShowModalInstallApp] = useState(false);
  const [showModalUpdateApp, setShowModalUpdateApp] = useState(false);
  const [showModalRemoveApp, setShowModalRemoveApp] = useState(false);
  const [showModalAdb, setShowModalAdb] = useState(false);
  const [showModalShell, setShowModalShell] = useState(false);
  const [showModalLog, setShowModalLog] = useState(false);
  const [showModalResetDevice, setShowModalResetDevice] = useState(false);
  const [showModalCustomInput, setShowModalCustomInput] = useState(false);
  const [showModalKeyBoardInput, setShowModalKeyBoardInput] = useState(false);
  const [showRemoteAssistanceModal, setShowRemoteAssistanceModal] = useState(
    false
  );
  const [operatorConfig, setOperatorConfig] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getOperatorConfig();
      if (data) {
        setOperatorConfig({ ...data?.data?.data });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowElements(true);
    }, 20000);
    return () => {
      clearTimeout(timeout);
    };
  }, [showElements]);

  const showModalScreen = (id: string) => {
    switch (id) {
      case "ADB_COMMAND":
        setShowModalAdb(!showModalAdb);
        break;
      case "LOG":
        setShowModalLog(!showModalLog);
        break;
      case "RESET_DEVICE":
        setShowModalResetDevice(!showModalShell);
        break;
      default:
        setShowModalShell(!showModalShell);
    }
  };

  const onClickAction = (type: number, id: string, extra?: any) => {
    setLoading(true);
    emitEventSocket({
      remote_action_type: type,
      remote_action_id: id,
      ...extra
    });
  };

  const messegeFeedback = () => {
    if (statusBox.userRejected) {
      return "User rejected permission. Please ask again!";
    } else {
      return showElements
        ? "Device could not be reached. Please try again later"
        : mandatoryUserConsent
        ? "Requesting user permission... Please wait!"
        : "Connecting to device... Please wait!";
    }
  };

  return (
    <Layout>
      {statusBox.remoteAssistanceClosed || statusBox.on ? (
        <Layout.Content
          style={{
            padding: statusBox.remoteAssistanceClosed
              ? "60px 50px"
              : "20px 50px"
          }}
          className="users-table-wrapper in main"
        >
          <Row>
            <Col span={18}>
              <Typography.Title
                level={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <DesktopOutlined />
                <span style={{ margin: "0 1rem 0 1rem" }}>
                  Remote Session Status:
                </span>

                {!statusBox.remoteAssistanceClosed ? (
                  <Tag color="#87d068">Open</Tag>
                ) : (
                  <Tag color="#f50">Closed</Tag>
                )}
              </Typography.Title>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              {!remoteAssistance.closed ? (
                <Button
                  className={style.TopButtons}
                  onClick={onEndSession}
                  type="primary"
                  size="large"
                  danger
                >
                  <i className="material-icons">power_off</i>
                  End Session
                </Button>
              ) : (
                <Button
                  type="primary"
                  icon={<RedoOutlined />}
                  className={`${style.TopButtons} buttonPurple`}
                  size="large"
                  hidden={localStorage.getItem("acr") === "OPR_VIEWER"}
                  onClick={() =>
                    setShowRemoteAssistanceModal(!showRemoteAssistanceModal)
                  }
                >
                  Start a new remote session
                </Button>
              )}
            </Col>
            <Col hidden={remoteAssistance.closed}>
              <div className="card-title col-md-6 col-sm-12"></div>
            </Col>
          </Row>
          <Row className="mb-4" gutter={16} hidden={remoteAssistance.closed}>
            <Col span={18} className="gutter-row">
              <ScreenRemoteAntd
                remoteAssistance={remoteAssistance}
                screenShot={statusBox.screenShot}
                emitEventSocket={emitEventSocket}
                deviceStatus={statusBox.deviceStatus}
                loading={loading}
                showModal={(id: string) => showModalScreen(id)}
                setLoading={setLoading}
                handleLock={handleLock}
              />
            </Col>
            <Col span={6} className="gutter-row">
              <DpadAntd
                emitEventSocket={emitEventSocket}
                onRemoteBack={() =>
                  onClickAction(0, "KEY_PRESS_BACK", { key_code: 4 })
                }
                onRemoteApps={() =>
                  onClickAction(0, "KEY_PRESS_APPS", { key_code: 3 })
                }
                onRemoteHome={() =>
                  onClickAction(20, "KEY_PRESS_HOME", { key_code: 3 })
                }
                onRemoteUp={() =>
                  onClickAction(0, "KEYCODE_DPAD_UP", { key_code: 19 })
                }
                onRemoteLeft={() =>
                  onClickAction(0, "KEYCODE_DPAD_LEFT", { key_code: 21 })
                }
                onRemoteRight={() =>
                  onClickAction(0, "KEYCODE_DPAD_RIGHT", { key_code: 22 })
                }
                onRemoteDown={() =>
                  onClickAction(0, "KEYCODE_DPAD_DOWN", { key_code: 20 })
                }
                onRemoteOk={() =>
                  onClickAction(0, "KEYCODE_DPAD_CENTER", { key_code: 23 })
                }
                deviceStatus={
                  statusBox.deviceStatus || remoteAssistance?.device_status
                }
                onVolumeDown={() =>
                  onClickAction(0, "KEYCODE_VOLUME_DOWN", { key_code: 25 })
                }
                onVolumeUp={() =>
                  onClickAction(0, "KEYCODE_VOLUME_UP", { key_code: 24 })
                }
                onMute={() =>
                  onClickAction(0, "KEYCODE_MUTE", { key_code: 164 })
                }
                onKeyboardInput={() => setShowModalKeyBoardInput(true)}
                onCustomInput={() => setShowModalCustomInput(true)}
              />
            </Col>
          </Row>
          <TabsActionsRemoteAntd
            deviceStatus={
              !statusBox.remoteAssistanceClosed
                ? statusBox.deviceStatus
                : remoteAssistance?.device_status
            }
            getStatus={() => console.log("getStatus")}
            remoteAssistance={remoteAssistance}
            remoteAssistanceClosed={
              statusBox.remoteAssistanceClosed ? true : false
            }
            loading={!remoteAssistance || loading}
            getRemoteAssistanceActionHistory={() =>
              console.log("_getRemoteAssistanceActionHistory")
            }
            openApp={(package_name: string) => {
              package_name && onClickAction(6, "OPEN_APP", { package_name });
            }}
            updateApp={(url: string) => {
              url && url === "Install App"
                ? setShowModalInstallApp(true)
                : setShowModalUpdateApp(true);
            }}
            onDelete={(package_name: string) => {
              package_name && onClickAction(7, "REMOVE_APP", { package_name });
            }}
            onClearData={(package_name: string) => {
              package_name && onClickAction(24, "CLEAR_DATA", { package_name });
            }}
            installApp={() => console.log("onShowInstallApkModal")}
            showUninstallApkModal={false}
            speedTest={speedTest}
            speedTestIsEnable={operatorConfig?.speedTest?.status}
            reloadHistory={reloadHistory}
            emitEventSocket={emitEventSocket}
          />
        </Layout.Content>
      ) : (
        <div className={style.LayerWaiting}>
          <h1 className="mb-4">{errorMessage || messegeFeedback()}</h1>
          <Spin size="large" spinning={!showElements} />
          <Space size="large">
            {(statusBox.userRejected || showElements) && (
              <Button
                type="primary"
                className={`${style.buttonStyle} mt-4`}
                size="large"
                onClick={() => {
                  setShowElements(false);
                  onRetry();
                }}
              >
                <i className="material-icons">loop</i>
                <span>Retry</span>
              </Button>
            )}
            <Button
              className={`${style.buttonStyle} mt-4`}
              onClick={onEndSession}
              type="primary"
              size="large"
              danger
            >
              <i className="material-icons">power_off</i>
              End Session
            </Button>
          </Space>
        </div>
      )}

      {(showModalInstallApp || showModalUpdateApp) && (
        <ModalRemoteAction
          title={showModalInstallApp ? "Install app" : "Update app"}
          showModal={showModalInstallApp || showModalUpdateApp}
          toggleModal={() =>
            showModalInstallApp
              ? setShowModalInstallApp(!showModalInstallApp)
              : setShowModalUpdateApp(!showModalUpdateApp)
          }
          emitEventSocket={(event: string) => {
            emitEventSocket({
              remote_action_type: 5,
              remote_action_id: "INSTALL_APP",
              apk_url: event
            });
            setShowModalInstallApp(false);
            setShowModalUpdateApp(false);
          }}
          nameButton={showModalInstallApp ? "Install App" : "Update app"}
          placeHolder="Enter a valid url"
          upload={true}
          typeForm="url"
        />
      )}
      <ModalRemoteAction
        title="Remove app"
        showModal={showModalRemoveApp}
        toggleModal={() => setShowModalRemoveApp(!showModalRemoveApp)}
        emitEventSocket={(valueToHandle: string) =>
          emitEventSocket({
            remote_action_type: 7,
            remote_action_id: "REMOVE_APP",
            package_name: valueToHandle
          })
        }
        nameButton="Remove App"
        placeHolder="Enter a package_name"
      />
      <ModalRemoteAction
        title="Adb Command"
        showModal={showModalAdb}
        toggleModal={() => setShowModalAdb(!showModalAdb)}
        emitEventSocket={(command: string) => {
          emitEventSocket({
            remote_action_type: 12,
            remote_action_id: "ADB COMAND",
            command: `adb ${command}`
          });
          setShowModalAdb(false);
        }}
        defaultValue="adb "
        nameButton="Send"
        placeHolder="Enter ADB command"
      />
      <ModalRemoteAction
        title="Download LOG"
        showModal={showModalLog}
        toggleModal={() => setShowModalLog(!showModalLog)}
        emitEventSocket={(lines: number | null) => {
          emitEventSocket({
            remote_action_type: 11,
            remote_action_id: "LOG",
            lines
          });
          setShowModalLog(false);
        }}
        nameButton="Send"
        placeHolder="Enter Number Lines"
        typeForm="number"
      />
      <ModalRemoteAction
        title="Shell command"
        showModal={showModalShell}
        toggleModal={() => setShowModalShell(!showModalShell)}
        emitEventSocket={(command: string) => {
          emitEventSocket({
            remote_action_type: 13,
            remote_action_id: "ADB SHELL COMAND",
            command: `${command}`
          });
          setShowModalShell(false);
        }}
        nameButton="Send"
        defaultValue="adb shell "
        placeHolder="Enter a shell command"
      />
      <ModalRemoteAction
        title="Key Code"
        showModal={showModalCustomInput}
        toggleModal={() => setShowModalCustomInput(!showModalCustomInput)}
        emitEventSocket={(valueToHandle: string) => {
          emitEventSocket({
            remote_action_type: 0,
            remote_action_id: "KEY_PRESS_CUSTOM",
            key_code: parseInt(valueToHandle)
          });
          setShowModalCustomInput(false);
        }}
        nameButton="Send"
        placeHolder="Enter a custom input"
        // typeForm="number"
      />
      <ModalTextInput
        title="Text input"
        showModal={showModalKeyBoardInput}
        toggleModal={() => setShowModalKeyBoardInput(!showModalKeyBoardInput)}
        emitEventSocket={(valueToHandle: string) => {
          setLoading(true);
          emitEventSocket({
            remote_action_type: 2,
            remote_action_id: "KEYBOARD_INPUT",
            input_text: valueToHandle
          });
        }}
        onLeft={() => {
          onClickAction(0, "LEFT", { key_code: 122 });
        }}
        onRight={() => {
          onClickAction(0, "RIGHT", { key_code: 123 });
        }}
        cleanString={() => {
          onClickAction(22, "KEYBOARD_INPUT", { input_text_length: 123 });
        }}
        nameButton="Send"
        placeHolder="Enter some text (alphanumeric only)"
        image={statusBox.screenShot}
        setLoading={setLoading}
        loading={loading}
      />
      <ModalResetDevice
        title="Reset Device"
        showModal={showModalResetDevice}
        toggleModal={() => setShowModalResetDevice(!showModalResetDevice)}
        emitEventSocket={(valueToHandle: number) => {
          emitEventSocket({
            remote_action_type: 27,
            remote_action_id: `RESET_DEVICE_${valueToHandle ? "FULL" : "SOFT"}`,
            type: valueToHandle
          });
          setShowModalResetDevice(false);
        }}
      />
      {remoteAssistance && showRemoteAssistanceModal && (
        <RemoteAssistanceModal
          toggle={() =>
            setShowRemoteAssistanceModal(!showRemoteAssistanceModal)
          }
          authorized={true}
          operatorDeviceId={remoteAssistance.operator_device_id}
          isOpen={showRemoteAssistanceModal}
        />
      )}
    </Layout>
  );
};

export default MainContent;
