import { getDevicesByID, updateDevice } from "common/api/devices";
import { getOperatorConfig } from "common/api/operators-config";
import {
  openSocket,
  updateRemoteAssistanceSession
} from "common/api/remote-assistances";
import { useHistory } from "context/historyActions";
import { useRemoteAssistance } from "context/remoteAssistance";
import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { MainContent } from "./components";
import {
  errorAlert,
  successAlert,
  downloadTxtFile,
  downloadImage,
  GET_STATUS,
  TAKE_SCREENSHOT
} from "./helpers/ultils";
import { onCreateSocket } from "common/socket.io";
import {
  ISingleRemoteAssistanceInterface,
  EmitEventType
} from "common/interfaces";
import { getSecretKeySocket } from "common/api/admin-config";

const VERSION = "2.0.54";

const BlockRemoteAssitances = ({ history }) => {
  const {
    remoteAssistance,
    statusBox,
    typeConfig,
    updatedStatusBox,
    onReloadRemoteAssistance,
    updatedRemoteAssitance
  } = useRemoteAssistance();
  const [loading, setLoading] = useState(true);
  const [mandatoryUserConsent, setMandatoryUserConsent] = useState(false);
  const [initRemote, setInitRemote] = useState(false);
  const [speedTest, setSpeedTest] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const {
    createNewAction,
    updateActionHistory,
    updateHistoryActions
  } = useHistory();

  const compareVersion2 = (ver1: string, ver2: string) => {
    ver1 = ver1
      .split(".")
      .map(s => s.padStart(10))
      .join(".");
    ver2 = ver2
      .split(".")
      .map(s => s.padStart(10))
      .join(".");
    return ver1 > ver2;
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataDevicesByID = await getDevicesByID(
        remoteAssistance?.operator_device_id
      );
      if (dataDevicesByID.status === 200) {
        const version = dataDevicesByID?.data?.resource?.device_control_version;
        if (version && compareVersion2(version, VERSION)) {
          const key = await getSecretKeySocket();
          if (key && remoteAssistance?.operator_device_id) {
            setSocket(onCreateSocket(key));
          }
        } else {
          setSocket(onCreateSocket());
        }
      }
    };
    if (remoteAssistance?.operator_device_id) fetchData();
  }, [remoteAssistance?.operator_device_id]);

  const emitEventSocket = useCallback(
    ({
      remote_action_type,
      remote_action_id,
      key_code,
      apk_url,
      package_name,
      input_text,
      input_text_length,
      volume_index,
      x,
      y,
      command,
      lines,
      type
    }: EmitEventType) => {
      const screenshot = (id: string) => {
        const split = id.includes("_ID_") ? id.split("_ID_")[0] : id;
        return TAKE_SCREENSHOT.includes(split);
      };
      if (!socket) return;
      socket.emit("remote_assistance_command_request", {
        remote_action_id,
        remote_action_type,
        key_code,
        apk_url,
        package_name,
        input_text,
        input_text_length,
        volume_index,
        x,
        y,
        command,
        lines,
        type,
        screenshot: screenshot(remote_action_id)
      });
    },
    [socket]
  );

  const emitConnect = useCallback(
    (mac_address: string) => {
      if (!socket) return;
      const errorMsg =
        "Remote assistance service is temporarily unavailable! Please try again later or contact your system administrator.";
      // Tell the server your mac address
      socket.emit("remote_assistance_connect", {
        // mac_address: "E8186350A59F"
        mac_address
      });

      socket.on("connect_error", function() {
        setErrorMessage(errorMsg);
      });

      socket.on("connect_timeout", function() {
        setErrorMessage(errorMsg);
      });
    },
    [socket]
  );

  const turnOffSocket = useCallback(
    (mac_adress: string | undefined) => {
      if (!socket) return;
      socket.off(mac_adress);
      socket.off("remote_assistance_connected");
      socket.off("remote_assistance_command_response");
      socket.off("remote_assistance_command_executed");
      socket.off("disconnected");
      socket.off("disconnect");
      socket.off("reconnect_error");
    },
    [socket]
  );

  const turnOnSocket = useCallback(
    (remoteAssistance: ISingleRemoteAssistanceInterface) => {
      const { mac_address } = remoteAssistance;
      if (mac_address) {
        emitConnect(mac_address);
        if (!socket) return;
        socket.on("reconnect", function() {
          emitConnect(mac_address);
        });

        emitEventSocket({
          remote_action_type: 3,
          remote_action_id: "FIRST_PING"
        });
      }
    },
    [emitConnect, emitEventSocket, socket]
  );

  const handleDataSocket = useCallback(
    (
      event: any,
      setStatusBox: Function,
      id: string,
      operator_device_id: string,
      mandatoryUserConsent: boolean,
      setLoading: Function,
      setSpeedTest: Function
    ) => {
      const {
        measurement,
        remote_action_id,
        code,
        command,
        error_message,
        status_payload,
        extra_content
      } = event;
      const remoteAction = remote_action_id?.toUpperCase();

      const initRemoteSessionHandle = () => {
        emitEventSocket({ remote_action_type: 3, remote_action_id: "PING" });
        emitEventSocket({
          remote_action_type: 4,
          remote_action_id: "SCREENSHOT"
        });
        emitEventSocket({
          remote_action_type: 16,
          remote_action_id: "GET_STATUS"
        });
      };

      if (code && code >= 200 && remote_action_id) {
        const remoteId = remote_action_id.includes("_ID_")
          ? remote_action_id.split("_ID_")[1]
          : null;
        if (remoteId && code !== 106) {
          const msgSucessMessage = "Action has been submitted and executed.";
          const extraContent =
            extra_content?.length >= 65535 ? msgSucessMessage : extra_content;
          updateActionHistory(remoteId, {
            code,
            responseData: error_message || extraContent || msgSucessMessage
          });
        }
      }

      if (remoteAction?.includes("FIRST_PING")) {
        if (mandatoryUserConsent) {
          emitEventSocket({
            remote_action_type: 21,
            remote_action_id: "ALLOW_REMOTE_ASSISTANT"
          });
        } else {
          initRemoteSessionHandle();
        }
      }

      if (remoteAction?.includes("TEST_SPEED")) {
        setSpeedTest({ ...event });
      }

      if (code === 401 && remoteAction?.includes("ALLOW_REMOTE_ASSISTANT")) {
        setStatusBox({ userRejected: true });
      }
      if (code > 200 && remoteAction) {
        errorAlert(`Action ${remoteAction} has problems`);
        setLoading(false);
      } else if (remoteAction?.includes("APP")) {
        successAlert(`Action ${remoteAction} success`);
      }

      //SUCCESS
      if (code === 200) {
        setLoading(false);
        if (remoteAction?.includes("LOG")) {
          downloadTxtFile(extra_content);
        }
        if (remoteAction?.includes("DOWNLOAD_SCREENSHOT")) {
          downloadImage(event.image);
        }

        if (event?.image) {
          setStatusBox({
            screenShot: `data:image/jpg;base64, ${event?.image}`
          });
        }

        if (remoteAction?.includes("LOCK_UNLOCK")) {
        }

        if (command === "GET_STATUS" || remoteAction?.includes("GET_STATUS")) {
          updateRemoteAssistanceSession(id, {
            device_status: JSON.stringify(measurement) || status_payload,
            operator_device_id: operator_device_id
          });
          setStatusBox({
            deviceStatus:
              JSON.parse(status_payload) || measurement || extra_content
          });
        }

        if (remoteAction === "PING") {
          setStatusBox({
            on: true
          });
        }

        if (remoteAction === "ALLOW_REMOTE_ASSISTANT") {
          initRemoteSessionHandle();
        }

        if (GET_STATUS.includes(remoteAction)) {
          emitEventSocket({
            remote_action_type: 16,
            remote_action_id: "GET_STATUS"
          });
        }
      }
    },
    [emitEventSocket, updateActionHistory]
  );

  const socketResponse = useCallback(
    ({
      operator_device_id,
      setStatusBox,
      mandatoryUserConsent,
      setLoading,
      id,
      setSpeedTest
    }) => {
      if (!socket) return;
      socket.off("disconnected").on("disconnected", function(data) {
        //console.log("disconnected", data);
      });
      socket.off("disconnect").on("disconnect", function(data) {
        //console.log("disconnect", data);
      });
      socket.off("reconnect_error").on("reconnect_error", function(data) {
        //console.log("reconnect_error", data);
      });
      socket
        .off("remote_assistance_command_response")
        .on("remote_assistance_command_response", event => {
          handleDataSocket(
            event,
            setStatusBox,
            id,
            operator_device_id,
            mandatoryUserConsent,
            setLoading,
            setSpeedTest
          );
        });
    },
    [handleDataSocket, socket]
  );

  useEffect(() => {
    if (remoteAssistance && typeConfig && loading && socket) {
      const initPushSock = async () => {
        if (!socket) return;
        const { data, status } = await openSocket({
          device_id: remoteAssistance?.operator_device_id,
          channel_action: 1,
          remote_action: 1,
          remote_session_id: "OPEN_PUSH",
          remote_action_type: 25
        });
        if (status === 200) {
          turnOnSocket(remoteAssistance);
          setTimeout(() => {
            emitEventSocket({
              remote_action_type: 3,
              remote_action_id: "FIRST_PING"
            });
          }, 2000);
        } else {
          errorAlert(data.error?.message);
          setErrorMessage(
            "Could not request device connection! Please set device to debug mode and restart device control app or reboot device"
          );
          setLoading(false);
        }
      };

      const initRemoteSession = async () => {
        const {
          status: statusDevice,
          data: {
            resource: { debug_mode }
          }
        } = await getDevicesByID(remoteAssistance?.operator_device_id);
        const momentNow = moment();
        const momentDebug = moment(debug_mode);
        const isMoreThan24 = momentNow.diff(momentDebug, "hours") > 24;
        const {
          data: {
            data: { pushNotifications }
          },
          status
        } = await getOperatorConfig();

        if (
          typeConfig?.remoteSupport?.async &&
          status === 200 &&
          pushNotifications.status
        ) {
          initPushSock();
          return;
        } else if (statusDevice && !isMoreThan24) {
          turnOnSocket(remoteAssistance);
          return;
        }
      };
      if (!remoteAssistance.closed && !initRemote) {
        initRemoteSession();
        setMandatoryUserConsent(
          typeConfig?.remoteSupport?.mandatoryUserConsent
        );
        socketResponse({
          operator_device_id: remoteAssistance.operator_device_id,
          setStatusBox: updatedStatusBox,
          //mandatoryUserConsent: false,
          mandatoryUserConsent: typeConfig?.remoteSupport?.mandatoryUserConsent,
          setLoading,
          id: remoteAssistance.id,
          setSpeedTest
        });
      }
      // setLoading(false);
      setInitRemote(true);
    }
  }, [
    remoteAssistance,
    typeConfig,
    updatedStatusBox,
    loading,
    turnOnSocket,
    socketResponse,
    initRemote,
    socket,
    emitEventSocket
  ]);

  const onEndSession: any = useCallback(async () => {
    try {
      await updateRemoteAssistanceSession(remoteAssistance?.id, {
        operator_device_id: remoteAssistance?.operator_device_id,
        service_desk_id: remoteAssistance?.service_desk_id,
        //authorized: remoteAssistance.authorized,
        closed: 1
      });
      updatedStatusBox({
        remoteAssistanceClosed: true
      });
      emitEventSocket({
        remote_action_type: 26,
        remote_action_id: "END_SECTION"
      });
    } catch (e) {
      // console.info(e);
    } finally {
      history.push(`/remote-assistance`);
    }
  }, [
    emitEventSocket,
    history,
    remoteAssistance?.id,
    remoteAssistance?.operator_device_id,
    remoteAssistance?.service_desk_id,
    updatedStatusBox
  ]);

  useEffect(() => {
    if (remoteAssistance?.mac_address && turnOffSocket) {
      return () => {
        // onEndSession();
        turnOffSocket(remoteAssistance?.mac_address);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteAssistance?.mac_address, turnOffSocket]);

  const onEmitEventSocket = (values: EmitEventType) => {
    if (!remoteAssistance) return;
    const { operator_device_id } = remoteAssistance;
    const registerActionT = async () => {
      const {
        status,
        data: {
          resource: { id }
        }
      } = await createNewAction({
        operator_device_id,
        action: values.remote_action_id,
        code: 100,
        response_data: "The action has been sent."
      });

      if (status === 201) {
        emitEventSocket({
          ...values,
          remote_action_id: `${values.remote_action_id}_ID_${id}`
        });
      }
    };
    if (values?.remote_action_id !== "TEST_SPEED") {
      registerActionT();
    } else {
      emitEventSocket({
        remote_action_type: 14,
        remote_action_id: "TEST_SPEED"
      });
    }
  };

  const onRetry = () => {
    emitEventSocket({
      remote_action_type: 3,
      remote_action_id: "FIRST_PING"
    });
    updatedStatusBox({ userRejected: false });
    onReloadRemoteAssistance();
  };
  const onReloadHistory = () => updateHistoryActions();

  const onLock = async lockId => {
    const result = await updateDevice(remoteAssistance?.operator_device_id, {
      lock: lockId === "LOCK_UNLOCK_1" ? 0 : 1
    });
    if (result.status === 200 && remoteAssistance) {
      updatedRemoteAssitance({
        operator_device: {
          ...remoteAssistance.operator_device,
          lock: result?.data?.resource?.lock
        }
      });

      onEmitEventSocket({
        remote_action_type: 9,
        remote_action_id: "LOCK_UNLOCK"
      });
    }
  };

  if (!remoteAssistance) return <div>Loading...</div>;

  return (
    <MainContent
      remoteAssistance={remoteAssistance}
      emitEventSocket={onEmitEventSocket}
      statusBox={statusBox}
      loading={loading}
      setLoading={setLoading}
      onEndSession={onEndSession}
      onRetry={onRetry}
      speedTest={speedTest}
      reloadHistory={onReloadHistory}
      handleLock={onLock}
      errorMessage={errorMessage}
      mandatoryUserConsent={mandatoryUserConsent}
    />
  );
};

export default memo(BlockRemoteAssitances);
