import React from "react";
import { Tooltip, Button } from "antd";
import { Action } from "common/interfaces";
import style from "./ActionButtonAntd.module.scss";

const IS_MODAL = ["ADB_COMMAND", "SHELL_COMMAND", "LOG", "RESET_DEVICE"];

const ActionButtonAntd = ({
  buttonAction,
  emitEventSocket,
  showModal,
  setLoading,
  handleLock
}: {
  buttonAction: Action;
  emitEventSocket?: any;
  showModal: Function;
  handleLock: Function;
  setLoading?: Function;
}) => {
  const iconToRender = buttonAction.icon?.on || buttonAction.icon;
  return (
    <Tooltip placement="top" title={buttonAction.label}>
      <Button
        onClick={async () => {
          if (IS_MODAL.includes(buttonAction.key_id)) {
            showModal(buttonAction.key_id);
          } else if (emitEventSocket) {
            if (buttonAction.key_id.includes("LOCK")) {
              handleLock(buttonAction.key_id);
            } else {
              emitEventSocket({
                remote_action_type: buttonAction.type,
                remote_action_id: buttonAction.key_id
              });
              if (buttonAction.key_id === "TAKE_SCREENSHOT" && setLoading) {
                setLoading(true);
              }
            }
          }
        }}
        disabled={buttonAction?.hidden}
        icon={iconToRender}
        className={
          buttonAction.key_id === "TAKE_SCREENSHOT" ? style.btnScreenshot : ""
        }
      />
    </Tooltip>
  );
};

export default ActionButtonAntd;
