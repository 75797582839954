import React from "react";
import { Modal } from "antd";
import style from "./ModalRemoteAction.module.scss";

const ModalRemoteActions = ({ message, showModal, onClose }) => {
  return (
    <Modal
      className={style.Modal}
      title="More details"
      visible={showModal}
      onCancel={onClose}
      width={1000}
      footer={[]}
    >
      {message}
    </Modal>
  );
};

export default ModalRemoteActions;
