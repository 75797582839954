import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import ProtectedRoutes from "./routesWrapper/ProtectedRoutes";
import store from "./redux/store/store";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import { NoAccess } from "./app-components/NoAccess";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import UnlockAccount from "./pages/UnlockAccount";
import RecoveryPage from "./pages/RecoveryPage/RecoveryPage";

import "flag-icon-css/css/flag-icon.min.css";
import "font-awesome/css/font-awesome.min.css";
import "simple-line-icons/css/simple-line-icons.css";
import "./scss/style.css";
import "./scss/custom.css";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={LoginPage} />
          <Route exact path="/no-access" name="NoAccess" component={NoAccess} />
          <Route
            exact
            path="/auth/password/forgot"
            name="Forgot password Page"
            component={ForgotPassword}
          />
          <Route
            exact
            path="/auth/password/reset"
            name="Reset password Page"
            component={ResetPassword}
          />
          <Route
            exact
            path="/auth/unlock"
            name="Unlock account"
            component={UnlockAccount}
          />
          <Route
            exact
            path="/recovery"
            name="Recovery"
            component={RecoveryPage}
          />
          <ProtectedRoutes />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
