import React, { Component } from "react";
import { Formik } from "formik";
import { get } from "lodash";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import { forgotPassword } from "../../common/api/authorization";
import style from "../ResetPassword/ResetPassword.module.scss";

const validationSchema: any = function(values: any): any {
  return Yup.object().shape({
    email: Yup.string()
      .email()
      .required("Email is required")
  });
};

const validate: any = (getValidationSchema: any): any => {
  return (values: any): any => {
    const validationSchema: any = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError: any = (validationError: any): any => {
  const FIRST_ERROR: any = 0;

  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
};

const initialValues: { email: string } = {
  email: ""
};

interface IStateInterface {
  responseMessage: any;
}

interface IForgotPasswordResult {
  message?: any;
  data: {
    message: string;
    error: { message: string };
  };
  status?: number;
}

class ForgotPassword extends Component<{}, IStateInterface> {
  constructor(props: any) {
    super(props);
    this.state = {
      responseMessage: null
    };
  }

  _onSubmit: any = async (values: { email: string }, { resetForm }: any) => {
    try {
      const result: IForgotPasswordResult = await forgotPassword(values);
      if (result && result.status === 200) {
        this.setState({
          responseMessage: get(result, "data")
        });
      } else {
        this.setState({
          responseMessage: get(result, "error")
        });
      }
      resetForm();
    } catch (e) {
      // console.log(e);
    }
  };

  _renderAlert: Function = () => {
    const { responseMessage }: IStateInterface = this.state;
    if (responseMessage) {
      const {
        details
      }:
        | { details: object; message: string; status: number }
        | boolean
        | any = responseMessage;

      const errorParse = details || responseMessage?.error?.details;
      if (responseMessage?.status === 200) {
        return (
          <Alert color="success">
            {get(responseMessage, "resource") ||
              get(responseMessage, "message")}
          </Alert>
        );
      }

      return (
        <Alert color="warning" className={style.loginAlert}>
          {!details && responseMessage && get(responseMessage, "message") && (
            <p>
              {get(responseMessage, "error.message") ||
                get(responseMessage, "message")}
            </p>
          )}

          {errorParse &&
            Object.keys(errorParse).map((item: any, index: any) => (
              <div key={index}>{`${item}: ${errorParse[item]}`}</div>
            ))}
        </Alert>
      );
    }
  };

  render(): React.ReactNode {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={8} md={6} lg={4}>
              <CardGroup>
                <Card className="p-4 container">
                  <CardBody>
                    {this._renderAlert()}
                    <Formik
                      initialValues={initialValues}
                      validate={validate(validationSchema)}
                      onSubmit={this._onSubmit}
                      render={({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit
                      }: any): any => (
                        <Form onSubmit={handleSubmit} name="simpleForm">
                          <FormGroup className="my-0" style={{ width: "100%" }}>
                            <p className={style.formTitle}>Forgot password?</p>
                            <InputGroup className="mb-4">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-user" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter your email"
                                invalid={!!errors.email}
                                required
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                            </InputGroup>
                            <Button
                              type="submit"
                              color="primary"
                              className="px-4"
                            >
                              Reset Password
                            </Button>
                          </FormGroup>
                        </Form>
                      )}
                    />
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter<any, any>(ForgotPassword);
