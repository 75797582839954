import {
  getRemoteAssistanceSessionActionsBySessionId,
  registerAction,
  updateAction
} from "common/api/remote-assistances";
import { ISingleRemoteAssistanceInterface } from "common/interfaces";
import React, { useState, useContext, createContext, useEffect } from "react";

type HistoryState = {
  historyActions: null | any;
  addHistoryActions: (history: any) => any;
  historyError: string | null;
  updateHistoryActions: () => void;
  updateHistoryupdaActionsPage: (page) => void;
  createNewAction: any;
  updateActionHistory: any;
};

const contextDefaultValues: HistoryState = {
  historyActions: null,
  addHistoryActions: () => {},
  historyError: null,
  updateHistoryActions: () => {},
  updateHistoryupdaActionsPage: page => {},
  createNewAction: null,
  updateActionHistory: null
};

export const HistoryContext = createContext<HistoryState>(contextDefaultValues);

export const HistoryProvider = ({ children, id }) => {
  const [
    historyActions,
    setHistoryActions
  ] = useState<ISingleRemoteAssistanceInterface | null>(null);
  const [historyError, setHistoryError] = useState<null | string>(null);
  const [page, setPage] = useState(1);
  const [updatedHistoryActions, setUpdatedHistoryActions] = useState(true);
  const addHistoryActions = newTodo => setHistoryActions(newTodo);

  useEffect(() => {
    const getHistoryActions = async () => {
      const result = await getRemoteAssistanceSessionActionsBySessionId(
        page,
        id
      );
      if (result?.status === 200) {
        setHistoryActions(result?.data?.resource);
      } else {
        setHistoryError("Error");
      }
    };
    if (updatedHistoryActions || page) {
      getHistoryActions();
      setUpdatedHistoryActions(false);
    }
  }, [id, page, updatedHistoryActions]);

  const createNewAction = async ({ operator_device_id, action, code }) => {
    const result = await registerAction({
      operator_remote_assistance_session_id: id,
      operator_device_id,
      action,
      response_code: code,
      response_data: "Action has been submitted."
    });
    updateHistoryActions();
    return result;
  };

  const updateActionHistory = async (
    actionId: string,
    { code, responseData }
  ) => {
    const result = await updateAction(actionId, {
      response_code: code,
      response_data: responseData
    });

    updateHistoryActions();
    return result;
  };

  const updateHistoryActions = () => setUpdatedHistoryActions(true);
  const updateHistoryupdaActionsPage = pageNum => setPage(pageNum);

  return (
    <HistoryContext.Provider
      value={{
        historyActions,
        historyError,
        addHistoryActions,
        updateHistoryActions,
        updateHistoryupdaActionsPage,
        createNewAction,
        updateActionHistory
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistory = () => {
  return useContext(HistoryContext);
};
