import React from "react";
import { withRouter } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

interface IPropsInterface {
  modalTitle: string;
  isOpen: boolean;
  children: any;
  toggle: any;
}

const ModalComponent = ({
  modalTitle,
  isOpen,
  toggle,
  children
}: IPropsInterface) => {
  return (
    <Modal backdrop="static" isOpen={isOpen} toggle={toggle} keyboard={true}>
      <ModalHeader>{modalTitle}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default withRouter<any, any>(ModalComponent);
