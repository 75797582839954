import axios from "../axiosInstance";
import { CORE_API_DOMAIN } from "../../API_routes";
import { IUpdateRemoteAssistance } from "../interfaces";
import { handleOrderBy } from "../helper/helper";

const apiPrefix: string = `${CORE_API_DOMAIN}/operator-remote-assistance-sessions`;
const actionApiPrefix: string = `${CORE_API_DOMAIN}/operator-remote-assistance-session-actions`;
const deviceApiPrefix: string = `${CORE_API_DOMAIN}/operator-devices`;

export const getRemoteAssistances: any = ({
  current_page,
  orderBy,
  direction,
  id,
  sequential_id,
  operator_device_id,
  service_desk_id,
  created_by,
  created_at,
  mac_address,
  provision_id
}: {
  current_page: number | string;
  orderBy?: string | null;
  direction?: string | null;
  id?: string | null;
  sequential_id?: number | null;
  operator_device_id?: string | null;
  service_desk_id?: string | null;
  created_by?: string | null;
  created_at?: string | null;
  mac_address?: string | null;
  provision_id?: string | number;
}): any => {
  return axios.get(
    `${apiPrefix}?page=${current_page ? current_page : 1}${
      orderBy ? "&order=" + handleOrderBy(orderBy) : "&order=created_at"
    }${direction ? "&direction=" + direction : "&direction=desc"}${
      id ? "&filter[uid]=" + id : ""
    }${mac_address ? "&filter[mac_address]=" + mac_address : ""}${
      sequential_id ? "&filter[sequential_id]=" + sequential_id : ""
    }${
      operator_device_id
        ? "&filter[operator_device_id]=" + operator_device_id
        : ""
    }${service_desk_id ? "&filter[service_desk_id]=" + service_desk_id : ""}${
      provision_id ? "&filter[provision_id]=" + provision_id : ""
    }${created_by ? "&filter[created_by]=" + created_by : ""}${
      created_at ? "&filter[created_at]=" + created_at : ""
    }`.trim()
  );
};
export const getRemoteAssistanceDevices: any = (data: {
  pageNumber: number | string;
  orderBy?: string | null;
  direction?: string | null;
  id?: number | null;
  mac_address?: string | null;
  provision_id?: string | null;
  serial_number?: string | null;
  pairing_code?: string | null;
}): any => {
  return axios.get(
    `${deviceApiPrefix}?page=${data.pageNumber || 1}&filter[status]=1${
      data.orderBy ? "&order=" + data.orderBy : ""
    }${data.direction ? "&direction=" + data.direction : ""}${
      data.id ? "&filter[uid]=" + data.id : ""
    }${data.mac_address ? "&filter[mac_address]=" + data.mac_address : ""}${
      data.serial_number ? "&filter[serial_number]=" + data.serial_number : ""
    }${data.provision_id ? "&filter[provision_id]=" + data.provision_id : ""}${
      data.pairing_code ? "&filter[pairing_code]=" + data.pairing_code : ""
    }`
  );
};

export const getRemoteAssistanceByID: any = (ID: number | string): any =>
  axios.get(`${apiPrefix}/${ID}`);

export const startRemoteAssistanceSession: any = (data: {
  operator_device_id: number;
  service_desk_id?: number;
  authorized?: boolean;
}) => axios.post(`${apiPrefix}`, { ...data });

export const onAction: any = (data, callBack) => {
  axios.post(`${actionApiPrefix}`, { ...data }).then(() => callBack());
};

export const updateAction: any = (id: string, data) => {
  return axios.put(`${actionApiPrefix}/${id}`, {
    ...data
  });
};

export const registerAction: any = data => {
  return axios.post(`${actionApiPrefix}`, {
    ...data
  });
};

export const updateRemoteAssistanceSession: any = (
  id: number | string,
  data: IUpdateRemoteAssistance
): any => axios.put(`${apiPrefix}/${id}`, { ...data });

export const getRemoteAssistanceSessionActionsBySessionId: any = (
  pageNumber: number | string,
  ID: string
): any =>
  axios.get(
    `${actionApiPrefix}?page=${pageNumber}&limit=30&order=id&direction=desc${
      ID ? "&filter[operator_remote_assistance_session_id]=" + ID : ""
    }&direction=desc`.replace(/\s/g, "")
  );

export const postApkFile = (file: any) => {
  let formData = new FormData();
  formData.append("file", file);

  return axios.post(`${CORE_API_DOMAIN}/apk/host`, formData);
};

export const openSocket = ({
  device_id,
  channel_action,
  remote_session_id,
  remote_action,
  remote_action_type
}: {
  device_id: string | null;
  channel_action: number;
  remote_session_id?: string | number | null;
  remote_action?: string | number | null;
  remote_action_type?: string | number | null;
}): Promise<{ status: number; data: { error: { message: string } } }> => {
  return axios.post(`${CORE_API_DOMAIN}/push/socket/open`, {
    device_id,
    channel_action,
    remote_session_id,
    remote_action,
    remote_action_type
  });
};

export const closeSocket = ({
  device_id,
  channel_action,
  remote_session_id,
  remote_action,
  remote_action_type
}: {
  device_id: string | null;
  channel_action: number;
  remote_session_id?: string | number | null;
  remote_action?: string | number | null;
  remote_action_type?: string | number | null;
}) => {
  return axios.post(`${CORE_API_DOMAIN}/push/socket/close`, {
    device_id,
    channel_action,
    remote_session_id,
    remote_action,
    remote_action_type
  });
};
