import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { Container, Spinner } from "reactstrap";

import {
  getOperators,
  deleteOperator,
  getServiceTypes,
  getTypes,
  deleteOperators,
  createOperators
} from "../../common/api/operators";
//import { getStatuses } from '../../common/api/common';
import ModalComponent from "../../app-components/ModalComponent";
import "./OperatorsListPage.scss";
import {
  ICreateOperatorInterface,
  IFilteredOperators,
  IOperatorsInterface,
  ISingleOperatorInterface
} from "../../common/interfaces";
import OperatorsTable from "../../app-components/Tables/OperatorsTable";
import OperatorForm from "../../app-components/Forms/OperatorForm";
import { getCountries } from "../../common/api/countries";
import { showAndHideAlertItem } from "../../common/helper/helper";

interface PropsInterface {
  getOperators(pageNumber?: string | number): void;
  match: any;
  history: any;
}

interface StateInterface {
  showCreateSystemUserForm: boolean;
  operators: IOperatorsInterface | null;
  statuses: Array<{ id: number; name: string }>;
  serviceTypes: Array<{ id: number; name: string }>;
  types: Array<{ id: number; name: string }>;
  filtering: {
    orderBy: string | null;
    direction?: string | null;
  };
  responseError: { details: object; message: string } | boolean | any;
  countries: [];
  responseItemMessage: null | string;
}

class OperatorsListPage extends Component<PropsInterface, StateInterface> {
  private readonly showAndHideAlertItem;
  constructor(props: PropsInterface) {
    super(props);
    this.state = {
      operators: null,
      showCreateSystemUserForm: false,
      statuses: [
        {
          id: 0,
          name: "Inactive"
        },
        {
          id: 1,
          name: "Active"
        }
      ],
      serviceTypes: [],
      types: [],
      filtering: {
        orderBy: null,
        direction: null
      },
      responseError: null,
      countries: [],
      responseItemMessage: null
    };
    this.showAndHideAlertItem = showAndHideAlertItem.bind(this);
  }

  static propTypes = {
    match: PropTypes.object
  };

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      filtering: { orderBy, direction }
    }: StateInterface = this.state;
    const {
      match: {
        params: { pageNumber }
      }
    }: PropsInterface = this.props;
    const {
      match: {
        params: { pageNumber: nextPageNumber }
      }
    }: PropsInterface = nextProps;

    if (nextPageNumber && pageNumber !== nextPageNumber) {
      await this._getOperators(nextPageNumber, 20, orderBy, direction);
    }
  }

  async componentDidMount() {
    const {
      match: {
        params: { pageNumber }
      },
      history: { location }
    }: PropsInterface = this.props;

    // const statuses = await getStatuses('Operator');
    // if (get(statuses, 'data.resource.data')) {
    //   this.setState({ statuses: get(statuses, 'data.resource.data') });
    // }

    if (get(location, "state.itemDeleted")) {
      this.showAndHideAlertItem(this.props);
    }

    const serviceTypes = await getServiceTypes();
    if (get(serviceTypes, "data.resource")) {
      this.setState({ serviceTypes: get(serviceTypes, "data.resource") });
    }

    const types = await getTypes();
    if (get(types, "data.resource")) {
      this.setState({ types: get(types, "data.resource") });
    }

    await this._getOperators(pageNumber);

    const countryResult = await getCountries();

    this.setState({
      countries: get(countryResult, "data.resource")
        ? get(countryResult, "data.resource")
        : []
    });
  }

  _getOperators = async (
    pageNumber: number | string = 1,
    limit?: number,
    orderBy?: string | null,
    direction?: string | null
  ): Promise<void> => {
    try {
      const result: {
        data: { resource: any };
        status: number;
      } = await getOperators(pageNumber, limit, orderBy, direction);
      if (result && get(result, "data.resource")) {
        this.setState({ operators: get(result, "data.resource") });
      }
    } catch (e) {}
  };

  _onFilter = (data: { orderBy: string; direction: string }): void => {
    const {
      match: {
        params: { pageNumber }
      }
    } = this.props;

    if (data) {
      const { orderBy, direction } = data;
      if (orderBy) {
        this.setState(
          {
            filtering: {
              orderBy,
              direction: direction || null
            }
          },
          () => {
            const {
              filtering: { orderBy, direction }
            } = this.state;
            if (orderBy === "City" || orderBy === "Country") {
              const orderWithId = orderBy + "_id";
              this._getOperators(pageNumber, undefined, orderWithId, direction);
            } else {
              this._getOperators(pageNumber, undefined, orderBy, direction);
            }
          }
        );
      }
    }
  };

  _onDeleteOperator = async (id): Promise<boolean> => {
    const { operators } = this.state;
    const result = await deleteOperator(id);

    if (result && result.status === 204) {
      this.showAndHideAlertItem(this.props);
      if (operators) {
        this.setState({
          operators: {
            ...operators,
            data: [
              ...operators.data.filter(
                (operator: ISingleOperatorInterface) => operator.id !== id
              )
            ]
          }
        });
      }

      return true;
    } else {
      this.setState({
        responseError:
          get(result, "data.error.message") ||
          get(result, "response.data.message") ||
          get(result, "data.message") ||
          get(result, "message")
      });

      return false;
    }
  };

  _onDeleteOperators = async (ids): Promise<boolean> => {
    const { operators } = this.state;
    const result = await deleteOperators(ids);

    if (result && result.status === 204) {
      if (operators) {
        this.setState({
          operators: {
            ...operators,
            data: [
              ...operators.data.filter(
                (operator: ISingleOperatorInterface) =>
                  !ids.includes(operator.id)
              )
            ]
          }
        });
      }
      this.showAndHideAlertItem(this.props);

      return true;
    } else {
      this.setState({
        responseError:
          get(result, "data.error.message") ||
          get(result, "response.data.message") ||
          get(result, "data.message") ||
          get(result, "message")
      });

      return false;
    }
  };

  _createNewOperator = async (
    data: ICreateOperatorInterface
  ): Promise<undefined | object | unknown> => {
    const {
      match: {
        params: { pageNumber }
      }
    } = this.props;

    try {
      const result = await createOperators(data);

      if (result && result.status === 201) {
        await this._getOperators(pageNumber);

        this.setState({
          showCreateSystemUserForm: false
        });
      }

      return result;
    } catch (er) {
      return er;
    }
  };

  _onShowForm = (): void => {
    this.setState(
      state => ({
        showCreateSystemUserForm: !state.showCreateSystemUserForm
      }),
      () => {
        const { showCreateSystemUserForm } = this.state;
        if (showCreateSystemUserForm) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  _getFilteredOperators = async (data: IFilteredOperators): Promise<void> => {
    try {
      const result = await getOperators(
        data.current_page,
        data.limit,
        data.orderBy,
        data.direction,
        data.type_id,
        data.name,
        data.email,
        data.country_id,
        data.city_id,
        data.status
      );

      if (result && result.status === 200 && get(result, "data.resource")) {
        this.setState({ operators: get(result, "data.resource") });
      }
    } catch (e) {
      //console.log(e);
    }
  };

  _toggleErrorResponse = (): void => {
    this.setState({
      responseError: null
    });
  };

  render(): JSX.Element {
    const {
      showCreateSystemUserForm,
      operators,
      filtering,
      statuses,
      types,
      serviceTypes,
      responseError,
      countries,
      responseItemMessage
    }: StateInterface = this.state;

    if (!operators) {
      return (
        <div className="d-flex justify-content-center col-12 mt-5 in">
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <main className="users-table-wrapper in main">
        <Container fluid>
          <OperatorsTable
            operators={operators}
            filtering={filtering}
            statuses={statuses}
            onShowForm={this._onShowForm}
            onDeleteOperator={this._onDeleteOperator}
            onDeleteOperators={this._onDeleteOperators}
            onFilter={this._onFilter}
            types={types}
            filteredOperators={this._getFilteredOperators}
            responseError={responseError}
            toggleErrorResponse={this._toggleErrorResponse}
            countries={countries}
            responseItemMessage={responseItemMessage}
          />
          <ModalComponent
            modalTitle="Create Operator"
            toggle={this._onShowForm}
            isOpen={showCreateSystemUserForm}
          >
            <OperatorForm
              title="Create Operator"
              statuses={statuses}
              types={types}
              serviceTypes={serviceTypes}
              onShowForm={this._onShowForm}
              createNewOperator={this._createNewOperator}
              countries={countries}
            />
          </ModalComponent>
        </Container>
      </main>
    );
  }
}

export default withRouter<any, any>(OperatorsListPage);
