import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import {
  Alert,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row
} from "reactstrap";
import { unlockAccount } from "../../common/api/authorization";
import style from "./UnlockAccount.module.scss";

interface IPropsInterface {
  history: { push (data: any): void };
  location: { search: any };
}

interface IStateInterface {
  hasResponse: boolean | String | null;
}

class UnlockAccount extends Component<IPropsInterface, IStateInterface> {
  constructor (props: IPropsInterface) {
    super(props);
    this.state = {
      hasResponse: null
    };
  }

  async componentDidMount (): Promise<void> {
    const queryString: string = this.props.location.search;
    const index: number = queryString.indexOf("=");
    const token: string = queryString.substring(index + 1);
    const result: { status: number } | Error | void = await unlockAccount(
      token
    );

    if (result) {
      this.setState({
        hasResponse: get(result, "data.message")
      });
    } else {
      this.setState({
        hasResponse: false
      });
    }
  }

  _renderAlert: Function = () => {
    const { hasResponse } = this.state;

    if (hasResponse) {
      return (
        <Alert color="warning">
          {hasResponse}
          <br />
          {"You will be redirected to login page in a few seconds"}
        </Alert>
      );
    } else if (hasResponse === false) {
      return (
        <Alert color="danger">Request failed! Please contact support.</Alert>
      );
    } else {
      return <Alert color="danger">Please provide valid token.</Alert>;
    }
  }

  _OnRedirectToLogin: any = () => {
    const { hasResponse } = this.state;

    if (hasResponse) {
      setTimeout(() => {
        this.props.history.push(`/login`);
      }, 5000);
    }
  }

  render (): JSX.Element {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={8} md={6} lg={4}>
              <CardGroup>
                <Card className="p-4">
                  <Row>
                    <Col xs="12">
                      <p className={style.unlockTitle}>Unlock Account</p>
                    </Col>
                  </Row>
                  <CardBody>
                    {this._renderAlert()}
                    {this._OnRedirectToLogin()}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter<any, any>(UnlockAccount);
