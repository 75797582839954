import React from "react";
type Props = { icon: string; value: string };

const Title = (props: Props) => {
  const { icon, value } = props;

  return (
    <div className="card-title col-12">
      <h1>
        <i className={`material-icons ${icon}`} /> {value}
      </h1>
    </div>
  );
};

export default Title;
