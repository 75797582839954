import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Alert,
  Spinner
} from "reactstrap";

interface IPropsInterface {
  appsLength?: boolean | null;
  modalTitle: string;
  isOpen: boolean;
  id: number | string;
  selectedRows?: Array<number>;
  onDelete: Function;
  onMultipleDelete?: Function;
  responseError?: any;
  onToggle(): void;
  toggleResponseError(): void;
  resetIds(): void;
  installedApps: number;
  setShowDeleteModal?: any;
}

class DeleteModal extends Component<IPropsInterface, any> {
  constructor(props: IPropsInterface) {
    super(props);
    this.state = {
      loading: false
    };
  }

  _renderAlert = responseError => {
    return (
      <Alert color="danger">
        {responseError}
        <br />
      </Alert>
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.id && prevProps.id !== this.props.id) {
      this.setState({ loading: false });
    }
    if (
      this.props.installedApps &&
      prevProps.installedApps !== this.props.installedApps
    ) {
      this.props.setShowDeleteModal();
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.setState({ loading: false });
  }

  _onDelete: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = (): void | JSX.Element => {
    const {
      id,
      selectedRows,
      onDelete,
      onMultipleDelete,
      onToggle,
      resetIds
    }: IPropsInterface = this.props;
    this.setState({ loading: true });

    if (id) {
      try {
        onDelete(id).then(resp => {
          if (resp === true) {
            onToggle();
            resetIds();
            this.setState({ loading: false });
          }
        });
      } catch (e) {
        // console.info(e);
      }
    } else if (onMultipleDelete && selectedRows && selectedRows.length > 0) {
      try {
        onMultipleDelete(selectedRows.map((item: any) => item)).then(resp => {
          if (resp === true) {
            onToggle();
            resetIds();
            this.setState({ loading: false });
          }
        });
      } catch (e) {
        // console.info(e);
      }
    }
  };

  _onDeleteApp: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = (): void | JSX.Element => {
    const { id, onDelete }: IPropsInterface = this.props;

    this.setState({ loading: true });

    if (id) {
      try {
        onDelete(id);
        //onToggle();
        //this.setState({ loading: false });
      } catch (e) {
        // console.info(e);
      }
    }
  };

  render(): React.ReactNode {
    const {
      modalTitle,
      isOpen,
      onToggle,
      toggleResponseError,
      responseError,
      id,
      selectedRows
    }: IPropsInterface = this.props;
    const { loading } = this.state;

    return (
      <Modal
        isOpen={id || (selectedRows && selectedRows.length) ? isOpen : false}
        toggle={onToggle}
        backdrop="static"
        className={"modal-danger"}
        unmountOnClose={true}
      >
        <ModalHeader>{modalTitle}</ModalHeader>
        {!loading || responseError ? (
          <>
            <ModalBody style={{ wordWrap: "break-word" }}>
              {responseError ? this._renderAlert(responseError) : ""}
              {this.props.children}
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={event => {
                  if (modalTitle === "Delete APP") {
                    this._onDeleteApp(event);
                  } else {
                    this._onDelete(event);
                  }
                }}
              >
                {modalTitle === "Delete APP" ? "Unistall" : "Delete"}
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  onToggle();
                  if (toggleResponseError) {
                    toggleResponseError();
                    this.setState({ loading: false });
                  }
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </>
        ) : (
          <div className="d-flex justify-content-center m-3 in">
            <Spinner color="primary" />
          </div>
        )}
      </Modal>
    );
  }
}

export default withRouter<any, any>(DeleteModal);
