import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  Spinner
} from "reactstrap";
import classNames from "classnames";
import style from "./TabsDeviceProfile.module.scss";
import DetailsWithImage from "../../../app-components/DetailsWithImage";
import DeviceProfileCofig from "../DeviceProfileCofig";
import DeviceProfileRoms from "../DeviceProfileRoms";
import DeviceProfileApps from "../DeviceProfileApps";
import DeviceProfileAppsConfig from "../DeviceProfileAppsConfig";
import DeviceProfileAppsPermissions from "../DeviceProfileAppsPermissions";

import { ISingleDeviceTypesInterface } from "common/interfaces";
import { ButtonToForm, renderStatuses, timeAgo } from "common/helper/helper";

interface IPropsInterface {
  loading: boolean;
  details: any;
  onShowForm: any;
  toggleDeleteModal: any;
}

const TabsDeviceProfile = ({
  loading,
  details,
  onShowForm,
  toggleDeleteModal
}: IPropsInterface) => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const _renderDataDetails: any = (
    deviceProfile: ISingleDeviceTypesInterface
  ) => {
    const {
      manufacturer,
      model,
      name,
      status,
      playback_support,
      profile_picture,
      created_by,
      updated_by,
      created_at,
      integration_uuid
    } = deviceProfile;

    const objToRender = [
      {
        name: "Picture",
        value: profile_picture
      },
      {
        name: "Status",
        value: renderStatuses(status)
      },
      {
        name: "Manufacturer",
        value: manufacturer
      },
      {
        name: "Model",
        value: model
      },
      {
        name: "Name",
        value: name
      },
      {
        name: "Device Type",
        value: playback_support?.name
      },

      {
        name: "Created By",
        value: created_by?.name
      },
      {
        name: "Updated By",
        value: updated_by?.name
      },

      {
        name: "Created At",
        value: timeAgo(created_at)
      },
      {
        name: "Integration Uuid",
        value: integration_uuid
      }
    ];

    return objToRender;
  };

  const _renderBottomButtons: any = () => {
    return (
      <div className={style.buttonsWrapper}>
        <ButtonToForm click={onShowForm} value="Edit" />
        <ButtonToForm color="danger" click={toggleDeleteModal} value="Delete" />
      </div>
    );
  };

  return (
    <Col xs="12" md="12" className="mt-1">
      {_renderBottomButtons()}
      <DetailsWithImage data={_renderDataDetails(details)} />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames(style.link, {
              active: activeTab === "1"
            })}
            onClick={() => {
              toggle("1");
            }}
          >
            <span className={activeTab === "1" ? style.active : ""}>
              App Installs & Updates
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames(style.link, {
              active: activeTab === "2"
            })}
            onClick={() => {
              toggle("2");
            }}
          >
            <span className={activeTab === "2" ? style.active : ""}>
              App Permissions
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames(style.link, {
              active: activeTab === "3"
            })}
            onClick={() => {
              toggle("3");
            }}
          >
            <span className={activeTab === "3" ? style.active : ""}>
              App Configurations{" "}
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames(style.link, {
              active: activeTab === "4"
            })}
            onClick={() => {
              toggle("4");
            }}
          >
            <span className={activeTab === "4" ? style.active : ""}>Roms</span>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === "5"
            })}
            onClick={() => {
              toggle("5");
            }}
          >
            <span className={activeTab === "5" ? style.active : ""}>
              Configurations
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className={style.TabContent}>
        {loading ? (
          <div className={style.progressCircle}>
            <Spinner color="primary" />
          </div>
        ) : (
          <>
            <TabPane tabId="1" className={style.TabPane}>
              <DeviceProfileApps />
            </TabPane>
            <TabPane tabId="2" className={style.TabPane}>
              <DeviceProfileAppsPermissions />
            </TabPane>
            <TabPane tabId="3" className={style.TabPane}>
              <DeviceProfileAppsConfig />
            </TabPane>
            <TabPane tabId="4" className={style.TabPane}>
              <DeviceProfileRoms />
            </TabPane>
            <TabPane tabId="5" className={style.TabPane}>
              <DeviceProfileCofig />
            </TabPane>
          </>
        )}
      </TabContent>
      <div className="d-flex mb-3 float-right mt-3">
        <strong className="mr-1">Last Updated Time:</strong>
        <span>
          <span>{timeAgo(details.updated_at)}</span>
        </span>
      </div>
    </Col>
  );
};

export default TabsDeviceProfile;
