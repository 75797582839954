import axios from "../axiosInstance";
import { CORE_API_DOMAIN } from "../../API_routes";
import { ICreateDeviceInterface } from "../interfaces";
import { get } from "lodash";

const apiPrefix: string = `${CORE_API_DOMAIN}/operator-devices`;
const apiPrefixStatus: string = `${CORE_API_DOMAIN}/query/status`;

export const getDevices: any = ({
  current_page,
  orderBy,
  direction,
  id,
  mac_address,
  provision_id,
  deviceTypeIds,
  romIds,
  created_at,
  updated_at,
  limit,
  name,
  uid,
  serial_number,
  status,
  integration_uuid,
  pairing_code,
  release_cycle
}): any => {
  const url = `${apiPrefix}${limit ? "?limit=" + limit : "?limit=30"}${
    current_page ? "&page=" + current_page : "&page=1"
  }${orderBy ? "&order=" + orderBy : ""}${
    direction ? "&direction=" + direction : ""
  }${id ? "&filter[id]=" + id : ""}${uid ? "&filter[uid]=" + uid : ""}${
    serial_number ? "&filter[serial_number]=" + serial_number : ""
  }${mac_address ? "&filter[mac_address]=" + mac_address : ""}${
    status ? "&filter[status]=" + status : ""
  }${provision_id ? "&filter[provision_id]=" + provision_id : ""}${
    name ? "&filter[name]=" + name : ""
  }${deviceTypeIds ? "&filter[operator_device_type_id]=" + deviceTypeIds : ""}${
    integration_uuid ? "&filter[integration_uuid]=" + integration_uuid : ""
  }${pairing_code ? "&filter[pairing_code]=" + pairing_code : ""}${
    release_cycle ? "&filter[release_cycle]=" + release_cycle : ""
  }${romIds ? "&filter[operator_rom_id]=" + romIds : ""}${
    created_at ? "&filter[created_at]=" + created_at : ""
  }${updated_at ? "&filter[updated_at]=" + updated_at : ""}`;
  return axios.get(url).then(resp => {
    if (get(resp, "data.resource")) {
      return get(resp, "data.resource");
      //console.log("resp.data.resource", resp.data.resource);
    }
  });
};

export const getDevicesByID: any = (ID: number | string): any =>
  axios.get(`${apiPrefix}/${ID}`);

export const getDeviceStatus: any = (mac_address: number | string): any =>
  axios.get(
    `${apiPrefixStatus}?order=updated_at&direction=DESC&list[]=&mac_address_values=${mac_address}`
  );

export const getDeviceStatusV2: any = (id: number | string): any =>
  axios.get(`${apiPrefix}/status/${id}`);

export const createDevice: any = (data: ICreateDeviceInterface): any =>
  axios.post(`${apiPrefix}`, { ...data });

export const deleteDevice: any = (id: number | string): any =>
  axios.delete(`${apiPrefix}/${id}`);

export const deleteDevices: any = (ids: Array<number | string>): any => {
  return axios.delete(
    `${apiPrefix}?${ids
      .map((item: number | string, index: number) => `ids[${index}]=${item}`)
      .join("&")}`
  );
};

export const updateDevice: any = (
  id: number | string,
  data: ICreateDeviceInterface
): any => axios.put(`${apiPrefix}/${id}`, { ...data });

export const uploadDevices: any = (
  csvFile: string | Blob,
  deviceTypeId: any
): any => {
  const formData: any = new FormData();

  const {
    operator_device_type_id_import,
    operator_device_release_cycle,
    isLock
  } = deviceTypeId;

  formData.append("devices", csvFile);
  formData.set("device_type_id", operator_device_type_id_import);
  formData.set("release_cycle", operator_device_release_cycle);
  formData.set("skip_first_row", "true");
  formData.set("lock", isLock ? "1" : "0");

  return axios.post(`${apiPrefix}/import`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};
