import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Alert,
  Card,
  CardHeader,
  CardBody,
  Row,
  CardFooter,
  Form,
  Container,
  Spinner
} from "reactstrap";
import { Formik } from "formik";
import style from "./AdminConfigPage.module.scss";
import { Switch } from "antd";
import { get } from "lodash";
import {
  updateAdminConfig,
  getAdminConfig,
  getHandshakeValidationOptions
} from "../../common/api/admin-config";
import { Handshake } from "./components";
import { RemoteAssistance } from "./components";
import { getDefaultRoles } from "../../common/api/common";

const AdminConfigPage = () => {
  let timeout: any = null;
  const [adminConfig, setAdminConfig] = useState<any>(null);
  const [handshakeValidationOptions, setHandshakeValidationOptions] = useState<
    any
  >(null);
  const [roles, setRoles] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingRAKey, setLoadingRAKey] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<any>(null);
  const [responseSuccess, setResponseSuccess] = useState<string>("");
  useEffect(() => {
    const fetchData = async () => {
      const data = await getAdminConfig();
      if (data) {
        setAdminConfig({ ...data?.data?.data });
      }

      const roles: {
        data: { resource: { data: string } };
      } = await getDefaultRoles();

      if (get(roles, "data.resource")) {
        setRoles(get(roles, "data.resource"));
      }

      const validationOptions = await getHandshakeValidationOptions();
      if (validationOptions) {
        setHandshakeValidationOptions({ ...validationOptions });
      }
    };
    fetchData();
  }, [responseSuccess]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [timeout]);

  const generateOptions = () => {
    return roles.map((item, key) => (
      <option value={item.id} key={item.id}>
        {item.name}
      </option>
    ));
  };

  const onReset = async values => {
    setLoadingRAKey(true);
    const result = await updateAdminConfig({
      ...adminConfig,
      remoteAssistance: {
        secretKey: ""
      }
    });
    if (result?.statusText === "Created") {
      setResponseSuccess("Secret key has been reset!");
      timeout = setTimeout(() => {
        setResponseSuccess("");
      }, 4000);
    } else {
      setResponseError(result?.response?.data?.error || result?.data?.message);
    }

    timeout = setTimeout(() => {
      setLoadingRAKey(false);
    }, 1000);
  };

  const _renderAlert: Function = () => {
    if (responseSuccess || responseError) {
      return (
        <Alert
          in={responseSuccess || responseError}
          color={responseError ? "danger" : "success"}
          tag="h5"
          className="mt-3"
        >
          {responseSuccess || responseError}
        </Alert>
      );
    }
  };

  const _renderButton: Function = () => {
    return (
      <Button
        type="submit"
        size="lg mr-2"
        color="primary"
        className={style.addButton}
      >
        <i className="material-icons">save</i>Save All
      </Button>
    );
  };

  const _renderSwitchLabel = ({ key, keyChild, valueKeyChild }) => {
    return (
      <div className={style.labelSwitchWrap}>
        <Col xs="9">
          <Label className={style.labelSwitch} htmlFor={keyChild}>
            {parseKeyTitle(keyChild, key)}
          </Label>
        </Col>
        <Col xs="3">
          <Switch
            style={{ float: "right" }}
            size="small"
            defaultChecked={valueKeyChild}
            disabled={
              typeof adminConfig[key]?.status === "boolean" &&
              !adminConfig[key]?.status &&
              keyChild !== "status"
            }
            onChange={(checked: boolean) => {
              setAdminConfig({
                ...adminConfig,
                [key]: {
                  ...adminConfig[key],
                  [keyChild]: checked
                }
              });
            }}
          />
        </Col>
      </div>
    );
  };

  const _renderInputTextLabel = ({
    key,
    keyChild,
    valueKeyChild,
    index,
    handleChange
  }) => {
    return (
      <>
        <Label className={style.labelSwitch} htmlFor={keyChild}>
          {parseKeyTitle(keyChild, key)}
        </Label>
        {keyChild === "role" && (
          <Input
            type="select"
            id={valueKeyChild + index}
            name={keyChild}
            required
            value={valueKeyChild}
            onChange={e => {
              setAdminConfig({
                ...adminConfig,
                [key]: {
                  ...adminConfig[key],
                  [keyChild]: e.target.value
                }
              });
            }}
          >
            {generateOptions()}
          </Input>
        )}
        {keyChild !== "role" &&
          _renderWrapInput({
            key,
            keyChild,
            valueKeyChild,
            index,
            handleChange
          })}
      </>
    );
  };

  const _renderWrapInput = ({
    key,
    keyChild,
    valueKeyChild,
    index,
    handleChange
  }) => {
    return (
      <>
        <Input
          type={validadeTypeInput(keyChild)}
          id={valueKeyChild + index}
          name={keyChild}
          defaultValue={valueKeyChild}
          placeholder={`Enter ${parseKeyTitle(keyChild)}`}
          readOnly={
            typeof adminConfig[key]?.status === "boolean" &&
            !adminConfig[key]?.status
          }
          onChange={e => {
            setAdminConfig({
              ...adminConfig,
              [key]: {
                ...adminConfig[key],
                [keyChild]: e.target.value
              }
            });
          }}
        />
        {keyChild === "logo" && valueKeyChild && (
          <img src={valueKeyChild} className={style.logo} alt="Logo" />
        )}
      </>
    );
  };

  const _renderBox = ({ side, handleChange }) => {
    if (adminConfig) {
      return Object.entries(adminConfig)
        .map(([key, keyValue], index) => {
          return (
            <div className={style.contentWrapper} key={key + index}>
              <Card>
                <CardHeader>
                  <h3 className={style.title}>
                    <i className="material-icons ">{parseKeyLogo(key)}</i>
                    <strong>{parseKeyTitle(key)}</strong>
                  </h3>
                </CardHeader>
                {key === "handshake" && (
                  <Handshake
                    values={adminConfig?.handshake}
                    validationOptions={handshakeValidationOptions?.data || []}
                    handleChange={newValue =>
                      setAdminConfig({
                        ...adminConfig,
                        [key]: {
                          ...adminConfig[key],

                          ...newValue
                        }
                      })
                    }
                  />
                )}

                {key === "remoteAssistance" && (
                  <RemoteAssistance
                    values={adminConfig?.remoteAssistance}
                    onReset={onReset}
                    loading={loadingRAKey}
                    handleChange={newValue =>
                      setAdminConfig({
                        ...adminConfig,
                        [key]: {
                          ...adminConfig[key],

                          ...newValue
                        }
                      })
                    }
                  />
                )}

                {key !== "handshake" && key !== "remoteAssistance" && (
                  <CardBody>
                    {keyValue
                      ? Object
                          //@ts-ignore
                          .entries(keyValue)
                          .map(([keyChild, valueKeyChild], indexChild) => {
                            return (
                              <FormGroup key={key + keyChild + index} row>
                                {typeof valueKeyChild === "boolean"
                                  ? _renderSwitchLabel({
                                      key,
                                      keyChild,
                                      valueKeyChild
                                    })
                                  : _renderInputTextLabel({
                                      key,
                                      keyChild,
                                      valueKeyChild,
                                      index,
                                      handleChange
                                    })}
                              </FormGroup>
                            );
                          })
                      : null}
                  </CardBody>
                )}
              </Card>
            </div>
          );
        })
        .filter((item, indexFilter) => side(indexFilter));
    }
  };

  const isNumber = key => {
    const arr = ["interval", "updateInterval", "specialInterval"];
    return arr.some(i => i === key);
  };

  const isEmail = key => {
    const email = ["to", "cc", "bcc"];
    return email[key]?.length > 0 || key.toLowerCase().indexOf("email") > -1
      ? true
      : false;
  };

  const validadeTypeInput = key => {
    if (isNumber(key)) {
      return "number";
    } else if (key.toLowerCase().indexOf("url") > -1) {
      return "url";
    } else if (
      key === "keys" ||
      key === "adminConfig" ||
      key === "androidConfig"
    ) {
      return "textarea";
    } else {
      return isEmail(key) ? "email" : "text";
    }
  };

  const parseKeyTitle = (key, keyMaster = "") =>
    parseLabelsForOtherStrings(key, keyMaster) ||
    key.replace(/([a-z0-9])([A-Z])/g, "$1 $2");

  const parseKeyLogo = key => {
    const newStrings = {
      details: "speaker_notes",
      disconnect: "phonelink_off",
      lock: "lock",
      analytics: "bug_report",
      pushNotifications: "add_alert",
      alarms: "alarm",
      publicApi: "public",
      authApi: "fingerprint",
      speedTest: "shutter_speed",
      handshake: "compare_arrows",
      operatorDefaults: "perm_data_setting",
      remoteAssistance: "desktop_windows",
      googleService: "android"
    };
    return newStrings[key] || "device_unknown";
  };

  const parseLabelsForOtherStrings = (key, keyMaster) => {
    const keyToShow = key && keyMaster === "" ? "keyMaster" + key : key;
    const newStrings = {
      to: "Report email to",
      cc: "Report email cc",
      bcc: "Report email bcc",
      logo: "Icon",
      details: "Operator details",
      graceperiod: "Graceperiod (in days)",
      timeoutInactive: "Timeout Inactive (in minutes)",
      checkInterval: "Check Interval (in minutes)",
      interval: "Interval (in hours)",
      authApi: "External Authentication Api",
      lock: "Lock devices outside gracefull period",
      tcUrl: "Terms and conditions Url",
      excludeInactive: "Exclude inactive and unprovisioned devices from lock",
      status: "Enable",
      reportUI: "Report Support App UI",
      role: "Default role on user creation",
      adminConfig: "Firebase Admin SDK JSON"
    };
    return newStrings[keyToShow];
  };

  const onSubmit = async values => {
    clearTimeout(timeout);
    setLoading(true);
    const result = await updateAdminConfig({
      ...adminConfig
    });

    if (result?.statusText === "Created") {
      setResponseSuccess("Configurations updated!");
      timeout = setTimeout(() => {
        setResponseSuccess("");
      }, 4000);
    } else {
      setResponseError(
        result?.response?.data?.error || result?.data?.message || "Error"
      );
      timeout = setTimeout(() => {
        setResponseError("");
      }, 6000);
    }

    timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const _loading = () => {
    return (
      <div className="d-flex justify-content-center m-5 in">
        <Spinner color="primary" />
      </div>
    );
  };

  const _wrapForm = () => {
    return (
      <div>
        <Formik
          initialValues={adminConfig}
          enableReinitialize={true}
          onSubmit={onSubmit}
          render={({ handleChange }) => (
            <Form onSubmit={onSubmit}>
              <CardHeader className={style.floatButtons}>
                {localStorage.getItem("acr") !== "OPR_VIEWER" &&
                  _renderButton()}
              </CardHeader>
              {_renderAlert()}
              <Row className="p-3">
                <Col md="6">
                  {_renderBox({
                    side: num => num % 2 === 0,
                    handleChange
                  })}
                </Col>
                <Col md="6">
                  {_renderBox({
                    side: num => num % 2 !== 0,
                    handleChange
                  })}
                </Col>
              </Row>
              <CardFooter className={style.floatButtons}>
                {localStorage.getItem("acr") !== "OPR_VIEWER" &&
                  _renderButton()}
              </CardFooter>
            </Form>
          )}
        />
      </div>
    );
  };

  return (
    <main className={"main admin-details-page-wrapper in"}>
      <Container fluid>
        <Row>
          <div className="card-title col-12">
            <h1>
              <i className="material-icons settings" />
              Configurations
            </h1>
          </div>
        </Row>
        <Card>{adminConfig && !loading ? _wrapForm() : _loading()}</Card>
      </Container>
    </main>
  );
};

export default AdminConfigPage;
